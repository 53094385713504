import { DeleteOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { ParsedQuery } from 'query-string';
import { cloneDeep } from 'lodash';
import { Filters, Part } from '../../../../api/API';
import { KeyValue, PartFilterFields, OptionShape } from '../../../../types';

import PartsFilterItem from './PartsFilterItem';
import styles from './PartsFilterTreeView.module.scss';
import { getSelectedValue, isMultipleFilter } from '~/utils/filter';

type PartsFilterTreeViewProps = {
  selectedFilters: PartFilterFields[];
  selectedFiltersData: ParsedQuery;
  partFiltersData: KeyValue<KeyValue<number>>;
  onFilterValueSelection: (
    partFilter: PartFilterFields,
    value: string | null,
    rootKey?: string,
  ) => void;
  part: Part;
  rootKey?: string;
};

const PartsFilterTreeView = ({
  selectedFilters,
  selectedFiltersData,
  partFiltersData,
  part,
  onFilterValueSelection,
  rootKey,
}: PartsFilterTreeViewProps) => {
  const getFilterOptions = (filter: PartFilterFields): OptionShape[] => {
    const key = filter.key === 't' ? 'Type' : filter.key; //special case

    if (!partFiltersData[key]) return [];

    return Object.keys(partFiltersData[key]).map((key) => ({
      text: key,
      value: key,
    }));
  };

  const handleRemoveFilter = (filter: PartFilterFields, rootKey?: string) => {
    return onFilterValueSelection(filter, null, rootKey);
  };

  const handleFilterInputChange = (value: string, filter: PartFilterFields) => {
    return onFilterValueSelection(filter, value, rootKey);
  };

  const getFilterType = (filters: Filters, filter: PartFilterFields): string => {
    if (isMultipleFilter(filter)) {
      if (filters.multiple && filters.multiple.includes(filter)) {
        return 'multiple';
      }
    } else {
      if (filters && filters.select && filters.select.includes(filter)) {
        return 'select';
      } else if (filters && filters.numeric && filters.numeric.includes(filter)) {
        return 'numeric';
      }
    }

    return 'unknown';
  };

  const extractFiltersData = (data: PartFilterFields) => {
    const filtersData: PartFilterFields[] = [];

    if (!isMultipleFilter(data)) return filtersData;

    // Iterate over each filter type and collect filter objects
    for (const filterType of Object.values(data.filters)) {
      if (filterType) {
        filtersData.push(...filterType);
      }
    }

    return filtersData;
  };

  const collectFilterData = (data: PartFilterFields): Filters => {
    const filterData: Filters = {
      select: [],
      numeric: [],
      multiple: [],
    };

    if (!isMultipleFilter(data)) {
      return filterData;
    }

    const filters = data.filters;

    Object.entries(filters).forEach(([key, filterArray]) => {
      if (filterArray) {
        filterData[key as keyof Filters]?.push(...filterArray);
      }
    });

    return filterData;
  };

  const renderMultiple = (filter: PartFilterFields) => {
    const filtersData = extractFiltersData(filter);
    const newPart = cloneDeep(part);
    newPart.filters = collectFilterData(filter);

    return (
      <PartsFilterTreeView
        selectedFilters={filtersData}
        selectedFiltersData={selectedFiltersData}
        partFiltersData={partFiltersData}
        part={newPart}
        onFilterValueSelection={onFilterValueSelection}
        rootKey={filter.key}
      />
    );
  };

  const buildView = (filter: PartFilterFields) => {
    if (typeof filter === 'undefined') {
      return null;
    }

    const options = getFilterOptions(filter);
    const selectedValue = String(getSelectedValue(filter, selectedFiltersData, rootKey));

    const filterType = part.filters && getFilterType(part.filters, filter);
    const isMultiple = filterType === 'multiple';

    return (
      <>
        <div id="parts-filter-tree-view-row" key={filter.key} className={styles.filterTreeRow}>
          <div id="parts-filter-tree-view-filter-label" className={styles.filterLabel}>
            {isMultipleFilter(filter) ? filter.name : filter.display_name}
          </div>
          <PartsFilterItem
            filter={filter}
            filterType={filterType}
            handleFilterInputChange={handleFilterInputChange}
            options={options}
            selectedValue={selectedValue}
          />
          {!rootKey && (
            <Button
              id="parts-filter-tree-view-delete"
              icon={<DeleteOutlined id="parts-filter-tree-view-delete-icon" />}
              onClick={() => {
                handleRemoveFilter(filter, isMultiple ? filter.key : undefined);
              }}
              className={styles.deleteButton}
            />
          )}
        </div>
        {isMultiple && renderMultiple(filter)}
      </>
    );
  };

  return (
    <div>
      <div id="parts-filter-tree-view-filter-tree" className={styles.filterTree}>
        {selectedFilters &&
          selectedFilters.map((filter) => {
            return buildView(filter);
          })}
      </div>
    </div>
  );
};

export default PartsFilterTreeView;
