import { JSONSchema7 } from 'json-schema';
import { useState } from 'react';
import { message, Select } from 'antd';
import { connect } from 'react-redux';

import styles from './TemplateSettings.module.scss';

import { FormSchema } from '~/components/FormSchema/FormSchema';
import { PaperSizeData } from '~/utils/paperSize';
import { AppState } from '~/store/reducers';
import { putUpdateTemplateSettings } from '~/api/AuthorizedPuts';
import { wizardUpdatePaperSizeData } from '~/store/actions/wizard/Action';

const requiredProperties: JSONSchema7 = {
  required: ['width', 'height', 'x', 'y'],
  properties: {
    width: {
      type: 'number',
    },
    height: {
      type: 'number',
    },
    x: {
      type: 'number',
    },
    y: {
      type: 'number',
    },
  },
};

const schema: JSONSchema7 = {
  type: 'object',
  required: ['firstName', 'lastName'],
  properties: {
    logoGeometry: {
      type: 'object',
      title: 'Logo Geometry',
      ...requiredProperties,
    },
    titleBlockGeometry: {
      type: 'object',
      title: 'Title Block Geometry',
      properties: {
        assemblyDescription: {
          type: 'object',
          title: 'Assembly Description',
          ...requiredProperties,
        },
        assemblyDrawingNumber: {
          type: 'object',
          title: 'Assembly Drawing Number',
          ...requiredProperties,
        },
        assemblyRevision: {
          type: 'object',
          title: 'Assembly Revision',
          ...requiredProperties,
        },
        assemblyDesignState: {
          type: 'object',
          title: 'Assembly Design State',
          ...requiredProperties,
        },
        customerPN: {
          type: 'object',
          title: 'Customer P/N',
          ...requiredProperties,
        },
        project: {
          type: 'object',
          title: 'Project',
          ...requiredProperties,
        },
        subject: {
          type: 'object',
          title: 'Subject',
          ...requiredProperties,
        },
        designer: {
          type: 'object',
          title: 'Designer',
          ...requiredProperties,
        },
        designDate: {
          type: 'object',
          title: 'Design Date',
          ...requiredProperties,
        },
        checkedBy: {
          type: 'object',
          title: 'Checked By',
          ...requiredProperties,
        },
        approvedBy: {
          type: 'object',
          title: 'Approved By',
          ...requiredProperties,
        },
        approvedDate: {
          type: 'object',
          title: 'Approved Date',
          ...requiredProperties,
        },
      },
    },
  },
};

const paperSizeList = ['a3', 'a4', 'b3', 'b4', 'letter', 'legal', 'ledger'];

type PropsFromState = {
  paperSizeData: PaperSizeData;
};

type PropsFromDispatch = {
  wizardUpdatePaperSizeData: typeof wizardUpdatePaperSizeData;
};

type TemplateSettingsProps = PropsFromState & PropsFromDispatch;

const TemplateSettings = ({ paperSizeData, wizardUpdatePaperSizeData }: TemplateSettingsProps) => {
  const [formData, setFormData] = useState(paperSizeData['a4']);
  const [paperSize, setPaperSize] = useState('a4');

  const handleChange = (value: string) => {
    setFormData(paperSizeData[value]);
    setPaperSize(value);
  };

  return (
    <div>
      <h1>Template Settings</h1>
      <div className={styles.selectWrapper}>
        <label>Select Paper Size:</label>
        <Select defaultValue="a4" style={{ width: 120 }} onChange={handleChange}>
          {paperSizeList.map((paperSize) => (
            <Select.Option key={paperSize} value={paperSize}>
              {paperSize}
            </Select.Option>
          ))}
        </Select>
      </div>
      <FormSchema
        cancelAction={() => {
          console.log('cancel ');
        }}
        confirmAction={async (data) => {
          try {
            const newData = paperSizeData;
            newData[paperSize] = data.formData;
            await putUpdateTemplateSettings('', {
              template_settings: JSON.stringify(newData),
            });
            wizardUpdatePaperSizeData(newData);
            message.success('Template settings updated!');
          } catch (error) {
            message.error('Failed to update template settings!');
          }
        }}
        customStyles={styles.templateSettingsForm}
        formData={formData}
        schema={schema}
      />
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  paperSizeData: state.assemblyWizard.paperSizeData,
});

const mapDispatchToProps = { wizardUpdatePaperSizeData };

export default connect(mapStateToProps, mapDispatchToProps)(TemplateSettings);
