/* eslint-disable @typescript-eslint/no-non-null-assertion */
import Axios, { AxiosError } from 'axios';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { store } from '~/store';
import { AppState } from '~/store/reducers';

import { makeActionCreator } from '../store/actions';
import { baseApiUrl } from '../utils/env';

import {
  DELETE_ASSEMBLY,
  DELETE_ASSEMBLY_DISCUSSION,
  DELETE_CANCEL_REVIEW,
  DELETE_PART_ITEM,
  REMOVE_ADMIN_ACCESS,
  REMOVE_BOOKMARK_PART_ITEM,
} from './../constants/index';
import { ENABLE_USER } from './../constants/index';
import { DELETE_SUBSCRIPTION } from './../constants/index';
import {
  defaultErrorHandler,
  ErrorHandler,
  handleAPIError,
  handleResponseDataNotJson,
} from './HttpError';

const deleteApiGenerator =
  (route: string, name: string) =>
  async (id?: string | number, errorHandler: ErrorHandler = defaultErrorHandler) => {
    const dispatch = store.dispatch as ThunkDispatch<AppState, void, AnyAction>;
    const getState = store.getState as () => AppState;

    if (id === undefined) {
      dispatch(
        makeActionCreator(
          `${name}_FAILURE`,
          'status',
          'statusText',
        )(400, 'No id specified for deletion.'),
      );

      return Promise.reject('No id specified for deletion.');
    }

    dispatch(makeActionCreator(`${name}_REQUEST`, `id`)(id)); // dispatch request action

    // prepare delete request
    const token = getState().data.auth.token;
    const config = {
      headers: { Authorization: 'Bearer ' + token },
    };
    const dataLayer: any[] = [];
    let updatedRoute = baseApiUrl + route;

    if (id) {
      updatedRoute += '/' + id;
    }

    try {
      const response = await Axios.delete(updatedRoute, config);
      const responseData = response.data;

      if (responseData instanceof Object) {
        dispatch(makeActionCreator(`${name}_SUCCESS`, 'id')(id));
      } else {
        handleResponseDataNotJson(dispatch, name, 'DELETE', dataLayer, route); // response data is not json
      }
      return responseData;
    } catch (error: any) {
      if ((error as AxiosError).response) {
        handleAPIError(errorHandler, dispatch, error.response, 'DELETE', dataLayer, route, name);
      }

      return Promise.reject(error);
    }
  };

export const deleteCancelSubscription = deleteApiGenerator(
  '/_a/user_subscription',
  DELETE_SUBSCRIPTION,
);

export const deleteAdminAccess = deleteApiGenerator('/_a/user_access/admin', REMOVE_ADMIN_ACCESS);

export const deleteDisableUser = deleteApiGenerator('/_a/user_account/disable', ENABLE_USER);

export const deleteBookmarkPartItem = deleteApiGenerator(
  '/_a/parts/favorite',
  REMOVE_BOOKMARK_PART_ITEM,
);

export const deletePartItem = deleteApiGenerator('/_a/part', DELETE_PART_ITEM);

export const deleteAssembly = deleteApiGenerator(`/_a/v2/assemblies`, DELETE_ASSEMBLY);

export const deleteAssemblyDiscussion = deleteApiGenerator(
  `/_a/v2/assemblies`,
  DELETE_ASSEMBLY_DISCUSSION,
);

export const deleteQuoteResponse = deleteApiGenerator(
  `/_a/quote/response`,
  DELETE_ASSEMBLY_DISCUSSION,
);

export const deleteCancelReview = deleteApiGenerator('/_a/v2/assemblies', DELETE_CANCEL_REVIEW);
