import React from 'react';
import { ArrayFieldTemplateProps } from '@anekonnect/react-jsonschema-form';
import { Button } from 'antd';
import { CaretDownFilled, CaretUpFilled, CopyOutlined, DeleteOutlined } from '@ant-design/icons';
import { ContactDetails } from './custom-ui';

const hideArrows = true;

const ArrayFieldTemplateTable = (props: ArrayFieldTemplateProps) => {
  const { idSchema, formData, uiSchema, formContext } = props;
  const className = 'icon-connector-wrapper';

  const connectorTabs = props.items.map((element, i) => {
    const { children } = element;
    const schemaProperties = children.props.schema.properties;
    let propsLength = 6;

    if (schemaProperties !== undefined) {
      const { ...properties } = schemaProperties;
      propsLength = Object.keys(properties).length;
    }

    const columns = propsLength > 6 ? 6 : propsLength;

    return (
      <div
        key={i}
        className={`row-element ${i > 0 ? 'row-element-hide-label' : ''} ${i % 2 ? 'odd' : 'even'}`}
      >
        <div className={`row-element-form columns-${columns}`}>
          {children}
          <div className="array-template-action">
            {!hideArrows && (
              <React.Fragment>
                <Button
                  className={className}
                  disabled={!element.hasMoveUp}
                  icon={<CaretUpFilled />}
                  onClick={element.onReorderClick(element.index, element.index - 1)}
                />
                <Button
                  className={className}
                  disabled={!element.hasMoveDown}
                  icon={<CaretDownFilled />}
                  onClick={element.onReorderClick(element.index, element.index + 1)}
                />
              </React.Fragment>
            )}
            <Button
              className={className}
              disabled={props.disabled}
              icon={<CopyOutlined />}
              onClick={element.onCopyIndexClick(element.index)}
            />
            <br />
            <Button
              className={className}
              disabled={props.disabled}
              icon={<DeleteOutlined />}
              onClick={element.onDropIndexClick(element.index)}
            />
          </div>
        </div>
        {uiSchema['ui:customUI'] === 'add-contact-details' && (
          <ContactDetails
            onChange={(data: any) => {
              const id = idSchema.$id.replace('root_', '');
              formContext.updateContactDetails(id, i, data);
            }}
            formData={formData[i].contact_details || []}
            contactName={formData[i][uiSchema['ui:contactKey']] || ''}
            filterBy={uiSchema['ui:filterBy']}
          />
        )}
      </div>
    );
  });

  return (
    <div className="form-group-array">
      <label className="control-label control-label-array">
        {props.title}
        {props.canAdd && props.items.length === 0 && (
          <Button disabled={props.disabled} type="primary" onClick={props.onAddClick}>
            Add
          </Button>
        )}
      </label>
      {connectorTabs}
      {props.items.length > 0 && (
        <label className="control-label control-label-array control-label-add-bottom">
          {props.canAdd && (
            <Button disabled={props.disabled} type="primary" onClick={props.onAddClick}>
              Add
            </Button>
          )}
        </label>
      )}
    </div>
  );
};

export default ArrayFieldTemplateTable;
