import { Space, Typography } from 'antd';
import { Link, useParams } from 'react-router-dom';
import { useAppSelector } from '~/store/hooks';
import { AppState } from '~/store/reducers';

const SuccessMessage = () => {
  const params = useParams();
  const id = params.id;

  const configData = useAppSelector((state: AppState) => state.data.tenantConfig.data);
  const locked = configData?.locked || false;
  const supportEmail = configData?.support_email || 'support@anekonnect.io';

  const getSuccessMessage = () => {
    switch (id) {
      case 'signup':
        if (locked) {
          return `Your verification email is on it's way. Click on the verification link in the email to fully activate your account.`;
        } else {
          return (
            <div>
              {/* create success message */}
              Your account has been created successfully, please login <Link to="/login">here</Link>
              .
            </div>
          );
        }
      case 'forgot':
        return `Email sent to the registered email address.`;
      case 'feedback':
        return `Feedback sent.`;
      default:
        return '';
    }
  };

  return (
    <Space
      direction="vertical"
      align="center"
      style={{
        width: '100%',
        height: 'calc(100vh - 230px)',
        justifyContent: 'center',
        textAlign: 'center',
      }}
    >
      <Typography.Title>Success!</Typography.Title>
      <img src="/img/success.png" alt="Success" style={{ width: '200px' }} />
      <Typography.Title level={5}>{getSuccessMessage()}</Typography.Title>
      <Typography>
        In case of any question or comments, react out to us at{' '}
        <a href={`mailto:${supportEmail}`}>{supportEmail}</a>.
      </Typography>
    </Space>
  );
};

export default SuccessMessage;
