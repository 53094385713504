import { mxCell, mxGraph } from '@anekonnect/mxgraph';
import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { ActionCreator } from 'redux';

import { deleteRelatedCells } from './Helper';

import { mx } from '~/constants/wizard';
import {
  wizardRemoveComponent,
  WizardRemoveComponent,
  wizardSetShowWireControl,
} from '~/store/actions/wizard/Component';
import { useAppDispatch, useAppSelector } from '~/store/hooks';
import { AppState } from '~/store/reducers';

type PropsFromDispatch = {
  wizardRemoveComponent: ActionCreator<WizardRemoveComponent>;
};

type EventDeleteProps = {
  graph: mxGraph;
} & PropsFromDispatch;

const EventDelete = ({ graph, wizardRemoveComponent }: EventDeleteProps) => {
  const parts = useAppSelector((state: AppState) => state.data.tenantConfig.data?.parts);
  const distpach = useAppDispatch();

  const deleteListener = useCallback(() => {
    const listPartsKey = parts?.map((part) => part.name) || [];

    const removeCell = (cell: mxCell) => {
      const idData = cell.id.split('_'); // structure id componentType_wizardType_componentId_componentSubId_furtherInformation
      const wizardTypeIndex = idData.findIndex((data) => data === 'ed' || data === 'schematics');
      const componentType = idData.slice(0, wizardTypeIndex).join('_');
      const componentId = idData[wizardTypeIndex + 1];
      const componentSubId = idData[wizardTypeIndex + 2];
      console.log(componentId, componentSubId, componentType, 'graph id');

      if (componentId && componentSubId && componentType) {
        wizardRemoveComponent(componentId, componentSubId, componentType);
        console.log('item remove complete');
      }
    };

    graph.addListener(mx.mxEvent.REMOVE_CELLS, (_sender, evt) => {
      const cells = evt.properties.cells;
      console.log('item remove complete below');

      cells.forEach((cell: mxCell) => {
        if (listPartsKey.some((key) => cell.id.includes(key)) && !cell.id.includes('dashed_edge')) {
          deleteRelatedCells(graph, cell);
          removeCell(cell);
        }
      });
      // graph.refresh();
      distpach(wizardSetShowWireControl(false));
    });
  }, [distpach, graph, parts, wizardRemoveComponent]);

  useEffect(deleteListener, [deleteListener]);

  return <React.Fragment />;
};

const mapDispatchToProps = {
  wizardRemoveComponent,
};

export default connect(null, mapDispatchToProps)(EventDelete);
