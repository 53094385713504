import { Form, Modal, Input, Radio } from 'antd';
import { connect } from 'react-redux';

import { GraphModalProps } from '../../Graph/Types';

import { AppState } from '~/store/reducers';

type PropsFromState = {
  openModalEditConnectorPin: boolean;
};

type ConnectorPinModalProps = {
  labelValue: string;
  position: string;
};

const ConnectorPinModal = ({
  form,
  title,
  openModalEditConnectorPin,
  onCancel,
  onOk,
  defaultValue,
}: GraphModalProps<ConnectorPinModalProps> & PropsFromState) => {
  return (
    <Modal
      title={title}
      open={openModalEditConnectorPin}
      forceRender
      onCancel={onCancel}
      onOk={onOk}
    >
      <Form form={form} initialValues={defaultValue} layout="vertical">
        <Form.Item label="Label Value" name="labelValue">
          <Input />
        </Form.Item>
        <Form.Item label="Select Position" name="position">
          <Radio.Group>
            <Radio value="left">Left</Radio>
            <Radio value="right">Right</Radio>
          </Radio.Group>
        </Form.Item>
      </Form>
    </Modal>
  );
};

const mapStateToProps = (state: AppState) => {
  // console.log(state,"modal page state");

  return {
    openModalEditConnectorPin: state.assemblyWizard.openModalEditConnectorPin,
  };
};

export default connect(mapStateToProps)(ConnectorPinModal);
