import { mxGraph, mxCell, mxCellState, mxRectangle as mxRectangleType } from '@anekonnect/mxgraph';

import { mx } from '~/constants/wizard';

const {
  mxPoint,
  mxUtils,
  mxDictionary,
  mxConstants,
  mxRectangle,
  mxEvent,
  mxObjectIdentity,
  mxEventObject,
} = mx;

export const defaultEdgeStyle = {
  edgeStyle: 'orthogonalEdgeStyle',
  rounded: '0',
  jettySize: 'auto',
  orthogonalLoop: '1',
};

export const initialTopSpacing = 3;

const currentEdgeStyle = mxUtils.clone(defaultEdgeStyle);

const roundableShapes = [
  'label',
  'rectangle',
  'internalStorage',
  'corner',
  'parallelogram',
  'swimlane',
  'triangle',
  'trapezoid',
  'ext',
  'step',
  'tee',
  'process',
  'link',
  'rhombus',
  'offPageConnector',
  'loopLimit',
  'hexagon',
  'manualInput',
  'card',
  'curlyBracket',
  'singleArrow',
  'callout',
  'doubleArrow',
  'flexArrow',
  'umlLifeline',
];

/**
 * HTML in-place editor
 */
export const isContentEditing = (graph: mxGraph, editingCell: mxCell) => {
  const state = graph.view.getState(editingCell);

  return state != null && state.style['html'] === 1;
};

/**
 * Returns true if the given cell is a table.
 */
export const isTable = (graph: mxGraph, cell: mxCell) => {
  const style = graph.getCellStyle(cell);

  return style != null && style['childLayout'] === 'tableLayout';
};

/**
 * Returns true if the given cell is a table row.
 */
export const isTableRow = (graph: mxGraph, cell: mxCell) => {
  return graph.model.isVertex(cell) && isTable(graph, graph.model.getParent(cell));
};

/**
 * Returns true if the given cell is a table cell.
 */
export const isTableCell = (graph: mxGraph, cell: mxCell) => {
  return graph.model.isVertex(cell) && isTableRow(graph, graph.model.getParent(cell));
};

/**
 * Returns the first parent that is not a part.
 */
export const isPart = (graph: mxGraph, cell: mxCell) => {
  return (
    mxUtils.getValue(graph.getCurrentCellStyle(cell), 'part', '0') === '1' ||
    isTableCell(graph, cell) ||
    isTableRow(graph, cell)
  );
};

/**
 * Returns the first parent that is not a part.
 */
export const getCompositeParent = (graph: mxGraph, cell: mxCell) => {
  const newCell = cell;

  // TODO: Fix this, it's not working and make memory leak issue
  // while (isPart(graph, cell)) {
  //   const temp = graph.model.getParent(cell);

  //   if (!graph.model.isVertex(temp)) {
  //     break;
  //   }

  //   newCell = temp;
  // }

  return newCell;
};

/**
 * Returns the first parent that is not a part.
 */
export const getCompositeParents = (graph: mxGraph, cells: mxCell[]) => {
  const lookup = new mxDictionary();
  const newCells = [];

  for (let i = 0; i < cells.length; i++) {
    let cell = getCompositeParent(graph, cells[i]);

    if (isTableCell(graph, cell)) {
      cell = graph.model.getParent(cell);
    }

    if (isTableRow(graph, cell)) {
      cell = graph.model.getParent(cell);
    }

    if (cell != null && !lookup.get(cell)) {
      lookup.put(cell, true);
      newCells.push(cell);
    }
  }

  return newCells;
};

/**
 * Never connects children in stack layouts or tables.
 */
export const isCloneConnectSource = (graph: mxGraph, source: mxCell) => {
  return isTableRow(graph, source) || isTableCell(graph, source);
};

/**
 * Returns the first parent with an absolute or no geometry.
 */
export const getAbsoluteParent = (graph: mxGraph, cell: mxCell) => {
  let result = cell;
  let geo = graph.getCellGeometry(result);

  while (geo != null && geo.relative) {
    result = graph.getModel().getParent(result);
    geo = graph.getCellGeometry(result);
  }

  return result;
};

/**
 * Creates lookup from object IDs to cell IDs.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const createCellLookup = (graph: mxGraph, cells: mxCell[], lookup: any = {}) => {
  for (let i = 0; i < cells.length; i++) {
    const cell = cells[i];
    lookup[mxObjectIdentity.get(cell)] = cell.getId();
    const childCount = graph.model.getChildCount(cell);

    for (let j = 0; j < childCount; j++) {
      createCellLookup(graph, [graph.model.getChildAt(cell, j)], lookup);
    }
  }

  return lookup;
};

/**
 * Updates cell IDs in custom links on the given cell and its label.
 */
export const updateCustomLinksForCell = function (graph: mxGraph, cell: mxCell) {
  const childCount = graph.model.getChildCount(cell);

  for (let i = 0; i < childCount; i++) {
    updateCustomLinksForCell(graph, graph.model.getChildAt(cell, i));
  }
};

/**
 * Updates cells IDs for custom links in the given cells using an
 * optional graph to avoid changing the undo history.
 */
export const updateCustomLinks = (graph: mxGraph, cells: mxCell[]) => {
  for (let i = 0; i < cells.length; i++) {
    if (cells[i] != null) {
      updateCustomLinksForCell(graph, cells[i]);
    }
  }
};

/**
 * Duplicates the given cells and returns the duplicates.
 */
export const duplicateCells = (graph: mxGraph, cells?: mxCell[], append = true) => {
  let currentCells = cells ? cells : graph.getSelectionCells();

  // Duplicates rows for table cells
  for (let i = 0; i < currentCells.length; i++) {
    if (isTableCell(graph, currentCells[i])) {
      currentCells[i] = graph.model.getParent(currentCells[i]);
    }
  }

  currentCells = graph.model.getTopmostCells(currentCells);

  const model = graph.getModel();
  const s = graph.gridSize;
  const select = [];

  model.beginUpdate();

  try {
    const cloneMap = {};
    const clones = graph.cloneCells(currentCells, false, cloneMap, true);

    for (let i = 0; i < currentCells.length; i++) {
      const parent = model.getParent(currentCells[i]);

      if (parent != null) {
        const child = graph.moveCells([clones[i]], s, s, false)[0];
        select.push(child);

        if (append) {
          model.add(parent, clones[i]);
        } else {
          // Maintains child index by inserting after clone in parent
          const index = parent.getIndex(currentCells[i]);
          model.add(parent, clones[i], index + 1);
        }

        // Extends tables
        if (isTable(graph, parent)) {
          const row = graph.getCellGeometry(clones[i]);
          let table = graph.getCellGeometry(parent);

          if (row != null && table != null) {
            table = table.clone();
            table.height += row.height;
            model.setGeometry(parent, table);
          }
        }
      } else {
        select.push(clones[i]);
      }
    }

    // Updates custom links after inserting into the model for cells to have new IDs
    updateCustomLinks(graph, clones);
    graph.fireEvent(new mxEventObject(mxEvent.CELLS_ADDED, 'cells', clones));
  } finally {
    model.endUpdate();
  }

  return select;
};

/**
 * Returns the current edge style as a string.
 */
export const createCurrentEdgeStyle = () => {
  let style = 'edgeStyle=' + (currentEdgeStyle['edgeStyle'] || 'none') + ';';
  const keys = [
    'shape',
    'curved',
    'rounded',
    'comic',
    'sketch',
    'fillWeight',
    'hachureGap',
    'hachureAngle',
    'jiggle',
    'disableMultiStroke',
    'disableMultiStrokeFill',
    'fillStyle',
    'curveFitting',
    'simplification',
    'comicStyle',
    'jumpStyle',
    'jumpSize',
  ];

  for (let i = 0; i < keys.length; i++) {
    if (currentEdgeStyle[keys[i]] != null) {
      style += keys[i] + '=' + currentEdgeStyle[keys[i]] + ';';
    }
  }

  // Overrides the global default to match the default edge style
  if (currentEdgeStyle['orthogonalLoop'] != null) {
    style += 'orthogonalLoop=' + currentEdgeStyle['orthogonalLoop'] + ';';
  } else if (defaultEdgeStyle['orthogonalLoop'] != null) {
    style += 'orthogonalLoop=' + defaultEdgeStyle['orthogonalLoop'] + ';';
  }

  // Overrides the global default to match the default edge style
  if (currentEdgeStyle['jettySize'] != null) {
    style += 'jettySize=' + currentEdgeStyle['jettySize'] + ';';
  } else if (defaultEdgeStyle['jettySize'] != null) {
    style += 'jettySize=' + defaultEdgeStyle['jettySize'] + ';';
  }

  // Special logic for custom property of elbowEdgeStyle
  if (currentEdgeStyle['edgeStyle'] === 'elbowEdgeStyle' && currentEdgeStyle['elbow'] != null) {
    style += 'elbow=' + currentEdgeStyle['elbow'] + ';';
  }

  if (currentEdgeStyle['html'] != null) {
    style += 'html=' + currentEdgeStyle['html'] + ';';
  } else {
    style += 'html=1;';
  }

  return style;
};

/**
 * Returns information about the current selection.
 */
export const applyNewEdgeStyle = (
  graph: mxGraph,
  source: mxCell,
  edges: mxCell[],
  dir?: string,
) => {
  const style = graph.getCellStyle(source);
  const temp = style['newEdgeStyle'];

  if (temp != null) {
    graph.model.beginUpdate();

    try {
      const styles = JSON.parse(temp);

      for (const key in styles) {
        graph.setCellStyles(key, styles[key], edges);

        // Sets elbow direction
        if (key === 'edgeStyle' && styles[key] === 'elbowEdgeStyle' && dir != null) {
          graph.setCellStyles(
            'elbow',
            dir === mxConstants.DIRECTION_SOUTH || dir === mxConstants.DIRECTION_NORTH
              ? 'vertical'
              : 'horizontal',
            edges,
          );
        }
      }
    } finally {
      graph.model.endUpdate();
    }
  }
};

/**
 * Adds a connection to the given vertex or clones the vertex in special layout
 * containers without creating a connection.
 */
export const connectVertex = (
  graph: mxGraph,
  source: mxCell,
  direction: string,
  length: number,
  evt: KeyboardEvent,
  forceClone = false,
  ignoreCellAt = false,
  createTarget?: (...args: any) => any,
  done?: (...args: any) => any,
) => {
  // Ignores relative edge labels
  if (source.geometry.relative && graph.model.isEdge(source.parent)) {
    return [];
  }

  let newSource = source;

  // Uses parent for relative child cells
  while (source.geometry.relative && graph.model.isVertex(source.parent)) {
    newSource = source.parent;
  }

  // Handles clone connect sources
  const cloneSource = isCloneConnectSource(graph, newSource);
  const composite = cloneSource ? source : getCompositeParent(graph, source);

  const pt =
    source.geometry.relative && source.parent.geometry != null
      ? new mxPoint(
          source.parent.geometry.width * source.geometry.x,
          source.parent.geometry.height * source.geometry.y,
        )
      : new mxPoint(composite.geometry.x, composite.geometry.y);

  if (direction === mxConstants.DIRECTION_NORTH) {
    pt.x += composite.geometry.width / 2;
    pt.y -= length;
  } else if (direction === mxConstants.DIRECTION_SOUTH) {
    pt.x += composite.geometry.width / 2;
    pt.y += composite.geometry.height + length;
  } else if (direction === mxConstants.DIRECTION_WEST) {
    pt.x -= length;
    pt.y += composite.geometry.height / 2;
  } else {
    pt.x += composite.geometry.width + length;
    pt.y += composite.geometry.height / 2;
  }

  const parentState = graph.view.getState(graph.model.getParent(source));
  const s = graph.view.scale;
  const t = graph.view.translate;
  let dx = t.x * s;
  let dy = t.y * s;

  if (parentState != null && graph.model.isVertex(parentState.cell)) {
    dx = parentState.x;
    dy = parentState.y;
  }

  // Workaround for relative child cells
  if (graph.model.isVertex(source.parent) && source.geometry.relative) {
    pt.x += source.parent.geometry.x;
    pt.y += source.parent.geometry.y;
  }

  // Checks end point for target cell and container
  const rect = !ignoreCellAt ? new mxRectangle(dx + pt.x * s, dy + pt.y * s).grow(40 * s) : null;
  let tempCells = rect != null ? graph.getCells(0, 0, 0, 0, null, null, rect, null, true) : null;
  const sourceState = graph.view.getState(source);
  let container: mxCell | null = null;
  let target: mxCell | null = null;

  if (tempCells != null) {
    tempCells = tempCells.reverse();

    for (let i = 0; i < tempCells.length; i++) {
      if (
        !graph.isCellLocked(tempCells[i]) &&
        !graph.model.isEdge(tempCells[i]) &&
        tempCells[i] !== source
      ) {
        // Direct parent overrides all possible containers
        if (
          !graph.model.isAncestor(source, tempCells[i]) &&
          graph.isContainer(tempCells[i]) &&
          (container == null || tempCells[i] === graph.model.getParent(source))
        ) {
          container = tempCells[i];
        }
        // Containers are used as target cells but swimlanes are used as parents
        else if (
          target == null &&
          graph.isCellConnectable(tempCells[i]) &&
          !graph.model.isAncestor(tempCells[i], source) &&
          !graph.isSwimlane(tempCells[i])
        ) {
          const targetState = graph.view.getState(tempCells[i]);

          if (
            sourceState != null &&
            targetState != null &&
            !mxUtils.intersects(sourceState, targetState)
          ) {
            target = tempCells[i];
          }
        }
      }
    }
  }

  const duplicate = !mxEvent.isShiftDown(evt) || mxEvent.isControlDown(evt) || forceClone;

  const result: mxCell[] = [];
  let realTarget = target;
  target = container;

  const execute = mxUtils.bind(graph, function (targetCell: mxCell | null) {
    if (createTarget == null || targetCell != null || (target == null && cloneSource)) {
      graph.model.beginUpdate();

      try {
        if (realTarget == null && duplicate) {
          // Handles relative and composite cells
          let cellToClone = getAbsoluteParent(graph, targetCell != null ? targetCell : source);
          cellToClone = cloneSource ? source : getCompositeParent(graph, cellToClone);
          realTarget =
            targetCell != null ? targetCell : duplicateCells(graph, [cellToClone], false)[0];

          if (targetCell != null) {
            graph.addCells([realTarget], graph.model.getParent(source), null, null, null, true);
          }

          const geo = graph.getCellGeometry(realTarget);

          if (geo != null) {
            geo.x = pt.x - geo.width / 2;
            geo.y = pt.y - geo.height / 2;
          }

          if (container != null) {
            graph.addCells([realTarget], container, null, null, null, true);
            target = null;
          } else if (duplicate && !cloneSource) {
            graph.addCells([realTarget], graph.getDefaultParent(), null, null, null, true);
          }
        }

        const edge =
          (mxEvent.isControlDown(evt) && mxEvent.isShiftDown(evt) && duplicate) ||
          (target == null && cloneSource)
            ? null
            : graph.insertEdge(
                graph.model.getParent(source),
                null,
                '',
                source,
                realTarget,
                createCurrentEdgeStyle(),
              );

        // Inserts edge before source
        if (edge != null && graph.connectionHandler.insertBeforeSource) {
          let tmp = source;

          while (
            tmp.parent != null &&
            tmp.geometry != null &&
            tmp.geometry.relative &&
            tmp.parent !== edge.parent
          ) {
            tmp = graph.model.getParent(tmp);
          }

          if (tmp != null && tmp.parent != null && tmp.parent === edge.parent) {
            const index = tmp.parent.getIndex(tmp);
            graph.model.add(tmp.parent, edge, index);
          }
        }

        // Special case: Click on west icon puts clone before cell
        if (
          target == null &&
          realTarget != null &&
          source.parent != null &&
          cloneSource &&
          direction === mxConstants.DIRECTION_WEST
        ) {
          const index = source.parent.getIndex(source);
          graph.model.add(source.parent, realTarget, index);
        }

        if (edge != null) {
          result.push(edge);
          applyNewEdgeStyle(graph, source, [edge], direction);
        }

        if (target == null && realTarget != null) {
          result.push(realTarget);
        }

        if (realTarget == null && edge != null) {
          edge.geometry.setTerminalPoint(pt, false);
        }

        if (edge != null) {
          graph.fireEvent(new mxEventObject('cellsInserted', 'cells', [edge]));
        }
      } finally {
        graph.model.endUpdate();
      }
    }

    if (done != null) {
      done(result);
    } else {
      return result;
    }
  });

  if (createTarget != null && realTarget == null && duplicate && (target != null || !cloneSource)) {
    createTarget(dx + pt.x * s, dy + pt.y * s, execute);
  } else {
    return execute(realTarget);
  }
};

export const isAutoSizeState = (state: mxCellState) => {
  return mx.mxUtils.getValue(state.style, mx.mxConstants.STYLE_AUTOSIZE, null) === '1';
};

export const isGlassState = (state: mxCellState) => {
  const shape = mx.mxUtils.getValue(state.style, mx.mxConstants.STYLE_SHAPE, null);

  return (
    shape === 'label' ||
    shape === 'rectangle' ||
    shape === 'internalStorage' ||
    shape === 'ext' ||
    shape === 'umlLifeline' ||
    shape === 'swimlane' ||
    shape === 'process'
  );
};

export const isRoundedState = (state: mxCellState) => {
  const { mxUtils, mxConstants } = mx;

  return state.shape != null
    ? state.shape.isRoundable()
    : mxUtils.indexOf(
        roundableShapes,
        mxUtils.getValue(state.style, mxConstants.STYLE_SHAPE, null),
      ) >= 0;
};

export const isLineJumpState = (state: mxCellState) => {
  const { mxUtils, mxConstants } = mx;

  const shape = mxUtils.getValue(state.style, mxConstants.STYLE_SHAPE, null);
  const curved = mxUtils.getValue(state.style, mxConstants.STYLE_CURVED, false);

  return !curved && (shape === 'connector' || shape === 'filledEdge' || shape === 'wire');
};

export const isImageState = (state: mxCellState) => {
  return mx.mxUtils.getValue(state.style, mx.mxConstants.STYLE_IMAGE, null) != null;
};

export const isShadowState = (state: mxCellState) => {
  const shape = mx.mxUtils.getValue(state.style, mx.mxConstants.STYLE_SHAPE, null);

  return shape !== 'image';
};

export const isSpecialColor = (color: string) => {
  const { mxUtils, mxConstants } = mx;

  return (
    mxUtils.indexOf(
      [
        mxConstants.STYLE_STROKECOLOR,
        mxConstants.STYLE_FILLCOLOR,
        'inherit',
        'swimlane',
        'indicated',
      ],
      color,
    ) >= 0
  );
};

export const isFillState = (graph: mxGraph, state: mxCellState) => {
  const { mxUtils, mxConstants } = mx;

  return (
    !isSpecialColor(state.style[mxConstants.STYLE_FILLCOLOR]) &&
    mxUtils.getValue(state.style, 'lineShape', null) !== '1' &&
    (graph.model.isVertex(state.cell) ||
      mxUtils.getValue(state.style, mxConstants.STYLE_SHAPE, null) === 'arrow' ||
      mxUtils.getValue(state.style, mxConstants.STYLE_SHAPE, null) === 'wire' ||
      mxUtils.getValue(state.style, mxConstants.STYLE_SHAPE, null) === 'filledEdge' ||
      mxUtils.getValue(state.style, mxConstants.STYLE_SHAPE, null) === 'flexArrow' ||
      mxUtils.getValue(state.style, mxConstants.STYLE_SHAPE, null) === 'mxgraph.arrows2.wedgeArrow')
  );
};

export const isGradientState = (graph: mxGraph, state: mxCellState) => {
  const { mxUtils, mxConstants } = mx;

  return (
    isFillState(graph, state) &&
    mxUtils.getValue(state.style, mxConstants.STYLE_SHAPE, null) !== 'wire'
  );
};

export const isStrokeState = () => {
  return true;
};

export const mergeStyle = (
  style: { [x: string]: any } | null,
  into: { [x: string]: any },
  initial: any,
) => {
  if (style != null) {
    const keys: any = {};

    for (const key in style) {
      const value = style[key];

      if (value != null) {
        keys[key] = true;

        if (into[key] == null && initial) {
          into[key] = value;
        } else if (into[key] !== value) {
          delete into[key];
        }
      }
    }

    for (const key in into) {
      if (!keys[key]) {
        delete into[key];
      }
    }
  }
};

export const isCellLocked = function (graph: mxGraph, cell: mxCell) {
  let newCell = cell;

  while (newCell != null) {
    if (mx.mxUtils.getValue(graph.getCurrentCellStyle(newCell), 'locked', '0') === '1') {
      return true;
    }

    newCell = graph.model.getParent(newCell);
  }

  return false;
};

export const isContainer = function (graph: mxGraph, cell: mxCell) {
  const style = graph.getCurrentCellStyle(cell);

  if (graph.isSwimlane(cell)) {
    return style['container'] !== '0';
  }

  return style['container'] === '1';
};

export const isCellFoldable = (graph: mxGraph, cell: mxCell) => {
  const style = graph.getCurrentCellStyle(cell);
  const { mxUtils, mxConstants } = mx;

  return (
    graph.foldingEnabled &&
    mxUtils.getValue(style, mxConstants.STYLE_RESIZABLE, '1') !== '0' &&
    (style['treeFolding'] === '1' ||
      (!isCellLocked(graph, cell) &&
        ((isContainer(graph, cell) && style['collapsible'] !== '0') ||
          (!isContainer(graph, cell) && style['collapsible'] === '1'))))
  );
};

/**
 * Delete all cells related to the given cell
 *
 * @param graph
 * @param cell
 */
export const deleteRelatedCells = (graph: mxGraph, cell: mxCell) => {
  const idData = cell.id.split('_'); // structure id componentType_wizardType_componentId_componentSubId_furtherInformation
  const wizardTypeIndex = idData.findIndex((data) => data === 'ed' || data === 'schematics');
  const componentId = idData[wizardTypeIndex + 1];
  const componentSubId = idData[wizardTypeIndex + 2];
  const prefix = `${componentId}_${componentSubId}`;

  graph.model.beginUpdate();

  try {
    const cells = graph.getChildCells(graph.getDefaultParent(), true, true);
    cells.forEach((cell: mxCell) => {
      const component = cell.id.includes(prefix);

      if (component) {
        graph.removeCells([cell]);
      }
    });
  } finally {
    graph.model.endUpdate();
  }
};

/**
 * Deletes the given cells
 */
export const deleteCells = (graph: mxGraph, cells: mxCell[], includeEdges = false) => {
  if (cells != null && cells.length > 0) {
    graph.model.beginUpdate();

    try {
      // Shrinks tables
      for (let i = 0; i < cells.length; i++) {
        const parent = graph.model.getParent(cells[i]);

        if (isTable(graph, parent)) {
          const row = graph.getCellGeometry(cells[i]);
          let table = graph.getCellGeometry(parent);

          if (row != null && table != null) {
            table = table.clone();
            table.height -= row.height;
            graph.model.setGeometry(parent, table);
          }
        }

        if (cells[i].isEdge()) {
          const removeList = [cells[i]];

          const id = cells[i].id.split('_').pop();

          if (id && graph.model.getCell(id)) {
            removeList.push(graph.model.getCell(id));
          }

          graph.removeCells(removeList);
        }
      }

      graph.removeCells(cells, includeEdges);
    } finally {
      graph.model.endUpdate();
    }
  }
};

/**
 * Hook for subclassers.
 */
export const getPagePadding = () => {
  return new mxPoint(0, 0);
};

/**
 * Specifies the size of the size for "tiles" to be used for a graph with
 * scrollbars but no visible background page. A good value is large
 * enough to reduce the number of repaints that is caused for auto-
 * translation, which depends on this value, and small enough to give
 * a small empty buffer around the graph. Default is 400x400.
 */
export const scrollTileSize = new mxRectangle(0, 0, 400, 400);

/**
 * Resets the state of the scrollbars.
 */
export const resetScrollbars = (graph: mxGraph) => {
  const c = graph.container;

  if (mxUtils.hasScrollbars(c)) {
    if (graph.pageVisible) {
      const pad = getPagePadding();
      c.scrollTop = Math.floor(pad.y - initialTopSpacing) - 1;
      c.scrollLeft = Math.floor(Math.min(pad.x, (c.scrollWidth - c.clientWidth) / 2)) - 1;

      // Scrolls graph to visible area
      const bounds = graph.getGraphBounds();

      if (bounds.width > 0 && bounds.height > 0) {
        if (bounds.x > c.scrollLeft + c.clientWidth * 0.9) {
          c.scrollLeft = Math.min(bounds.x + bounds.width - c.clientWidth, bounds.x - 10);
        }

        if (bounds.y > c.scrollTop + c.clientHeight * 0.9) {
          c.scrollTop = Math.min(bounds.y + bounds.height - c.clientHeight, bounds.y - 10);
        }
      }
    } else {
      const bounds = graph.getGraphBounds();

      if (bounds.width === 0 && bounds.height === 0) {
        c.scrollLeft = (c.scrollWidth - c.clientWidth) / 2;
        c.scrollTop = (c.scrollHeight - c.clientHeight) / 2;
      } else {
        const width = Math.max(bounds.width, scrollTileSize.width * graph.view.scale);
        const height = Math.max(bounds.height, scrollTileSize.height * graph.view.scale);
        c.scrollTop = Math.floor(
          Math.max(0, bounds.y - Math.max(20, (c.clientHeight - height) / 4)),
        );
        c.scrollLeft = Math.floor(Math.max(0, bounds.x - Math.max(0, (c.clientWidth - width) / 2)));
      }
    }
  }
};

/**
 * Function: fitWindow
 *
 * Sets the current visible rectangle of the window in graph coordinates.
 */
export const fitWindow = (graph: mxGraph, bounds: mxRectangleType, border = 10) => {
  const cw = graph.container.clientWidth - border;
  const ch = graph.container.clientHeight - border;
  const scale = Math.floor(20 * Math.min(cw / bounds.width, ch / bounds.height)) / 20;
  graph.zoomTo(scale);

  if (mxUtils.hasScrollbars(graph.container)) {
    const t = graph.view.translate;
    graph.container.scrollTop =
      (bounds.y + t.y) * scale - Math.max((ch - bounds.height * scale) / 2 + border / 2, 0);
    graph.container.scrollLeft =
      (bounds.x + t.x) * scale - Math.max((cw - bounds.width * scale) / 2 + border / 2, 0);
  }
};

/**
 * Turns the given cells and returns the changed cells.
 */
export const turnShapes = (graph: mxGraph, cells: mxCell[], backwards = false) => {
  const model = graph.getModel();
  const select = [];

  model.beginUpdate();

  try {
    for (let i = 0; i < cells.length; i++) {
      const cell = cells[i];

      if (model.isEdge(cell)) {
        const src = model.getTerminal(cell, true);
        const trg = model.getTerminal(cell, false);

        model.setTerminal(cell, trg, true);
        model.setTerminal(cell, src, false);

        let geo = model.getGeometry(cell);

        if (geo != null) {
          geo = geo.clone();

          if (geo.points != null) {
            geo.points.reverse();
          }

          const sp = geo.getTerminalPoint(true);
          const tp = geo.getTerminalPoint(false);

          geo.setTerminalPoint(sp, false);
          geo.setTerminalPoint(tp, true);
          model.setGeometry(cell, geo);

          // Inverts constraints
          const edgeState = graph.view.getState(cell);
          const sourceState = graph.view.getState(src);
          const targetState = graph.view.getState(trg);

          if (edgeState != null) {
            const sc =
              sourceState != null
                ? graph.getConnectionConstraint(edgeState, sourceState, true)
                : null;
            const tc =
              targetState != null
                ? graph.getConnectionConstraint(edgeState, targetState, false)
                : null;

            graph.setConnectionConstraint(cell, src, true, tc);
            graph.setConnectionConstraint(cell, trg, false, sc);

            // Inverts perimeter spacings
            const temp = mxUtils.getValue(
              edgeState.style,
              mxConstants.STYLE_SOURCE_PERIMETER_SPACING,
              null,
            );
            graph.setCellStyles(
              mxConstants.STYLE_SOURCE_PERIMETER_SPACING,
              mxUtils.getValue(edgeState.style, mxConstants.STYLE_TARGET_PERIMETER_SPACING, null),
              [cell],
            );
            graph.setCellStyles(mxConstants.STYLE_TARGET_PERIMETER_SPACING, temp, [cell]);
          }

          select.push(cell);
        }
      } else if (model.isVertex(cell)) {
        let geo = graph.getCellGeometry(cell);

        if (geo != null) {
          // Rotates the size and position in the geometry
          if (
            !isTable(graph, cell) &&
            !isTableRow(graph, cell) &&
            !isTableCell(graph, cell) &&
            !graph.isSwimlane(cell)
          ) {
            geo = geo.clone();
            geo.x += geo.width / 2 - geo.height / 2;
            geo.y += geo.height / 2 - geo.width / 2;
            const tmp = geo.width;
            geo.width = geo.height;
            geo.height = tmp;
            model.setGeometry(cell, geo);
          }

          // Reads the current direction and advances by 90 degrees
          const state = graph.view.getState(cell);

          if (state != null) {
            const dirs = [
              mxConstants.DIRECTION_EAST,
              mxConstants.DIRECTION_SOUTH,
              mxConstants.DIRECTION_WEST,
              mxConstants.DIRECTION_NORTH,
            ];
            const dir = mxUtils.getValue(
              state.style,
              mxConstants.STYLE_DIRECTION,
              mxConstants.DIRECTION_EAST,
            );
            graph.setCellStyles(
              mxConstants.STYLE_DIRECTION,
              dirs[mxUtils.mod(mxUtils.indexOf(dirs, dir) + (backwards ? -1 : 1), dirs.length)],
              [cell],
            );
          }

          select.push(cell);
        }
      }
    }
  } finally {
    model.endUpdate();
  }

  return select;
};

export const getResizableCells = (graph: mxGraph, a: mxCell[]) => {
  return graph.model.filterCells(a, (b) => {
    return graph.isCellResizable(b);
  });
};

export const getEditableCells = (graph: mxGraph, a: mxCell[]) => {
  return graph.model.filterCells(a, (b) => {
    return graph.isCellEditable(b);
  });
};
