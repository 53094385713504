import axios, { AxiosError } from 'axios';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import {
  ErrorHandler,
  defaultErrorHandler,
  handleResponseDataNotJson,
  handleAPIError,
} from './HttpError';

import { RESET_PASSWORD } from '~/constants';
import { makeActionCreator } from '~/store/actions/index';
import { baseApiUrl } from '~/utils/env';
import { store } from '~/store';
import { AppState } from '~/store/reducers';

const putApiGenerator =
  (route: string, name: string, successRedirect: string) =>
  (id: string, data: any, errorHandler: ErrorHandler = defaultErrorHandler) => {
    const dispatch = store.dispatch as ThunkDispatch<AppState, void, AnyAction>;

    // dispatch request action
    dispatch(makeActionCreator(`${name}_REQUEST`)());

    let updatedRoute = baseApiUrl + route;

    if (id !== undefined && id !== '') {
      updatedRoute += '/' + id;
    }

    const dataLayer: any[] = [];

    return axios.put(updatedRoute, data).then(
      (response) => {
        const responseData = response.data;

        if (responseData instanceof Object) {
          dispatch(makeActionCreator(`${name}_SUCCESS`)());

          // redirect on successful update
          if (successRedirect !== undefined) {
            setTimeout(() => window.location.replace(successRedirect), 500);
          }
        } else {
          // response data is not json
          handleResponseDataNotJson(dispatch, name, 'PUT', dataLayer, route, errorHandler);
        }

        return responseData;
      },
      (error: AxiosError) => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        handleAPIError(errorHandler, dispatch, error.response!, 'PUT', dataLayer, route, name);
        throw error;
      },
    );
  };

export const putResetPassword = putApiGenerator(
  '/_a/users/reset-password',
  RESET_PASSWORD,
  '/login',
);
