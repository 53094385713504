import { Modal } from 'antd';

import Editor from './Editor';
import { DiscussionTopic } from '~/store/reducers/wizard/State';

const ACTIONS = ['EDIT_TOPIC', 'EDIT_REPLY'] as const;
type ACTION = (typeof ACTIONS)[number];

type EditModalProps = {
  visible: boolean;
  onCancel: () => void;
  setDiscussions: (discussions: DiscussionTopic[], scroll?: boolean) => void;
  action: ACTION;
  topicId: string;
  replyId?: string;
  data: {
    title?: string;
    description?: string;
  };
};

const EditModal = ({
  visible,
  onCancel,
  topicId,
  replyId,
  action,
  setDiscussions,
  data,
}: EditModalProps) => {
  return (
    <Modal
      footer={null}
      title="Edit Topic Discussion"
      open={visible}
      width={1000}
      onCancel={onCancel}
    >
      <Editor
        action={action}
        callback={onCancel}
        data={data}
        replyId={replyId}
        setDiscussions={setDiscussions}
        topicId={topicId}
      />
    </Modal>
  );
};

export default EditModal;
