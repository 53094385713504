import { Col, Row, Spin } from 'antd';
import { isEmpty } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';

import { Part } from '~/api/API';
import { getPartItem } from '~/api/AuthorizedGets';
import { postCreatePartItem } from '~/api/AuthorizedPosts';
import { putUpdatePartItem } from '~/api/AuthorizedPuts';
import { FormSchema } from '~/components/FormSchema/FormSchema';
import { GET_LINK_VIEW_PARTS_ITEM } from '~/constants/paths';
import { snackBarOpen } from '~/store/actions/ui/SnackBar';
import { AppState } from '~/store/reducers';
import { getParts, selectPartByPartName } from '~/utils/parts';
import { isStateLoading } from '~/utils/state';

import styles from './PartsAddItem.module.scss';

type PropsFromState = {
  parts: Part[] | null;
  isLoading: boolean;
};

type PropsFromDispatch = {
  snackBarOpen: typeof snackBarOpen;
};

type PartsAddItemProps = {
  edit?: boolean;
  copy?: boolean;
} & PropsFromState &
  PropsFromDispatch;

const PartsAddItem = ({ parts, edit, copy, isLoading, snackBarOpen }: PartsAddItemProps) => {
  const navigate = useNavigate();
  const params = useParams();
  const partName = params.partName;
  const partItemId = params.partItemId;

  const [formData, setFormData] = useState(null as any);

  const part = useMemo(() => {
    if (!parts) return null;
    return selectPartByPartName(parts, partName || '');
  }, [parts, partName]);

  const handlePartItemId = useCallback(() => {
    if (!partItemId) return;
    getPartItem(partItemId).then((data: any) => {
      if (copy) delete data.name;
      setFormData(data);
    });
  }, [partItemId, copy]);

  useEffect(handlePartItemId, [handlePartItemId]);

  const schema = useMemo(() => {
    if (!part) return null;
    const writeSchema = JSON.parse(part.write_schema);
    part.file_types.forEach((fileType) => {
      writeSchema.properties[fileType.name] = {
        format: 'data-url',
        title: fileType.display_name,
        type: 'string',
      };
    });

    return writeSchema;
  }, [part]);

  const uiSchema = useMemo(() => {
    if (!part?.form_ui_schema) {
      return null;
    }

    return JSON.parse(part.form_ui_schema);
  }, [part]);

  const newFormData = useMemo(() => {
    const newData = JSON.parse(JSON.stringify(formData));

    if (formData && formData.files) {
      formData.files.forEach((data: any) => {
        newData[data.type] = data;
      });
    }

    return newData;
  }, [formData]);

  const handleFormSubmit = async (values: any, files?: any) => {
    if (!part) {
      return;
    }

    const partName = part.name;
    const _files: any[] = [];

    if (files) {
      const revIdSchema = Object.entries(values.idSchema).reduce(
        (result: any, [objKey, objVal]: [string, any]) => {
          if (!objVal.$id) return result;
          result[objVal.$id] = {
            id: objKey,
          };

          return result;
        },
        {},
      );

      Object.keys(files).forEach((fileKey: any) => {
        if (revIdSchema[fileKey]) {
          _files.push({
            id: revIdSchema[fileKey].id,
            data: files[fileKey],
          });
        }
      });
    }

    const data = edit
      ? await putUpdatePartItem(formData.id, part.name, values.formData, _files)
      : await postCreatePartItem(part.name, values.formData, _files);
    snackBarOpen(`${partName} is successfully ${edit ? 'edited' : copy ? 'copied' : 'created'}`);

    navigate(GET_LINK_VIEW_PARTS_ITEM(partName, data.id));
  };

  const isCreateNewMode = !edit && !copy;
  const isRender = isCreateNewMode ? true : !isEmpty(formData);

  return (
    <Spin
      className={styles.loading}
      spinning={isLoading || !part || (!isCreateNewMode && !formData)}
    >
      <Row justify="center">
        <Col md={24} sm={24} xs={24}>
          <h1 className={styles.header}>
            {part ? `${edit ? 'Edit' : copy ? 'Copy' : 'Add New'} ${part.display_name}` : ''}
          </h1>
          {schema && isRender && (
            <FormSchema
              cancelAction={() => navigate(-1)}
              confirmAction={handleFormSubmit}
              formData={newFormData}
              schema={schema}
              uiSchema={uiSchema}
              onChange={(data: any) => setFormData(data.formData)}
            />
          )}
        </Col>
      </Row>
    </Spin>
  );
};

const mapStateToProps = (state: AppState) => {
  const parts = getParts(state) || [];

  return {
    isLoading: isStateLoading(state.action, 'partItem'),
    parts: parts,
  };
};

const mapDispatchToProps = {
  snackBarOpen,
};

export default connect(mapStateToProps, mapDispatchToProps)(PartsAddItem);
