import { Button, message, Space, Table, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { getVendorUsers } from '~/api/AuthorizedGets';

type SourceType = {
  key: string;
  id: string;
  logo: string;
  vendorName: string;
  country: string;
};

const VendorsUI = (props: any) => {
  const { disabled, onChange } = props;
  const [selectedVendors, setSelectedVendors] = useState<string[]>([]);
  const [dataSource, setDataSource] = useState<SourceType[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    onChange(selectedVendors);
  }, [selectedVendors, onChange]);

  useEffect(() => {
    const getVendors = async () => {
      setIsLoading(true);
      const data = await getVendorUsers();

      if (data) {
        setDataSource(
          data.map((vendor: any) => ({
            key: vendor.id,
            id: vendor.id,
            logo: vendor.logo?.url || '/img/logo.png',
            vendorName: vendor.companyName,
            country: vendor.country || '-',
          })),
        );
      }

      setIsLoading(false);
    };

    getVendors();
  }, []);

  const columns = [
    {
      title: 'Company',
      dataIndex: 'logo',
      key: 'logo',
      render: (logo: string) => <img src={logo} width={40} alt="logo" />,
    },
    {
      title: 'Partner Name',
      dataIndex: 'vendorName',
      key: 'vendorName',
    },
    {
      title: 'Country of Molding',
      dataIndex: 'country',
      key: 'country',
      filters: [
        {
          text: 'United Kingdom',
          value: 'United Kingdom',
        },
        {
          text: 'United States',
          value: 'United States',
        },
      ],
      onFilter: (value: any, record: any) => record.country.startsWith(value),
      filterSearch: true,
    },
    {
      title: 'Action',
      key: 'action',
      render: (_: any, record: any) => (
        <Button
          onClick={() => {
            if (selectedVendors.includes(record.id)) {
              setSelectedVendors(selectedVendors.filter((vendor) => vendor !== record.id));
            } else {
              if (selectedVendors.length >= 3) {
                message.error('You have selected 3 vendors');
              } else {
                setSelectedVendors([...selectedVendors, record.id]);
              }
            }
          }}
          type={selectedVendors.includes(record.id) ? 'primary' : 'default'}
          disabled={disabled}
        >
          {selectedVendors.includes(record.id) ? 'Deselect' : 'Select'}
        </Button>
      ),
    },
  ];

  return (
    <Space direction="vertical" size="middle" style={{ width: '100%' }}>
      <Typography.Title level={5}>
        Please select 3 cable molding parter from the list below to request a quote.
      </Typography.Title>
      <Table dataSource={dataSource} columns={columns} loading={isLoading} />
    </Space>
  );
};

export default VendorsUI;
