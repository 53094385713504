import { DownloadOutlined } from '@ant-design/icons';
import { Dropdown, MenuProps, Spin } from 'antd';
import { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { CSVLink } from 'react-csv';
import { mxGraph } from '@anekonnect/mxgraph';
import { PDFDownloadLink } from '@react-pdf/renderer';

import { PDFPreview } from '../../WizardCableTestCard';

import { mx } from '~/constants/wizard';
import { AppState } from '~/store/reducers';
import { getPaperSize } from '~/utils/paperSize';
import { BillOfMaterialsData } from '~/store/reducers/wizard/State';
import { postExportSVG } from '~/api/AuthorizedPosts';
import { getPaymentStatus, paymentStatusIsPaid } from '~/utils/helper';
import MenuButton from './MenuButton';
import { useAppSelector } from '~/store/hooks';

type PropsFromState = {
  assemblyName?: string;
  drawingNumber?: string;
  drawingRevision?: string;
  paperSize: string;
  billOfMaterialsData: BillOfMaterialsData[];
  whoamiData: {
    payment_status?: string;
    type?: string;
  };
};

type MenuDownloadProps = {
  graph: mxGraph;
} & PropsFromState;

const MenuDownload = ({
  assemblyName,
  drawingNumber,
  drawingRevision,
  graph,
  paperSize,
  billOfMaterialsData,
  whoamiData,
}: MenuDownloadProps) => {
  const UIConfig = useAppSelector((state: AppState) => state.data.tenantConfig.data?.ui_config);
  const cableTestCardData = useAppSelector(
    (state: AppState) => state.assemblyWizard.cableTestCardData,
  );
  const whoami = useAppSelector((state: AppState) => state.data.whoami);

  const [isLoading, setIsLoading] = useState(false);
  const paymentStatus = getPaymentStatus(whoamiData);
  const isPaid = paymentStatusIsPaid(paymentStatus);

  const fileName = `${assemblyName}${drawingNumber ? `-${drawingNumber}` : ''}${
    drawingRevision ? `-${drawingRevision}` : ''
  }`;

  const csvHeaders = [
    {
      label: 'Item No',
      key: 'itemNo',
    },
    {
      label: 'Quantity',
      key: 'itemQuantity',
    },
    {
      label: 'Units',
      key: 'itemUnits',
    },
    {
      label: 'Description',
      key: 'itemDescription',
    },
    {
      label: 'Part Number',
      key: 'itemPartNumber',
    },
    {
      label: 'Manufacturer',
      key: 'itemManufacturer',
    },
  ];

  const onExport = useCallback(
    (fileName: string | undefined, type: string) => {
      const paperSizeData = getPaperSize(paperSize);
      const backgrounds = graph.getBackgroundImages();

      graph.zoomActual(); // zoom to actual size
      const imgExport = new mx.mxImageExport();
      const bounds = graph.getGraphBounds();

      let width = Math.ceil(bounds.x + bounds.width);
      let height = Math.ceil(bounds.y + bounds.height);

      if (backgrounds.length > 0) {
        width = Math.max(
          ...[
            Math.max(
              ...backgrounds.map(function (o) {
                return o.x + o.width;
              }),
            ),
            width,
          ],
        );

        height = Math.max(
          ...[
            Math.max(
              ...backgrounds.map(function (o) {
                return o.y + o.height;
              }),
            ),
            height,
          ],
        );
      }

      const horizontalPageCount = Math.ceil(width / paperSizeData.width);
      const verticalPageCount = Math.ceil(height / paperSizeData.height);

      const getSVG = (x: number, y: number) => {
        // Prepares SVG document that holds the output
        const svgDoc = mx.mxUtils.createXmlDocument();
        const root =
          svgDoc.createElementNS != null
            ? svgDoc.createElementNS(mx.mxConstants.NS_SVG, 'svg')
            : svgDoc.createElement('svg');

        if (root.style != null) {
          root.style.backgroundColor = '#FFFFFF';
        } else {
          root.setAttribute('style', 'background-color: #ffffff;');
        }

        if (svgDoc.createElementNS == null) {
          root.setAttribute('xmlns', mx.mxConstants.NS_SVG);
          root.setAttribute('xmlns:xlink', mx.mxConstants.NS_XLINK);
        } else {
          // KNOWN: Ignored in IE9-11, adds namespace for each image element instead. No workaround.
          root.setAttributeNS(
            'http://www.w3.org/2000/xmlns/',
            'xmlns:xlink',
            mx.mxConstants.NS_XLINK,
          );
        }

        if (type === 'pdf') {
          root.setAttribute('width', (paperSizeData.width - 20).toString());
          root.setAttribute('height', (paperSizeData.height - 20).toString());
          root.setAttribute(
            'viewBox',
            `${x - 10} ${y - 10} ${paperSizeData.width + 20} ${paperSizeData.height + 20}`,
          );
        } else {
          root.setAttribute('width', width.toString());
          root.setAttribute('height', height.toString());
        }

        root.setAttribute('version', '1.1');

        if (backgrounds != null && backgrounds.length > 0) {
          const groupBgImage =
            svgDoc.createElementNS != null
              ? svgDoc.createElementNS(mx.mxConstants.NS_SVG, 'g')
              : svgDoc.createElement('g');
          groupBgImage.setAttribute('transform', 'translate(0.5,0.5)');

          backgrounds.forEach((background) => {
            const backgroundImage =
              svgDoc.createElementNS != null
                ? svgDoc.createElementNS(mx.mxConstants.NS_SVG, 'image')
                : svgDoc.createElement('image');
            backgroundImage.setAttribute('x', background.x.toString());
            backgroundImage.setAttribute('y', background.y.toString());
            backgroundImage.setAttribute('xlink:href', background.src);
            backgroundImage.setAttribute('width', background.width.toString());
            backgroundImage.setAttribute('height', background.height.toString());

            groupBgImage.appendChild(backgroundImage);
          });

          root.appendChild(groupBgImage);
        }

        // Adds group for anti-aliasing via transform
        const group =
          svgDoc.createElementNS != null
            ? svgDoc.createElementNS(mx.mxConstants.NS_SVG, 'g')
            : svgDoc.createElement('g');
        group.setAttribute('transform', 'translate(0.5,0.5)');
        root.appendChild(group);
        svgDoc.appendChild(root);

        // Renders graph. Offset will be multiplied with state's scale when painting state.
        const svgCanvas = new mx.mxSvgCanvas2D(group);

        // Displayed if a viewer does not support foreignObjects (which is needed to HTML output)
        svgCanvas.foAltText = '[Not supported by viewer]';
        imgExport.drawState(graph.getView().getState(graph.model.root), svgCanvas);

        // Fix wire colors issue
        const paths = root.querySelectorAll('path');
        paths.forEach((path) => {
          const isDashed = path.getAttribute('stroke-dasharray');
          const isWhite = path.getAttribute('stroke') === 'white';

          if (isDashed) {
            path.setAttribute('stroke-dasharray', '10 20');
          }

          if (isWhite && !isDashed) {
            const whiteStyle = `filter: drop-shadow(-1px 1px 0px rgb(128, 128, 128)) drop-shadow(1px -1px 0px rgb(128, 128, 128));`;
            path.setAttribute('style', whiteStyle);
          }
        });

        return root;
      };

      if (type === 'pdf') {
        const printPDF = async () => {
          setIsLoading(true);
          const svgsData = [];

          try {
            for (let v = 0; v < verticalPageCount; v++) {
              for (let h = 0; h < horizontalPageCount; h++) {
                const svg = getSVG(h * paperSizeData.width, v * paperSizeData.height);
                const svgData = new XMLSerializer().serializeToString(svg);
                const xml = encodeURIComponent(svgData);
                svgsData.push(xml);
              }
            }
          } catch (error) {
            console.log(error);
          }

          postExportSVG({
            data: svgsData,
            width: paperSizeData.width,
            height: paperSizeData.height,
          })
            .then((data: any) => {
              const blob = new Blob([data], { type: 'application/pdf' });
              const href = window.URL.createObjectURL(blob);
              const a = document.createElement('a');
              a.href = href;
              a.download = fileName + '.pdf';
              document.body.appendChild(a);
              a.click();
              document.body.removeChild(a);
            })
            .catch((err: any) => console.error(err));

          setIsLoading(false);
        };

        printPDF();
      }

      if (type === 'svg') {
        const svg = getSVG(0, 0);
        const svgData = new XMLSerializer().serializeToString(svg);
        const xml = encodeURIComponent(svgData);
        const a = document.createElement('a');
        a.href = 'data:image/svg+xml; charset=utf8, ' + xml;
        a.download = fileName + '.svg';
        a.innerHTML = 'download the svg file';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    },
    [graph, paperSize],
  );

  const items: MenuProps['items'] = [
    {
      key: 'engineering-drawing',
      label: 'Engineering Drawing',
      type: 'group',
      children: [
        {
          key: 'engineering-drawing-pdf',
          label: 'PDF',
          disabled: !assemblyName || !isPaid,
          onClick: () => onExport(fileName, 'pdf'),
        },
        {
          key: 'engineering-drawing-svg',
          label: 'SVG',
          disabled: !assemblyName || !isPaid,
          onClick: () => onExport(fileName, 'svg'),
        },
      ],
    },
    {
      key: 'bom',
      label: 'BOM',
      type: 'group',
      children: [
        {
          key: 'bom-csv',
          label:
            billOfMaterialsData.length === 0 || !assemblyName ? (
              'Export to CSV'
            ) : (
              <CSVLink
                data={billOfMaterialsData}
                filename={`BOM-${fileName}.csv`}
                headers={csvHeaders}
              >
                Export to CSV
              </CSVLink>
            ),
          disabled: billOfMaterialsData.length === 0 || !assemblyName,
        },
      ],
    },
    {
      key: 'testing',
      label: 'Testing',
      type: 'group',
      children: [
        {
          key: 'cable-test-card',
          label: (
            <PDFDownloadLink
              document={
                <PDFPreview
                  data={cableTestCardData}
                  logoUrl={UIConfig?.logo_url || whoami?.data?.logo?.url}
                />
              }
              fileName="cable-test-card.pdf"
            >
              {({ blob, url, loading, error }) => {
                console.log('blob', blob);
                console.log('url', url);
                console.log('loading', loading);
                console.log('error', error);

                return loading ? 'Loading document...' : 'Cable Test Card';
              }}
            </PDFDownloadLink>
          ),
        },
      ],
    },
  ];

  return (
    <Dropdown menu={{ items }} trigger={['click']}>
      <Spin spinning={isLoading}>
        <MenuButton icon={<DownloadOutlined />} label="Download" />
      </Spin>
    </Dropdown>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    assemblyName: state.assemblyWizard.name,
    drawingNumber: state.assemblyWizard.drawingNumber,
    drawingRevision: state.assemblyWizard.drawingRevision,
    paperSize: state.assemblyWizard.paperSize,
    billOfMaterialsData: state.assemblyWizard.billOfMaterials.data ?? [],
    whoamiData: state.data.whoami.data,
  };
};

export default connect(mapStateToProps)(MenuDownload);
