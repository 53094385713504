import { Action, ActionCreator } from 'redux';

export enum SideMenuBarActionTypes {
  OPEN = 'SET_SIDE_MENU_BAR_OPEN',
  CLOSE = 'SET_SIDE_MENU_BAR_CLOSE',
}

// ACTIONS
export type SideMenuBarOpen = Action<SideMenuBarActionTypes.OPEN>;

export type SideMenuBarClose = Action<SideMenuBarActionTypes.CLOSE>;

// ACTION CREATORS
export const sideMenuBarOpen: ActionCreator<SideMenuBarOpen> = () => ({
  type: SideMenuBarActionTypes.OPEN,
});

export const sideMenuBarClose: ActionCreator<SideMenuBarClose> = () => ({
  type: SideMenuBarActionTypes.CLOSE,
});
