import { Reducer } from 'react';
import { cloneDeep, setWith, isEmpty } from 'lodash';

import { HiddenComponent, WizardState } from './State';

import { initialWizardState } from '.';

import {
  WizardAddComponent,
  WizardRemoveComponent,
  WizardUpdateComponentName,
  WizardUpdateComponentCableLength,
  WizardComponentTypes,
  WizardSetComponentsData,
  WizardAddHiddenComponent,
  WizardRemoveHiddenComponent,
  WizardLoadHiddenComponents,
  WizardSetDBComponentsData,
  WizardSetShowComponentControl,
  WizardSetShowWireControl,
} from '~/store/actions/wizard/Component';
import { PartItem } from '~/types';

const INITIAL_STATE_ADVANCED_OPTIONS = {
  shrinkTube: '0',
  labelingDetails: [],
  couplingNut: '0',
  couplingMaterial: '',
  lockingSleeveType: 'm',
  bendRadiusProtector: null,
  bendRadiusProtectorSize: null,
  overmouldMaterial: '',
  splice: '0',
  shellMaterial: '',
  probeLength: '',
  keyway: '0',
};

const wizardAddComponent = (action: WizardAddComponent, state: WizardState): WizardState => {
  const item = action.item;
  let itemSubId = 0;
  const itemId = item.id;
  const itemAlias = action.alias;

  const components = cloneDeep(state.components);

  if (!isEmpty(components) && !isEmpty(components[item.type])) {
    const component = components[item.type][itemId];

    if (component) {
      const keys = Object.keys(component);
      const lastKey = keys[keys.length - 1];
      itemSubId = parseInt(lastKey, 10) + 1;
    }
  }

  const key = `[${item.type}][${item.id}][${itemSubId}]`;
  const componentsData = setWith(components, key, { ...item }, Object);
  const componentsKey = componentsData[item.type][item.id][itemSubId] as PartItem;
  const componentType = componentsKey?.type;

  let defaultAlias = itemAlias || componentsKey?.name;

  if (itemSubId > 0) {
    defaultAlias += `(${itemSubId})`;
  }

  componentsKey['defaultAlias'] = defaultAlias;
  componentsKey['advancedQuestions'] = { ...INITIAL_STATE_ADVANCED_OPTIONS };
  componentsKey['textDetail'] = '';

  if (componentType === 'cable') {
    componentsKey['cableLength'] = 3;
    componentsKey['cableLengthUnit'] = 'mm';
  }

  return {
    ...state,
    components: componentsData,
    currentActiveId: itemId,
  };
};

const wizardUpdateComponentName = (
  action: WizardUpdateComponentName,
  state: WizardState,
): WizardState => {
  const components = cloneDeep(state.components);
  const itemType = action.itemType;

  if (itemType.includes('instrument-child')) {
    return state;
  }

  const component = components[itemType][action.id][action.subId];

  component['name'] = action.name;

  return {
    ...state,
    components,
  };
};

const wizardUpdateComponentCableLength = (
  action: WizardUpdateComponentCableLength,
  state: WizardState,
): WizardState => {
  const components = cloneDeep(state.components);
  const component = components[action.itemType][action.id][action.subId];

  component['cableLength'] = Number(action.cableLength);

  return {
    ...state,
    components,
  };
};

const wizardRemoveComponent = (action: WizardRemoveComponent, state: WizardState): WizardState => {
  const components = cloneDeep(state.components);

  if (action.itemType.includes('instrument-child')) {
    return state;
  }

  if (
    !components[action.itemType] ||
    !components[action.itemType][action.id] ||
    !components[action.itemType][action.id][action.subId]
  ) {
    return state;
  }

  delete components[action.itemType][action.id][action.subId];

  if (Object.keys(components[action.itemType][action.id]).length === 0) {
    delete components[action.itemType][action.id];
  }

  if (Object.keys(components[action.itemType]).length === 0) {
    delete components[action.itemType];
  }

  return {
    ...state,
    components,
  };
};

const wizardAddHiddenComponent = (
  action: WizardAddHiddenComponent,
  state: WizardState,
): WizardState => {
  const { cell } = action;

  const label = cell.getAttribute('label', null);
  const cellId = cell.id;
  const idData = cell.id.split('_');
  const componentType = idData[0];
  const wizardType = idData[1];
  const sideView = cell.id.includes('side') ? 'side view' : '';
  const frontView = cell.id.includes('front') ? 'front view' : '';

  const identifier = `${componentType} ${wizardType} ${label} ${sideView || frontView}`;

  const hiddenComponent = {
    id: cellId,
    identifier,
  };

  return {
    ...state,
    hiddenComponents: [...state.hiddenComponents, hiddenComponent],
  };
};

const wizardRemoveHiddenComponent = (
  action: WizardRemoveHiddenComponent,
  state: WizardState,
): WizardState => {
  const { id } = action;

  const hiddenComponents = state.hiddenComponents.filter((item: HiddenComponent) => item.id !== id);

  return {
    ...state,
    hiddenComponents,
  };
};

export const componentReducer: Reducer<
  WizardState,
  | WizardAddComponent
  | WizardUpdateComponentName
  | WizardUpdateComponentCableLength
  | WizardRemoveComponent
  | WizardSetComponentsData
  | WizardAddHiddenComponent
  | WizardRemoveHiddenComponent
  | WizardLoadHiddenComponents
  | WizardSetDBComponentsData
  | WizardSetShowComponentControl
  | WizardSetShowWireControl
> = (
  state: WizardState = initialWizardState,
  action:
    | WizardAddComponent
    | WizardUpdateComponentName
    | WizardUpdateComponentCableLength
    | WizardRemoveComponent
    | WizardSetComponentsData
    | WizardAddHiddenComponent
    | WizardRemoveHiddenComponent
    | WizardLoadHiddenComponents
    | WizardSetDBComponentsData
    | WizardSetShowComponentControl
    | WizardSetShowWireControl,
): WizardState => {
  switch (action.type) {
    case WizardComponentTypes.WIZARD_ADD_COMPONENT: {
      return wizardAddComponent(action, state);
    }
    case WizardComponentTypes.WIZARD_UPDATE_COMPONENT_NAME: {
      return wizardUpdateComponentName(action, state);
    }
    case WizardComponentTypes.WIZARD_UPDATE_COMPONENT_CABLE_LENGTH: {
      return wizardUpdateComponentCableLength(action, state);
    }
    case WizardComponentTypes.WIZARD_REMOVE_COMPONENT: {
      return wizardRemoveComponent(action, state);
    }
    case WizardComponentTypes.WIZARD_SET_COMPONENTS_DATA:
      return {
        ...state,
        components: action.components,
      };
    case WizardComponentTypes.WIZARD_ADD_HIDDEN_COMPONENT:
      return wizardAddHiddenComponent(action, state);
    case WizardComponentTypes.WIZARD_REMOVE_HIDDEN_COMPONENT:
      return wizardRemoveHiddenComponent(action, state);
    case WizardComponentTypes.WIZARD_LOAD_HIDDEN_COMPONENTS:
      return {
        ...state,
        hiddenComponents: action.hiddenComponents,
      };
    case WizardComponentTypes.WIZARD_SET_DB_COMPONENTS_DATA:
      return {
        ...state,
        dbComponents: action.components,
      };
    case WizardComponentTypes.WIZARD_SET_SHOW_COMPONENT_CONTROL:
      return {
        ...state,
        showComponentControl: action.show,
      };
    case WizardComponentTypes.WIZARD_SET_SHOW_WIRE_CONTROL:
      return {
        ...state,
        showWireControl: action.show,
      };
    default:
      return state;
  }
};
