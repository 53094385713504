import { configureStore } from '@reduxjs/toolkit';

import { initialActionsState } from './reducers/Actions';
import { initialConfigsState } from './reducers/configs';
import { initialDataState } from './reducers/data';
import { rootReducer } from './reducers/index';
import { initialUIState } from './reducers/ui';
import { initialWizardState } from './reducers/wizard';

const initialState = {
  action: initialActionsState,
  assemblyWizard: initialWizardState,
  data: initialDataState,
  ui: initialUIState,
  configs: initialConfigsState,
};

export const store = configureStore({
  reducer: rootReducer,
  preloadedState: initialState,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
