import { mxCell } from '@anekonnect/mxgraph';
import { mx } from '~/constants/wizard';

export const rectangleLabelFixer = (cell: mxCell) => {
  const isRectangle = (cell: mxCell) => {
    if (cell) {
      return cell.id.includes('rectangle');
    }
  };

  if (isRectangle(cell)) {
    const currentStyle = cell.getStyle();
    const newStyle = currentStyle.replace('verticalLabelPosition=top;verticalAlign=bottom;', '');
    cell.setStyle(newStyle);

    const offsetY = -cell.geometry.height / 2 - 11;

    if (!cell.geometry?.offset?.y) {
      cell.geometry.offset = new mx.mxPoint(cell.geometry?.offset?.x || 0, offsetY);
    }
  }
};
