const Constants = {
  penetratorContainerId: 'penetrator_draw',
  penetratorPinId: 'penetrator_pin',
  penetratorWidth: 40,
  penetratorHeight: 40,
  portLeftId: 'port_left',
  portRightId: 'port_right',
  portTopId: 'port_top',
  portBottomId: 'port_bottom',
  portWidth: 10,
  portHeight: 16,
  endTerminalPointStart: '-0.5',
  endTerminalPointEnd: '0.5',
  baseColor: 'black',
  innerEdgeId: 'inner_edge',
  outerEdgeId: 'outer_edge',
};

export default Constants;
