export const IMAGE_BASE_URL = '/img/';
export const LINK_HOME_PAGE = '/';
export const LINK_INDUSTRY_SOLUTIONS_PAGE = 'https://anekonnect.io/industry-solutions';
export const LINK_INDUSTRY_SOLUTIONS_PAGE_PLANS = 'https://anekonnect.io/industry-solutions#plans';
export const LINK_UNDER_CONSTRUCTION_PAGE = '/under-construction';
export const LINK_NOTIFICATIONS = '/notifications';
export const LINK_ASSEMBLIES = '/assemblies';
export const LINK_ASSEMBLY_NEW = '/assemblies/wizard';
export const LINK_ASSEMBLY_EDIT = '/assemblies/wizard/edit';
export const LINK_ASSEMBLY_DETAIL = '/assembly/';
export const LINK_LOGIN_PAGE = '/login';
export const LINK_SIGNUP_PAGE = '/signup';
export const LINK_CONSUMER_SOLUTIONS = 'https://anekonnect.io/consumer-solutions';
export const LINK_CONSUMER_SOLUTIONS_PLANS = 'https://anekonnect.io/consumer-solutions#plans';
export const LINK_VIDEOS = 'https://anekonnect.io/resources-video';
export const LINK_FAQS = 'https://anekonnect.io/resources-faq';
export const LINK_CONTACT_US = 'https://anekonnect.io/contact';
export const LINK_ABOUT_US = 'https://anekonnect.io/about';
export const LINK_NEWS_AND_EVENTS = '/under-construction';
export const LINK_LINKEDIN = 'https://www.linkedin.com/company/18156456/';
export const LINK_TWITTER = 'https://twitter.com/anekonnect_io';
export const LINK_PRIVACY_POLICY = 'https://anekonnect.io/home/privacy-policy/';
export const LINK_TERMS_CONDITIONS = 'https://anekonnect.io/home/terms-of-use/';
export const LINK_LIST_USER = '/users/';
export const LINK_USER_PROFILE = '/user/profile';
export const LINK_MY_PROFILE = '/users/my-profile/';
export const LINK_SUPPORT = 'https://support.anekonnect.io/';
export const LINK_CREATE_VENDOR = '/vendors/create';
export const LINK_REQUESTED_QUOTES = '/requested-quotes';
export const LINK_QUOTE_RESPONSES = '/quote-responses';

export const LINK_LIST_PARTS = '/parts';
export const GET_LINK_VIEW_PARTS = (partName: string) => `/parts/${partName}`;
export const GET_LINK_ADD_PARTS_ITEM = (partName: string) => `/parts/${partName}/add`;
export const GET_LINK_VIEW_PARTS_ITEM = (partName: string, partId: string | number) =>
  `/parts/${partName}/${partId}`;
export const GET_LINK_COPY_PARTS_ITEM = (partName: string, partId: string | number) =>
  `/parts/${partName}/${partId}/copy`;
export const GET_LINK_EDIT_PARTS_ITEM = (partName: string, partId: string | number) =>
  `/parts/${partName}/${partId}/edit`;

export type menu = {
  title: string;
  url?: string;
  icon?: string;
  matchUrl?: string[];
  external?: boolean;
  target?: '_blank' | '_parent' | '_self' | '_top';
  subMenu?: subMenu[];
};

export type subMenu = {
  title: string;
  url: string;
  icon?: string;
  matchUrl?: string[];
  hideInTenant?: boolean;
  external?: boolean;
  target?: '_blank' | '_parent' | '_self' | '_top';
  onClick?: () => void;
};

export const MENU_HEADER_CAD_MODELS: menu = {
  title: 'Design Application',
  subMenu: [
    {
      title: 'Design New Assembly',
      url: LINK_ASSEMBLY_NEW,
      target: '_blank',
      external: true,
    },
    {
      title: 'Catalogued Parts',
      url: LINK_LIST_PARTS,
      hideInTenant: true,
    },
  ],
};

export const MENU_HEADER_SOLUTIONS: menu = {
  title: 'Pricing',
  subMenu: [
    {
      title: 'Individuals & Small Business',
      url: LINK_CONSUMER_SOLUTIONS,
      external: true,
    },
    {
      title: 'Enterprise',
      url: LINK_INDUSTRY_SOLUTIONS_PAGE,
      external: true,
    },
  ],
};

export const MENU_HEADER_RESOURCES: menu = {
  title: 'Resources',
  subMenu: [
    {
      title: 'Videos',
      url: LINK_VIDEOS,
      external: true,
    },
    {
      title: 'FAQs',
      url: LINK_FAQS,
      external: true,
    },
  ],
};

export const MENU_HEADER_LOGIN: menu = {
  title: 'Auth',
  subMenu: [
    {
      title: 'Login',
      url: LINK_LOGIN_PAGE,
    },
    {
      title: 'Signup',
      url: LINK_SIGNUP_PAGE,
    },
  ],
};

export const MENU_HEADER_SIGNIN: subMenu[] = [
  {
    title: 'Design New Assembly',
    url: LINK_ASSEMBLY_NEW,
    icon: 'login-header-create-assembly',
    matchUrl: [LINK_ASSEMBLY_NEW, LINK_ASSEMBLY_EDIT],
  },
  {
    title: 'My Assemblies',
    url: LINK_ASSEMBLIES,
    icon: 'login-header-assemblies',
  },
  {
    title: 'Cataloged Parts',
    url: LINK_LIST_PARTS,
    target: '_blank',
    icon: 'login-header-connectors',
  },
  {
    title: 'Notifications',
    url: LINK_NOTIFICATIONS,
    icon: 'login-header-notifications',
  },
  {
    title: 'Help',
    url: LINK_SUPPORT,
    target: '_blank',
    external: true,
    icon: 'login-header-faqs',
  },
];
