import { Image, Document, Page, Text, View, StyleSheet, Font } from '@react-pdf/renderer';
import { Key, useEffect, useState } from 'react';
import { AppState } from '~/store/reducers';
import { getImageDimension, resizeImageByHeight } from '../MxGraph/Helper';

Font.register({
  family: 'Oswald',
  src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf',
});

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 24,
    textAlign: 'center',
    fontFamily: 'Oswald',
    marginBottom: '20px',
  },
  text: {
    fontSize: 11,
  },
  textTitle: {
    fontSize: 11,
    minWidth: '150px',
  },
  valueBox: {
    fontSize: 11,
    borderBottom: '1px solid black',
    minWidth: '150px',
    paddingBottom: '5px',
  },
  wrapperRowStart: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  wrapperRowEnd: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  wrapperRowSpace: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '20px',
  },
  divider: {
    width: '100%',
    height: '1px',
    borderBottom: '1px solid black',
    marginTop: '25px',
    marginBottom: '5px',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
});

type PDFPreviewProps = {
  data: AppState['assemblyWizard']['cableTestCardData'];
  logoUrl?: string;
};

const PDFPreview = ({
  data,
  logoUrl = 'https://storage.googleapis.com/anekonnect.appspot.com/21f6ec1f-75e8-401c-a0ee-a4a2aff5d141.png',
}: PDFPreviewProps) => {
  const [imageDimension, setImageDimension] = useState({ width: 0, height: 0 });

  useEffect(() => {
    getImageDimension(logoUrl).then((res) => {
      setImageDimension(resizeImageByHeight(res, 72));
    });
  }, [logoUrl]);

  const renderTestsView = (data: any) => (
    <>
      <View style={styles.wrapperRowSpace}>
        <Text style={[styles.text, { minWidth: '160px' }]}>Test</Text>
        <View style={{ display: 'flex', flexDirection: 'row' }}>
          <Text style={[styles.text, { minWidth: '55px', marginRight: '5px' }]}>Test Value</Text>
          <Text style={[styles.text, { minWidth: '25px', marginRight: '5px' }]}>Unit</Text>
          <Text style={[styles.text, { minWidth: '100px', marginRight: '5px' }]}>
            @Constraint Value
          </Text>
          <Text style={[styles.text, { minWidth: '25px' }]}>Unit</Text>
        </View>
        <Text style={[styles.textTitle, { minWidth: '90px' }]}>Tested By n' Date</Text>
      </View>
      {data?.map((item: any, index: Key) => (
        <View style={styles.wrapperRowSpace} key={index}>
          <Text style={[styles.valueBox, { minWidth: '160px' }]}>{item?.test}</Text>
          <View style={{ display: 'flex', flexDirection: 'row' }}>
            <Text style={[styles.valueBox, { minWidth: '55px', marginRight: '5px' }]}>
              {item?.testValue}
            </Text>
            <Text style={[styles.valueBox, { minWidth: '25px', marginRight: '5px' }]}>
              {item?.testUnit}
            </Text>
            <Text style={[styles.valueBox, { minWidth: '100px', marginRight: '5px' }]}>
              {`@${item?.constraintValue || ''}`}
            </Text>
            <Text style={[styles.valueBox, { minWidth: '25px' }]}>{item?.constraintUnit}</Text>
          </View>
          <Text style={[styles.valueBox, { minWidth: '90px' }]}>{item?.testedByNDate}</Text>
        </View>
      ))}
    </>
  );

  return (
    <Document>
      <Page size="A4" style={styles.body}>
        <Image
          src={logoUrl}
          style={{
            height: imageDimension.height,
            width: imageDimension.width,
            marginBottom: '10px',
          }}
        />
        <Text style={styles.title}>Cable Test Card</Text>
        <View>
          <View style={styles.wrapperRowSpace}>
            <Text style={styles.text}>File #</Text>
            <Text style={styles.valueBox}>{data?.fileNumber}</Text>
          </View>
          <View style={styles.wrapperRowSpace}>
            <View style={styles.wrapperRowStart}>
              <Text style={styles.text}>Drawing #</Text>
              <Text style={[styles.valueBox, { marginLeft: '10px' }]}>
                {data?.drawingInfo?.drawingNumber}
              </Text>
            </View>
            <View style={styles.wrapperRowEnd}>
              <Text style={styles.text}>Rev</Text>
              <Text style={[styles.valueBox, { marginLeft: '10px' }]}>
                {data?.drawingInfo?.drawingRevision}
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.divider} />
        {data?.testInstruments?.length > 0 && (
          <>
            <View>
              <View style={styles.wrapperRowSpace}>
                <Text style={[styles.textTitle, { minWidth: '180px' }]}>Instrument</Text>
                <Text style={styles.textTitle}>Calibration Expiration Date</Text>
                <Text style={styles.textTitle}>Serial Number</Text>
              </View>
              {data?.testInstruments?.map((item: any, index: Key) => (
                <View style={styles.wrapperRowSpace} key={index}>
                  <Text style={[styles.valueBox, { minWidth: '180px' }]}>{item.instrument}</Text>
                  <Text style={styles.valueBox}>{item.calibrationExpirationDate}</Text>
                  <Text style={styles.valueBox}>{item.serialNumber}</Text>
                </View>
              ))}
            </View>
            <View style={styles.divider} />
          </>
        )}
        <View>
          <View style={styles.wrapperRowSpace}>
            <Text style={styles.textTitle}>Visual Inspections</Text>
            <Text style={styles.textTitle}>Tested By</Text>
          </View>
          <View style={styles.wrapperRowSpace}>
            <Text style={styles.textTitle}>Cabel Dimensions</Text>
            <Text style={styles.valueBox}>{data?.visualInspections?.dimensions}</Text>
          </View>
          <View style={styles.wrapperRowSpace}>
            <Text style={styles.textTitle}>Cabel Molding</Text>
            <Text style={styles.valueBox}>{data?.visualInspections?.molding}</Text>
          </View>
          <View style={styles.wrapperRowSpace}>
            <Text style={styles.textTitle}>Jacket Integrity</Text>
            <Text style={styles.valueBox}>{data?.visualInspections?.cableJacketInspection}</Text>
          </View>
        </View>
        <View style={styles.divider} />
        <View>
          <Text style={{ fontSize: '18px', marginTop: '10px' }}>Tests</Text>
          {data?.electricalTests?.length > 0 && (
            <View>
              <View style={styles.wrapperRowSpace}>
                <Text style={styles.textTitle}>Electrical Tests</Text>
              </View>
              {renderTestsView(data?.electricalTests)}
            </View>
          )}
          {data?.coaxTests?.length > 0 && (
            <View>
              <View style={styles.wrapperRowSpace}>
                <Text style={styles.textTitle}>Coax Tests</Text>
              </View>
              {renderTestsView(data?.coaxTests)}
            </View>
          )}
          {data?.fiberOpticTests?.length > 0 && (
            <View>
              <View style={styles.wrapperRowSpace}>
                <Text style={styles.textTitle}>Fiber Optic Tests</Text>
              </View>
              {renderTestsView(data?.fiberOpticTests)}
            </View>
          )}
          {data?.powerTests?.length > 0 && (
            <View>
              <View style={styles.wrapperRowSpace}>
                <Text style={styles.textTitle}>Power Tests</Text>
              </View>
              {renderTestsView(data?.powerTests)}
            </View>
          )}
          {data?.ethernetTests?.length > 0 && (
            <View>
              <View style={styles.wrapperRowSpace}>
                <Text style={styles.textTitle}>Power Tests</Text>
              </View>
              {renderTestsView(data?.ethernetTests)}
            </View>
          )}
          {data?.hyperbericTests?.length > 0 && (
            <View>
              <View style={styles.wrapperRowSpace}>
                <Text style={styles.textTitle}>Power Tests</Text>
              </View>
              {renderTestsView(data?.hyperbericTests)}
            </View>
          )}
        </View>
        <View style={styles.divider} />
        <View>
          <Text style={{ fontSize: '16px', marginTop: '20px' }}>Comments</Text>
          <Text
            style={{
              fontSize: '11px',
              lineHeight: '1.6',
              padding: '20px',
              border: '1px solid black',
              borderRadius: '5px',
              marginTop: '10px',
            }}
          >
            {data?.comments}
          </Text>
        </View>
        <View style={{ marginTop: '30px' }}>
          <Text style={{ fontSize: '16px' }}>Approvals</Text>
          <View style={styles.wrapperRowSpace}>
            <Text style={styles.textTitle}>Date</Text>
            <Text style={styles.textTitle}>Engineer</Text>
            <Text style={styles.textTitle}>QC Inspector</Text>
            <Text style={styles.textTitle}>Third Party</Text>
          </View>
          <View style={styles.wrapperRowSpace}>
            <Text style={styles.textTitle}>{data?.approvals?.date}</Text>
            <Text style={styles.textTitle}>{data?.approvals?.engineer}</Text>
            <Text style={styles.textTitle}>{data?.approvals?.qcInspector}</Text>
            <Text style={styles.textTitle}>{data?.approvals?.thirdParty}</Text>
          </View>
        </View>
        <Text
          render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
          style={styles.pageNumber}
          fixed
        />
      </Page>
    </Document>
  );
};

export default PDFPreview;
