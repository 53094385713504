import { mxGraph } from '@anekonnect/mxgraph';
import React, { useRef } from 'react';

import { ComponentData } from '../../Types';

import Draw from './Draw';
import Events from './Events';
import { SplitJunctionCellRef } from './Types';

import { ObjectShape } from '~/store/reducers/configs';

type SplitJunctionProps = {
  graph: mxGraph;
  data: ComponentData;
  objects: ObjectShape[];
};

const SplitJunction = ({ graph, data: splitJunctionData, objects }: SplitJunctionProps) => {
  const splitJunctionCellRef = useRef<SplitJunctionCellRef>({
    hoverCellRects: [],
  });

  const drawProps = {
    graph,
    data: splitJunctionData,
    splitJunctionCellRef,
    objects,
  };

  const eventsProps = {
    graph,
    splitJunctionCellRef,
  };

  return (
    <React.Fragment>
      <Draw {...drawProps} />
      <Events {...eventsProps} />
    </React.Fragment>
  );
};

export default SplitJunction;
