import React from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ReadOnlyField = (props: any) => {
  const { id, label, required, value, schema } = props;

  return (
    <React.Fragment>
      <label className="control-label" htmlFor={id}>
        {label}
        {required ? '*' : null}
      </label>
      <div className="field-content-input">
        <input
          className="form-control"
          style={{
            background: 'rgba(0,0,0,0.02)',
            cursor: 'not-allowed',
          }}
          type="text"
          value={value || schema.default}
        />
      </div>
    </React.Fragment>
  );
};

export { ReadOnlyField };
