import { LoadingOutlined } from '@ant-design/icons';
import { Result } from 'antd';
import { useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getZendeskToken } from '~/api/AuthorizedGets';
import { LINK_SUPPORT } from '~/constants/paths';
import { useAppSelector } from '~/store/hooks';
import { AppState } from '~/store/reducers';

const Zendesk = () => {
  const navigate = useNavigate();

  const { authenticated: isAuthenticated } = useAppSelector((state: AppState) => state.data.auth);
  const tenantConfig = useAppSelector((state: AppState) => state.data.tenantConfig);
  const tenantConfigData = tenantConfig.data;
  const primaryColor = tenantConfigData?.ui_config?.primary_color || '#00D3B2';

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const returnTo = params.get('return_to');

  const formRef = useRef<HTMLFormElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const zendeskCallback = async () => {
      const response = await getZendeskToken();

      if (response?.token) {
        const form = formRef.current;
        const input = inputRef.current;

        if (form && input) {
          input.value = response.token;
          form.submit();
        }
      } else {
        window.location.replace(LINK_SUPPORT);
      }
    };

    if (!isAuthenticated) {
      navigate(`/login?return_to=${returnTo}`);
    } else {
      zendeskCallback();
    }
  }, [isAuthenticated, navigate, returnTo]);

  return (
    <>
      <form ref={formRef} action="https://anekonnect.zendesk.com/access/jwt" method="POST">
        <input type="hidden" name="jwt" ref={inputRef} />
      </form>
      <Result
        icon={<LoadingOutlined style={{ color: primaryColor }} />}
        title="Please wait, we are checking your account now"
        style={{
          height: 'calc(100vh - 227px)',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      />
    </>
  );
};

export default Zendesk;
