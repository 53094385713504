import React from 'react';
import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import { Tooltip } from 'antd';
import Icon from '@ant-design/icons';

type RenderComponentProps = {
  title: string;
  component: React.ComponentType<CustomIconComponentProps | React.SVGProps<SVGSVGElement>>;
  onClick: () => void;
};

const RenderComponent = ({ title, component, onClick }: RenderComponentProps) => (
  <Tooltip placement="top" title={title}>
    <Icon component={component} style={{ fontSize: '32px' }} onClick={onClick} />
  </Tooltip>
);

export default RenderComponent;
