import { Reducer } from 'redux';

import { SnackBarOpen, SnackBarClose, SnackBarActionTypes } from './../../actions/ui/SnackBar';
import { SUCCESS } from './../../../constants/index';

// STATE
export type SnackBarState = {
  open: boolean;
  message: string;
  status: string;
  delay: number;
};

export const initialSnackBarState: SnackBarState = {
  open: false,
  message: 'Success',
  status: SUCCESS,
  delay: 0,
};

// REDUCER
export const snackBarReducer: Reducer<SnackBarState> = (
  state: SnackBarState = initialSnackBarState,
  action: SnackBarOpen | SnackBarClose,
) => {
  switch (action.type) {
    case SnackBarActionTypes.OPEN:
      return {
        ...state,
        open: true,
        message: action.message,
        status: action.status,
        delay: action.delay,
      };
    case SnackBarActionTypes.CLOSE:
      return initialSnackBarState;
    default:
      return state;
  }
};
