import { mxGraph } from '@anekonnect/mxgraph';
import React from 'react';

import { ComponentData } from '../../Types';

import Draw from './Draw';

import { ObjectShape } from '~/store/reducers/configs';

type RectangleProps = {
  graph: mxGraph;
  data: ComponentData;
  objects: ObjectShape[];
};

const Rectangle = ({ graph, data: rectangleData, objects }: RectangleProps) => {
  const drawProps = {
    data: rectangleData,
    graph,
    objects,
  };

  return (
    <React.Fragment>
      <Draw {...drawProps} />
    </React.Fragment>
  );
};

export default Rectangle;
