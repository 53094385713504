const Constants = {
  connectorContainerId: 'connector_draw',
  connectorPinId: 'connector_pin',
  connectorWidth: 40,
  connectorHeight: 40,
  portLeftId: 'port_left',
  portRightId: 'port_right',
  portTopId: 'port_top',
  portBottomId: 'port_bottom',
  portWidth: 10,
  portHeight: 16,
  endTerminalPointStart: '-0.5',
  endTerminalPointEnd: '0.5',
  baseColor: 'black',
  innerEdgeId: 'inner_edge',
  outerEdgeId: 'outer_edge',
};

export default Constants;
