import { Card, List, Typography } from 'antd';
import Search from 'antd/lib/input/Search';
import * as queryString from 'query-string';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { Part } from '../../api/API';
import { LINK_LIST_PARTS } from '../../constants/paths';
import { AppState } from '../../store/reducers';
import { getParts } from '../../utils/parts';
import { isStateLoading } from '../../utils/state';

import PartsSearch from './components/PartsSearch';
import styles from './Parts.module.scss';

type PropsFromState = {
  parts: Part[];
  isLoading: boolean;
};

type PartsProps = PropsFromState;

const Parts = (props: PartsProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [pageTitle, setPageTitle] = useState('Parts');
  const [isThisSearchMode, setIsThisSearchMode] = useState(false);
  const [searchQuery, setSearchQuery] = useState<{ q?: string }>({});
  const search = location && location.search ? location.search : '';

  useEffect(() => {
    setSearchQuery(queryString.parse(search) || {});
  }, [search]);

  const searchText = (searchQuery && (searchQuery.q as string)) || '';

  const handleSearchQuery = useCallback(() => {
    setIsThisSearchMode(Object.values(searchQuery).length > 0);
    setPageTitle(!searchText ? 'Parts' : `Search Result for "${searchText}"`);
  }, [searchQuery, searchText]);

  useEffect(handleSearchQuery, [handleSearchQuery]);

  const onSearch = (searchString: string) => {
    const query: { q?: string } = {
      ...searchQuery,
      q: searchString,
    };

    if (!searchString) {
      delete query.q;
    }

    const search = queryString.stringify(query);

    navigate({
      pathname: LINK_LIST_PARTS,
      search,
    });
  };

  return (
    <Fragment>
      <div className={styles.header}>
        <Typography.Title level={2} style={{ marginTop: '0px' }}>
          {pageTitle}
        </Typography.Title>
        <Search
          className={styles.search}
          enterButton={false}
          placeholder="Search Parts"
          value={searchText}
          onChange={(e) => onSearch(e.target.value)}
          onSearch={onSearch}
          size="large"
        />
      </div>
      {!isThisSearchMode && (
        <List
          dataSource={props.parts}
          grid={{
            gutter: 16,
            xs: 1,
            sm: 2,
            md: 3,
            lg: 4,
            xl: 5,
            xxl: 6,
          }}
          loading={props.isLoading || props.parts.length === 0}
          renderItem={(part) => (
            <List.Item>
              <Link to={'/parts/' + part.name}>
                <Card className={styles.card}>{part.display_name}</Card>
              </Link>
            </List.Item>
          )}
        />
      )}
      {isThisSearchMode && <PartsSearch filters={searchQuery} query={searchText} search={search} />}
    </Fragment>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    parts: getParts(state) || [],
    isLoading: isStateLoading(state.action, 'whoami'),
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Parts);
