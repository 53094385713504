import { Fragment, useCallback, useMemo, useRef, useState } from 'react';

import { Menu, Button, Tooltip, Drawer, MenuProps } from 'antd';
import { LoadingOutlined, LoginOutlined, MenuOutlined, UserAddOutlined } from '@ant-design/icons';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';

import useWindow from '~/hooks/useWindow';

import {
  LINK_LOGIN_PAGE,
  LINK_SIGNUP_PAGE,
  LINK_ASSEMBLY_NEW,
  LINK_ASSEMBLIES,
  LINK_LIST_PARTS,
  LINK_NOTIFICATIONS,
  LINK_USER_PROFILE,
  LINK_LIST_USER,
  LINK_CREATE_VENDOR,
  LINK_REQUESTED_QUOTES,
  LINK_QUOTE_RESPONSES,
  LINK_SUPPORT,
  LINK_PRIVACY_POLICY,
  LINK_TERMS_CONDITIONS,
} from '~/constants/paths';
import { AppState } from '~/store/reducers';
import { logout } from '~/api/Authorizations';
import { getZendeskToken } from '~/api/AuthorizedGets';
import { useAppSelector } from '~/store/hooks';

import styles from './NavBar.module.scss';

type PropsFromState = {
  isAuthenticated: boolean;
  whoamiData: {
    type: string;
    name: string;
    username: string;
    permissions: string[];
    roles: string[];
  };
  isTenant?: boolean;
};

type NavBarProps = PropsFromState;

const NavBar = ({ isAuthenticated, whoamiData, isTenant }: NavBarProps) => {
  const location = useLocation();
  const navigation = useNavigate();
  const windowWidth = useWindow();
  const isDesktop = windowWidth > 1072;
  const tenantConfig = useAppSelector((state: AppState) => state.data.tenantConfig.data);

  const primaryColor = tenantConfig?.ui_config?.primary_color || '#00D3B2';
  const formRef = useRef<HTMLFormElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const permissions = useMemo(
    () => (whoamiData !== undefined ? whoamiData['permissions'] : []),
    [whoamiData],
  );
  const roles = useMemo(() => (whoamiData !== undefined ? whoamiData['roles'] : []), [whoamiData]);

  const [showDrawer, setShowDrawer] = useState(false);

  const generateButtonIcon = useCallback(
    (title: string, icon: string, link: string, external = false, reloadDocument = false) => {
      return isDesktop ? (
        <Tooltip color={primaryColor} placement="bottom" title={title}>
          {external ? (
            <a href={link} target="_blank" rel="noreferrer">
              <i className={`${styles.menuIcon} icomoon-${icon}`} />
            </a>
          ) : (
            <Link to={link} reloadDocument={reloadDocument}>
              <i className={`${styles.menuIcon} icomoon-${icon}`} />
            </Link>
          )}
        </Tooltip>
      ) : (
        <Fragment>
          {external ? (
            <a href={link} target="_blank" rel="noreferrer">
              {title}
            </a>
          ) : (
            <Link to={link} reloadDocument={reloadDocument}>
              {title}
            </Link>
          )}
        </Fragment>
      );
    },
    [isDesktop, primaryColor],
  );

  const zendeskLogin = useCallback(async () => {
    if (!isAuthenticated) {
      window.open(LINK_SUPPORT, '_blank', 'noopener noreferrer');
      return;
    }

    const response = await getZendeskToken();

    if (response?.token) {
      const form = formRef.current;
      const input = inputRef.current;

      if (form && input) {
        input.value = response.token;
        form.submit();
      }
    } else {
      window.open(LINK_SUPPORT, '_blank', 'noopener noreferrer');
    }
  }, [isAuthenticated]);

  const renderHelpMenu = useCallback(() => {
    return isDesktop ? (
      <Tooltip color={primaryColor} placement="bottom" title={'Help'}>
        <div onClick={zendeskLogin}>
          <i className={`${styles.menuIcon} icomoon-login-header-faqs`} />
        </div>
      </Tooltip>
    ) : (
      <Fragment>
        <div onClick={zendeskLogin}>Help</div>
      </Fragment>
    );
  }, [isDesktop, primaryColor, zendeskLogin]);

  const guestMenu: MenuProps['items'] = useMemo(
    () => [
      {
        label: generateButtonIcon(
          'Design New Assembly',
          'login-header-create-assembly',
          LINK_ASSEMBLY_NEW,
          false,
          true,
        ),
        key: LINK_ASSEMBLY_NEW,
      },
      {
        label: generateButtonIcon(
          'Cataloged Parts',
          'login-header-connectors',
          LINK_LIST_PARTS,
          true,
        ),
        key: LINK_LIST_PARTS,
      },
      {
        label: renderHelpMenu(),
        key: 'help',
      },
      !isDesktop
        ? {
            label: 'Login',
            key: LINK_LOGIN_PAGE,
            onClick: () => {
              navigation(LINK_LOGIN_PAGE);
            },
          }
        : null,
      isTenant === false && !isDesktop
        ? {
            label: 'Sign Up',
            key: LINK_SIGNUP_PAGE,
            onClick: () => {
              navigation(LINK_SIGNUP_PAGE);
            },
          }
        : null,
    ],
    [generateButtonIcon, isDesktop, isTenant, navigation, renderHelpMenu],
  );

  const authMenu: MenuProps['items'] = useMemo(
    () => [
      {
        label: generateButtonIcon(
          'Design New Assembly',
          'login-header-create-assembly',
          LINK_ASSEMBLY_NEW,
          false,
          true,
        ),
        key: LINK_ASSEMBLY_NEW,
      },
      {
        label: generateButtonIcon('My Assemblies', 'login-header-assemblies', LINK_ASSEMBLIES),
        key: LINK_ASSEMBLIES,
      },
      {
        label: generateButtonIcon(
          'Cataloged Parts',
          'login-header-connectors',
          LINK_LIST_PARTS,
          true,
        ),
        key: LINK_LIST_PARTS,
      },
      isTenant === false && roles?.includes('Vendor')
        ? {
            label: generateButtonIcon(
              'Requested Quotes',
              'login-header-quotes',
              LINK_REQUESTED_QUOTES,
            ),
            key: LINK_REQUESTED_QUOTES,
          }
        : null,
      isTenant === false && !roles?.includes('Vendor')
        ? {
            label: generateButtonIcon(
              'Quote Dashboard',
              'login-header-quotes',
              LINK_QUOTE_RESPONSES,
            ),
            key: LINK_QUOTE_RESPONSES,
          }
        : null,
      {
        label: generateButtonIcon(
          'Notifications',
          'login-header-notifications',
          LINK_NOTIFICATIONS,
        ),
        key: LINK_NOTIFICATIONS,
      },
      {
        label: renderHelpMenu(),
        key: 'help',
      },
      {
        label: whoamiData?.name || whoamiData?.username || <LoadingOutlined />,
        key: 'user',
        children: [
          {
            label: 'My Profile',
            key: LINK_USER_PROFILE,
            onClick: () => {
              navigation(LINK_USER_PROFILE);
            },
          },
          isTenant === false &&
            roles?.includes('SuperAdmin') && {
              label: 'Create Vendor',
              key: LINK_CREATE_VENDOR,
              onClick: () => {
                navigation(LINK_CREATE_VENDOR);
              },
            },
          permissions?.includes('ReadAllUser') && {
            label: 'Users',
            key: LINK_LIST_USER,
            onClick: () => {
              navigation(LINK_LIST_USER);
            },
          },
          {
            label: 'Logout',
            key: 'logout',
            onClick: () => {
              logout();
            },
          },
          isTenant === false && {
            label: 'Policy Documents',
            key: 'policy-documents',
            children: [
              {
                key: 'privacy-policy',
                label: (
                  <a href={LINK_PRIVACY_POLICY} rel="noopener noreferrer" target="_blank">
                    Privacy Policy
                  </a>
                ),
              },
              {
                key: 'terms-and-conditions',
                label: (
                  <a href={LINK_TERMS_CONDITIONS} rel="noopener noreferrer" target="_blank">
                    Terms And Conditions
                  </a>
                ),
              },
              {
                key: 'return-policy',
                label: (
                  <a
                    href="https://anekonnect.io/returnpolicy"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Return Policy
                  </a>
                ),
              },
              {
                key: 'disclaimer',
                label: (
                  <a
                    href="https://anekonnect.io/disclaimer"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Disclaimer
                  </a>
                ),
              },
            ],
          },
        ],
      },
    ],
    [
      generateButtonIcon,
      isTenant,
      roles,
      renderHelpMenu,
      whoamiData?.name,
      whoamiData?.username,
      permissions,
      navigation,
    ],
  );

  const renderLoginButtons = () => {
    return (
      <div className={styles.loginButtons}>
        <Link to={LINK_LOGIN_PAGE}>
          <Button icon={<LoginOutlined />} type="dashed">
            Login
          </Button>
        </Link>
        {isTenant === false && (
          <Link to={LINK_SIGNUP_PAGE}>
            <Button icon={<UserAddOutlined />} type="primary">
              Sign Up
            </Button>
          </Link>
        )}
      </div>
    );
  };

  const renderMenu = () => {
    return (
      <Fragment>
        <Menu
          items={isAuthenticated ? authMenu : guestMenu}
          mode={isDesktop ? 'horizontal' : 'inline'}
          selectedKeys={[location?.pathname]}
          disabledOverflow={isDesktop}
          className={isDesktop ? styles.antMenu : ''}
          onClick={() => {
            setShowDrawer(false);
          }}
          triggerSubMenuAction="click"
        />
        {!isAuthenticated && isDesktop && renderLoginButtons()}
      </Fragment>
    );
  };

  return (
    <Fragment>
      <form
        ref={formRef}
        action="https://anekonnect.zendesk.com/access/jwt"
        method="POST"
        target="_blank"
      >
        <input type="hidden" name="jwt" ref={inputRef} />
      </form>
      <div className={styles.menuDesktop}>{renderMenu()}</div>
      <div className={styles.menuMobile}>
        <Button icon={<MenuOutlined />} onClick={() => setShowDrawer(true)} />
        <Drawer
          rootClassName={styles.drawerBody}
          title="MENU"
          open={showDrawer}
          onClose={() => setShowDrawer(false)}
        >
          {renderMenu()}
        </Drawer>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    isAuthenticated: state.data.auth.authenticated,
    whoamiData: state.data.whoami.data,
    isTenant: state.data.tenantConfig.isTenant,
  };
};

export default connect(mapStateToProps)(NavBar);
