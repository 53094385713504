import { StyleMap } from '@anekonnect/mxgraph';
import React, { useRef } from 'react';

import { GraphProps } from '../../Graph/Types';
import { ComponentData } from '../../Types';

import Draw from './Draw';
import Events from './Events';

import { ObjectShape } from '~/store/reducers/configs';

type PenetratorProps = {
  data: ComponentData;
  objects: ObjectShape[];
} & Pick<GraphProps, 'graph'>;

const Penetrator = ({ graph, data, objects }: PenetratorProps) => {
  const defaultStylesRef = useRef<StyleMap>();

  const drawProps = {
    graph,
    defaultStylesRef,
    data,
    objects,
  };

  return (
    <React.Fragment>
      <Draw {...drawProps} />
      <Events graph={graph} />
    </React.Fragment>
  );
};

export default Penetrator;
