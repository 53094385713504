import React from 'react';
import { connect } from 'react-redux';

import { AppState } from '../store/reducers';

import './X3d.scss';

type OwnProps = {
  fileUrl: string;
  jpegFileUrl: string;
  name: string;
  width: number;
  height: number;
  className?: string;
};
type PropsFromState = {
  supportsWebgl: boolean;
};
type ComponentProps = OwnProps & PropsFromState;

declare let x3dom: { reload: { (): void; (): void } };

class X3d extends React.Component<ComponentProps> {
  componentDidMount() {
    x3dom.reload();
  }

  componentDidUpdate(prevProps: ComponentProps) {
    if (prevProps.fileUrl && this.props.fileUrl && prevProps.fileUrl !== this.props.fileUrl) {
      x3dom.reload();
    }
  }

  render() {
    const { fileUrl, jpegFileUrl, supportsWebgl, width, height, name, className } = this.props;

    let x3dWidth = width || 450;
    const x3dHeight = height || 450;

    if (x3dWidth) {
      x3dWidth = window.innerWidth < x3dWidth ? window.innerWidth : x3dWidth;
    }

    const innerHtml = `
            <x3d width='100%' height='${x3dHeight}px'>
                <scene>
                    <inline nameSpaceName="Component" mapDEFToID="true" url='${fileUrl}' />
                </scene>
            </x3d>
        `;

    const jpegFileData = {
      url: jpegFileUrl ? jpegFileUrl : '/img/coming-soon.jpg',
      alt: jpegFileUrl ? `Image for ${name}` : 'Coming Soon',
    };

    return (
      <div className={className}>
        {supportsWebgl && fileUrl ? (
          <div dangerouslySetInnerHTML={{ __html: innerHtml }} className="converter-img clearfix" />
        ) : (
          <img
            alt={jpegFileData.url}
            height={`${x3dHeight}px`}
            src={jpegFileData.url}
            width={`${x3dWidth}px`}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  return {
    supportsWebgl: state.ui.browserSupport.webgl,
  };
};

export default connect(mapStateToProps)(X3d);
