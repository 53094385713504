import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { Location } from 'history';
import { useSelector } from 'react-redux';
import { AppState } from '~/store/reducers';

type AnonymousRouteProps = {
  component: React.ComponentType<any>;
  redirectTo?: Location;
};

export const AnonymousRoute = ({ redirectTo, component: Component }: AnonymousRouteProps) => {
  const { authenticated: isAuthenticated } = useSelector((state: AppState) => state.data.auth);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const returnTo = params.get('return_to');

  if (!isAuthenticated || location.pathname === '/sso/zendesk') {
    return <Component />;
  }

  if (location.pathname === '/login' && returnTo) {
    window.location.href = `/sso/zendesk?return_to=${returnTo}`;
    return;
  }

  const defaultRedirectTo: Location = {
    pathname: '/assemblies/wizard',
    state: { nextPathname: location.pathname },
    key: '',
    search: '',
    hash: '',
  };

  return <Navigate to={redirectTo || defaultRedirectTo} />;
};
