import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, InputRef, message, Space, Spin, Table, Typography } from 'antd';
import { ColumnType } from 'antd/es/table';
import { FilterConfirmProps, SortOrder } from 'antd/es/table/interface';
import moment from 'moment';
import { useCallback, useEffect, useRef, useState } from 'react';
import Highlighter from 'react-highlight-words';

import { getAllQuoteOrders, getAllQuoteResponse } from '~/api/AuthorizedGets';
import { postCreateQuoteOrder } from '~/api/AuthorizedPosts';

import { ViewQuoteModal } from './modals';

const QuoteResponses = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [quoteResponses, setQuoteResponses] = useState([]);
  const [quoteOrders, setQuoteOrders] = useState<any[]>([]);
  const [activeDocument, setActiveDocument] = useState({} as any);
  const [isViewQuoteModalOpen, setIsViewQuoteModalOpen] = useState(false);

  // Search Table
  const searchInput = useRef<InputRef>(null);
  const [searchText, setSearchText] = useState('');

  const handleSearch = (selectedKeys: string[], confirm: (param?: FilterConfirmProps) => void) => {
    confirm();
    setSearchText(selectedKeys[0]);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = useCallback(
    (dataIndex: any, key?: string): ColumnType<any> => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
        <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
          <Input
            ref={searchInput}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys as string[], confirm)}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys as string[], confirm)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button
              onClick={() => clearFilters && handleReset(clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                close();
              }}
            >
              close
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered: boolean) => (
        <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
      ),
      onFilter: (value, record) => {
        const data = key ? record[dataIndex][key] : record[dataIndex];

        return data
          .toString()
          .toLowerCase()
          .includes((value as string).toLowerCase());
      },
      onFilterDropdownOpenChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.current?.select(), 100);
        }
      },
      render: (cell) => {
        let text = cell ? cell.toString() : '';

        if (key) {
          text = cell[key] ? cell[key].toString() : '';
        }

        return (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text}
          />
        );
      },
    }),
    [searchText],
  );

  const columns = [
    {
      title: 'RFQ ID',
      dataIndex: 'request_id',
      key: 'request_id',
      sorter: (a: any, b: any) => {
        return a.request_id ? a.request_id.localeCompare(b.request_id) : 0;
      },
    },
    {
      title: 'Drawing #',
      dataIndex: 'assembly',
      key: 'assembly',
      ...getColumnSearchProps('assembly', 'user_drawing_number'),
    },
    {
      title: 'Drawing Rev',
      dataIndex: 'assembly',
      key: 'assembly',
      render: (assembly: any) => assembly.user_drawing_revision,
    },
    {
      title: 'Quantity Requested',
      dataIndex: 'request',
      key: 'request',
      render: (request: any) => request.quantity,
    },
    {
      title: 'Date Requested',
      dataIndex: 'request',
      key: 'request',
      render: (request: any) => moment(request.creation_date).format('LL'),
      defaultSortOrder: 'descend' as SortOrder,
      sorter: (a: any, b: any) =>
        moment(a.request.creation_date).unix() - moment(b.request.creation_date).unix(),
    },
    {
      title: 'Vendor',
      dataIndex: 'vendor',
      key: 'vendor',
      ...getColumnSearchProps('vendor', 'company_name'),
    },
    {
      title: 'Quote Document',
      dataIndex: 'quote_document',
      key: 'quote_document',
      render: (document: any) => (
        <Button
          onClick={() => {
            setActiveDocument(document);
            setIsViewQuoteModalOpen(true);
          }}
        >
          View Quote Document
        </Button>
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (_text: any, record: any) => {
        const isOrderCreated = quoteOrders.some((order: any) => order.response_id === record.id);
        return (
          <Space size="middle">
            <Button
              onClick={async () => {
                setIsLoading(true);
                const payload = {
                  request_id: record.request_id,
                  response_id: record.id,
                };

                const response = await postCreateQuoteOrder(payload);

                if (response.id) {
                  message.success('Order Created Successfully');

                  const newQuoteOrders = [...quoteOrders];
                  newQuoteOrders.push(response);
                  setQuoteOrders(newQuoteOrders);
                }
                setIsLoading(false);
              }}
              type="primary"
              disabled={isOrderCreated}
              block
            >
              {isOrderCreated ? 'Order Has Created' : 'Order This Quote'}
            </Button>
          </Space>
        );
      },
    },
  ];

  useEffect(() => {
    const getQuoteResponses = async () => {
      const response = await getAllQuoteResponse();

      if (response.data) {
        const validQuotes = response.data.filter((order: any) => order.bidding === true);
        setQuoteResponses(validQuotes);
      }
    };

    const getQuoteOrders = async () => {
      const response = await getAllQuoteOrders();

      if (response.data) {
        setQuoteOrders(response.data);
      }
    };

    getQuoteResponses();
    getQuoteOrders();
  }, []);

  return (
    <Spin spinning={isLoading}>
      <Space direction="vertical" style={{ width: '100%' }}>
        <Typography.Title level={2}>Quote Dashboard</Typography.Title>
        <Table columns={columns} dataSource={quoteResponses} />
      </Space>
      <ViewQuoteModal
        open={isViewQuoteModalOpen}
        setOpen={setIsViewQuoteModalOpen}
        pdf={activeDocument?.url}
      />
    </Spin>
  );
};

export default QuoteResponses;
