import { mxGraph } from '@anekonnect/mxgraph';
import { Tabs, TabsProps } from 'antd';
import React from 'react';

import ArrangeTab from './ArrangeTab';
import AttributeEditor from './AttributeEditor';

type RightSidebarProps = {
  graph: mxGraph;
};

const RightSidebar = ({ graph }: RightSidebarProps) => {
  const items: TabsProps['items'] = [
    {
      key: 'style',
      label: 'Style',
      children: <AttributeEditor graph={graph} />,
    },
    {
      key: 'arrange',
      label: 'Arrange',
      children: <ArrangeTab graph={graph} />,
    },
  ];

  return <Tabs defaultActiveKey="style" style={{ overflow: 'auto' }} type="card" items={items} />;
};

export default RightSidebar;
