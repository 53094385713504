import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { Location } from 'history';
import { useSelector } from 'react-redux';
import { AppState } from '~/store/reducers';

type PrivateRouteProps = {
  component: React.ComponentType<any>;
  redirectTo?: Location;
};

export const PrivateRoute = ({
  redirectTo,
  component: Component,
  ...restProps
}: PrivateRouteProps) => {
  const { authenticated: isAuthenticated } = useSelector((state: AppState) => state.data.auth);
  const location = useLocation();

  if (isAuthenticated) {
    return <Component {...restProps} />;
  }

  const defaultRedirectTo: Location = {
    pathname: '/login',
    state: { nextPathname: location.pathname },
    key: '',
    search: '',
    hash: '',
  };

  return <Navigate to={redirectTo || defaultRedirectTo} />;
};
