import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { message } from 'antd';

import App from './App';
import { ForgotPassword, ResetPassword } from './pages/password';
import Login from './pages/login';
import NotFound from './pages/NotFound';
import SignUp from './pages/signup';
import SuccessMessage from './pages/SuccessMessage';
import { AnonymousRoute, PrivateRoute } from './routers';
import { AppState } from './store/reducers';
import Users, { UserDetail } from './pages/users';
import { Parts, PartsAddItem, PartsView, PartsViewItem } from './pages/parts';
import Wizard from './pages/assemblies/wizard/Wizard';
import Assemblies from './pages/assemblies/Assemblies';
import Notifications from './pages/notifications';
import { ChangePassword, EditProfile, TemplateSettings, UserProfile } from './pages/user';
import { logout } from './api/Authorizations';
import { QuoteResponses, RequestedQuote, VendorsCreate } from './pages/vendors';
import { LINK_QUOTE_RESPONSES, LINK_REQUESTED_QUOTES } from './constants/paths';
import InvitedUser from './pages/invited-user';
import { Zendesk } from './pages/sso';

const AppRoutes = () => {
  const { authenticated: isAuthenticated, expiresAt } = useSelector(
    (state: AppState) => state.data.auth,
  );
  const { isTenant, data: tenantConfig } = useSelector(
    (state: AppState) => state.data.tenantConfig,
  );
  const authenticationRequiredPages = tenantConfig?.ui_config?.authentication_required_pages || [];
  const authSystem = tenantConfig?.auth_system || ''

  const isTokenExpired = useCallback(() => {
    if (expiresAt === null) {
      return true;
    }

    const now = new Date();

    return now.getTime() / 1000 > +expiresAt;
  }, [expiresAt]);

  useEffect(() => {
    if (isAuthenticated) {
      if (isTokenExpired()) {
        message.warning('Your session has expired. Please login again.');
        setTimeout(() => {
          logout();
        }, 2000);
      }
    }
  }, [isAuthenticated, isTokenExpired]);

  return (
    <App>
      <Routes>
        {authenticationRequiredPages.includes('/assemblies/wizard') ? (
          <>
            <Route element={<PrivateRoute component={Wizard} />} path="/" />
            <Route element={<PrivateRoute component={Wizard} />} path="/assemblies/wizard" />
            <Route
              element={<PrivateRoute component={Wizard} />}
              path="/assemblies/wizard/edit/:itemId"
            />
          </>
        ) : (
          <>
            <Route element={<AnonymousRoute component={Wizard} />} path="/" />
            <Route Component={Wizard} path="/assemblies/wizard" />
            <Route Component={Wizard} path="/assemblies/wizard/edit/:itemId" />
          </>
        )}

        <Route element={<AnonymousRoute component={Login} />} path="/login" />
        {isTenant && (authSystem === 'google' || authSystem === 'microsoft') && (
          <Route element={<AnonymousRoute component={Login} />} path="/anekonnect-login" />
        )}
        {isTenant === false && (
          <Route element={<AnonymousRoute component={SignUp} />} path="/signup" />
        )}

        <Route element={<AnonymousRoute component={InvitedUser} />} path="/invited-user" />
        <Route element={<AnonymousRoute component={SuccessMessage} />} path="/successmessage/:id" />
        <Route element={<AnonymousRoute component={ForgotPassword} />} path="/forgot-password" />
        <Route element={<AnonymousRoute component={ResetPassword} />} path="/resetpassword" />
        <Route element={<PrivateRoute component={Assemblies} />} path="/assemblies" />
        <Route element={<PrivateRoute component={UserProfile} />} path="/user/profile" />
        <Route element={<PrivateRoute component={EditProfile} />} path="/user/edit" />
        <Route
          element={<PrivateRoute component={TemplateSettings} />}
          path="/user/template-settings"
        />
        <Route element={<PrivateRoute component={ChangePassword} />} path="/user/password" />
        <Route element={<PrivateRoute component={Users} />} path="/users" />
        <Route element={<PrivateRoute component={UserDetail} />} path="/users/:name" />

        {authenticationRequiredPages.includes('/parts') ? (
          <>
            <Route element={<PrivateRoute component={Parts} />} path="/parts" />
            <Route element={<PrivateRoute component={PartsView} />} path="/parts/:partName" />
            <Route
              element={<PrivateRoute component={PartsViewItem} />}
              path="/parts/:partName/:partItemId"
            />
          </>
        ) : (
          <>
            <Route Component={Parts} path="/parts" />
            <Route Component={PartsView} path="/parts/:partName" />
            <Route Component={PartsViewItem} path="/parts/:partName/:partItemId" />
          </>
        )}

        <Route element={<PrivateRoute component={PartsAddItem} />} path="/parts/:partName/add" />
        <Route
          element={<PrivateRoute component={(props: any) => <PartsAddItem edit {...props} />} />}
          path="/parts/:partName/:partItemId/edit"
        />
        <Route
          element={<PrivateRoute component={(props: any) => <PartsAddItem copy {...props} />} />}
          path="/parts/:partName/:partItemId/copy"
        />
        <Route element={<PrivateRoute component={Notifications} />} path="/notifications" />
        <Route element={<PrivateRoute component={VendorsCreate} />} path="/vendors/create" />
        <Route element={<PrivateRoute component={RequestedQuote} />} path={LINK_REQUESTED_QUOTES} />
        <Route element={<PrivateRoute component={QuoteResponses} />} path={LINK_QUOTE_RESPONSES} />
        <Route element={<AnonymousRoute component={Zendesk} />} path="/sso/zendesk" />
        <Route Component={NotFound} path="*" />
      </Routes>
    </App>
  );
};

export default AppRoutes;
