import { Button, Input, Space } from 'antd';
import { useEffect, useState } from 'react';
import { useAppSelector } from '~/store/hooks';
import { AppState } from '~/store/reducers';

const CustomAddressFields = (props: any) => {
  const { schema, onChange, formData, uiSchema, disabled } = props;
  const objectProperties = Object.entries(schema.properties);
  const customCopyAddress = uiSchema['func:copyAddress'];

  const whoami = useAppSelector((state: AppState) => state.data.whoami.data);

  const [data, setData] = useState<any>(formData || {});

  const copyFromFieldButton = (copyObject: any) => {
    return (
      <Button
        type="primary"
        onClick={() => {
          setData({
            ...data,
            ...copyObject.values,
          });
        }}
        disabled={disabled}
      >
        {copyObject.title}
      </Button>
    );
  };

  useEffect(() => {
    onChange(data);
  }, [data, onChange]);

  return (
    <Space direction="vertical" style={{ width: '100%', padding: '1.75rem' }}>
      <Space style={{ marginBottom: '1rem' }}>
        <Button
          type="primary"
          onClick={() => {
            setData({
              ...data,
              ...whoami.address,
            });
          }}
          disabled={disabled}
        >
          Copy From Profile Address
        </Button>
        {customCopyAddress &&
          customCopyAddress.map((copyObject: any) => copyFromFieldButton(copyObject))}
      </Space>

      <Space direction="vertical" style={{ width: '100%' }}>
        {Object.values(
          objectProperties.map((op: any) => {
            const key = op[0];
            const property = op[1];
            const isRequired = schema.required.find((r: string) => r === key);
            const title = property.title;
            const legend = isRequired ? title + '*' : title;

            return (
              <Space direction="vertical" style={{ width: '100%' }}>
                <div className="control-label">{legend}</div>
                <Input
                  size="large"
                  required={isRequired}
                  onChange={(event) => setData({ ...data, [key]: event.target.value })}
                  value={data[key]}
                  disabled={disabled}
                />
              </Space>
            );
          }),
        )}
      </Space>
    </Space>
  );
};

export default CustomAddressFields;
