import { JSONSchema7 } from 'json-schema';
import { useState } from 'react';
import { connect } from 'react-redux';

import { FormSchema } from '~/components/FormSchema/FormSchema';
import { wizardSetDrawingNotesData } from '~/store/actions/wizard/Action';
import { AppState } from '~/store/reducers';
import { DrawingNotes, Note } from '~/store/reducers/wizard/State';

type PropsFromState = {
  drawingNotes: DrawingNotes;
};

type PropsFromDispatch = {
  wizardSetDrawingNotesData: typeof wizardSetDrawingNotesData;
};

type WizardDwgNotesProps = PropsFromState & PropsFromDispatch;

type FormData = {
  formData: {
    drawingNotes: Note[];
  };
};

const WizardDwgNotes = ({ drawingNotes, wizardSetDrawingNotesData }: WizardDwgNotesProps) => {
  const [data, setData] = useState<Note[]>(drawingNotes.data ?? []);

  const handleChange = (data: FormData) => {
    const formData: Note[] = [...data.formData.drawingNotes];
    setData(formData);
    wizardSetDrawingNotesData(formData);
  };

  const renderForm = () => {
    const schema: JSONSchema7 = {
      properties: {
        drawingNotes: {
          items: {
            properties: {
              name: {
                type: 'string',
                title: 'Name',
                default: '',
              },
              description: {
                type: 'string',
                title: 'Description',
                default: '',
              },
            },
            additionalProperties: false,
            type: 'object',
          },
          type: 'array',
          title: 'Drawing Notes',
        },
      },
      additionalProperties: false,
      type: 'object',
    };

    const formData = { drawingNotes: data || [] };

    return (
      <FormSchema
        cancelAction={() => console.log('cancel call')}
        confirmAction={() => console.log('confirm call')}
        formData={formData}
        schema={schema}
        showActions={false}
        onChange={(formData) => handleChange(formData)}
      />
    );
  };

  return <div className="wizard_drawing_notes">{renderForm()}</div>;
};

const mapStateToProps = (state: AppState) => {
  return {
    drawingNotes: state.assemblyWizard.drawingNotes,
  };
};

const mapDispatchToProps = {
  wizardSetDrawingNotesData,
};

export default connect(mapStateToProps, mapDispatchToProps)(WizardDwgNotes);
