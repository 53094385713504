import { mxCell, mxGraph } from '@anekonnect/mxgraph';
import React, { useCallback, useEffect } from 'react';

import { Ref } from '../../Types';

import { SplitJunctionCellRef } from './Types';

import { mx } from '~/constants/wizard';

type EventsProps = {
  graph: mxGraph;
  splitJunctionCellRef: Ref<SplitJunctionCellRef>;
};

const Events = ({ graph, splitJunctionCellRef }: EventsProps) => {
  const handleResizeContainer = useCallback(() => {
    const { mxEvent } = mx;

    // Automatically resize container
    const setResizeContainer = (cell: mxCell) => {
      const state = graph.view.getState(cell);

      if (state) {
        const parent = state.cell.parent;

        if (parent) {
          const children = Object.values(parent.children) as mxCell[];

          if (children.length) {
            children.map((cell) => {
              // Update the parent first,
              // Before applying it to the cell image
              graph.refresh(parent);

              // Update cell image size geometry
              cell.geometry.width = parent.geometry.width;
              cell.geometry.height = parent.geometry.height;
              graph.refresh(cell);

              return cell;
            });
          }
        }
      }
    };

    // For update width and height image cell according container
    graph.model.addListener(mxEvent.CHANGE, function () {
      const { hoverCellRects } = splitJunctionCellRef.current;

      if (hoverCellRects && hoverCellRects.length) {
        hoverCellRects.map((cell) => setResizeContainer(cell));
      }
    });
  }, [graph, splitJunctionCellRef]);

  useEffect(handleResizeContainer, [handleResizeContainer]);

  const handleLabelPositionOnResize = useCallback(() => {
    graph.addListener(mx.mxEvent.CELLS_RESIZED, (_sender: any, evt: any) => {
      const { cells } = evt.properties;
      const { mxGeometry } = mx;

      cells.forEach((cell: any) => {
        if (cell.id.includes('split_junction_schematics')) {
          const { geometry } = cell;

          if (geometry instanceof mxGeometry) {
            const { height } = geometry;
            const { x } = geometry.offset;

            geometry.offset = new mx.mxPoint(x, -height / 2 - 27);
          }
        }
      });
    });
  }, [graph]);

  useEffect(handleLabelPositionOnResize, [handleLabelPositionOnResize]);

  return <React.Fragment />;
};

export default Events;
