import React from 'react';
import { Link } from 'react-router-dom';

type ConditionalLinkProps = {
  to: string;
  title: string;
  onClick?: () => void;
};

const externalUrl = (str: string) => {
  const pattern = /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\\/]))?/;

  return pattern.test(str);
};

const ConditionalLink: React.FC<ConditionalLinkProps> = ({
  to,
  title = 'Link',
  onClick = () => null,
}) => {
  if (externalUrl(to)) {
    return (
      <a href={to} rel="noopener noreferrer" target="_blank" onClick={onClick}>
        {title}
      </a>
    );
  }

  return <Link to={to}>{title}</Link>;
};

export default ConditionalLink;
