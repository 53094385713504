import { Button, message, Modal, Select, SelectProps } from 'antd';
import { useEffect, useState } from 'react';
import { getDataAssembliesItem, getReviewers } from '~/api/AuthorizedGets';
import { putUpdateRequestReview } from '~/api/AuthorizedPuts';
import { wizardLoadAssemblyData } from '~/store/actions/wizard/Action';
import { useAppDispatch, useAppSelector } from '~/store/hooks';

type RFAModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const RFAModal = ({ isOpen, onClose }: RFAModalProps) => {
  const id = useAppSelector((state) => state.assemblyWizard.id);
  const dispatch = useAppDispatch();

  const [reviewers, setReviewers] = useState<SelectProps['options']>([]);
  const [selectedReviewers, setSelectedReviewers] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getReviewersData = async () => {
      const response = await getReviewers();

      setReviewers(response);
    };

    if (isOpen) {
      getReviewersData();
    }
  }, [isOpen]);

  const filterOption = (input: string, option?: any) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const handleRequestReview = async () => {
    try {
      if (selectedReviewers.length === 0) {
        message.error('Please select at least 1 reviewer');
        return;
      }

      setIsLoading(true);

      const response = await putUpdateRequestReview(`${id}/review`, {
        reviewers: selectedReviewers,
      });

      if (response) {
        const data = await getDataAssembliesItem(String(id));

        dispatch(wizardLoadAssemblyData(data));

        message.success('Request for review sent!');
        onClose();
      }

      setIsLoading(false);
    } catch (error) {
      message.error('Something went wrong');

      setIsLoading(false);
    }
  };

  return (
    <Modal
      footer={[
        <Button key="cancel" onClick={onClose}>
          Cancel
        </Button>,
        <Button key="ok" type="primary" onClick={handleRequestReview} loading={isLoading}>
          Request
        </Button>,
      ]}
      title="Route for Approval"
      open={isOpen}
      onCancel={onClose}
      onOk={onClose}
    >
      <Select
        mode="multiple"
        style={{ width: '100%' }}
        placeholder="Select Reviewers (max 3)"
        optionLabelProp="label"
        options={reviewers?.map((reviewer) => ({
          label: reviewer.name,
          value: reviewer.id,
          desc: `${reviewer.name} - ${reviewer.email}`,
        }))}
        optionRender={(option) => option.data.desc}
        filterOption={filterOption}
        onSelect={(value) => {
          if (selectedReviewers.length >= 3) {
            message.error('You can only select up to 3 reviewers');
            return;
          }

          setSelectedReviewers([...selectedReviewers, value]);
        }}
        onDeselect={(value) =>
          setSelectedReviewers(selectedReviewers.filter((reviewer) => reviewer !== value))
        }
        value={selectedReviewers}
      />
    </Modal>
  );
};

export default RFAModal;
