import { mxCell } from '@anekonnect/mxgraph';

import Constants from './Constants';

type EdgeCategories = {
  defaultEdge: mxCell | null;
  dashedEdge: mxCell | null;
};

/**
 * Find edge according to the type: default, dashed and white
 * @param {mxCell[]} edges
 * @return {EdgeCategories} object
 */
export const findEdgeCategories = (edges: mxCell[]): EdgeCategories => {
  let defaultEdge: mxCell | null = null;
  let dashedEdge: mxCell | null = null;

  if (edges.length) {
    edges.map((edge) => {
      if (!edge.id.includes(Constants.dashedEdgeId)) {
        defaultEdge = edge;
      }

      if (edge.id.includes(Constants.dashedEdgeId)) {
        dashedEdge = edge;
      }

      return edge;
    });
  }

  return {
    defaultEdge,
    dashedEdge,
  };
};

/**
 * Returns value for dashed edge id from current cell
 * @param {mxCell} edge
 * @param {string} id
 */
export const generateDashedEdgeId = (edge: mxCell, id: string) => {
  const dashedEdgeId = Constants.dashedEdgeId;

  const sourceParent = edge.source?.parent;
  const sourceTarget = edge.target?.parent;

  let result = '';

  if (sourceParent && sourceTarget) {
    const parentId = sourceParent?.id;

    result = `${parentId}_${dashedEdgeId}_${id}`;
  }

  return result;
};
