/**
 * @date {String} : "2018-04-17T16:44:13.965743Z"
 *
 * @output {String} : Apr 04, 2018
 */
export const renderDate = (date: string): string => {
  const month = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'June',
    'July',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const newDate = new Date(date);

  return `${month[newDate.getMonth()]} ${newDate.getDate()}, ${newDate.getFullYear()}`;
};

/**
 * Get an array of 'properties' for schema
 */
export const getPropertyArray = (
  properties: { [key: string]: { type: string; title: string } },
  item: { [key: string]: string },
): { label: string; value: string }[] => {
  return Object.keys(properties)
    .filter((key) => {
      return item[key] !== undefined;
    })
    .map((key) => {
      const value = properties[key];

      if (value.type === 'date') {
        return {
          label: value.title,
          value: renderDate(item[key]),
        };
      }

      return {
        label: value.title,
        value: item[key],
      };
    });
};
