import { mxGraph, mxCell } from '@anekonnect/mxgraph';

import Constant from '../Constant';
import {
  getTotalHeightOfContainer,
  getLabelPin,
  getCenter,
  getPrevHeight,
  getCurrentLastOffsetY,
} from '../Helper';
import { ComponentData, Possible, Ref } from '../../../Types';
import { PreviousGeo } from '../Types';

import { createCableContainer, createCablePin, createLabelPin } from './Core';

import { mx } from '~/constants/wizard';
import { ConfigProp } from '~/store/reducers/configs';

export const shieldedFiberOptic = (
  graph: mxGraph,
  componentData: ComponentData,
  parent: mxCell,
  geometryRef: Ref<PreviousGeo[]>,
  indexOfCore: number,
  configs: ConfigProp,
) => {
  const { mxPoint } = mx;
  const { defaultAlias, position, type, id, index } = componentData;

  let connectorPinWidth = 0;
  let cablePinHeight = 0;

  const connectorPinGeoX = 0;
  const endTerminalPointLeft = '-0.5';
  const endTerminalPointRight = '0.5';

  const totalHeightOfContainer = getTotalHeightOfContainer(componentData);
  const rootId = `${type}_schematics_${id}_${index}_draw`;

  let resultOfssets: Possible = [];
  let componentContainer: mxCell | null = null;

  const coreData = componentData.cores[indexOfCore];
  const isFirstKey = indexOfCore === 0;

  if (isFirstKey) {
    componentContainer = createCableContainer(
      graph,
      componentData,
      `${rootId}_container`,
      defaultAlias,
      parent,
      totalHeightOfContainer,
      {
        x: position.schematics.x,
        y: position.schematics.y,
      },
      configs,
    );
  } else {
    componentContainer =
      geometryRef.current[indexOfCore - 1]?.componentContainer ||
      geometryRef.current[0]?.componentContainer;
  }

  const children = componentContainer?.children || [];
  let lastChildIndex = children.length;

  const lastIndex = coreData.count - 1;
  const { gauge, units } = getLabelPin(componentData, 'cores', indexOfCore);
  const prevHeight = getPrevHeight(componentData, indexOfCore);

  for (let index = 0; index < coreData.count; index++) {
    const { currentlastOffsetY } = getCurrentLastOffsetY(
      geometryRef,
      indexOfCore,
      index,
      Constant.cablePinHeight.shieldedFiberOptic,
      prevHeight,
    );

    const currentHeight = Constant.cablePinHeight.shieldedFiberOptic;
    let lastOffsetY = 0;

    if (isFirstKey) {
      lastOffsetY = currentlastOffsetY;
    } else {
      lastOffsetY = currentlastOffsetY + currentHeight * index;
    }

    if (componentContainer) {
      const connectorPin = createCablePin(
        graph,
        componentData,
        `${rootId}_core_${coreData.type}_${Constant.cablePinId}_${lastChildIndex}`,
        null,
        componentContainer,
        Constant.cableContainerWidth,
        currentHeight,
        lastOffsetY,
        isFirstKey,
      );
      lastChildIndex++;

      cablePinHeight = connectorPin.geometry.height;
      connectorPinWidth = connectorPin.geometry.width;

      if (index === lastIndex) {
        resultOfssets.push({
          lastOffsetY: connectorPin.geometry.offset.y,
          componentContainer: isFirstKey ? componentContainer : null,
          key: `${indexOfCore}_${coreData.type}`,
        });

        geometryRef.current[indexOfCore] = resultOfssets[0];
        resultOfssets = [];
      }

      const labelPin = createLabelPin(
        graph,
        `${rootId}_${Constant.labelPinId}_${index}`,
        `#${gauge} ${units}`,
        // TODO: Make to const
        64,
        10,
        connectorPin,
      );
      labelPin.geometry.offset.y = labelPin.geometry.offset.y + 3;

      const portTop = graph.insertVertex(
        connectorPin,
        `${rootId}_${Constant.portTopId}_${index}`,
        null,
        0,
        0,
        connectorPinWidth,
        4,
        'shape=line;align=center;verticalAlign=middle;spacingRight=10;fontColor=#000;strokeColor=#000;rotatable=0;',
      );
      portTop.geometry.relative = true;
      portTop.geometry.offset = new mxPoint(connectorPinGeoX, 0);
      portTop.setConnectable(false);

      const smallEllipse = graph.insertVertex(
        connectorPin,
        Constant.bezierSmallEllipseId,
        null,
        0,
        0,
        4,
        4,
        `shape=ellipse;align=center;verticalAlign=middle;fillColor=#000;strokeColor=none;rotatable=0;`,
      );
      smallEllipse.geometry.relative = true;
      smallEllipse.geometry.offset = new mxPoint(
        getCenter(connectorPinGeoX, connectorPinWidth, smallEllipse.geometry.width),
        0,
      );
      smallEllipse.setConnectable(false);

      const portLeftOnTop = graph.insertVertex(
        connectorPin,
        `${rootId}_${Constant.portLeftOnTopId}_${index}`,
        null,
        0,
        0,
        10,
        4,
        'shape=line;align=right;verticalAlign=middle;routingCenterX=0;fontColor=#000;strokeColor=#000;rotatable=0;',
      );
      portLeftOnTop.geometry.relative = true;
      portLeftOnTop.geometry.offset = new mxPoint(-10, 0);
      portLeftOnTop.setConnectable(true);

      const bigEllipse = graph.insertVertex(
        connectorPin,
        `${rootId}_${Constant.bigEllipseId}`,
        null,
        0,
        0,
        18,
        cablePinHeight - 7,
        `shape=ellipse;align=center;verticalAlign=middle;fillColor=none;strokeColor=#000;dashed=1;rotatable=0;`,
      );
      bigEllipse.geometry.relative = true;
      bigEllipse.geometry.offset = new mxPoint(
        getCenter(connectorPinGeoX, connectorPinWidth, bigEllipse.geometry.width),
        4,
      );
      bigEllipse.setConnectable(false);

      const portRightOnTop = graph.insertVertex(
        connectorPin,
        `${rootId}_${Constant.portRightOnTopId}_${index}`,
        null,
        0,
        0,
        10,
        4,
        'shape=line;align=left;verticalAlign=middle;routingCenterX=0;spacingLeft=10;fontColor=#000;strokeColor=#000;rotatable=0;',
      );
      portRightOnTop.geometry.relative = true;
      portRightOnTop.geometry.offset = new mxPoint(connectorPin.geometry.width, 0);
      portRightOnTop.setConnectable(true);

      const portLeft = graph.insertVertex(
        connectorPin,
        `${rootId}_${Constant.portLeftId}_${index}`,
        null,
        0,
        0,
        connectorPin.geometry.width - labelPin.geometry.width + 2,
        4,
        'shape=line;align=right;verticalAlign=middle;routingCenterX=' +
          endTerminalPointLeft +
          ';fontColor=#000;strokeColor=#000;',
      );
      portLeft.geometry.relative = true;
      portLeft.geometry.offset = new mxPoint(
        -(connectorPin.geometry.width - labelPin.geometry.width) / 2 - 2,
        getCenter(connectorPin.geometry.y, connectorPin.geometry.height, portLeft.geometry.height) +
          3,
      );
      portLeft.setConnectable(true);

      const portRight = graph.insertVertex(
        connectorPin,
        `${rootId}_${Constant.portRightId}_${index}`,
        null,
        0,
        0,
        connectorPin.geometry.width - labelPin.geometry.width + 2,
        4,
        `shape=line;align=left;verticalAlign=middle;routingCenterX=${endTerminalPointRight};spacingLeft=10;fontColor=${Constant.baseColor};strokeColor=${Constant.baseColor};rotatable=0;`,
      );
      portRight.geometry.relative = true;
      portRight.geometry.offset = new mxPoint(
        connectorPin.geometry.width - labelPin.geometry.offset.x,
        getCenter(
          connectorPin.geometry.y,
          connectorPin.geometry.height,
          portRight.geometry.height,
        ) + 3,
      );
      portRight.setConnectable(true);
    }
  }
};
