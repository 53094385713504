import { Modal, Select } from 'antd';
import { useEffect, useState } from 'react';
import { getUserRoles } from '~/api/AuthorizedGets';

import { putUpdateUserRoles } from '~/api/AuthorizedPuts';
import { useAppSelector } from '~/store/hooks';

type EditRolesProps = {
  isOpen: boolean;
  id: string;
  currentRoles: string[];
  onCancel: () => void;
  onOk: () => void;
};

const EditRoles = ({ isOpen, id, currentRoles, onCancel, onOk }: EditRolesProps) => {
  const userRoles = useAppSelector((state) => state.data?.whoami?.data?.roles);
  const [options, setOptions] = useState<string[]>([]);
  const [selectedItems, setSelectedItems] = useState<string[]>(currentRoles);

  const filteredOptions = options.filter((o) => o && !selectedItems?.includes(o));

  useEffect(() => {
    const getRoles = async () => {
      const response = await getUserRoles();

      const roles = response.roles.map((role: { name: string }) => {
        if (role.name === 'SuperAdmin') {
          if (userRoles?.includes('SuperAdmin')) {
            return role.name;
          } else {
            return null;
          }
        }
        return role.name;
      });
      setOptions(roles);
    };

    if (userRoles?.includes('SuperAdmin') || userRoles?.includes('Admin')) {
      getRoles();
    }
  }, [userRoles]);

  useEffect(() => {
    setSelectedItems(currentRoles);
  }, [currentRoles]);

  return (
    <Modal
      title="Edit Roles"
      open={isOpen}
      onCancel={() => onCancel()}
      onOk={async () => {
        await putUpdateUserRoles(id, { roles: selectedItems });

        onOk();
      }}
    >
      <Select
        mode="multiple"
        placeholder="Please choose the roles"
        value={selectedItems}
        onChange={setSelectedItems}
        style={{ width: '100%' }}
        options={filteredOptions.map((item) => ({
          value: item,
          label: item,
        }))}
      />
    </Modal>
  );
};

export default EditRoles;
