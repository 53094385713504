import { mxCell, mxGraph, mxMouseEvent } from '@anekonnect/mxgraph';
import React, { useCallback, useEffect, useRef } from 'react';
import { FormInstance } from 'antd/lib/form/Form';
import { connect } from 'react-redux';

import { Ref } from '../../Types';
import Constant from '../Connector/Constant';

import { findConnectorPortCell } from './Helper';

import { mx } from '~/constants/wizard';
import { wizardSetOpenModalEditConnectorPin } from '~/store/actions/wizard/Action';

type PropsFromDispatch = {
  wizardSetOpenModalEditConnectorPin: typeof wizardSetOpenModalEditConnectorPin;
};

type EventsProps = {
  graph: mxGraph;
  connectorPinRef: Ref<mxCell | null>;
  forms: {
    connectorPinForm: FormInstance;
  };
} & PropsFromDispatch;

const Events = ({
  graph,
  connectorPinRef,
  forms,
  wizardSetOpenModalEditConnectorPin,
}: EventsProps) => {
  const isConnectorPinDragRef = useRef<boolean>(false);

  const findSelectedCell = useCallback(
    (me: mxMouseEvent) => {
      const cell = me.getCell();

      if (!cell) {
        return;
      }

      const parent = cell.getParent();

      if (!parent) {
        return;
      }

      const setConnectorFormDefaultValue = (cell: mxCell) => {
        const { leftCell, rightCell } = findConnectorPortCell(graph, cell);

        const leftValue = leftCell?.value;
        const rightValue = rightCell?.value;
        const isNullValues = !leftValue && !rightValue;

        // Change default input value
        if (!isNullValues) {
          forms.connectorPinForm.setFieldsValue({
            labelValue: leftValue || rightValue,
            position: rightValue ? 'right' : 'left',
          });
        } else {
          forms.connectorPinForm.setFieldsValue({
            labelValue: '',
            position: 'right',
          });
        }
      };

      const isNode = mx.mxUtils.isNode(parent.value, 'component');
      const configs = isNode ? parent.value.getAttribute('configs', null) : null;

      if (configs) {
        const { editable } = JSON.parse(configs);

        if (editable) {
          const cellId = cell.getId();

          const isClick = !isConnectorPinDragRef.current;

          if (isClick) {
            const hasConnectorPinId = cellId.includes(Constant.connectorPinId);

            if (hasConnectorPinId) {
              connectorPinRef.current = cell;
              wizardSetOpenModalEditConnectorPin(true);
              setConnectorFormDefaultValue(cell);
            }
          }
        }
      }
    },
    [graph, forms, connectorPinRef, wizardSetOpenModalEditConnectorPin],
  );

  const changeConnectorPinCursor = useCallback(
    (me: mxMouseEvent) => {
      if (graph) {
        const state = graph.view.getState(me.getCell());

        if (state) {
          const cellId = state.cell.getId();
          const hasCellId = cellId.includes(Constant.connectorPinId);
          const hasCableId = cellId.includes('cable');

          if (hasCellId && !hasCableId) state.setCursor('pointer');
        }
      }
    },
    [graph],
  );

  const graphMouseEvent = useCallback(() => {
    graph.addMouseListener({
      mouseUp(_sender, me) {
        findSelectedCell(me);

        return;
      },
      mouseDown() {
        isConnectorPinDragRef.current = false;

        return;
      },
      mouseMove(_sender, me) {
        isConnectorPinDragRef.current = true;
        changeConnectorPinCursor(me);

        return;
      },
    });
  }, [graph, changeConnectorPinCursor, findSelectedCell]);

  useEffect(graphMouseEvent, [graphMouseEvent]);

  return <React.Fragment />;
};

const mapDispatchToProps = {
  wizardSetOpenModalEditConnectorPin,
};

export default connect(null, mapDispatchToProps)(Events);
