import { mxCell, mxCellState } from '@anekonnect/mxgraph';
import React, { useCallback, useEffect, useRef } from 'react';

import Constant from './Constant';
import { GraphEventsProps, GraphProps } from './Types';

import { mx } from '~/constants/wizard';
import { useAppDispatch } from '~/store/hooks';
import { wizardSetShowComponentControl } from '~/store/actions/wizard/Component';

const { mxConstants, mxEvent } = mx;

type PickGraphProps = Pick<GraphProps, 'graph' | 'graphRefs'>;

type EventsProps = GraphEventsProps & PickGraphProps;

const Events = ({ graph, graphRefs, setCurrentCell }: EventsProps) => {
  const isSelectionEvent = useRef<boolean>(false);
  const dispatch = useAppDispatch();

  const handleComponentContainerAction = useCallback(
    (cell: mxCell) => {
      const state = graph.view.getState(cell);

      const { componentCellRef } = graphRefs.current;

      const isHasContainerId = (state: mxCellState) => {
        if (state) {
          const cellId = state.cell.getId();
          const includesContainerId =
            cellId.includes(Constant.componentContainer) ||
            cellId.includes(Constant.componentContainerShielded);

          return includesContainerId;
        }
      };

      const isBezierCurve = (cell: mxCell) => {
        if (cell) {
          return cell.id.includes('bezier_curve');
        }
      };

      const isRectangle = (cell: mxCell) => {
        if (cell) {
          return cell.id.includes('rectangle');
        }
      };

      const isDrawingTools = (cell: mxCell) => {
        console.log(cell, 'cellprops');

        if (cell) {
          return cell.id.includes('drawing_tools_shape');
        }
      };
      console.log(
        'is--------ceell',
        cell,
        isHasContainerId(state),
        isBezierCurve(cell),
        isRectangle(cell),
        isDrawingTools(cell),
      );
      if (
        isHasContainerId(state) ||
        isBezierCurve(cell) ||
        isRectangle(cell) ||
        isDrawingTools(cell)
      ) {
        componentCellRef.current.componentContainer = cell;

        setCurrentCell(cell);
        dispatch(wizardSetShowComponentControl(true));
      } else {
        setCurrentCell(null);
        dispatch(wizardSetShowComponentControl(false));
      }
    },
    [graph, graphRefs, setCurrentCell, dispatch],
  );

  // Handle selection event, when cells selected
  const handleSelection = useCallback(() => {
    const that = document;

    that.addEventListener('mouseup', () => {
      // Reset styles to default
      mxConstants.VERTEX_SELECTION_STROKEWIDTH = 1;
      mxConstants.VERTEX_SELECTION_COLOR = Constant.vertexSelectionColor;

      const selections = graph.getSelectionCells();

      if (selections && selections.length) {
        graph.setSelectionCells(selections);
        isSelectionEvent.current = true;
      } else {
        isSelectionEvent.current = false;
      }
    });
  }, [graph]);

  useEffect(() => {
    const that = document;
    that.addEventListener('selectionchange', handleSelection);

    return () => {
      that.removeEventListener('selectionchange', handleSelection);
      that.removeEventListener('mouseup', handleSelection);
    };
  }, [graph, handleSelection]);

  const graphMouseEvent = useCallback(() => {
    graph.addMouseListener({
      mouseUp() {
        return;
      },
      mouseDown() {
        return;
      },
      mouseMove(_key, state) {
        if (state) {
          const cell = state.getCell();

          if (cell) {
            const isTable = cell.id.includes('table');

            if (isTable) {
              graph.view.getState(cell).setCursor('move');
            }
          }
        }

        return;
      },
    });
  }, [graph]);

  useEffect(graphMouseEvent, [graphMouseEvent]);

  const mxGraphEvent = useCallback(() => {
    graph.addListener(mxEvent.MOVE_CELLS, () => {
      dispatch(wizardSetShowComponentControl(false));
    });

    graph.addListener(mxEvent.CLICK, (_sender, evt) => {
      const cell = evt.getProperty('cell');
      console.log(cell, 'cellprops2');

      if (cell) {
        console.log(cell, 'is____cell');
        handleComponentContainerAction(cell);
      } else {
        setCurrentCell(null);
        dispatch(wizardSetShowComponentControl(false));
      }
    });
  }, [dispatch, graph, handleComponentContainerAction, setCurrentCell]);

  useEffect(mxGraphEvent, [mxGraphEvent]);

  return <React.Fragment />;
};

export default Events;
