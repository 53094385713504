import { mxCell } from '@anekonnect/mxgraph';
import { ListComponent } from '~/store/reducers/wizard/State';

export const findComponentByCellId = (components: ListComponent, cellId: string) => {
  try {
    const idData = cellId.split('_'); // structure id componentType_wizardType_componentId_componentSubId_furtherInformation
    const wizardTypeIndex = idData.findIndex((data) => data === 'ed' || data === 'schematics');
    const componentType = idData.slice(0, wizardTypeIndex).join('_');
    const componentId = idData[wizardTypeIndex + 1];
    const componentSubId = idData[wizardTypeIndex + 2];
    const component = components[componentType][componentId][componentSubId];

    return component;
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const getComponentsNameFromCells = (
  cells: mxCell[],
  components: ListComponent,
  partsKeys: string[],
) => {
  const componentsName = cells
    .map((cell) => {
      if (partsKeys.some((key) => cell.id.includes(key)) && !cell.id.includes('dashed_edge')) {
        const component = findComponentByCellId(components, cell.id);

        if (!component) {
          return null;
        }

        return component.name;
      }

      return null;
    })
    .filter((name) => name !== null);
  return [...new Set(componentsName)];
};
