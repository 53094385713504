import { LeftOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Row } from 'antd';
import { Link } from 'react-router-dom';

import styles from './styles.module.scss';

import { postSendForgotPasswordEmail } from '~/api/AuthorizedPosts';

type valuesProps = {
  email: string;
};

const ForgotPassword = () => {
  const onFinish = (values: valuesProps) => {
    postSendForgotPasswordEmail({ email: values.email });
  };

  return (
    <div className={styles.wrapper}>
      <Row>
        <Col offset={2} span={20}>
          <Link className={styles.linkBack} to="/login">
            <LeftOutlined className={styles.linkBackIcon} />
            Go back to login
          </Link>
          <div className={styles.header}>
            <h1>Forgot your password?</h1>
            <span>
              If you need help resetting you password, we can help by sending you a link to reset
              it.
            </span>
          </div>
          <div className={styles.body}>
            <Form layout="vertical" name="forgot-password" onFinish={onFinish}>
              <Form.Item
                label="Enter your email"
                name="email"
                rules={[
                  { required: true, message: 'Please input your Email!' },
                  { type: 'email', message: 'The input is not valid Email!' },
                ]}
              >
                <Input placeholder="Email" size="large" />
              </Form.Item>

              <Form.Item>
                <Button htmlType="submit" size="large" type="primary">
                  Send Email
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ForgotPassword;
