import { mxCell, mxGraph } from '@anekonnect/mxgraph';
import { useEffect } from 'react';
import { connect } from 'react-redux';

import { mx } from '~/constants/wizard';
import { wizardSetBillOfMaterialsPosition } from '~/store/actions/wizard/Action';
import { AppState } from '~/store/reducers';
import { BillOfMaterialsData, Point } from '~/store/reducers/wizard/State';
import { BottomToTopTableTemplate, TopToBottomTableTemplate } from './templates';

type PropsFromState = {
  billOfMaterialsData: BillOfMaterialsData[];
  billOfMaterialsPosition: Point;
  bomTableConfig: string;
};

type PropsFromDispatch = {
  wizardSetBillOfMaterialsPosition: typeof wizardSetBillOfMaterialsPosition;
};

type BOMTableProps = {
  graph: mxGraph;
} & PropsFromState &
  PropsFromDispatch;

const BOMTable = ({
  graph,
  billOfMaterialsData,
  billOfMaterialsPosition,
  bomTableConfig,
  wizardSetBillOfMaterialsPosition,
}: BOMTableProps) => {
  const tablePrefix = 'bill-of-materials-table';

  // Save last position of BOM table
  useEffect(() => {
    graph.addListener(mx.mxEvent.CELLS_MOVED, (_sender, evt) => {
      const cells = evt.properties.cells;

      if (cells.length > 0) {
        cells.forEach((cell: mxCell) => {
          if (cell.id === tablePrefix) {
            wizardSetBillOfMaterialsPosition(cell.getGeometry());
          }
        });
      }
    });
  }, [graph, wizardSetBillOfMaterialsPosition]);

  if (!bomTableConfig) {
    return;
  }

  const tableConfigJson = JSON.parse(bomTableConfig);
  const template = tableConfigJson?.table?.template;
  const showTitle =
    tableConfigJson?.title?.showTitle !== undefined ? tableConfigJson?.title?.showTitle : true;

  switch (template) {
    case 'top-to-bottom':
      return (
        <TopToBottomTableTemplate
          graph={graph}
          tablePrefix={tablePrefix}
          tableConfigJson={tableConfigJson}
          data={billOfMaterialsData}
          position={billOfMaterialsPosition}
          showTitle={showTitle}
        />
      );
    case 'bottom-to-top':
      return (
        <BottomToTopTableTemplate
          graph={graph}
          tablePrefix={tablePrefix}
          tableConfigJson={tableConfigJson}
          data={billOfMaterialsData}
          position={billOfMaterialsPosition}
          showTitle={showTitle}
        />
      );
    default:
      return (
        <TopToBottomTableTemplate
          graph={graph}
          tablePrefix={tablePrefix}
          tableConfigJson={tableConfigJson}
          data={billOfMaterialsData}
          position={billOfMaterialsPosition}
          showTitle={showTitle}
        />
      );
  }
};

const mapStateToProps = (state: AppState) => {
  return {
    billOfMaterialsData: state.assemblyWizard.billOfMaterials.data ?? [],
    billOfMaterialsPosition: state.assemblyWizard.billOfMaterials.position,
    bomTableConfig: state.data.tenantConfig.data?.bill_of_materials_table_config_display,
  };
};

const mapDispatchToProps = {
  wizardSetBillOfMaterialsPosition,
};

export default connect(mapStateToProps, mapDispatchToProps)(BOMTable);
