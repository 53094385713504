import { message } from 'antd';
import { useEffect } from 'react';
import { connect } from 'react-redux';

import { ERROR, SUCCESS } from '~/constants';
import { snackBarClose } from '~/store/actions/ui/SnackBar';
import { AppState } from '~/store/reducers';

type OwnProps = {
  snackBarData: {
    message: string;
    status: string;
    open: boolean;
    delay: number;
  };
};

type PropsFromDispatch = {
  snackBarClose: typeof snackBarClose;
};

type SnackBarProps = OwnProps & PropsFromDispatch;

const SnackBar = ({ snackBarData, snackBarClose }: SnackBarProps) => {
  const getAntdMessageType = (status: string) => {
    switch (status) {
      case SUCCESS:
        return 'success';
      case ERROR:
        return 'error';
      default:
        return 'warning';
    }
  };

  useEffect(() => {
    if (snackBarData && snackBarData.open && snackBarData.message) {
      const promise: any = message[getAntdMessageType(snackBarData.status)](
        snackBarData.message,
        5,
      );
      promise.then(() => {
        snackBarClose();
      });
    }
  });

  return null;
};

const mapStateToProps = (state: AppState) => {
  return {
    snackBarData: state.ui.snackBar,
  };
};

const mapDispatchToProps = {
  snackBarClose,
};

export default connect(mapStateToProps, mapDispatchToProps)(SnackBar);
