import { Form, Input, InputRef } from 'antd';
import { useContext, useEffect, useRef, useState } from 'react';
import EditableContext from './EditableContex';

type Item = {
  key: string;
  name: string;
  age: string;
  address: string;
};

type EditableCellProps = {
  title: React.ReactNode;
  editable: boolean;
  children: any;
  dataIndex: keyof Item;
  record: Item;
  handleSave: (record: Item) => void;
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}: EditableCellProps) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<InputRef>(null);
  const form = useContext(EditableContext);

  useEffect(() => {
    if (editing) {
      inputRef?.current?.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form?.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const save = async () => {
    try {
      const values = await form?.validateFields();

      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;

  if (
    editable &&
    record.key !== '2' &&
    children[1] !== 'Variable Name' &&
    children[1] !== 'Column Width in Pixels'
  ) {
    childNode = editing ? (
      <Form.Item
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
        style={{ margin: 0 }}
      >
        <Input ref={inputRef} onBlur={save} onPressEnter={save} />
      </Form.Item>
    ) : (
      <div className="editable-cell-value-wrap" style={{ paddingRight: 24 }} onClick={toggleEdit}>
        {children}
      </div>
    );
  } else {
    childNode = children;
  }

  return <td {...restProps}>{childNode}</td>;
};

export default EditableCell;
