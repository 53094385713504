import { EyeOutlined } from '@ant-design/icons';
import { mxCell, mxGraph, mxImage } from '@anekonnect/mxgraph';
import { Dropdown, MenuProps, Space } from 'antd';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { AppState } from '~/store/reducers';
import MenuButton from './MenuButton';
import { useAppSelector } from '~/store/hooks';
import {
  WizardSetMenuViewActiveList,
  wizardSetMenuViewActiveList,
} from '~/store/actions/wizard/Action';
import { ActionCreator } from 'redux';
import { BillOfMaterialsData } from '~/store/reducers/wizard/State';

type PropsFromState = {
  billOfMaterialsData: BillOfMaterialsData[];
  menuViewActiveList: string[];
};

type PropsFromDispatch = {
  wizardSetMenuViewActiveList: ActionCreator<WizardSetMenuViewActiveList>;
};

type MenuViewProps = { graph: mxGraph } & PropsFromState & PropsFromDispatch;

const MenuView = ({
  graph,
  billOfMaterialsData,
  menuViewActiveList,
  wizardSetMenuViewActiveList,
}: MenuViewProps) => {
  const { isTenant } = useAppSelector((state) => state.data.tenantConfig);

  const [latestBackgroundImages, setLatestBackgroundImages] = useState<mxImage[]>([]);

  useEffect(() => {
    if (!menuViewActiveList.includes('grid')) {
      const element = document.getElementsByClassName('mxContainer')[0] as HTMLElement;
      element.style.background = 'none';
    }

    if (!menuViewActiveList.includes('approval-histories-table')) {
      const approvalHistoriesTable = graph.getModel().getCell('approval-histories-table');

      graph.toggleCells(false, [approvalHistoriesTable], true);
    }
  }, [graph, menuViewActiveList]);

  useEffect(() => {
    if (!menuViewActiveList.includes('bom') && billOfMaterialsData.length > 0) {
      const BOMTable = graph.getModel().getCell('bill-of-materials-table');

      graph.toggleCells(false, [BOMTable], true);
    }
  }, [billOfMaterialsData.length, graph, menuViewActiveList]);

  const handleToggle = (key: string): boolean => {
    const objectIndex = menuViewActiveList.findIndex((currentKey) => currentKey === key);

    if (objectIndex === -1) {
      wizardSetMenuViewActiveList([key, ...menuViewActiveList]);
      return false;
    }

    const updatedMenuViewActiveList = menuViewActiveList.filter((currentKey) => currentKey !== key);

    // If the updated list is empty, add the 'disabled-all' key
    if (updatedMenuViewActiveList.length === 0) {
      updatedMenuViewActiveList.push('disabled-all');
    }

    wizardSetMenuViewActiveList(updatedMenuViewActiveList);

    return true;
  };

  const handleClickTemplate = () => {
    const toggle = handleToggle('template');

    if (toggle) {
      setLatestBackgroundImages(graph.getBackgroundImages());
      graph.setBackgroundImages([]);
    } else {
      graph.setBackgroundImages(latestBackgroundImages);
    }

    graph.refresh();
  };

  const handleClickLogo = () => {
    const toggle = handleToggle('logo');

    const cells: mxCell[] = Object.values(graph.model.cells);

    cells.forEach((cell) => {
      const isTemplateLogo = cell.id.includes('template_logo_page');

      if (isTemplateLogo) {
        graph.toggleCells(!toggle, [cell], true);
      }
    });
  };

  const handleClickGrid = () => {
    const toggle = handleToggle('grid');
    const element = document.getElementsByClassName('mxContainer')[0] as HTMLElement;

    if (toggle) {
      element.style.background = 'none';
    } else {
      element.style.background = "url('/img/wires-grid.gif')";
    }
  };

  const handleClickBOM = () => {
    const toggle = handleToggle('bom');

    const BOMTable = graph.getModel().getCell('bill-of-materials-table');

    graph.toggleCells(!toggle, [BOMTable], true);
  };

  const handleClickStatusWatermark = () => {
    const toggle = handleToggle('status-watermark');

    const cells: mxCell[] = Object.values(graph.model.cells);

    cells.forEach((cell) => {
      const isWatermarkTemplate = cell.id.includes('template_watermark_page');

      if (isWatermarkTemplate) {
        graph.toggleCells(!toggle, [cell], true);
      }
    });
  };

  const handleClickApprovalHistories = () => {
    const toggle = handleToggle('approval-histories');

    const approvalHistoriesTable = graph.getModel().getCell('approval-histories-table');

    graph.toggleCells(!toggle, [approvalHistoriesTable], true);
  };

  const items: MenuProps['items'] = [
    {
      key: 'template',
      onClick: handleClickTemplate,
      label: 'Template',
    },
    {
      key: 'logo',
      onClick: handleClickLogo,
      label: 'Logo',
    },
    {
      key: 'grid',
      onClick: handleClickGrid,
      label: 'Grid',
    },
    {
      key: 'bom',
      onClick: handleClickBOM,
      label: 'BOM',
    },
    isTenant
      ? {
          key: 'status-watermark',
          onClick: handleClickStatusWatermark,
          label: 'Status Watermark',
        }
      : null,
    {
      key: 'approval-histories',
      onClick: handleClickApprovalHistories,
      label: 'Approval Histories',
    },
  ];

  const menuSelected: MenuProps['selectedKeys'] = menuViewActiveList;

  return (
    <Dropdown menu={{ items, selectedKeys: menuSelected }} trigger={['click']}>
      <Space>
        <MenuButton icon={<EyeOutlined />} label="View" />
      </Space>
    </Dropdown>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    billOfMaterialsData: state.assemblyWizard.billOfMaterials.data ?? [],
    menuViewActiveList: state.assemblyWizard.menuViewActiveList ?? [],
  };
};

const mapDispatchToProps = {
  wizardSetMenuViewActiveList,
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuView);
