import React from 'react';
import { ArrayFieldTemplateProps } from '@anekonnect/react-jsonschema-form';
import { Button } from 'antd';
import { CaretDownFilled, CaretUpFilled, CopyOutlined, DeleteOutlined } from '@ant-design/icons';

const hideArrows = true;

const ArrayFieldTemplateForm = (props: ArrayFieldTemplateProps) => {
  const className = 'icon-connector-wrapper';

  const connectorTabs = props.items.map((element, i) => {
    const { children } = element;
    const {
      props: { formData },
    } = children;

    const columns = formData ? Object.keys(formData).length : 1;

    return (
      <div
        key={i}
        className={`row-element ${i > 0 ? 'row-element-hide-label' : ''} ${i % 2 ? 'odd' : 'even'}`}
      >
        <div className={`row-element-form columns-${columns}`}>
          {element.children}
          <div className="array-template-action">
            {!hideArrows && (
              <React.Fragment>
                <Button
                  className={className}
                  disabled={!element.hasMoveUp}
                  icon={<CaretUpFilled />}
                  onClick={element.onReorderClick(element.index, element.index - 1)}
                />
                <Button
                  className={className}
                  disabled={!element.hasMoveDown}
                  icon={<CaretDownFilled />}
                  onClick={element.onReorderClick(element.index, element.index + 1)}
                />
              </React.Fragment>
            )}
            <Button
              className={className}
              icon={<CopyOutlined />}
              onClick={element.onCopyIndexClick(element.index)}
            />
            <br />
            <Button
              className={className}
              icon={<DeleteOutlined />}
              onClick={element.onDropIndexClick(element.index)}
            />
          </div>
        </div>
      </div>
    );
  });

  return (
    <div className="form-group-array">
      {props.canAdd && props.items.length === 0 && (
        <label className="control-label control-label-array">
          <Button type="primary" onClick={props.onAddClick}>
            Add
          </Button>
        </label>
      )}
      {connectorTabs}
      {props.items.length > 0 && (
        <label className="control-label control-label-array control-label-add-bottom">
          {props.canAdd && (
            <Button type="primary" onClick={props.onAddClick}>
              Add
            </Button>
          )}
        </label>
      )}
    </div>
  );
};

export default ArrayFieldTemplateForm;
