import { JSONSchema7 } from 'json-schema';
import * as React from 'react';
import Form from '@anekonnect/react-jsonschema-form';
import { Button, Image, Space, Tooltip } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import ArrayFieldTemplateForm from './Elements/ArrayFieldTemplateForm';
import ArrayFieldTemplateTable from './Elements/ArrayFieldTemplateTable';
import CustomCheckbox from './Elements/CustomCheckbox';
import CustomTitleField from './Elements/CustomTitleField';
import { ReadOnlyField } from './Elements/ReadOnlyField';
import CustomFieldTemplate from './Elements/CustomFieldTemplate';
import CustomTextField from './Elements/CustomTextField';
import styles from './FormSchema.module.scss';
import './FormSchema.scss';
import CustomSelect from './Elements/CustomSelect';
import CustomAddressFields from './Elements/CustomAddressField';

type OwnOptionalProps = {
  showActions?: boolean;
  onChange?(data: any, files?: any): void;
  uiSchema?: any;
  submitText?: string;
  arrayFieldTemplate?: string;
  customStyles?: any;
};

type OwnProps = {
  formData: any;
  cancelAction(): void;
  confirmAction(data: any, files?: any): void;
  schema: JSONSchema7;
  isPaid?: boolean;
  disabled?: boolean;
};

type OwnState = {
  formData: any;
  files: any;
  inProgress: boolean;
  [key: string]: any;
};

class FormSchema extends React.Component<OwnProps & OwnOptionalProps, OwnState> {
  state: OwnState = {
    formData: {},
    files: {},
    inProgress: false,
  };
  static defaultProps: OwnOptionalProps = {
    showActions: true,
    submitText: 'Save',
    arrayFieldTemplate: 'table',
  };

  constructor(props: OwnProps) {
    super(props);
    this.state = {
      formData: this.props.formData || {},
      files: {},
      inProgress: false,
      isPaid: this.props.isPaid === undefined ? true : this.props.isPaid,
    };
  }

  componentDidMount() {
    window.addEventListener('keydown', this.handleKeydown);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeydown);
  }

  UNSAFE_componentWillReceiveProps(nextProps: any) {
    this.setState({ formData: nextProps.formData });
  }

  handleKeydown = (e: KeyboardEvent) => {
    switch (e.keyCode) {
      case 27: // Esc
        this.props.cancelAction();
        break;
      default:
        return;
    }
  };

  encodeImageFileAsURL(element: any, props: any) {
    const file: any = element.files[0];
    this.setState({
      files: {
        ...this.state.files,
        [props.id]: file,
      },
    });
  }

  getFormActions = () => {
    const { cancelAction, showActions, submitText, disabled } = this.props;

    if (!showActions) return <div />;

    return (
      <Space className={styles.actionButton}>
        <Button onClick={cancelAction} disabled={disabled}>
          Cancel
        </Button>
        <Button htmlType="submit" type="primary" disabled={disabled}>
          {submitText}
        </Button>
      </Space>
    );
  };

  updateContactDetails = (id: string, index: number, newContent: any) => {
    const { formData } = this.state;
    const data = formData;

    data[id][index].contact_details = newContent;

    this.setState({ formData: data });
  };

  render() {
    const { formData } = this.state;
    const { confirmAction, schema, onChange, uiSchema, arrayFieldTemplate, customStyles } =
      this.props;

    const getArrayFieldTemplate = () => {
      switch (arrayFieldTemplate) {
        case 'form':
          return ArrayFieldTemplateForm;
        case 'table':
          return ArrayFieldTemplateTable;
        default:
          return ArrayFieldTemplateTable;
      }
    };

    const handleDeleteFile = (type: string) => {
      const { onChange, formData } = this.props;
      const data = JSON.parse(JSON.stringify(formData));
      delete data[type];

      if (data.files) {
        const newFiles = data.files.filter(function (obj: { type: string; url: string }) {
          return obj.type !== type;
        });
        data.files = newFiles;
      }

      if (onChange) {
        onChange({ formData: data });
      }
    };

    const CustomFileWidget = (props: any, i: any) => {
      return (
        <div key={i} className={styles.fileContainer}>
          <input
            accept={props.options.accept}
            id={props.id}
            type="file"
            onChange={(e) => this.encodeImageFileAsURL(e.target, props)}
          />
          {props.value && props.value.url && (
            <div className={styles.buttonWrapper}>
              <Image src={props.value.url} width={200} />
              <Tooltip placement="right" title="Delete current file">
                <Button
                  icon={<DeleteOutlined />}
                  type="primary"
                  danger
                  onClick={() => handleDeleteFile(props.value.type)}
                />
              </Tooltip>
            </div>
          )}
        </div>
      );
    };

    const widgets = {
      CheckboxWidget: CustomCheckbox,
      FileWidget: CustomFileWidget,
      ReadOnlyWidget: ReadOnlyField,
      SelectWidget: CustomSelect,
      TextWidget: CustomTextField,
    };

    const fields = {
      TitleField: CustomTitleField,
      DescriptionField: CustomTitleField,
      address: CustomAddressFields,
    };

    return (
      <div
        className={`component-schema-form ${
          arrayFieldTemplate === 'form' ? 'template-form' : 'template-table'
        } ${customStyles || ''}`}
      >
        <div>
          <Form
            ArrayFieldTemplate={getArrayFieldTemplate()}
            FieldTemplate={CustomFieldTemplate}
            disabled={!this.state.isPaid || this.props.disabled}
            enctype="multipart/form-data"
            fields={fields}
            formData={formData}
            schema={schema}
            uiSchema={uiSchema || {}}
            widgets={widgets}
            noValidate
            onChange={(data) => {
              this.setState(
                {
                  formData: data.formData,
                },
                () => {
                  if (onChange) {
                    onChange(data, this.state.files);
                  }
                },
              );
            }}
            onSubmit={(data) => {
              confirmAction(data, this.state.files);
            }}
            formContext={{ updateContactDetails: this.updateContactDetails }}
          >
            {this.getFormActions()}
          </Form>
        </div>
      </div>
    );
  }
}

export { FormSchema };
