import React from 'react';

import { GraphProps, GraphEventsProps } from '../../Graph/Types';
import { ComponentData } from '../../Types';
import Wire from '../Wire';

import Draw from './Draw';

import { ObjectShape } from '~/store/reducers/configs';

type CableProps = {
  data: ComponentData;
  objects: ObjectShape[];
};

type graphProps = Pick<GraphProps, 'graph'>;
type Props = CableProps & graphProps & GraphEventsProps;

const Cable: React.FC<Props> = (props) => {
  return (
    <React.Fragment>
      <Draw {...props} />
      <Wire {...props} />
    </React.Fragment>
  );
};

export default Cable;
