/* eslint-disable @typescript-eslint/no-explicit-any */
import { JSONSchema4, JSONSchema6Type } from 'json-schema';

import { MultiTableType, TableDataType } from '../types/TableData';

/**
  data: "MCIL8M"
    rowSchema:
      title: "Name"
      type: "string"
 */
const getArrayData = (
  schema: JSONSchema4,
  data: { [key: string]: JSONSchema6Type }[],
  _currentRecursiveLevel: number,
) => {
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  return data.map((item) => generateData(schema, item, _currentRecursiveLevel + 1));
};

export const generateData = (
  schema: JSONSchema4,
  data: {
    [id: string]: JSONSchema6Type;
  },
  _currentRecursiveLevel = 1,
): TableDataType => {
  const extraTables: any = [];
  const properties = Object.keys(schema.properties || {});
  const rowData: any = [];

  properties.forEach((property) => {
    if (schema.properties) {
      const rowSchema = schema.properties[property];

      // render Object
      if (rowSchema.type === 'object') {
        if (data[property] && data[property] instanceof Object) {
          extraTables.push({
            title: rowSchema.title,
            icon: rowSchema.icon,
            component: rowSchema.use,
            multiTableType: MultiTableType.FLAT,
            data: [
              generateData(
                rowSchema as JSONSchema4,
                data[property] as {
                  [id: string]: JSONSchema6Type;
                },
                _currentRecursiveLevel + 1,
              ),
            ],
          });
        } else {
          extraTables.push({
            title: rowSchema.title,
            icon: rowSchema.icon,
            multiTableType: MultiTableType.FLAT,
            component: rowSchema.use,
            data: [generateData(rowSchema as JSONSchema4, data, _currentRecursiveLevel + 1)],
          });
        }
      } else if (
        rowSchema.type === 'array' &&
        rowSchema.items &&
        data[property] != null &&
        (rowSchema.items as JSONSchema4).type === 'object' &&
        (data[property] as any[]).length > 0 // avoid create a table without data
      ) {
        const items = { ...rowSchema.items, title: rowSchema.title };
        extraTables.push({
          title: rowSchema.title,
          icon: rowSchema.icon,
          component: rowSchema.use,
          multiTableType: MultiTableType.TABS,
          data: getArrayData(
            items as JSONSchema4,
            data[property] as { [key: string]: JSONSchema6Type }[],
            _currentRecursiveLevel,
          ),
        });
      } else if (
        rowSchema.type === 'array' &&
        rowSchema.items &&
        data[property] != null &&
        (rowSchema.items as JSONSchema4).type === 'string' &&
        (data[property] as any[]).length > 0 &&
        _currentRecursiveLevel === 1
      ) {
        extraTables.push({
          title: rowSchema.title,
          icon: rowSchema.icon,
          component: rowSchema.use,
          multiTableType: MultiTableType.FLAT,
          data: [
            [
              {
                rowSchema,
                data: data[property],
              },
            ],
          ],
        });
      } else if (data[property] != null) {
        if (rowSchema.derived_unit !== undefined) {
          rowData.push({
            rowSchema: { ...rowSchema, units: data[rowSchema.derived_unit] },
            data: data[property],
          });
        } else {
          rowData.push({
            rowSchema,
            data: data[property],
          });
        }
      }
    }
  });

  if (extraTables.length > 0) {
    return [...rowData, ...extraTables];
  }

  return rowData;
};
