import { LeftOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, message, Row, Spin } from 'antd';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';

import styles from './styles.module.scss';

import { putResetPassword } from '~/api/UnAuthorizedPuts';
import { AppState } from '~/store/reducers';
import { isStateLoading } from '~/utils/state';

type PropsFromState = {
  isLoading: boolean;
};

type ResetPasswordProps = PropsFromState;

type valuesProps = {
  password: string;
  confirm: string;
};

const ResetPassword = ({ isLoading }: ResetPasswordProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [token, setToken] = useState('');

  useEffect(() => {
    const query = queryString.parse(location.search);

    if (!query.token) {
      navigate('/login');
    }

    setToken(query.token as string);
  }, [location.search, navigate]);

  const onFinish = async (values: valuesProps) => {
    const response = await putResetPassword('', { password: values.password, token });

    if (response) {
      message.success('Password reset successfully');
    }
  };

  return (
    <div className={styles.wrapper}>
      <Spin spinning={isLoading}>
        <Row>
          <Col offset={2} span={20}>
            <Link className={styles.linkBack} to="/login">
              <LeftOutlined className={styles.linkBackIcon} />
              Go back to login
            </Link>
            <div className={styles.header}>
              <h1>Reset Your Password</h1>
            </div>
            <div className={styles.body}>
              <Form layout="vertical" name="reset-password" onFinish={onFinish}>
                <Form.Item
                  label="New Password"
                  name="password"
                  rules={[{ required: true, message: 'Please input your new Password!' }]}
                  hasFeedback
                >
                  <Input.Password placeholder="New Password" size="large" />
                </Form.Item>

                <Form.Item
                  dependencies={['password']}
                  label="Confirm New Password"
                  name="confirm"
                  rules={[
                    {
                      required: true,
                      message: 'Please confirm your password!',
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                          return Promise.resolve();
                        }

                        return Promise.reject(
                          new Error('The two passwords that you entered do not match!'),
                        );
                      },
                    }),
                  ]}
                  hasFeedback
                >
                  <Input.Password placeholder="Confirm New Password" size="large" />
                </Form.Item>

                <Form.Item style={{ marginTop: '1rem' }}>
                  <Button htmlType="submit" size="large" type="primary">
                    Reset Password
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </Col>
        </Row>
      </Spin>
    </div>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    isLoading: isStateLoading(state.action, 'resetPassword'),
  };
};

export default connect(mapStateToProps)(ResetPassword);
