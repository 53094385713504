import { mxGraph, mxCell } from '@anekonnect/mxgraph';
import { range } from 'lodash';

import Constant from '../Constant';
import {
  getTotalHeightOfContainer,
  getLabelPin,
  getCenter,
  getPrevHeight,
  getCurrentLastOffsetY,
} from '../Helper';
import { ComponentData, Possible, Ref } from '../../../Types';
import { PreviousGeo } from '../Types';

import { createCableContainer, createCablePin, createLabelPin } from './Core';

import { mx } from '~/constants/wizard';
import { ConfigProp } from '~/store/reducers/configs';

export const triTwisted = (
  graph: mxGraph,
  componentdata: ComponentData,
  parent: mxCell,
  geometryRef: Ref<PreviousGeo[]>,
  indexOfCore: number,
  configs: ConfigProp,
) => {
  const { mxPoint } = mx;
  const { defaultAlias, position, type, id, index } = componentdata;

  const endTerminalPointLeft = '-0.5';
  const endTerminalPointRight = '0.5';

  const totalHeightOfContainer = getTotalHeightOfContainer(componentdata);
  const rootId = `${type}_schematics_${id}_${index}_draw`;
  const countCellsQT = 3;

  let resultOfssets: Possible = [];
  let componentContainer: mxCell | null = null;
  let firstPortLeft = null;

  const coreData = componentdata.cores[indexOfCore];
  const { gauge, units } = getLabelPin(componentdata, 'cores', indexOfCore);
  const prevHeight = getPrevHeight(componentdata, indexOfCore);
  const isFirstKey = indexOfCore === 0;

  if (isFirstKey) {
    componentContainer = createCableContainer(
      graph,
      componentdata,
      `${rootId}_container`,
      defaultAlias,
      parent,
      totalHeightOfContainer,
      {
        x: position.schematics.x,
        y: position.schematics.y,
      },
      configs,
    );
  } else {
    componentContainer =
      geometryRef.current[indexOfCore - 1]?.componentContainer ||
      geometryRef.current[0]?.componentContainer;
  }

  const children = componentContainer?.children || [];
  let lastChildIndex = children.length;

  const totalCable = coreData.count * countCellsQT;
  const countRanges = range(0, totalCable, 3);
  const lastIndex = totalCable - 1;

  for (let index = 0; index < coreData.count * countCellsQT; index++) {
    const stepIndex = countRanges.includes(index);

    const { currentlastOffsetY } = getCurrentLastOffsetY(
      geometryRef,
      indexOfCore,
      index,
      Constant.cablePinHeight.triTwisted,
      prevHeight,
    );

    let lastOffsetY = 0;
    const currentHeight = Constant.cablePinHeight.triTwisted;

    if (isFirstKey) {
      lastOffsetY = currentlastOffsetY;
    } else {
      lastOffsetY = currentlastOffsetY + currentHeight * index;
    }

    if (componentContainer) {
      const connectorPin = createCablePin(
        graph,
        componentdata,
        `${rootId}_core_${coreData.type}_${Constant.cablePinId}_${lastChildIndex}`,
        null,
        componentContainer,
        Constant.cableContainerWidth,
        Constant.cablePinHeight.triTwisted,
        lastOffsetY,
        isFirstKey,
      );
      lastChildIndex++;

      const labelPin = createLabelPin(
        graph,
        `${rootId}_${Constant.labelPinId}_${index}`,
        `${gauge} ${units}`,
        // TODO: Make to const
        40,
        10,
        connectorPin,
      );

      const portLeftInside = graph.insertVertex(
        connectorPin,
        `${rootId}_${Constant.portLeftInsideId}_${index}`,
        null,
        0,
        0,
        (connectorPin.geometry.width - labelPin.geometry.width) / 2,
        4,
        'shape=line;align=right;verticalAlign=middle;routingCenterX=' +
          endTerminalPointLeft +
          ';fontColor=#000;strokeColor=#000;rotatable=0;',
      );
      portLeftInside.geometry.relative = true;
      portLeftInside.geometry.offset = new mxPoint(
        0,
        getCenter(
          connectorPin.geometry.y,
          connectorPin.geometry.height,
          portLeftInside.geometry.height,
        ),
      );
      portLeftInside.setConnectable(false);
      firstPortLeft = portLeftInside;

      if (stepIndex) {
        if (firstPortLeft) {
          const shieldedTriTwistedHeight = Constant.cablePinHeight.shieldedTriTwisted;
          // Implements custom shape (quadTwistedShape)
          const cableQuadTwisted = graph.insertVertex(
            componentContainer,
            `${rootId}_${Constant.cableQuadTwistedShapeId}_${index}`,
            null,
            5,
            connectorPin.geometry.offset.y + firstPortLeft.geometry.offset.y,
            10,
            shieldedTriTwistedHeight * countCellsQT - shieldedTriTwistedHeight / 2,
            `shape=quadTwistedShape;align=center;verticalAlign=middle;fillColor=none;strokeColor=#000;rotatable=0;`,
          );
          cableQuadTwisted.geometry.relative = false;
          cableQuadTwisted.setConnectable(false);
        }
      }

      if (lastIndex === index) {
        resultOfssets.push({
          lastOffsetY: connectorPin.geometry.offset.y,
          componentContainer: isFirstKey ? componentContainer : null,
          key: `${indexOfCore}_${coreData.type}`,
        });

        geometryRef.current[indexOfCore] = resultOfssets[0];
        resultOfssets = [];
      }

      const portLeftOutside = graph.insertVertex(
        connectorPin,
        `${rootId}_${Constant.portLeftOutsideId}_${index}`,
        null,
        0,
        0,
        8,
        4,
        'shape=line;align=right;verticalAlign=middle;routingCenterX=' +
          endTerminalPointLeft +
          ';fontColor=#000;strokeColor=#000;rotatable=0;',
      );
      portLeftOutside.geometry.relative = true;
      portLeftOutside.geometry.offset = new mxPoint(
        -8,
        getCenter(
          connectorPin.geometry.y,
          connectorPin.geometry.height,
          portLeftOutside.geometry.height,
        ),
      );
      portLeftOutside.setConnectable(true);

      const portRightInside = graph.insertVertex(
        connectorPin,
        `${rootId}_${Constant.portRightInsideId}_${index}`,
        null,
        0,
        0,
        (connectorPin.geometry.width - labelPin.geometry.width) / 2,
        4,
        `shape=line;align=left;verticalAlign=middle;routingCenterX=${endTerminalPointRight};spacingLeft=10;fontColor=${Constant.baseColor};strokeColor=${Constant.baseColor};rotatable=0;`,
      );
      portRightInside.geometry.relative = true;
      portRightInside.geometry.offset = new mxPoint(
        connectorPin.geometry.width - labelPin.geometry.offset.x,
        getCenter(
          connectorPin.geometry.y,
          connectorPin.geometry.height,
          portRightInside.geometry.height,
        ),
      );
      portRightInside.setConnectable(false);
      const portRightOutside = graph.insertVertex(
        connectorPin,
        `${rootId}_${Constant.portRightOutsideId}_${index}`,
        null,
        0,
        0,
        8,
        4,
        `shape=line;align=left;verticalAlign=middle;routingCenterX=${endTerminalPointRight};spacingLeft=10;fontColor=${Constant.baseColor};strokeColor=${Constant.baseColor};rotatable=0;`,
      );
      portRightOutside.geometry.relative = true;
      portRightOutside.geometry.offset = new mxPoint(
        connectorPin.geometry.width,
        getCenter(
          connectorPin.geometry.y,
          connectorPin.geometry.height,
          portRightInside.geometry.height,
        ),
      );
      portRightOutside.setConnectable(true);
    }
  }
};
