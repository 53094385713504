import { Modal } from 'antd';
import { useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

type ViewQuoteModalProps = {
  pdf: string;
  open: boolean;
  setOpen: (open: boolean) => void;
};

const ViewQuoteModal = ({ pdf, open, setOpen }: ViewQuoteModalProps) => {
  const [numPages, setNumPages] = useState<number>(1);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  return (
    <Modal
      title="View Quote"
      open={open}
      onCancel={() => {
        setOpen(false);
      }}
      onOk={() => {
        setOpen(false);
      }}
      width={800}
    >
      <Document file={pdf} onLoadSuccess={onDocumentLoadSuccess}>
        {Array.from(new Array(numPages), (el, index) => (
          <Page key={`page_${index + 1}`} pageNumber={index + 1} width={750} />
        ))}
      </Document>
    </Modal>
  );
};

export default ViewQuoteModal;
