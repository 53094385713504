import { Modal, Select } from 'antd';
import { useEffect, useState } from 'react';

import { putUpdateUserPayment } from '~/api/AuthorizedPuts';

type EditPaymentStatusProps = {
  isOpen: boolean;
  onCancel: () => void;
  onOk: () => void;
  id: string;
  value: string;
};

const EditPaymentStatus = ({ isOpen, onCancel, onOk, id, value }: EditPaymentStatusProps) => {
  const [currentValue, setCurrentValue] = useState<string>(value);

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  const handlePaymentStatusChange = (value: string) => {
    setCurrentValue(value);
  };

  return (
    <Modal
      title="Edit Payment Status"
      open={isOpen}
      onCancel={onCancel}
      onOk={async () => {
        if (currentValue) {
          await putUpdateUserPayment(id, { payment_status: currentValue });
        }
        onOk();
      }}
    >
      <Select
        style={{ width: 120 }}
        defaultValue={currentValue}
        onChange={handlePaymentStatusChange}
        options={[
          { label: 'Paid', value: 'Paid' },
          { label: 'Trial', value: 'Trial' },
          { label: 'Abandoned', value: 'Abandoned' },
        ]}
      />
    </Modal>
  );
};

export default EditPaymentStatus;
