import { ReactNode } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

type LoadingProps = { children: ReactNode; isLoading: boolean };

const Loading = ({ children, isLoading }: LoadingProps) => {
  return (
    <Spin
      spinning={isLoading}
      indicator={<LoadingOutlined style={{ fontSize: 32 }} spin />}
      style={{ maxHeight: '100%' }}
    >
      {children}
    </Spin>
  );
};

export default Loading;
