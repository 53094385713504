import { mxCell, mxGraph, mxMouseEvent } from '@anekonnect/mxgraph';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import Constant from './Constant';
import PenetratorPinModal from './Modal';

import { mx } from '~/constants/wizard';

type EventsProps = {
  graph: mxGraph;
};

const Events = ({ graph }: EventsProps) => {
  const isConnectorPinDragRef = useRef<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [cell, setCell] = useState<mxCell | null>(null);

  const findSelectedCell = useCallback(
    (me: mxMouseEvent) => {
      const cell = me.getCell();

      if (!cell) {
        return;
      }

      const parent = cell.getParent();

      if (!parent) {
        return;
      }

      const isNode = mx.mxUtils.isNode(parent.value, 'component');
      const configs = isNode ? parent.value.getAttribute('configs', null) : null;

      if (configs) {
        const { editable } = JSON.parse(configs);

        if (editable) {
          const cellId = cell.getId();

          const isClick = !isConnectorPinDragRef.current;

          if (isClick) {
            const hasConnectorPinId = cellId.includes(Constant.penetratorPinId);

            if (hasConnectorPinId) {
              setOpenModal(true);
              setCell(cell);
            }
          }
        }
      }
    },
    [setOpenModal],
  );

  const changeConnectorPinCursor = useCallback(
    (me: mxMouseEvent) => {
      if (graph) {
        const state = graph.view.getState(me.getCell());

        if (state) {
          const cellId = state.cell.getId();
          const hasCellId = cellId.includes(Constant.penetratorPinId);
          const hasCableId = cellId.includes('cable');

          if (hasCellId && !hasCableId) state.setCursor('pointer');
        }
      }
    },
    [graph],
  );

  const graphMouseEvent = useCallback(() => {
    graph.addMouseListener({
      mouseUp(_sender, me) {
        findSelectedCell(me);

        return;
      },
      mouseDown() {
        isConnectorPinDragRef.current = false;

        return;
      },
      mouseMove(_sender, me) {
        isConnectorPinDragRef.current = true;
        changeConnectorPinCursor(me);

        return;
      },
    });
  }, [graph, changeConnectorPinCursor, findSelectedCell]);

  useEffect(graphMouseEvent, [graphMouseEvent]);

  return (
    <React.Fragment>
      <PenetratorPinModal
        cell={cell}
        graph={graph}
        handleClose={() => setOpenModal(false)}
        isOpen={openModal}
      />
    </React.Fragment>
  );
};

export default Events;
