import { mxGraph, mxMouseEvent } from '@anekonnect/mxgraph';
import React, { useEffect, useCallback, useRef } from 'react';

type EventsProps = {
  graph: mxGraph;
};

const Events = ({ graph }: EventsProps) => {
  const isEdgeSelected = useRef<boolean>(false);
  const isSelectionEvent = useRef<boolean>(false);

  const handleClickEdge = useCallback(
    (me: mxMouseEvent) => {
      const cell = me.getCell();
      const state = graph.view.getState(cell);

      if (state) {
        const isEdge = graph.model.isEdge(cell);

        if (isEdge) {
          graph.setSelectionCell(cell);
          const isCellSelected = graph.isCellSelected(cell);

          if (isCellSelected) {
            isEdgeSelected.current = true;
          }
        } else {
          isEdgeSelected.current = false;
        }
      } else {
        isEdgeSelected.current = false;
      }
    },
    [graph],
  );

  const handleHoverEdge = useCallback(
    (me: mxMouseEvent) => {
      const cell = me.getCell();
      const state = graph.view.getState(cell);
      const isSelected = isEdgeSelected.current;

      if (state) {
        if (!isSelected) {
          const isEdge = graph.model.isEdge(me.getCell());

          // For draw line
          const isArrowEdge = cell?.id.includes('arrow');
          const isBezierCurve = cell?.id.includes('bezier_curve');

          if (isEdge && isBezierCurve && !isArrowEdge) {
            // Disable to fix unselected issues
            // graph.setSelectionCell(cell);
          }
        }
      } else {
        const isDimensionLine = cell?.id.includes('bezier_dimension_line');

        if (isDimensionLine) {
          if (!isSelected && !isSelectionEvent.current) {
            graph.clearSelection();
          }
        }
      }
    },
    [graph],
  );

  useEffect(() => {
    graph.addMouseListener({
      mouseUp(_key) {
        // Disable this to fix multiple selection issues at 28/12/2023
        // Need to check if this is still needed, if not remove this
        // handleClickEdge(me);

        return;
      },
      mouseDown() {
        return;
      },
      mouseMove(_key) {
        // Disable this to fix multiple selection issues at 28/12/2023
        // Need to check if this is still needed, if not remove this
        // handleHoverEdge(me);

        return;
      },
    });
  }, [graph, handleHoverEdge, handleClickEdge]);

  return <React.Fragment />;
};

export default Events;
