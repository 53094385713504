import factory from '@anekonnect/mxgraph';

export const pinHeight = 40;
export const minYPos = 35;

/* dnd */
export const itemTypes = {
  RECTANGLE: 'rectangle',
};

export const mx = factory({
  mxImageBasePath: 'img',
});

// Set global variables for MxGraph, due to mxgraph decoder issue
(window as any)['mxEditor'] = mx.mxEditor;
(window as any)['mxGeometry'] = mx.mxGeometry;
(window as any)['mxDefaultKeyHandler'] = mx.mxDefaultKeyHandler;
(window as any)['mxDefaultPopupMenu'] = mx.mxDefaultPopupMenu;
(window as any)['mxGraph'] = mx.mxGraph;
(window as any)['mxStylesheet'] = mx.mxStylesheet;
(window as any)['mxDefaultToolbar'] = mx.mxDefaultToolbar;
(window as any)['mxGraphModel'] = mx.mxGraphModel;
(window as any)['mxPoint'] = mx.mxPoint;
