/* eslint-disable @typescript-eslint/no-unused-vars */
import { DeleteOutlined } from '@ant-design/icons';
import { Button, Input, List, Modal, Space, notification } from 'antd';
import { set } from 'lodash';
import { useEffect, useState } from 'react';
import { postInviteUser } from '~/api/AuthorizedPosts';
import Loading from '~/components/Loading';
import AddUserModal from './AddUserModal';

type InviteUserModalProps = {
  showModal: boolean;
  onClose: () => void;
};

type User = {
  name: string;
  email: string;
};

const InviteUserModal = ({ showModal, onClose }: InviteUserModalProps) => {
  const [isLoading, setIsloading] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [userList, setUserList] = useState<User[]>([]);
  const [showAddUser, setShowAddUser] = useState(false);
  const [message, setMessage] = useState('');

  useEffect(() => {
    if (showModal) {
      setUserList([]);
      setMessage('');
    }
  }, [showModal]);

  const inviteHandler = async () => {
    setIsloading(true);
    const transformedUserList = userList.map((user) => {
      return { name: user.name, address: user.email };
    });

    const result = await postInviteUser({
      emails: transformedUserList,
      message: message,
    });

    if (result?.status === 'done') {
      const existingUsers = result.existingUsers.split(',');
      const existingUsersList = userList.filter((user) => existingUsers.includes(user.email));
      const newUserList = userList.filter((user) => !existingUsers.includes(user.email));

      if (newUserList.length > 0) {
        api.success({
          message: 'Invitation Sent',
          description: `The following users have been invited: ${newUserList
            .map((user) => user.email)
            .join(', ')}`,
          duration: 0,
        });
      }

      if (existingUsersList.length > 0) {
        api.error({
          message: 'Unable to Invite',
          description: `The following users already exist in the system: ${existingUsersList
            .map((user) => user.email)
            .join(', ')}`,
          duration: 0,
        });
      }
    }

    setIsloading(false);
    onClose();
  };

  return (
    <>
      {contextHolder}
      <AddUserModal
        showModal={showAddUser}
        onClose={() => setShowAddUser(false)}
        handleAdd={(values) => {
          setUserList([...userList, values]);
        }}
      />
      <Modal
        title="Invite Users"
        open={showModal}
        centered
        onCancel={() => onClose()}
        onOk={() => {
          inviteHandler();
        }}
        footer={[
          <Button key="back" onClick={() => onClose()} disabled={isLoading}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={() => inviteHandler()} loading={isLoading}>
            Send Invitation
          </Button>,
        ]}
      >
        <Loading isLoading={isLoading}>
          <Space direction="vertical" style={{ width: '100%' }}>
            <List
              itemLayout="horizontal"
              dataSource={userList}
              locale={{
                emptyText: <span>No users to invite, please a user.</span>,
              }}
              renderItem={(item) => (
                <List.Item>
                  <List.Item.Meta title={item.name} description={item.email} />
                  <Button
                    onClick={() => {
                      setUserList(userList.filter((user) => user.email !== item.email));
                    }}
                    danger
                    icon={<DeleteOutlined />}
                  />
                </List.Item>
              )}
            />
            <Button onClick={() => setShowAddUser(true)}>Add User</Button>
            <Input.TextArea
              placeholder="Add a message to the invitation email"
              onChange={(e) => setMessage(e.target.value)}
            />
          </Space>
        </Loading>
      </Modal>
    </>
  );
};

export default InviteUserModal;
