import {
  BookFilled,
  BookOutlined,
  CloudDownloadOutlined,
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  ShareAltOutlined,
} from '@ant-design/icons';
import { Badge, Button, Modal, Space, Spin, Tooltip } from 'antd';
import { isEmpty } from 'lodash';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { Part } from '~/api/API';
import { deleteBookmarkPartItem, deletePartItem } from '~/api/AuthorizedDeletes';
import { getPartItem } from '~/api/AuthorizedGets';
import { putAddBookmarkPartItem } from '~/api/AuthorizedPuts';
import {
  GET_LINK_COPY_PARTS_ITEM,
  GET_LINK_EDIT_PARTS_ITEM,
  GET_LINK_VIEW_PARTS,
} from '~/constants/paths';
import { snackBarOpen } from '~/store/actions/ui/SnackBar';
import { useAppSelector } from '~/store/hooks';
import { AppState } from '~/store/reducers';
import { PartItem } from '~/types';
import { getParts, hasItemAccessOf, selectPartByPartName } from '~/utils/parts';
import { isStateLoading } from '~/utils/state';

import PartsBreadcrumb from './components/PartsBreadcrumb';
import PartCarousel from './components/PartsCarousel';
import PartsDownloadSVGModal from './components/PartsDownloadSVGModal';
import PartsShareItemModal from './components/PartsShareItemModal';
import PartsViewItemData from './components/PartsViewItemData/PartsViewItemData';
import styles from './PartsViewItem.module.scss';

type PropsFromState = {
  parts: Part[];
  isLoading: boolean;
  supportsWebgl: boolean;
};

type PropsFromDispatch = {
  snackBarOpen: typeof snackBarOpen;
};

type PartsViewItemProps = PropsFromState & PropsFromDispatch;

const PartsViewItem = (props: PartsViewItemProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const partItemId = params.partItemId;
  const partName = params.partName || '';
  const permissions = useAppSelector((state) => state.data.whoami.data?.permissions);
  const tenantConfig = useAppSelector((state: AppState) => state.data.tenantConfig.data);
  const primaryColor = tenantConfig?.ui_config?.primary_color || '#00D3B2';

  const [itemData, setItemData] = useState<PartItem>();
  const [openShareModal, setOpenShareModal] = useState(false);
  const [openDownloadSVGModal, setOpenDownloadSVGModal] = useState(false);

  const part = useMemo(() => selectPartByPartName(props.parts, partName), [props.parts, partName]);

  const fetchPartData = () => {
    getPartItem(partItemId).then((data: PartItem) => setItemData(new PartItem(data)));
  };

  useEffect(fetchPartData, [partItemId]);

  const schema = useMemo(() => (part ? JSON.parse(part.read_schema) : null), [part]);

  if (props.isLoading || isEmpty(itemData)) return <Spin className={styles.loading} spinning />;

  const handleBookmark = async (item: PartItem) => {
    if (!item.is_favorite) {
      await putAddBookmarkPartItem(String(item.id));
    } else {
      await deleteBookmarkPartItem(item.id);
    }
    itemData.is_favorite = !itemData.is_favorite;
    setItemData(PartItem.from(itemData));
  };

  const canCopy = hasItemAccessOf(itemData, 'COPY');
  const canBookmark = hasItemAccessOf(itemData, 'BOOKMARK');
  const canShare = hasItemAccessOf(itemData, 'SHARE');
  const canDelete = hasItemAccessOf(itemData, 'DELETE');
  // const canDownloadSVG = itemData?.files !== null;
  const canDownloadSVG = false; // Disable for now, until we have a function to limit the download SVGs

  const actionButtons = [];

  if (permissions?.includes('WriteAllPart') && !itemData.library) {
    actionButtons.push(
      <Tooltip key="edit" color={primaryColor} placement="top" title={`Edit ${partName}`}>
        <Button
          icon={<EditOutlined />}
          size="large"
          onClick={() => navigate(GET_LINK_EDIT_PARTS_ITEM(partName, itemData.id))}
        />
      </Tooltip>,
    );
  }

  if (canCopy) {
    actionButtons.push(
      <Tooltip key="copy" color={primaryColor} placement="top" title={`Copy ${partName}`}>
        <Button
          icon={<CopyOutlined />}
          size="large"
          onClick={() => navigate(GET_LINK_COPY_PARTS_ITEM(partName, itemData.id))}
        />
      </Tooltip>,
    );
  }

  if (canBookmark) {
    actionButtons.push(
      <Tooltip key="bookmark" color={primaryColor} placement="top" title={`Bookmark ${partName}`}>
        <Button
          icon={
            itemData.is_favorite ? <BookFilled key="bookmark" /> : <BookOutlined key="bookmark" />
          }
          size="large"
          onClick={() => handleBookmark(itemData)}
        />
      </Tooltip>,
    );
  }

  if (canShare) {
    actionButtons.push(
      <Tooltip key="share" color={primaryColor} placement="top" title={`Share ${partName}`}>
        <Button
          icon={<ShareAltOutlined key="share" />}
          size="large"
          onClick={() => {
            setItemData(itemData);
            setOpenShareModal(true);
          }}
        />
      </Tooltip>,
    );
  }

  const showDeleteConfirm = () => {
    Modal.confirm({
      title: 'Do you want to delete this part?',
      icon: <ExclamationCircleOutlined />,
      onOk: () => {
        deletePartItem(itemData?.id).then(() => {
          props.snackBarOpen('Part is successfully deleted');
          navigate(GET_LINK_VIEW_PARTS(partName));
        });
      },
      okText: 'Yes',
      cancelText: 'No',
    });
  };

  if (canDelete && !itemData.library) {
    actionButtons.push(
      <Tooltip key="share" color={primaryColor} placement="top" title={`Delete ${partName}`}>
        <Button icon={<DeleteOutlined key="delete" />} size="large" onClick={showDeleteConfirm} />
      </Tooltip>,
    );
  }

  if (canDownloadSVG) {
    actionButtons.push(
      <Tooltip
        key="download"
        color={primaryColor}
        placement="top"
        title={`Download vector images of ${partName}`}
      >
        <Button
          icon={<CloudDownloadOutlined key="download" />}
          size="large"
          onClick={() => {
            setItemData(itemData);
            setOpenDownloadSVGModal(true);
          }}
        />
      </Tooltip>,
    );
  }

  return (
    <Fragment>
      <PartsShareItemModal
        handleCancel={() => setOpenShareModal(false)}
        isOpen={openShareModal}
        item={itemData}
      />
      <PartsDownloadSVGModal
        handleCancel={() => setOpenDownloadSVGModal(false)}
        isOpen={openDownloadSVGModal}
        item={itemData}
      />
      <PartsBreadcrumb
        data={itemData}
        partName={partName}
        parts={props.parts}
        search={location.search}
      />
      <Space style={{ position: 'relative', width: '100%' }}>
        <h1>{itemData?.name}</h1>
        {itemData?.library && (
          <Badge dot color={primaryColor} style={{ position: 'absolute', right: 0, top: -5 }} />
        )}
      </Space>
      {itemData && part && (
        <PartCarousel itemData={itemData} part={part} supportsWebgl={props.supportsWebgl} />
      )}
      <Space className={styles.actionButtons}>{actionButtons}</Space>
      {itemData && <PartsViewItemData data={itemData} schema={schema} />}
    </Fragment>
  );
};

const mapStateToProps = (state: AppState) => {
  const parts = getParts(state) || [];

  return {
    parts,
    isLoading: isStateLoading(state.action, 'tenantConfig', 'partItem'),
    supportsWebgl: state.ui.browserSupport.webgl,
  };
};

const mapDispatchToProps = {
  snackBarOpen,
};

export default connect(mapStateToProps, mapDispatchToProps)(PartsViewItem);
