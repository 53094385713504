import { combineReducers } from 'redux';

import { wizardReducer } from './wizard/index';
import { WizardState } from './wizard/State';
import { actionsReducers, ActionsState } from './Actions';
import { uiReducers } from './ui/index';
import { dataReducers, DataState } from './data/index';
import { UIState } from './ui';
import { configsReducers, ConfigsState } from './configs';

export type AppState = {
  action: ActionsState;
  assemblyWizard: WizardState;
  data: DataState;
  ui: UIState;
  configs: ConfigsState;
};

export const rootReducer = combineReducers<AppState>({
  action: actionsReducers, // ActionsState
  assemblyWizard: wizardReducer, // WizardState
  data: dataReducers, // DataState
  ui: uiReducers, // UIState
  configs: configsReducers, // ComponentConfigsState
});
