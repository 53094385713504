import {
  mxGraphExportObject,
  mxGraph,
  mxCell,
  mxRectangle,
  mxGeometryChange,
} from '@anekonnect/mxgraph';

import { getPaperSize } from '~/utils/paperSize';
import { mx } from '~/constants/wizard';

export const setGraphSetting = (mxObj: mxGraphExportObject, graph: mxGraph, size: string) => {
  const graphModel = graph.getModel();

  const { mxClient, mxEvent, mxConstants } = mxObj;

  // Enable page breaks
  const paperSize = getPaperSize(size);
  graph.pageFormat = new mx.mxRectangle(0, 0, paperSize.width, paperSize.height);
  graph.pageScale = 1;
  graph.pageBreaksVisible = true;
  graph.pageBreakDashed = true;
  graph.preferPageSize = true;

  // Stops panning while freehand is active
  if (mxClient.IS_TOUCH) {
    graph.panningHandler.isPanningTrigger = function (me) {
      const evt = me.getEvent();

      return (
        (me.getState() == null && !mxEvent.isMouseEvent(evt)) ||
        (mxEvent.isPopupTrigger(evt) &&
          (me.getState() == null || mxEvent.isControlDown(evt) || mxEvent.isShiftDown(evt)))
      );
    };
  }

  //Maximum size
  // graph.maximumGraphBounds = new mxRectangle(0, 0, window.innerWidth, window.innerHeight);
  graph.border = 50;

  // Panning handler consumed right click so this must be
  // disabled if right click should stop connection handler.;
  graph.panningHandler.usePopupTrigger = false;

  // Enables return key to stop editing (use shift-enter for newlines)
  graph.setEnterStopsCellEditing(true);

  // Adds a special tooltip for edges
  graph.setTooltips(false);

  // Make label of vertex movable
  graph.setVertexLabelsMovable(true);

  mxConstants.LABEL_HANDLE_FILLCOLOR = 'red';

  // Disable editing labelConnectorPin
  // graph.startEditingAtCell = () => graph.stopEditing(true);

  // Override this function because, cell can't draggable
  // const selfSelectCellForEvent = graph.selectCellForEvent;
  // graph.selectCellForEvent = function (cell) {
  //   const isEdge = graphModel.isEdge(cell);
  //   const isVertex = graphModel.isVertex(cell);

  //   if (isEdge) selfSelectCellForEvent.apply(this, [cell]);

  //   if (isVertex) {
  //     // TODO: Make to constant
  //     const rectId = Constant.type.engineering.componentContainer;
  //     const isRectCell = cell.id.includes(rectId);

  //     // if (isRectCell) selfSelectCellForEvent.apply(this, [cell]);
  //     selfSelectCellForEvent.apply(this, [cell]);
  //   }

  //   return cell;
  // };

  const getTooltipForCell = graph.getTooltipForCell;
  graph.getTooltipForCell = function (cell) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    type possible = any;

    // TODO: Type checking is doesn't match @typed-mxgraph, make your own types
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self: possible = this;

    let tip = '';

    if (cell) {
      const src = graphModel.getTerminal(cell, true);
      const term = graphModel.getTerminal(cell, false);

      if (src) {
        tip += self.getTooltipForCell(src) + ' ';
      }

      const parent = self.getModel().getParent(cell);
      const isVertex = self.getModel().isVertex(parent);

      if (isVertex) {
        tip += self.getTooltipForCell(parent) + '.';
      }

      tip += getTooltipForCell.apply(this, [cell]);

      const trg = self.getModel().getTerminal(cell, false);

      if (trg) {
        tip += ' ' + self.getTooltipForCell(term);
      }
    }

    return tip;
  };
};

export const loadGlobalSetting = (mxObj: mxGraphExportObject, graph: mxGraph) => {
  const { mxEdgeHandler, mxEvent, mxVertexHandler, mxImage, mxPoint } = mxObj;

  graph.setHtmlLabels(true);

  graph.convertValueToString = (cell: mxCell) => {
    if (mx.mxUtils.isNode(cell.value, 'component')) {
      return cell.getAttribute('label', '');
    }

    return cell.value;
  };

  graph.setConnectable(true);

  mxEdgeHandler.prototype.isConnectableCell = (cell) => {
    return graph.connectionHandler.isConnectableCell(cell);
  };

  // START - Custom Rotation
  const rotationHandle = new mxImage(
    'data:image/svg+xml;base64,PCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj48c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHdpZHRoPSIxNnB4IiBoZWlnaHQ9IjE2cHgiIHZpZXdCb3g9IjAgMCAyNCAyNCIgdmVyc2lvbj0iMS4xIj48cGF0aCBzdHJva2U9IiMyOWI2ZjIiIGZpbGw9IiMyOWI2ZjIiIGQ9Ik0xNS41NSA1LjU1TDExIDF2My4wN0M3LjA2IDQuNTYgNCA3LjkyIDQgMTJzMy4wNSA3LjQ0IDcgNy45M3YtMi4wMmMtMi44NC0uNDgtNS0yLjk0LTUtNS45MXMyLjE2LTUuNDMgNS01LjkxVjEwbDQuNTUtNC40NXpNMTkuOTMgMTFjLS4xNy0xLjM5LS43Mi0yLjczLTEuNjItMy44OWwtMS40MiAxLjQyYy41NC43NS44OCAxLjYgMS4wMiAyLjQ3aDIuMDJ6TTEzIDE3Ljl2Mi4wMmMxLjM5LS4xNyAyLjc0LS43MSAzLjktMS42MWwtMS40NC0xLjQ0Yy0uNzUuNTQtMS41OS44OS0yLjQ2IDEuMDN6bTMuODktMi40MmwxLjQyIDEuNDFjLjktMS4xNiAxLjQ1LTIuNSAxLjYyLTMuODloLTIuMDJjLS4xNC44Ny0uNDggMS43Mi0xLjAyIDIuNDh6Ii8+PC9zdmc+',
    16,
    16,
  );

  mxVertexHandler.prototype.rotationEnabled = true;

  const createSizerShape = mxVertexHandler.prototype.createSizerShape;
  mxVertexHandler.prototype.createSizerShape = function (
    bounds: mxRectangle,
    index: number,
    fillColor: string,
  ) {
    if (index === mxEvent.ROTATION_HANDLE) {
      this.handleImage = rotationHandle;
    }

    return createSizerShape.apply(this, [bounds, index, fillColor]);
  };

  // Adds rotation handle and live preview
  mxVertexHandler.prototype.manageSizers = true;
  mxVertexHandler.prototype.livePreview = true;

  mxVertexHandler.prototype.getRotationHandlePosition = function () {
    const padding = this.getHandlePadding();

    return new mxPoint(
      this.bounds.x + this.bounds.width - this.rotationHandleVSpacing + padding.x / 2,
      this.bounds.y + this.rotationHandleVSpacing - padding.y / 2,
    );
  };
  // END - Custom Rotation
};

export const createUndoManager = (mxObjt: mxGraphExportObject, graph: mxGraph) => {
  const undoManager = new mxObjt.mxUndoManager();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const listener = (_sender: unknown, evt: any) => {
    const edit = evt.getProperty('edit');

    if (edit.changes) {
      // Only register move and resize actions
      const includeGeometry = edit.changes.filter((change: mxGeometryChange) => {
        if (change.constructor.name === 'mxGeometryChange') {
          // except for schematics drawing
          if (change?.previous?.relative !== change?.geometry?.relative) {
            return false;
          }

          return true;
        }

        if (change.constructor.name === 'mxStyleChange') {
          return true;
        }

        return false;
      });

      if (includeGeometry.length > 0) {
        undoManager.undoableEditHappened(edit);
      }
    }
  };
  graph.getModel().addListener(mx.mxEvent.UNDO, listener);
  graph.getView().addListener(mx.mxEvent.UNDO, listener);

  return undoManager;
};
