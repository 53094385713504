import { FileOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Dropdown, Modal, message, MenuProps, Space } from 'antd';
import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import {
  wizardSetNewTemplatePosition,
  wizardSetPaperSize,
  wizardUpdatePaperSizeData,
} from '~/store/actions/wizard/Action';
import { AppState } from '~/store/reducers';
import { PaperSize, PaperSizeData } from '~/utils/paperSize';
import { putUpdateTemplateSettings } from '~/api/AuthorizedPuts';
import { TitleBlockData } from '~/store/reducers/wizard/State';
import MenuButton from './MenuButton';

type PropsFromState = {
  paperSize: string;
  paperSizeData: PaperSizeData;
  newTemplatePosition: PaperSize | null;
  newTitleBlockStyle: TitleBlockData[] | null;
  isViewOnly: boolean;
  whoamiData: {
    payment_status?: string;
    type?: string;
  };
};

type PropsFromDispatch = {
  wizardSetPaperSize: typeof wizardSetPaperSize;
  wizardUpdatePaperSizeData: typeof wizardUpdatePaperSizeData;
  wizardSetNewTemplatePosition: typeof wizardSetNewTemplatePosition;
};

type MenuPageProps = PropsFromState & PropsFromDispatch;

const MenuPage = ({
  paperSize,
  paperSizeData,
  newTemplatePosition,
  newTitleBlockStyle,
  wizardSetPaperSize,
  wizardUpdatePaperSizeData,
  wizardSetNewTemplatePosition,
  isViewOnly,
  whoamiData,
}: MenuPageProps) => {
  const modalConfirm = () => {
    if (newTemplatePosition && !isViewOnly && whoamiData) {
      Modal.confirm({
        title: 'We are detect you changes the default template, do you want to save it?',
        icon: <ExclamationCircleOutlined />,
        okText: 'Yes',
        cancelText: 'No',
        onOk: async () => {
          const data = _.cloneDeep(paperSizeData);
          data[paperSize] = newTemplatePosition;

          await putUpdateTemplateSettings('', {
            template_settings: JSON.stringify(data),
          });
          wizardUpdatePaperSizeData(data);
          wizardSetNewTemplatePosition(null);

          message.success(`Template updated for ${paperSize} size`);
        },
        onCancel: () => {
          wizardSetNewTemplatePosition(null);
        },
      });
    }
  };

  const alertUser = useCallback(
    (event: any) => {
      event.preventDefault();

      if (newTemplatePosition || newTitleBlockStyle) {
        event.returnValue = '';
      }
    },
    [newTemplatePosition, newTitleBlockStyle],
  );

  useEffect(() => {
    window.addEventListener('beforeunload', alertUser);

    return () => {
      window.removeEventListener('beforeunload', alertUser);
    };
  }, [alertUser]);

  const changePaperSize = (data: { key: React.Key }) => {
    modalConfirm();
    wizardSetPaperSize(data.key);
  };

  const items: MenuProps['items'] = [
    {
      key: 'a-paper-sizes',
      label: 'A Paper Sizes',
      type: 'group',
      children: [
        {
          key: 'a3',
          label: 'A3',
        },
        {
          key: 'a4',
          label: 'A4',
        },
      ],
    },
    {
      key: 'b-paper-sizes',
      label: 'B Paper Sizes',
      type: 'group',
      children: [
        {
          key: 'b3',
          label: 'B3',
        },
        {
          key: 'b4',
          label: 'B4',
        },
      ],
    },
    {
      key: 'us-paper-sizes',
      label: 'US Paper Sizes',
      type: 'group',
      children: [
        {
          key: 'letter',
          label: 'Letter',
        },
        {
          key: 'legal',
          label: 'Legal',
        },
        {
          key: 'ledger',
          label: 'Ledger',
        },
      ],
    },
  ];

  const onClick: MenuProps['onClick'] = (data) => {
    changePaperSize(data);
  };

  const selectedKeys: MenuProps['selectedKeys'] = [paperSize];

  return (
    <Dropdown menu={{ items, onClick, selectedKeys }} trigger={['click']}>
      <Space>
        <MenuButton icon={<FileOutlined />} label="Page" />
      </Space>
    </Dropdown>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    paperSize: state.assemblyWizard.paperSize,
    paperSizeData: state.assemblyWizard.paperSizeData,
    newTemplatePosition: state.assemblyWizard.newTemplatePosition,
    newTitleBlockStyle: state.assemblyWizard.newTitleBlockStyle,
    isViewOnly: state.assemblyWizard.isViewOnly,
    whoamiData: state.data.whoami.data,
  };
};

const mapDispatchToProps = {
  wizardSetPaperSize,
  wizardUpdatePaperSizeData,
  wizardSetNewTemplatePosition,
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuPage);
