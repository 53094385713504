import React, { useCallback, useEffect } from 'react';
import { mxCell, mxGraph } from '@anekonnect/mxgraph';

import { ComponentData, Ref } from '../../Types';

import Constant from './Constant';
import { SplitJunctionCellRef } from './Types';

import { mx } from '~/constants/wizard';
import { ObjectShape } from '~/store/reducers/configs';

type DrawProps = {
  graph: mxGraph;
  data: ComponentData;
  objects: ObjectShape[];
  splitJunctionCellRef: Ref<SplitJunctionCellRef>;
};

const Draw = ({ graph, data, splitJunctionCellRef, objects }: DrawProps) => {
  const { defaultAlias, position, type, index, id } = data;

  const drawSplitJunction = useCallback(
    (parent: mxCell) => {
      objects.forEach((object) => {
        const configs = object.configs;
        const { mxPoint } = mx;
        const rootId = `${type}_schematics_${id}_${index}_draw`;
        const parentId = `${rootId}_container_parent`;

        if (graph.getModel().getCell(parentId)) {
          return;
        }

        const doc = mx.mxUtils.createXmlDocument();
        const node = doc.createElement('component');
        node.setAttribute('label', defaultAlias);
        node.setAttribute('configs', JSON.stringify(configs));

        /** CREATE SPLIT JUNCTION CONTAINER */
        const splitJunctionContainer = graph.insertVertex(
          parent,
          `${rootId}_container_parent`,
          node,
          position.schematics.x, //splitJunctionPositions?.x
          position.schematics.y, //splitJunctionPositions?.y
          Constant.splitJunctionWidth,
          Constant.splitJunctionHeight,
          // @see: https://jgraph.github.io/mxgraph/docs/js-api/files/util/mxConstants-js.html#mxConstants
          // `shadow=0;fillColor=none;strokeColor=#000;strokeWidth=1;dashed=1;rounded=0;verticalAlign=top;spacingTop=${splitJunctionPositions?.y - (splitJunctionPositions.y + 30)
          // }`,
          `shadow=0;fillColor=none;strokeColor=#000;strokeWidth=1;dashed=1;rounded=0;`,
        );
        splitJunctionContainer.setConnectable(false);
        splitJunctionContainer.geometry.offset = new mxPoint(
          0,
          -Constant.splitJunctionHeight / 2 - 27,
        );
        /** END OF SPLIT JUNCTION CONTAINER */

        /** CREATE SPLIT JUNCTION PIN */
        const splitJunctionPin = graph.insertVertex(
          splitJunctionContainer,
          `${rootId}_${Constant.splitJunctionPinId}`,
          null,
          0,
          0,
          Constant.splitJunctionWidth,
          Constant.splitJunctionHeight,
          `fillColor=none;rounded=0;strokeWidth=0;strokeColor=none`,
        );
        splitJunctionPin.geometry.relative = true;
        splitJunctionPin.geometry.offset = new mxPoint(0, 0);
        splitJunctionPin.setId(`${rootId}_${Constant.splitJunctionPinId}`);
        splitJunctionCellRef.current.hoverCellRects.push(splitJunctionPin);
        /** END OF SPLIT JUNCTION PIN**/
      });
    },
    [graph, defaultAlias, splitJunctionCellRef, position, type, index, id, objects],
  );

  const addSplitJunction = useCallback(() => {
    const { mxClient, mxUtils } = mx;

    if (!mxClient.isBrowserSupported) {
      mxUtils.error('Browser is not supported!', 200, false);
    } else {
      const parent = graph.getDefaultParent();
      graph.getModel().beginUpdate();

      try {
        if (id) {
          drawSplitJunction(parent);
        }
      } finally {
        graph.getModel().endUpdate();
      }
    }
  }, [graph, id, drawSplitJunction]);

  useEffect(addSplitJunction, [addSplitJunction]);

  return <React.Fragment />;
};

export default Draw;
