import { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Badge, Button, Grid, Space } from 'antd';
import { FilterOutlined } from '@ant-design/icons';

import { Part } from '~/api/API';
import { getParts, selectPartByPartName } from '~/utils/parts';
import { AppState } from '~/store/reducers';
import { KeyValue, PartFilterFields } from '~/types';
import { getPartFilters } from '~/api/AuthorizedGets';

import PartsFilterTreeView from './PartsFilterTreeView';
import styles from './PartsFilter.module.scss';
import PartsFilterAddModal from './PartsFilterAddModal';

import { objectFromEntries } from '~/utils/helper';
import {
  convertStringKeyToObject,
  createDeepObject,
  extractNestedKey,
  isMultipleFilter,
} from '~/utils/filter';
import { isStateLoading } from '~/utils/state';

const { useBreakpoint } = Grid;

type PropsFromState = {
  partFiltersFields: PartFilterFields[];
  part?: Part | null;
  isLoading: boolean;
};

type PartsFilterProps = {
  filters: KeyValue;
  resetFilters: () => void;
  handleFiltering: (filters: KeyValue) => void;
  noDefaultFilterField?: boolean;
  selectedPartName?: string;
};

type SelectedFiltersData = {
  [key: string]: any; // Replace `any` with the appropriate type if you know the structure of the values
};

const PartsFilter = (props: PartsFilterProps & PropsFromState) => {
  const { lg } = useBreakpoint();
  const [showFilterOptions, setShowFilterOptions] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState([] as PartFilterFields[]);
  const [selectedFiltersData, setSelectedFiltersData] = useState<SelectedFiltersData>({});
  const [partFiltersData, setPartFiltersData] = useState<KeyValue<KeyValue<number>>>({});

  // Ignore key page
  if ('page' in props.filters) {
    delete props.filters.page;
  }

  const hasFilters = useMemo(() => Object.keys(props.filters).length > 0, [props.filters]);

  useEffect(() => {
    const filterKeys = Object.keys(props.filters);

    const filterPartFilters = (
      partFilters: PartFilterFields[],
      filterKeys: string[],
    ): PartFilterFields[] => {
      return partFilters.filter((partFilter) => {
        if (isMultipleFilter(partFilter)) {
          return filterKeys.some((key) => {
            if (key.includes('multiple')) {
              const nestedKey = extractNestedKey(key);
              return partFilter.key === nestedKey;
            }
            return false;
          });
        } else {
          return filterKeys.includes(partFilter.key);
        }
      });
    };

    const filtersSelected = filterPartFilters(props.partFiltersFields, filterKeys);

    setSelectedFilters(filtersSelected);
  }, [props.filters, props.partFiltersFields]);

  const handleFiltersChange = useCallback(() => {
    async function getFilterSet(queryArray: any) {
      const allFilters = await queryArray.map(async (filterItem: any) => {
        const newFilterItem = objectFromEntries(filterItem);
        newFilterItem.t = props.selectedPartName;
        const filters = await getPartFilters(newFilterItem);

        return filters;
      });
      const filterSet = await Promise.all(allFilters);

      return filterSet;
    }

    const queryArray = Object.entries(props.filters) ? Object.entries(props.filters) : [];
    const newQueryArray: any = [[]];
    const reversePoppedKeyArray: any = [];

    queryArray.map((item, index, array) => {
      newQueryArray.push(array.slice(0, index + 1));
      reversePoppedKeyArray.push(item[0]);

      return null;
    });

    const poppedKeyArray = reversePoppedKeyArray.reverse();

    getFilterSet(newQueryArray.reverse()).then((filterArray) => {
      const allFilterSet: any = filterArray.reduce(
        (total: any, currentValue: any, currentIndex: number) => {
          const poppedKeyIndex = currentIndex - 1;
          const popValue = poppedKeyArray[poppedKeyIndex];

          if (popValue) {
            const newValue = {
              ...total,
              [popValue]: currentValue[popValue],
            };

            return newValue;
          }

          return currentValue;
        },
        {},
      );
      setPartFiltersData(allFilterSet);
    });
  }, [props.filters, props.selectedPartName]);

  useEffect(handleFiltersChange, [handleFiltersChange]);

  useEffect(() => {
    if (props.filters) {
      setSelectedFiltersData(props.filters);
    }
  }, [props.filters]);

  const handleFilterSelection = (partFilter: PartFilterFields) => {
    setSelectedFilters((filters) => [...filters, partFilter]);
  };

  const handleValueSelection = (
    partFilter: PartFilterFields,
    value: string | null,
    rootKey?: string,
  ) => {
    const newSelectedFiltersData = convertStringKeyToObject(
      JSON.parse(JSON.stringify(selectedFiltersData)),
    );

    if (value !== null) {
      if (rootKey) {
        createDeepObject(newSelectedFiltersData, ['multiple', rootKey, partFilter.key], value);
      } else {
        createDeepObject(newSelectedFiltersData, [partFilter.key], value);
      }
    } else {
      if (rootKey) {
        delete newSelectedFiltersData['multiple'][rootKey];
      } else {
        delete newSelectedFiltersData[partFilter.key];
      }

      const index = selectedFilters.findIndex((e) => e.key === partFilter.key);

      if (index !== -1) {
        selectedFilters.splice(index, 1);
      }

      if (partFilter.key === 't') {
        return props.resetFilters();
      }
    }

    props.handleFiltering(newSelectedFiltersData);
  };

  const filterModalCloseHandler = () => {
    setShowFilterOptions(false);
  };

  return (
    <div className={styles.partsFilter}>
      {lg && (
        <Space>
          <Button type="primary" onClick={() => setShowFilterOptions(true)}>
            Add Filter
          </Button>
          {hasFilters && <Button onClick={() => props.resetFilters()}>Reset</Button>}
        </Space>
      )}

      {!lg && (
        <Badge count={selectedFilters.length}>
          <Button
            icon={<FilterOutlined />}
            type={selectedFilters.length > 0 ? 'primary' : 'default'}
            onClick={() => setShowFilterOptions(true)}
          />
        </Badge>
      )}

      {lg && hasFilters && props.part && (
        <PartsFilterTreeView
          part={props.part}
          partFiltersData={partFiltersData}
          selectedFilters={selectedFilters}
          selectedFiltersData={selectedFiltersData}
          onFilterValueSelection={(partFilters, value, rootKey) =>
            handleValueSelection(partFilters, value, rootKey)
          }
        />
      )}

      {showFilterOptions && props.part && (
        <PartsFilterAddModal
          handleFilterSelection={handleFilterSelection}
          handleValueSelection={handleValueSelection}
          open={showFilterOptions}
          part={props.part}
          partFilters={props.partFiltersFields}
          partFiltersData={partFiltersData}
          selectedFilters={selectedFilters}
          selectedFiltersData={selectedFiltersData}
          onClose={filterModalCloseHandler}
          isLoading={props.isLoading}
        />
      )}
    </div>
  );
};

const defaultFilterField: PartFilterFields = {
  field: 'type',
  display_name: 'Type',
  key: 't',
};

const mapStateToProps = (state: AppState, props: PartsFilterProps) => {
  const filtersFields: PartFilterFields[] = props.noDefaultFilterField ? [] : [defaultFilterField];
  const parts = getParts(state) || [];
  const partName = props.filters && props.filters.t ? props.filters.t : props.selectedPartName;

  if (partName && state.data.tenantConfig.data) {
    const part = selectPartByPartName(state.data.tenantConfig.data.parts, partName as string);

    if (part && part.filters && part.filters.select) {
      filtersFields.push(...part.filters.select);
    }

    if (part && part.filters && part.filters.numeric) {
      filtersFields.push(...part.filters.numeric);
    }

    if (part && part.filters && part.filters.multiple) {
      filtersFields.push(...part.filters.multiple);
    }
  }

  return {
    partFiltersFields: filtersFields,
    part: selectPartByPartName(parts, partName as string),
    isLoading: isStateLoading(state.action, 'partFilters', 'searchPartItems'),
  };
};

export default connect(mapStateToProps)(PartsFilter);
