/* eslint-disable @typescript-eslint/no-explicit-any */
import { Checkbox } from 'antd';
import React from 'react';

const CustomCheckbox = (props: any) => {
  const { id, label, required } = props;

  return (
    <React.Fragment>
      <label className="control-label" htmlFor={id}>
        {label}
        {required ? '*' : null}
      </label>
      <Checkbox
        checked={props.value}
        defaultChecked={props.value}
        name="editing"
        onChange={(e) => props.onChange((e.target as any).checked)}
      />
    </React.Fragment>
  );
};

export default CustomCheckbox;
