import React from 'react';
import { Grid, Layout } from 'antd';

import styles from './Header.module.scss';
import NavBar from './NavBar';
import { useAppSelector } from '~/store/hooks';
import { AppState } from '~/store/reducers';

const { useBreakpoint } = Grid;

const Header: React.FC = () => {
  const { xs } = useBreakpoint();
  const homeUrl =
    useAppSelector((state: AppState) => state.data.tenantConfig.data?.home_url) ||
    'https://anekonnect.io/';

  return (
    <Layout.Header className={styles.header}>
      <a href={homeUrl}>
        <img alt="logo" height={xs ? 50 : 55} src={'/img/logo.png'} className={styles.logo} />
      </a>
      <NavBar />
    </Layout.Header>
  );
};

export default Header;
