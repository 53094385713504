import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Button, Checkbox, Col, Input, Row } from 'antd';
import { Form } from 'antd';
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ActionCreator } from 'redux';

import { getCoupon, getSubscription, getWhoami } from '~/api/AuthorizedGets';
import { postCreateSubscription } from '~/api/AuthorizedPosts';
import { putUpdateSubscription } from '~/api/AuthorizedPuts';
import { ERROR } from '~/constants';
import { LINK_PRIVACY_POLICY, LINK_TERMS_CONDITIONS } from '~/constants/paths';
import { SnackBarOpen, snackBarOpen } from '~/store/actions/ui/SnackBar';
import { AppState } from '~/store/reducers';

import CardSection from './CardSection';
import styles from './CheckoutForm.module.scss';

type PropsFromState = {
  whoamiData: {
    email: string;
  };
};

type PropsFromDispatch = {
  snackBarOpen: ActionCreator<SnackBarOpen>;
};

type CheckoutFormProps = {
  summaryDetails: any;
  amount: number;
  couponSelected: any;
  handleCouponSelected: (coupon: any) => void;
  subscriptionDataHandler: (data: any) => void;
  currentPlan: any;
  handleAgreement: () => void;
  isAgree: boolean;
  isUpdatePlan?: boolean;
  isUpdateCard?: boolean;
  isActionUpdateCard?: boolean;
  callback?: () => void;
} & PropsFromState &
  PropsFromDispatch;

const CheckoutForm: React.FC<CheckoutFormProps> = (props) => {
  const [cardError, setCardError] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [isCouponError, setIsCouponError] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  const stripePaymentMethodHandler = useCallback(
    (result: any) => {
      if (result.error) {
        setCardError(result.error.message);
        setIsLoading(false);
      } else {
        try {
          if (props.isActionUpdateCard) {
            putUpdateSubscription('', { payment_method: result.paymentMethod.id }).then(() => {
              props.snackBarOpen('Updated card successfully');
            });
          } else {
            postCreateSubscription({
              plan_id: props.currentPlan.id,
              payment_method: result.paymentMethod.id,
              coupon_id: props.couponSelected.id,
            }).then(() => {
              props.snackBarOpen('Payment is successful');
              getWhoami();
              getSubscription().then((data: any) => {
                props.subscriptionDataHandler(data);
              });
            });
          }
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
          props.snackBarOpen(error, ERROR);
        }
      }
    },
    [props],
  );

  const handleSubmit = useCallback(() => {
    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    if (!cardElement) {
      return;
    }

    try {
      setIsLoading(true);
      stripe
        .createPaymentMethod({
          type: 'card',
          card: cardElement,
          billing_details: { email: props.whoamiData.email },
        })
        .then((result: any) => {
          stripePaymentMethodHandler(result);
        });

      if (props.callback) {
        props.callback();
      }
    } catch (error) {
      snackBarOpen(error, ERROR);
    }
  }, [elements, props, stripe, stripePaymentMethodHandler]);

  const handleUpdateCardInformation = useCallback(() => {
    if (props.isActionUpdateCard) {
      handleSubmit();
    }
  }, [handleSubmit, props.isActionUpdateCard]);

  useEffect(handleUpdateCardInformation, [handleUpdateCardInformation]);

  const cardValidation = (e: any) => {
    setCardError(e && e.error && e.error.message);
  };

  const couponValidation = (e: any) => {
    if (e.preventDefault) {
      e.preventDefault();
    }

    const value = e.target.value;

    if (isEmpty(value)) {
      setIsCouponError(false);

      return;
    }

    getCoupon(value).then((coupon: any) => {
      if (coupon.valid) {
        props.handleCouponSelected(coupon);
        setIsCouponError(false);
      } else {
        setIsCouponError(true);
      }
    });
  };

  return (
    <Row justify="center">
      <Col lg={10} md={24} sm={24} xs={24}>
        <Form className={styles.wrapper} onFinish={handleSubmit}>
          {!props.isUpdatePlan && <CardSection cardValidation={cardValidation} />}
          {cardError !== '' && <p className={styles.error}>{cardError}</p>}
          {!props.isUpdateCard && (
            <React.Fragment>
              <div className="coupon-code">
                {isEmpty(props.couponSelected.id) ? (
                  <React.Fragment>
                    <Input
                      addonAfter="Apply"
                      placeholder="Enter Discount Code"
                      onPressEnter={(e) => couponValidation(e)}
                    />
                    <p>If you have a discount code please enter it here.</p>
                  </React.Fragment>
                ) : (
                  <p>
                    Discount code <b>{props.couponSelected.name}</b> was applied
                  </p>
                )}
                {isCouponError && (
                  <p className={styles.error}>The discount code you entered is not valid.</p>
                )}
              </div>

              <div className={styles.summaryWrapper}>
                <div className={styles.summaryTitle}>SUMMARY</div>
                {props.summaryDetails.map((summary: any) => {
                  return (
                    <p key={summary.title}>
                      <span>{summary.title}</span> <span>{summary.amount}</span>
                    </p>
                  );
                })}
                <p className={styles.totalAmount}>
                  <span>Total Amount</span> <span>${props.amount}</span>
                </p>
              </div>
            </React.Fragment>
          )}

          <Checkbox
            checked={props.isAgree}
            className={styles.agreement}
            onChange={() => props.handleAgreement()}
          >
            <strong>
              I agree to the{' '}
              <a href={LINK_PRIVACY_POLICY} rel="noopener noreferrer" target="_blank">
                privacy policy
              </a>
              {' and '}
              <a href={LINK_TERMS_CONDITIONS} rel="noopener noreferrer" target="_blank">
                terms and conditions
              </a>
            </strong>
          </Checkbox>

          {!props.isUpdatePlan && !props.isUpdateCard && (
            <Button
              disabled={!stripe || isLoading || !props.isAgree}
              htmlType="submit"
              loading={isLoading}
              size="large"
              type="primary"
              block
            >
              Subscribe
            </Button>
          )}
        </Form>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state: AppState) => {
  return { whoamiData: state.data.whoami.data };
};

const mapDispatchToProps = {
  snackBarOpen,
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutForm);
