import { InfoCircleOutlined } from '@ant-design/icons';
import { Dropdown, MenuProps, Space } from 'antd';
import { LINK_PRIVACY_POLICY, LINK_TERMS_CONDITIONS } from '~/constants/paths';
import MenuButton from './MenuButton';

const MenuInfo = () => {
  const items: MenuProps['items'] = [
    {
      key: 'privacy-policy',
      label: (
        <a href={LINK_PRIVACY_POLICY} rel="noopener noreferrer" target="_blank">
          Privacy Policy
        </a>
      ),
    },
    {
      key: 'terms-and-conditions',
      label: (
        <a href={LINK_TERMS_CONDITIONS} rel="noopener noreferrer" target="_blank">
          Terms And Conditions
        </a>
      ),
    },
    {
      key: 'return-policy',
      label: (
        <a href="https://anekonnect.io/returnpolicy" rel="noopener noreferrer" target="_blank">
          Return Policy
        </a>
      ),
    },
    {
      key: 'disclaimer',
      label: (
        <a href="https://anekonnect.io/disclaimer" rel="noopener noreferrer" target="_blank">
          Disclaimer
        </a>
      ),
    },
  ];

  return (
    <Dropdown menu={{ items }} trigger={['click']}>
      <Space>
        <MenuButton icon={<InfoCircleOutlined />} label="Info" />
      </Space>
    </Dropdown>
  );
};

export default MenuInfo;
