import { AutoComplete, Badge, Input, SelectProps } from 'antd';
import Axios from 'axios';
import { groupBy, isEmpty } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { getSearchPartItems } from '~/api/AuthorizedGets';
import { useAppSelector } from '~/store/hooks';
import { AppState } from '~/store/reducers';
import { PartItem } from '~/types';
import { isStateLoading } from '~/utils/state';

import styles from './SearchComponentUI.module.scss';

const renderTitle = (title: string, count: number) => {
  return (
    <div className={styles.titleWrapper}>
      <span className={styles.titleText}>{title}</span>
      <Badge count={count} />
    </div>
  );
};

const renderItem = (title: string, key?: number, addComponent?: () => void) => {
  return {
    key,
    value: title,
    label: (
      <div className={styles.item} onClick={() => addComponent && addComponent()}>
        {title}
      </div>
    ),
  };
};

const SearchComponentUI = (props: any) => {
  const { value, onChange, placeholder } = props;

  const state = useAppSelector((state: AppState) => state);
  const isLoading = isStateLoading(state.action, 'searchPartItems');
  const filterBy = props?.options?.filterBy || '';

  const [searchValue, setSearchValue] = useState<string>(value);
  const [partItems, setPartItems] = useState<PartItem[]>([]);
  const [options, setOptions] = useState<SelectProps<object>['options']>([]);

  const doSearch = useCallback(() => {
    if (isEmpty(searchValue)) {
      setPartItems([]);

      return;
    }

    const source = Axios.CancelToken.source();
    const filters = {
      t: filterBy,
    };

    getSearchPartItems(searchValue, filters, source).then((result: PartItem[]) => {
      if (result !== undefined) {
        setPartItems(PartItem.fromArray(result));
      }
    });

    return () => {
      // Cancel Request when unmounting
      source.cancel();
    };
  }, [filterBy, searchValue]);

  useEffect(doSearch, [doSearch]);

  const getOptions = useCallback(() => {
    const groups = groupBy(partItems, 'type');
    const keys = Object.keys(groups);

    let result: SelectProps<object>['options'] = keys.map((key) => {
      const count = groups[key].length;

      return {
        label: renderTitle(key, count),
        options: groups[key].map((item) => {
          return renderItem(item.name, item.id, () => onChange(item.name));
        }),
      };
    });

    if (isEmpty(result) && !isEmpty(searchValue)) {
      result = [
        {
          label: renderTitle('Not Found', 0),
          options: [renderItem(`Component ${searchValue} is not found`)],
        },
      ];
    }

    setOptions(result);
  }, [partItems, searchValue, onChange]);

  useEffect(getOptions, [getOptions]);

  return (
    <AutoComplete
      popupClassName="certain-category-search-dropdown"
      options={options}
      style={{ width: '100%' }}
      defaultValue={searchValue}
    >
      <Input.Search
        size="large"
        loading={isLoading}
        placeholder={placeholder}
        value={searchValue}
        enterButton
        onChange={(e) => setSearchValue(e.target.value)}
        onSearch={setSearchValue}
      />
    </AutoComplete>
  );
};

export default SearchComponentUI;
