import Axios from 'axios';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Part } from '../../../api/API';
import { deleteBookmarkPartItem } from '../../../api/AuthorizedDeletes';
import { getSearchPartItems } from '../../../api/AuthorizedGets';
import { putAddBookmarkPartItem } from '../../../api/AuthorizedPuts';
import { AppState } from '../../../store/reducers';
import { PartItem } from '../../../types';
import { getParts } from '../../../utils/parts';
import { isStateLoading } from '../../../utils/state';

import PartsItemCard from './PartsItemCard';

type PropsFromState = {
  parts: Part[];
  isLoading: boolean;
};

type PartsSearchProps = {
  query: string;
  filters: object;
  search: string;
} & PropsFromState;

const PartsSearch = (props: PartsSearchProps) => {
  const [partItems, setPartItems] = useState<PartItem[]>([]);

  const doSearch = () => {
    const source = Axios.CancelToken.source();
    getSearchPartItems(props.query, props.filters, source).then((result: any) => {
      if (result !== undefined) {
        setPartItems(PartItem.fromArray(result));
      }
    });

    return () => {
      // Cancel Request when unmounting
      source.cancel();
    };
  };

  useEffect(doSearch, [props.query, props.filters]);

  const handleBookmark = async (item: PartItem) => {
    if (!item.is_favorite) {
      await putAddBookmarkPartItem(String(item.id));
    } else {
      await deleteBookmarkPartItem(item.id);
    }
    item.is_favorite = !item.is_favorite;
    setPartItems((v) => [...v]);
  };

  return (
    <PartsItemCard
      handleBookmark={handleBookmark}
      isLoading={props.isLoading}
      partItems={partItems}
      parts={props.parts}
    />
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    parts: getParts(state) || [],
    isLoading: isStateLoading(state.action, 'searchPartItems'),
  };
};

export default connect(mapStateToProps)(PartsSearch);
