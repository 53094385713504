import {
  CloseOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { Button, Form, Input, Row, Col } from 'antd';
import { useRef, useState } from 'react';
import { mxGraph } from '@anekonnect/mxgraph';

import WizardHeaderAddComponent from './WizardHeaderAddComponent';
import WizardHeaderSaveAssembly from './WizardHeaderSaveAssembly';
import { AssemblyForm } from './Types';
import WizardHeaderMenu from './WizardHeaderMenu';
import WizardHeaderTag from './WizardHeaderTag';
import styles from './WizardHeader.module.scss';

import { AppState } from '~/store/reducers';
import { sideMenuBarClose, sideMenuBarOpen } from '~/store/actions/ui/SideMenuBar';
import { useAppDispatch, useAppSelector } from '~/store/hooks';
import useClickOutside from '~/hooks/useClickOutside';
import WizardHeaderRFA from './WizardHeaderRFA';
import { putUpdateAssemblyNoRedirect } from '~/api/AuthorizedPuts';
import { snackBarOpen } from '~/store/actions/ui/SnackBar';

type WizardHeaderProps = {
  graph: mxGraph;
  assemblyName: string;
};

const WizardHeader = ({ graph, assemblyName }: WizardHeaderProps) => {
  const addComponentRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector((state: AppState) => state.data.auth.authenticated);
  const userId = useAppSelector((state: AppState) => state.data.whoami.data?.id);
  const {
    id: assemblyId,
    status,
    reviewers,
    approvalHistoriesTable,
    createdBy,
  } = useAppSelector((state: AppState) => state.assemblyWizard);
  const assemblyStatus = status.toLowerCase();
  const sideMenuBar = useAppSelector((state: AppState) => state.ui.sideMenuBar);
  const [assemblyForm] = Form.useForm<AssemblyForm>();

  const [showAddComponent, setShowAddComponent] = useState(false);

  useClickOutside(addComponentRef, () => setShowAddComponent(false));

  const handleSideMenuBar = (isOpen: boolean) => {
    if (isOpen) {
      dispatch(sideMenuBarOpen());
    } else {
      dispatch(sideMenuBarClose());
    }
  };

  const updateApprovalHistoriesTablePositionHandler = async () => {
    const response = await putUpdateAssemblyNoRedirect(`${assemblyId}/approval-histories-table`, {
      approval_histories_table: JSON.stringify(approvalHistoriesTable),
    });

    if (response?.id) {
      dispatch(snackBarOpen('Approval histories table position is successfully updated'));
    }
  };

  return (
    <>
      <WizardHeaderMenu assemblyForm={assemblyForm} graph={graph} />

      <Row className={styles.root}>
        <Col span={24}>
          <div className={styles.wapper}>
            <div className={styles.leftWrapper}>
              <div
                className={`${styles.buttonSideMenu} ${
                  !sideMenuBar.open && styles.buttonSideMenuClose
                }`}
              >
                {sideMenuBar.open ? (
                  <Button icon={<MenuUnfoldOutlined />} onClick={() => handleSideMenuBar(false)} />
                ) : (
                  <Button icon={<MenuFoldOutlined />} onClick={() => handleSideMenuBar(true)} />
                )}
              </div>
              <Button
                disabled={!assemblyId && !isAuthenticated ? true : false}
                icon={showAddComponent ? <CloseOutlined /> : <PlusOutlined />}
                type="primary"
                onClick={() => setShowAddComponent(!showAddComponent)}
              >
                {showAddComponent
                  ? 'Close Component Selection'
                  : !assemblyId && !isAuthenticated
                  ? 'Name and Save assembly to add components'
                  : 'Add Component'}
              </Button>
            </div>

            <div className={styles.rightWrapper}>
              <Form
                form={assemblyForm}
                initialValues={{
                  assemblyName: assemblyName || null,
                }}
                layout="inline"
                style={{ marginRight: '-10px', gap: '5px' }}
              >
                <Form.Item
                  name="assemblyName"
                  rules={[{ required: true, message: 'Please input assembly name!' }]}
                  style={{ width: '250px' }}
                >
                  <Input disabled={assemblyId ? true : false} placeholder="Assembly Name" />
                </Form.Item>

                {!isAuthenticated && (
                  <Form.Item
                    name="email"
                    rules={[{ required: true, message: 'Please input email address!' }]}
                    style={{ width: '250px' }}
                  >
                    <Input placeholder="Email Address" />
                  </Form.Item>
                )}
              </Form>
              {isAuthenticated && <WizardHeaderTag />}
              <WizardHeaderSaveAssembly assemblyForm={assemblyForm} graph={graph} />
              {assemblyStatus === 'under review' && reviewers?.includes(userId) && (
                <WizardHeaderRFA />
              )}
              {assemblyStatus === 'published' && createdBy === userId && (
                <Button type="primary" onClick={updateApprovalHistoriesTablePositionHandler}>
                  Update Approval Histories Table Position
                </Button>
              )}
            </div>
          </div>
          {showAddComponent && <WizardHeaderAddComponent innerRef={addComponentRef} />}
        </Col>
      </Row>
    </>
  );
};

export default WizardHeader;
