import { Reducer, combineReducers } from 'redux';

import {
  SUCCESS,
  FAILURE,
  WHOAMI,
  LOGIN_USER,
  OAUTH_USER,
  CREATE_USER,
  SEND_FORGOT_PASSWORD_EMAIL,
  TENANT_CONFIG,
  UPDATE_USER,
  CHANGE_PASSWORD,
  RESET_PASSWORD,
  GET_PLANS,
  GET_SUBSCRIPTION,
  CREATE_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION,
  DELETE_SUBSCRIPTION,
  GET_ALL_USER,
  GET_USER,
  SEARCH_PART_ITEMS,
  READ_PART_FILTERS,
  READ_PART_ITEMS,
  READ_PART_ITEM,
  GET_DATA_ASSEMBLIES,
  SAVE_DATA_ASSEMBLY,
  UPDATE_ASSEMBLY_NO_REDIRECT,
  COPY_ASSEMBLY,
  GET_NOTIFICATIONS,
  DELETE_ASSEMBLY_DISCUSSION,
  POST_EXPORT_SVG,
  UPDATE_TEMPLATE_SETTINGS,
  UPDATE_TITLE_BLOCK_SCHEMA,
  AS_BUILT_ASSEMBLY,
} from './../../constants/index';
import { ApiFailure, ApiRequest, ApiSuccess } from './../actions/Api';

export type ActionState = {
  inProgress: boolean;
  result: string;
  statusText: string;
  code: string;
};

export const initialActionState: ActionState = {
  inProgress: false,
  result: '',
  statusText: '',
  code: '',
};

export const actionReducer = (name: string, successText = ''): Reducer<ActionState> => {
  return (
    state: ActionState = initialActionState,
    action: ApiRequest | ApiSuccess | ApiFailure,
  ) => {
    switch (action.type) {
      case `${name}_REQUEST`:
        return {
          ...initialActionState,
          inProgress: true,
        };
      case `${name}_SUCCESS`:
        return {
          ...initialActionState,
          result: SUCCESS,
          statusText: successText,
        };
      case `${name}_FAILURE`:
        return {
          ...initialActionState,
          result: FAILURE,
          statusText: (action as ApiFailure).statusText,
          code: (action as ApiFailure).code,
        };
      case `${name}_RESET`:
        return initialActionState;
      default:
        return state;
    }
  };
};

export type ActionsState = {
  createUser: ActionState;
  updateUser: ActionState;
  changePassword: ActionState;
  resetPassword: ActionState;
  login: ActionState;
  oauth: ActionState;
  whoami: ActionState;
  users: ActionState;
  user: ActionState;
  sendForgotPasswordEmail: ActionState;
  tenantConfig: ActionState;
  plans: ActionState;
  subscription: ActionState;
  createSubscription: ActionState;
  updateSubscription: ActionState;
  cancelSubscription: ActionState;
  searchPartItems: ActionState;
  partItems: ActionState;
  partFilters: ActionState;
  partItem: ActionState;
  dataAssemblies: ActionState;
  saveDataAssembly: ActionState;
  updateAssemblyNoRedirect: ActionState;
  postCopyAssembly: ActionState;
  postAsBuiltAssembly: ActionState;
  notifications: ActionState;
  deleteAssemblyDiscussion: ActionState;
  postExportSVG: ActionState;
  putUpdateTemplateSettings: ActionState;
  putUpdateTitleBlockSchema: ActionState;
};

export const initialActionsState = {
  createUser: initialActionState,
  updateUser: initialActionState,
  changePassword: initialActionState,
  resetPassword: initialActionState,
  login: initialActionState,
  oauth: initialActionState,
  whoami: initialActionState,
  users: initialActionState,
  user: initialActionState,
  sendForgotPasswordEmail: initialActionState,
  tenantConfig: initialActionState,
  plans: initialActionState,
  subscription: initialActionState,
  createSubscription: initialActionState,
  updateSubscription: initialActionState,
  cancelSubscription: initialActionState,
  searchPartItems: initialActionState,
  partItems: initialActionState,
  partFilters: initialActionState,
  partItem: initialActionState,
  dataAssemblies: initialActionState,
  saveDataAssembly: initialActionState,
  updateAssemblyNoRedirect: initialActionState,
  postCopyAssembly: initialActionState,
  postAsBuiltAssembly: initialActionState,
  notifications: initialActionState,
  deleteAssemblyDiscussion: initialActionState,
  postExportSVG: initialActionState,
  putUpdateTemplateSettings: initialActionState,
  putUpdateTitleBlockSchema: initialActionState,
};

export const actionsReducers = combineReducers<ActionsState>({
  createUser: actionReducer(CREATE_USER),
  updateUser: actionReducer(UPDATE_USER),
  changePassword: actionReducer(CHANGE_PASSWORD),
  resetPassword: actionReducer(RESET_PASSWORD),
  login: actionReducer(LOGIN_USER),
  oauth: actionReducer(OAUTH_USER),
  whoami: actionReducer(WHOAMI),
  users: actionReducer(GET_ALL_USER),
  user: actionReducer(GET_USER),
  sendForgotPasswordEmail: actionReducer(SEND_FORGOT_PASSWORD_EMAIL),
  tenantConfig: actionReducer(TENANT_CONFIG),
  plans: actionReducer(GET_PLANS),
  subscription: actionReducer(GET_SUBSCRIPTION),
  createSubscription: actionReducer(CREATE_SUBSCRIPTION),
  updateSubscription: actionReducer(UPDATE_SUBSCRIPTION),
  cancelSubscription: actionReducer(DELETE_SUBSCRIPTION),
  searchPartItems: actionReducer(SEARCH_PART_ITEMS),
  partItems: actionReducer(READ_PART_ITEMS),
  partFilters: actionReducer(READ_PART_FILTERS),
  partItem: actionReducer(READ_PART_ITEM),
  dataAssemblies: actionReducer(GET_DATA_ASSEMBLIES),
  saveDataAssembly: actionReducer(SAVE_DATA_ASSEMBLY),
  updateAssemblyNoRedirect: actionReducer(UPDATE_ASSEMBLY_NO_REDIRECT),
  postCopyAssembly: actionReducer(COPY_ASSEMBLY),
  postAsBuiltAssembly: actionReducer(AS_BUILT_ASSEMBLY),
  notifications: actionReducer(GET_NOTIFICATIONS),
  deleteAssemblyDiscussion: actionReducer(DELETE_ASSEMBLY_DISCUSSION),
  postExportSVG: actionReducer(POST_EXPORT_SVG),
  putUpdateTemplateSettings: actionReducer(UPDATE_TEMPLATE_SETTINGS),
  putUpdateTitleBlockSchema: actionReducer(UPDATE_TITLE_BLOCK_SCHEMA),
});
