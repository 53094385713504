import { ReactNode } from 'react';

import { ColorType, IconType } from '../../../../../types';

export enum MultiTableType {
  TABS = 'tabs',
  FLAT = 'flat',
}

export type TableSchemaIcon = {
  border_color: ColorType;
  name: string;
  type: IconType;
};

export type TableData = {
  rowSchema: any;
  data: ReactNode;
  title?: string;
  component?: string;
  icon?: TableSchemaIcon;
};

export type MultiTableDataType = {
  title: string;
  data: Array<Array<TableData>>;
  component?: string;
  multiTableType: MultiTableType;
  icon?: TableSchemaIcon;
};

export type SingleTableDataType = Array<TableData> | MultiTableDataType;
export type TableDataType = Array<SingleTableDataType>;
