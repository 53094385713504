import { mxCell, mxGraph } from '@anekonnect/mxgraph';

import Constant from './Constant';

/**
 * Returns port id according position
 * @param  {string} value
 * @param  {string} containerId
 * @param  {'left'|'right'} position
 * @param  {number} stateValue
 */
export const getPortId = (
  key: string,
  value: string,
  containerId: string,
  position: 'left' | 'right',
  stateValue: number,
) => {
  let currentPosition = '';

  switch (position) {
    case 'left':
      currentPosition = Constant.portLeftId;
      break;
    case 'right':
      currentPosition = Constant.portRightId;
      break;
    default:
      currentPosition = '';
      break;
  }

  return `${key}_${value}_${containerId}_${currentPosition}_${stateValue}`;
};

export const getKeyOfId = (cell: mxCell) => {
  const newCellIds = cell.id.split('_');

  return `${newCellIds[0]}_${newCellIds[1]}`;
};

export const findConnectorPortCell = (graph: mxGraph, cell: mxCell) => {
  let leftCell = null;
  let rightCell = null;

  const isConnectorPin = cell.id.includes('connector_pin');

  if (isConnectorPin) {
    const leftPortId = cell.id.replace('connector_pin', 'port_left');
    const rightPortId = cell.id.replace('connector_pin', 'port_right');

    if (leftPortId && rightPortId) {
      leftCell = graph.model.getCell(leftPortId);
      rightCell = graph.model.getCell(rightPortId);

      // Update cell when connector value updated
      cell.parent.children.map((child) => {
        const isLeftPort = child.id === leftPortId;
        const isRightPort = child.id === rightPortId;

        if (isLeftPort) {
          leftCell = child;
        } else if (isRightPort) {
          rightCell = child;
        }

        return child;
      });
    }
  }

  return {
    leftCell,
    rightCell,
  };
};
