/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, Form, Input, Modal } from 'antd';
import { useState } from 'react';

type AddUserModalProps = {
  showModal: boolean;
  onClose: () => void;
  handleAdd: (values: any) => void;
};

type FieldType = {
  name: string;
  email: string;
};

const AddUserModal = ({ showModal, onClose, handleAdd }: AddUserModalProps) => {
  const [form] = Form.useForm();

  const onFinish = (values: any) => {
    handleAdd(values);
    onClose();
  };

  return (
    <Modal
      title="Add User to the Invite List"
      open={showModal}
      centered
      onCancel={() => onClose()}
      onOk={async () => {
        const values = await form.validateFields();
        form.resetFields();
        onFinish(values);
      }}
    >
      <Form form={form} labelCol={{ span: 4 }} wrapperCol={{ span: 20 }} preserve={false}>
        <Form.Item<FieldType>
          label="Name"
          name="name"
          rules={[{ required: true, message: 'Please input the name!' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item<FieldType>
          label="Email"
          name="email"
          rules={[{ required: true, type: 'email', message: 'Please input the email!' }]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddUserModal;
