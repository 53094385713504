import { NavigationNode, Part } from '../api/API';
import { AppState } from '../store/reducers';
import { PartItem } from '../types';

export const getParts = (state: AppState) => {
  return state.data.tenantConfig.data && state.data.tenantConfig.data.parts
    ? state.data.tenantConfig.data.parts
    : null;
};

export const selectPartByPartName = (parts: Part[] | null | undefined, partName: string) => {
  if (!parts || !parts.length) return null;

  return parts.find((e) => e.name === partName) || null;
};

export const selectNavigation = (navigation: NavigationNode[], partName: string) => {
  try {
    return navigation.find((e) => e.name === partName) || null;
  } catch (error) {
    return null;
  }
};

type AccessType = 'DELETE' | 'EDIT' | 'BOOKMARK' | 'COPY' | 'SHARE' | 'UPLOAD';

export const hasItemAccessOf = (item: PartItem, accessType: AccessType) => {
  if (accessType === 'DELETE') return !!item.can_delete;

  if (accessType === 'EDIT') return !!item.can_edit;

  if (accessType === 'BOOKMARK') return !!item.can_bookmark;

  if (accessType === 'COPY') return !!item.can_copy;

  if (accessType === 'SHARE') return !!item.can_share;

  throw new Error(`Access Type ${accessType} doesn't match!`);
};
