import { mxGraph, mxGraphExportObject } from '@anekonnect/mxgraph';

export const setWireStyles = (_mxGraphObj: mxGraphExportObject, graph: mxGraph) => {
  const JOIN_NODE_SIZE = 7;

  let style = graph.getStylesheet().getDefaultEdgeStyle();
  delete style['endArrow'];
  style['edgeStyle'] = 'wireEdgeStyle';
  style['strokeWidth'] = 2;

  // Sets join node size
  style['startSize'] = JOIN_NODE_SIZE;
  style['endSize'] = JOIN_NODE_SIZE;

  // Vertex
  style = graph.getStylesheet().getDefaultVertexStyle();
};

export default setWireStyles;
