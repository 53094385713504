import { Button, Input, Modal } from 'antd';
import React from 'react';

import { CopyInput, SelectEmailInput } from '../Inputs';

type ShareProps = {
  isOpen: boolean;
  onCancel: () => void;
  onOK: () => void;
  isAuthenticated: boolean;
  emails: string[];
  onEmailsChange: (emails: string[]) => void;
  onMessageChange: (message: string) => void;
};

export const ShareModal = ({
  isOpen,
  onCancel,
  onOK,
  isAuthenticated,
  emails,
  onEmailsChange,
  onMessageChange,
}: ShareProps) => {
  return (
    <Modal
      footer={[
        <Button key="cancel" onClick={onCancel}>
          Cancel
        </Button>,
        <Button key="ok" type="primary" onClick={onOK}>
          {isAuthenticated ? 'Share' : 'Ok'}
        </Button>,
      ]}
      title={`Share ${isAuthenticated ? 'via email' : 'via link'}`}
      open={isOpen}
      onCancel={onCancel}
      onOk={onOK}
    >
      {isAuthenticated ? (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
          <SelectEmailInput emails={emails} onEmailsChange={onEmailsChange} />
          <Input.TextArea placeholder="Message" onChange={(e) => onMessageChange(e.target.value)} />
        </div>
      ) : (
        <CopyInput />
      )}
    </Modal>
  );
};
