import { mxCell, mxGraph, mxUndoManager } from '@anekonnect/mxgraph';
import Mousetrap from 'mousetrap';
import React, { useCallback, useEffect } from 'react';

import {
  deleteCells,
  fitWindow,
  getCompositeParents,
  getPagePadding,
  getResizableCells,
  isTable,
  isTableRow,
  resetScrollbars,
  turnShapes,
} from './Helper';

import { mx } from '~/constants/wizard';
import { useAppDispatch, useAppSelector } from '~/store/hooks';
import { wizardSetShowComponentControl } from '~/store/actions/wizard/Component';
import { getComponentsNameFromCells } from '~/utils/component';
import { Modal } from 'antd';

type KeyboardShortcutsProps = {
  graph: mxGraph;
  undoManager?: mxUndoManager;
};

const KeyboardShortcuts = ({ graph, undoManager }: KeyboardShortcutsProps) => {
  const dispatch = useAppDispatch();
  const components = useAppSelector((state) => state.assemblyWizard.components);
  const parts = useAppSelector((state) => state.data.tenantConfig.data?.parts);

  const handleCloseComponentControl = useCallback(() => {
    dispatch(wizardSetShowComponentControl(false));
  }, [dispatch]);

  const copyXml = useCallback(() => {
    const { mxUtils } = mx;

    let cells = null;

    // Enable native clipboard
    if (window === window.top && !mx.mxClient.IS_FF && navigator.clipboard != null) {
      if (!graph.isSelectionEmpty()) {
        cells = mxUtils.sortCells(
          graph.getExportableCells(graph.model.getTopmostCells(graph.getSelectionCells())),
        );
        const xml = mxUtils.getXml(graph.encodeCells(cells));
        navigator.clipboard.writeText(xml);
      }
    }

    return cells;
  }, [graph]);

  const nudge = useCallback(
    (keyCode: number, stepSize = 1, resize = false) => {
      let thread = null;

      if (!graph.isSelectionEmpty() && graph.isEnabled()) {
        const cells = getCompositeParents(graph, graph.getSelectionCells());
        const cell = cells.length > 0 ? cells[0] : null;

        if (cell != null) {
          if (resize) {
            // Resizes all selected vertices
            graph.getModel().beginUpdate();

            try {
              for (let i = 0; i < cells.length; i++) {
                if (graph.getModel().isVertex(cells[i]) && graph.isCellResizable(cells[i])) {
                  let geo = graph.getCellGeometry(cells[i]);

                  if (geo != null) {
                    geo = geo.clone();

                    if (keyCode === 37) {
                      geo.width = Math.max(0, geo.width - stepSize);
                    } else if (keyCode === 38) {
                      geo.height = Math.max(0, geo.height - stepSize);
                    } else if (keyCode === 39) {
                      geo.width += stepSize;
                    } else if (keyCode === 40) {
                      geo.height += stepSize;
                    }

                    graph.getModel().setGeometry(cells[i], geo);
                  }
                }
              }
            } finally {
              graph.getModel().endUpdate();
            }
          } else {
            // Moves vertices up/down in a stack layout
            const scale = graph.getView().scale;
            const handler = graph.graphHandler;

            if (handler != null) {
              if (handler.first == null) {
                handler.start(cell, 0, 0);
              }

              if (handler.first != null) {
                let dx = 0;
                let dy = 0;

                if (keyCode === 37) {
                  dx = -stepSize;
                } else if (keyCode === 38) {
                  dy = -stepSize;
                } else if (keyCode === 39) {
                  dx = stepSize;
                } else if (keyCode === 40) {
                  dy = stepSize;
                }

                handler.currentDx += dx * scale;
                handler.currentDy += dy * scale;
                handler.checkPreview();
                handler.updatePreview();
              }

              // Groups move steps in undoable change
              if (thread != null) {
                window.clearTimeout(thread);
              }

              thread = window.setTimeout(function () {
                if (handler.first != null) {
                  const dx = handler.roundLength(handler.currentDx / scale);
                  const dy = handler.roundLength(handler.currentDy / scale);
                  handler.moveCells(handler.cells, dx, dy);
                  handler.reset();
                }
              }, 400);
            }
          }
        }
      }
    },
    [graph],
  );

  const excludeLogos = useCallback(() => {
    const getLogoIds = () => {
      const logoCells: mxCell[] = [];
      graph.getSelectionCells().forEach((cell) => {
        if (cell.id.includes('template_logo_page')) {
          logoCells.push(cell);
        }
      });

      return logoCells;
    };

    graph.getSelectionModel().changeSelection([], getLogoIds());
  }, [graph]);

  const deleteConfirmation = useCallback(
    (deleteHandler: () => void) => {
      const cells = graph.getSelectionCells();
      const listPartsKey = parts?.map((part) => part.name) || [];
      const componentsName = getComponentsNameFromCells(cells, components, listPartsKey);

      if (componentsName.length === 0) {
        Modal.confirm({
          title: 'Are you sure you want to delete the selected component?',
          onOk() {
            deleteHandler();
          },
          okText: 'Yes',
        });
      } else {
        Modal.confirm({
          title: 'Are you sure you want to delete the selected component?',
          content: (
            <div>
              <p>
                The following components will be deleted:
                <br />
                {componentsName.map((name, key) => (
                  <>
                    <b>
                      {key + 1 + '.'} <span key={name}>{name}</span>
                    </b>
                    <br />
                  </>
                ))}
              </p>
              <p>Do you want to continue?</p>
            </div>
          ),
          onOk() {
            deleteHandler();
          },
          okText: 'Yes',
        });
      }
    },
    [graph, parts, components],
  );

  useEffect(() => {
    // Undo
    Mousetrap.bind(['ctrl+z', 'command+z'], () => {
      undoManager?.undo();
    });

    // Redo
    Mousetrap.bind(['ctrl+shift+z', 'command+shift+z'], () => {
      undoManager?.redo();
    });

    // Delete
    Mousetrap.bind(['backspace', 'del'], () => {
      // Cancels interactive operations
      graph.escape();

      const deleteHandler = () => {
        deleteCells(graph, graph.getDeletableCells(graph.getSelectionCells()));

        handleCloseComponentControl();
      };

      deleteConfirmation(deleteHandler);
    });

    // Delete All
    Mousetrap.bind(['ctrl+backspace', 'command+backspace', 'ctrl+del', 'command+del'], () => {
      // Cancels interactive operations
      graph.escape();

      const deleteHandler = () => {
        deleteCells(graph, graph.getDeletableCells(graph.getSelectionCells()), true);

        handleCloseComponentControl();
      };

      deleteConfirmation(deleteHandler);
    });

    // Reset View
    Mousetrap.bind(['home'], () => {
      graph.zoom(1, false);
      resetScrollbars(graph);
    });

    // Fit Window
    Mousetrap.bind(['ctrl+shift+h', 'command+shift+h'], () => {
      let bounds = graph.isSelectionEmpty()
        ? graph.getGraphBounds()
        : graph.getBoundingBox(graph.getSelectionCells());
      const t = graph.view.translate;
      const s = graph.view.scale;
      const { mxRectangle } = mx;

      bounds.x = bounds.x / s - t.x;
      bounds.y = bounds.y / s - t.y;
      bounds.width /= s;
      bounds.height /= s;

      if (graph.backgroundImage != null) {
        bounds = mxRectangle.fromRectangle(bounds);
        bounds.add(
          new mxRectangle(0, 0, graph.backgroundImage.width, graph.backgroundImage.height),
        );
      }

      if (bounds.width === 0 || bounds.height === 0) {
        graph.zoomTo(1);
        resetScrollbars(graph);
      } else {
        fitWindow(graph, bounds);
      }

      return false;
    });

    // Fit Page
    Mousetrap.bind(['ctrl+j', 'command+j'], () => {
      const fmt = graph.pageFormat;
      const ps = graph.pageScale;
      const cw = graph.container.clientWidth - 10;
      const ch = graph.container.clientHeight - 10;
      const scale = Math.floor(20 * Math.min(cw / fmt.width / ps, ch / fmt.height / ps)) / 20;
      graph.zoomTo(scale);

      if (mx.mxUtils.hasScrollbars(graph.container)) {
        const pad = getPagePadding();
        graph.container.scrollTop = pad.y * graph.view.scale - 1;
        graph.container.scrollLeft =
          Math.min(
            pad.x * graph.view.scale,
            (graph.container.scrollWidth - graph.container.clientWidth) / 2,
          ) - 1;
      }
    });

    // Fit Two Pages
    Mousetrap.bind(['ctrl+shift+j', 'command+shift+j'], () => {
      const fmt = graph.pageFormat;
      const ps = graph.pageScale;
      const cw = graph.container.clientWidth - 10;
      const ch = graph.container.clientHeight - 10;

      const scale = Math.floor(20 * Math.min(cw / (2 * fmt.width) / ps, ch / fmt.height / ps)) / 20;
      graph.zoomTo(scale);

      if (mx.mxUtils.hasScrollbars(graph.container)) {
        const pad = getPagePadding();
        graph.container.scrollTop = Math.min(
          pad.y,
          (graph.container.scrollHeight - graph.container.clientHeight) / 2,
        );
        graph.container.scrollLeft = Math.min(
          pad.x,
          (graph.container.scrollWidth - graph.container.clientWidth) / 2,
        );
      }

      return false;
    });

    // Object turn
    Mousetrap.bind(['ctrl+r', 'command+r'], () => {
      turnShapes(graph, getResizableCells(graph, graph.getSelectionCells()));

      return false;
    });

    // Select All
    Mousetrap.bind(['ctrl+a', 'command+a'], () => {
      graph.selectAll(null, true);

      excludeLogos();

      return false;
    });

    // Select None
    Mousetrap.bind(['ctrl+shift+a', 'command+shift+a'], () => {
      graph.clearSelection();

      return false;
    });

    // Select Verticles
    Mousetrap.bind(['ctrl+shift+i', 'command+shift+i'], () => {
      graph.selectVertices(null, true);

      excludeLogos();

      return false;
    });

    // Select Edges
    Mousetrap.bind(['ctrl+shift+e', 'command+shift+e'], () => {
      graph.selectEdges();

      return false;
    });

    // To Back
    Mousetrap.bind(['ctrl+shift+b', 'command+shift+b'], () => {
      graph.orderCells(true);

      return false;
    });

    // To Front
    Mousetrap.bind(['ctrl+shift+f', 'command+shift+f'], () => {
      graph.orderCells(false);

      return false;
    });

    // Duplicate
    // Mousetrap.bind(['command+d', 'ctrl+d'], () => {
    //   graph.setSelectionCells(duplicateCells(graph));
    //   graph.scrollCellToVisible(graph.getSelectionCell());

    //   return false;
    // });

    // Autosize
    Mousetrap.bind(['ctrl+shift+y', 'command+shift+y'], () => {
      const cells = graph.getSelectionCells();

      if (cells != null) {
        graph.getModel().beginUpdate();

        try {
          for (let i = 0; i < cells.length; i++) {
            const cell = cells[i];

            if (graph.getModel().isVertex(cell)) {
              if (graph.getModel().getChildCount(cell) > 0) {
                graph.updateGroupBounds([cell], 0, true);
              } else {
                graph.updateCellSize(cell);
              }
            }
          }
        } finally {
          graph.getModel().endUpdate();
        }
      }
    });

    /**
     * Disabled cut, copy, and paste with input from Bhavin. To be discussed with him before reactivation
     */
    // // Cut
    // Mousetrap.bind(['ctrl+x', 'command+x'], () => {
    //   let cells = null;
    //   const { mxClipboard } = mx;

    //   try {
    //     cells = copyXml();

    //     if (cells != null) {
    //       graph.removeCells(cells, false);
    //     }
    //   } catch (e) {
    //     // ignore
    //   }

    //   try {
    //     if (cells == null) {
    //       mxClipboard.cut(graph);
    //     }
    //   } catch (e) {
    //     // ignore
    //   }
    // });

    // // Copy
    // Mousetrap.bind(['ctrl+c', 'command+c'], () => {
    //   try {
    //     copyXml();
    //   } catch (e) {
    //     // ignore
    //   }

    //   try {
    //     mx.mxClipboard.copy(graph);
    //   } catch (e) {
    //     // ignore
    //   }
    // });

    // // Paste
    // Mousetrap.bind(['ctrl+v', 'command+v'], () => {
    //   try {
    //     const cells = mx.mxClipboard.paste(graph);

    //     if (cells != null) {
    //       graph.scrollCellToVisible(graph.getSelectionCell());
    //     }
    //   } catch (e) {
    //     // ignore
    //   }
    // });

    // Group
    Mousetrap.bind(['ctrl+g', 'command+g'], () => {
      if (graph.isEnabled()) {
        let cells = mx.mxUtils.sortCells(graph.getSelectionCells(), true);

        if (cells.length === 1 && !isTable(graph, cells[0]) && !isTableRow(graph, cells[0])) {
          graph.setCellStyles('container', '1');
        } else {
          cells = graph.getCellsForGroup(cells);

          if (cells.length > 1) {
            graph.setSelectionCell(graph.groupCells(null, 0, cells));
          }
        }
      }

      return false;
    });

    // Ungroup
    Mousetrap.bind(['ctrl+shift+g', 'command+shift+g'], () => {
      if (graph.isEnabled()) {
        const cells = graph.getSelectionCells();

        graph.model.beginUpdate();

        try {
          if (cells != null) {
            for (let i = 0; i < cells.length; i++) {
              if (graph.model.contains(cells[i])) {
                if (graph.model.getChildCount(cells[i]) === 0 && graph.model.isVertex(cells[i])) {
                  graph.setCellStyles('container', '0', [cells[i]]);
                }

                graph.ungroupCells([cells[i]]);
              }
            }
          }
        } finally {
          graph.model.endUpdate();
        }
      }

      return false;
    });

    // Lock Unlock
    Mousetrap.bind(['ctrl+l', 'command+l'], () => {
      if (!graph.isSelectionEmpty()) {
        graph.getModel().beginUpdate();

        const { mxUtils, mxConstants } = mx;

        try {
          const cells = graph.getSelectionCells();
          const style = graph.getCurrentCellStyle(graph.getSelectionCell());
          const value = mxUtils.getValue(style, mxConstants.STYLE_EDITABLE, 1) === 1 ? 0 : 1;
          graph.setCellStyles(mxConstants.STYLE_MOVABLE, value, cells);
          graph.setCellStyles(mxConstants.STYLE_RESIZABLE, value, cells);
          graph.setCellStyles(mxConstants.STYLE_ROTATABLE, value, cells);
          graph.setCellStyles(mxConstants.STYLE_DELETABLE, value, cells);
          graph.setCellStyles(mxConstants.STYLE_EDITABLE, value, cells);
          graph.setCellStyles('locked', value === 1 ? 0 : 1, cells);
          graph.setCellStyles('connectable', value, cells);
        } finally {
          graph.getModel().endUpdate();
        }
      }

      return false;
    });

    // Editing cell value
    Mousetrap.bind(['f2'], () => {
      if (graph.isEnabled()) {
        graph.startEditingAtCell();
      }
    });

    // Move left
    Mousetrap.bind(['left'], () => {
      nudge(37);

      return false;
    });

    // Move left with shift
    Mousetrap.bind(['shift+left'], () => {
      nudge(37, graph.gridSize);

      return false;
    });

    // Move up
    Mousetrap.bind(['up'], () => {
      nudge(38);

      return false;
    });

    // Move up with shift
    Mousetrap.bind(['shift+up'], () => {
      nudge(38, graph.gridSize);

      return false;
    });

    // Move right
    Mousetrap.bind(['right'], () => {
      nudge(39);

      return false;
    });

    // Move right with shift
    Mousetrap.bind(['shift+right'], () => {
      nudge(39, graph.gridSize);

      return false;
    });

    // Move down
    Mousetrap.bind(['down'], () => {
      nudge(40);

      return false;
    });

    // Move down with shift
    Mousetrap.bind(['shift+down'], () => {
      nudge(40, graph.gridSize);

      return false;
    });

    // Resize left
    Mousetrap.bind(['ctrl+left', 'command+left'], () => {
      nudge(37, 1, true);

      return false;
    });

    // Resize left with shift
    Mousetrap.bind(['ctrl+shift+left', 'command+shift+left'], () => {
      nudge(37, graph.gridSize, true);

      return false;
    });

    // Resize up
    Mousetrap.bind(['ctrl+up', 'command+up'], () => {
      nudge(38, 1, true);

      return false;
    });

    // Resize up with shift
    Mousetrap.bind(['ctrl+shift+up', 'command+shift+up'], () => {
      nudge(38, graph.gridSize, true);

      return false;
    });

    // Resize right
    Mousetrap.bind(['ctrl+right', 'command+right'], () => {
      nudge(39, 1, true);

      return false;
    });

    // Resize right with shift
    Mousetrap.bind(['ctrl+shift+right', 'command+shift+right'], () => {
      nudge(39, graph.gridSize, true);

      return false;
    });

    // Resize down
    Mousetrap.bind(['ctrl+down', 'command+down'], () => {
      nudge(40, 1, true);

      return false;
    });

    // Resize down with shift
    Mousetrap.bind(['ctrl+shift+down', 'command+shift+down'], () => {
      nudge(40, graph.gridSize, true);

      return false;
    });
  }, [
    graph,
    undoManager,
    copyXml,
    nudge,
    excludeLogos,
    handleCloseComponentControl,
    deleteConfirmation,
  ]);

  return <React.Fragment />;
};

export default KeyboardShortcuts;
