export const CSS_COLOR_NAMES = [
  { label: 'Alice Blue', value: 'aliceblue' },
  { label: 'Antique White', value: 'antiquewhite' },
  { label: 'Aqua', value: 'aqua' },
  { label: 'Aquamarine', value: 'aquamarine' },
  { label: 'Azure', value: 'azure' },
  { label: 'Beige', value: 'beige' },
  { label: 'Bisque', value: 'bisque' },
  { label: 'Black', value: 'black' },
  { label: 'Blanched Almond', value: 'blanchedalmond' },
  { label: 'Blue', value: 'blue' },
  { label: 'Blue Violet', value: 'blueviolet' },
  { label: 'Brown', value: 'brown' },
  { label: 'Burly Wood', value: 'burlywood' },
  { label: 'Cadet Blue', value: 'cadetblue' },
  { label: 'Chartreuse', value: 'chartreuse' },
  { label: 'Chocolate', value: 'chocolate' },
  { label: 'Coral', value: 'coral' },
  { label: 'Cornflower Blue', value: 'cornflowerblue' },
  { label: 'Cornsilk', value: 'cornsilk' },
  { label: 'Crimson', value: 'crimson' },
  { label: 'Cyan', value: 'cyan' },
  { label: 'Dark Blue', value: 'darkblue' },
  { label: 'Dark Cyan', value: 'darkcyan' },
  { label: 'Dark Golden Rod', value: 'darkgoldenrod' },
  { label: 'Dark Gray', value: 'darkgray' },
  { label: 'Dark Green', value: 'darkgreen' },
  { label: 'Dark Khaki', value: 'darkkhaki' },
  { label: 'Dark Magenta', value: 'darkmagenta' },
  { label: 'Dark Olive Green', value: 'darkolivegreen' },
  { label: 'Dark Orange', value: 'darkorange' },
  { label: 'Dark Orchid', value: 'darkorchid' },
  { label: 'Dark Red', value: 'darkred' },
  { label: 'Dark Salmon', value: 'darksalmon' },
  { label: 'Dark Sea Green', value: 'darkseagreen' },
  { label: 'Dark Slate Blue', value: 'darkslateblue' },
  { label: 'Dark Slate Gray', value: 'darkslategray' },
  { label: 'Dark Turquoise', value: 'darkturquoise' },
  { label: 'Dark Violet', value: 'darkviolet' },
  { label: 'Deep Pink', value: 'deeppink' },
  { label: 'Deep Sky Blue', value: 'deepskyblue' },
  { label: 'Dim Gray', value: 'dimgray' },
  { label: 'Dodger Blue', value: 'dodgerblue' },
  { label: 'Fire Brick', value: 'firebrick' },
  { label: 'Floral White', value: 'floralwhite' },
  { label: 'Forest Green', value: 'forestgreen' },
  { label: 'Fuchsia', value: 'fuchsia' },
  { label: 'Gainsboro', value: 'gainsboro' },
  { label: 'Ghost White', value: 'ghostwhite' },
  { label: 'Gold', value: 'gold' },
  { label: 'Golden Rod', value: 'goldenrod' },
  { label: 'Gray', value: 'gray' },
  { label: 'Green', value: 'green' },
  { label: 'Green Yellow', value: 'greenyellow' },
  { label: 'Honey Dew', value: 'honeydew' },
  { label: 'Hot Pink', value: 'hotpink' },
  { label: 'Indian Red', value: 'indianred' },
  { label: 'Indigo', value: 'indigo' },
  { label: 'Ivory', value: 'ivory' },
  { label: 'Khaki', value: 'khaki' },
  { label: 'Lavender', value: 'lavender' },
  { label: 'Lavender Blush', value: 'lavenderblush' },
  { label: 'Lawn Green', value: 'lawngreen' },
  { label: 'Lemon Chiffon', value: 'lemonchiffon' },
  { label: 'Light Blue', value: 'lightblue' },
  { label: 'Light Coral', value: 'lightcoral' },
  { label: 'Light Cyan', value: 'lightcyan' },
  { label: 'Light Golden Rod Yellow', value: 'lightgoldenrodyellow' },
  { label: 'Light Gray', value: 'lightgray' },
  { label: 'Light Green', value: 'lightgreen' },
  { label: 'Light Pink', value: 'lightpink' },
  { label: 'Light Salmon', value: 'lightsalmon' },
  { label: 'Light Sea Green', value: 'lightseagreen' },
  { label: 'Light Sky Blue', value: 'lightskyblue' },
  { label: 'Light Slate Gray', value: 'lightslategray' },
  { label: 'Light Steel Blue', value: 'lightsteelblue' },
  { label: 'Light Yellow', value: 'lightyellow' },
  { label: 'Lime', value: 'lime' },
  { label: 'Lime Green', value: 'limegreen' },
  { label: 'Linen', value: 'linen' },
  { label: 'Magenta', value: 'magenta' },
  { label: 'Maroon', value: 'maroon' },
  { label: 'Medium Aquamarine', value: 'mediumaquamarine' },
  { label: 'Medium Blue', value: 'mediumblue' },
  { label: 'Medium Orchid', value: 'mediumorchid' },
  { label: 'Medium Purple', value: 'mediumpurple' },
  { label: 'Medium Sea Green', value: 'mediumseagreen' },
  { label: 'Medium Slate Blue', value: 'mediumslateblue' },
  { label: 'Medium Spring Green', value: 'mediumspringgreen' },
  { label: 'Medium Turquoise', value: 'mediumturquoise' },
  { label: 'Medium Violet Red', value: 'mediumvioletred' },
  { label: 'Midnight Blue', value: 'midnightblue' },
  { label: 'Mint Cream', value: 'mintcream' },
  { label: 'Misty Rose', value: 'mistyrose' },
  { label: 'Moccasin', value: 'moccasin' },
  { label: 'Navajo White', value: 'navajowhite' },
  { label: 'Navy', value: 'navy' },
  { label: 'Old Lace', value: 'oldlace' },
  { label: 'Olive', value: 'olive' },
  { label: 'Olive Drab', value: 'olivedrab' },
  { label: 'Orange', value: 'orange' },
  { label: 'Orange Red', value: 'orangered' },
  { label: 'Orchid', value: 'orchid' },
  { label: 'Pale Golden Rod', value: 'palegoldenrod' },
  { label: 'Pale Green', value: 'palegreen' },
  { label: 'Pale Turquoise', value: 'paleturquoise' },
  { label: 'Pale Violet Red', value: 'palevioletred' },
  { label: 'Papaya Whip', value: 'papayawhip' },
  { label: 'Peach Puff', value: 'peachpuff' },
  { label: 'Peru', value: 'peru' },
  { label: 'Pink', value: 'pink' },
  { label: 'Plum', value: 'plum' },
  { label: 'Powder Blue', value: 'powderblue' },
  { label: 'Purple', value: 'purple' },
  { label: 'Red', value: 'red' },
  { label: 'Rosy Brown', value: 'rosybrown' },
  { label: 'Royal Blue', value: 'royalblue' },
  { label: 'Saddle Brown', value: 'saddlebrown' },
  { label: 'Salmon', value: 'salmon' },
  { label: 'Sandy Brown', value: 'sandybrown' },
  { label: 'Sea Green', value: 'seagreen' },
  { label: 'Sea Shell', value: 'seashell' },
  { label: 'Sienna', value: 'sienna' },
  { label: 'Silver', value: 'silver' },
  { label: 'Sky Blue', value: 'skyblue' },
  { label: 'Slate Blue', value: 'slateblue' },
  { label: 'Slate Gray', value: 'slategray' },
  { label: 'Snow', value: 'snow' },
  { label: 'Spring Green', value: 'springgreen' },
  { label: 'Steel Blue', value: 'steelblue' },
  { label: 'Tan', value: 'tan' },
  { label: 'Teal', value: 'teal' },
  { label: 'Thistle', value: 'thistle' },
  { label: 'Tomato', value: 'tomato' },
  { label: 'Turquoise', value: 'turquoise' },
  { label: 'Violet', value: 'violet' },
  { label: 'Wheat', value: 'wheat' },
  { label: 'White', value: 'white' },
  { label: 'White Smoke', value: 'whitesmoke' },
  { label: 'Yellow', value: 'yellow' },
  { label: 'Yellow Green', value: 'yellowgreen' },
];
