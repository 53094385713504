import { RollbackOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import React from 'react';

import { Filter, NavigationNode, Part } from '../../../api/API';
import { KeyValue } from '../../../types';

import styles from './PartsNavigation.module.scss';

type PartsNavigationProps = {
  part: Part;
  selectedNavigations: NavigationNode[] | null;
  partFiltersData: KeyValue<KeyValue<number>> | undefined;
  showNavigationBack: boolean;
  isLoading: boolean;
  handleFiltering: (filters: KeyValue, preserveOldFilters: boolean) => void;
  handleNavigationBack: () => void;
};

const PartsNavigation: React.FC<PartsNavigationProps> = (props) => {
  const {
    part,
    selectedNavigations,
    partFiltersData,
    showNavigationBack,
    isLoading,
    handleFiltering,
    handleNavigationBack,
  } = props;

  const [filtersFields, setFiltersFields] = React.useState<Filter[]>([] as Filter[]);

  const getSelectedName = () => {
    let filterName: string | string[] | null =
      selectedNavigations &&
      selectedNavigations.map((e) => {
        const key = e.name;

        if (partFiltersData && !partFiltersData[key]) {
          return part && part.display_name;
        }

        const filter = filtersFields.find((filter) => filter.key === key);

        return filter ? filter.display_name : 'Manufacturer';
      });

    if (selectedNavigations == null) {
      filterName = part && part.display_name;
    }

    return filterName ? filterName : 'Manufacturer';
  };

  React.useEffect(() => {
    const updateFiltersFields = [];

    if (part && part.filters && part.filters.select) {
      updateFiltersFields.push(...part.filters.select);
    }

    if (part && part.filters && part.filters.numeric) {
      updateFiltersFields.push(...part.filters.numeric);
    }
    setFiltersFields(updateFiltersFields);
  }, [part]);

  return (
    <div className={styles.partsNavigation}>
      <div className={styles.navigationMenu}>
        Select: <span>{getSelectedName()}</span>{' '}
        {showNavigationBack && (
          <Tooltip placement="bottom" title="Navigate Back">
            <Button
              icon={<RollbackOutlined />}
              size="small"
              type="link"
              onClick={() => handleNavigationBack()}
            />
          </Tooltip>
        )}
      </div>
      <div className={styles.navigationLink}>
        {!isLoading &&
          selectedNavigations &&
          selectedNavigations.map((e) => {
            const key = e.name;

            if (!partFiltersData || !partFiltersData[key]) {
              return '';
            }

            return Object.keys(partFiltersData[key]).map((val) => {
              return (
                <div key={key + val} onClick={() => handleFiltering({ [key]: val }, true)}>
                  {val}
                </div>
              );
            });
          })}
      </div>
    </div>
  );
};

export default PartsNavigation;
