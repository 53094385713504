export type Geometry = {
  x: number;
  y: number;
  width: number;
  height: number;
};

export type Font = {
  fontSize?: number;
  fontColor?: string;
};

export type TitleBlockGeometry = {
  [key: string]: Geometry & Font;
};

export type PaperSize = {
  width: number;
  height: number;
  logoGeometry: Geometry;
  titleBlockGeometry: TitleBlockGeometry;
};

export type PaperSizeData = {
  [key: string]: PaperSize;
};

export const paperSizeData: PaperSizeData = {
  a3: {
    width: 1587,
    height: 1123,
    logoGeometry: {
      x: 1229,
      y: 850,
      width: 280.8,
      height: 60,
    },
    titleBlockGeometry: {
      assemblyDescription: {
        x: 880,
        y: 936,
        width: 310,
        height: 40,
      },
      assemblyDrawingNumber: {
        x: 1220,
        y: 964,
        width: 190,
        height: 46,
      },
      assemblyRevision: {
        x: 1420,
        y: 964,
        width: 100,
        height: 50,
      },
      assemblyDesignState: {
        x: 1428,
        y: 693,
        width: 90,
        height: 57,
      },
      customerPN: {
        x: 762,
        y: 730,
        width: 179,
        height: 80,
      },
      project: {
        x: 840,
        y: 1024,
        width: 343,
        height: 30,
      },
      subject: {
        x: 840,
        y: 984,
        width: 343,
        height: 30,
      },
      designer: {
        x: 950,
        y: 693,
        width: 110,
        height: 57,
      },
      designDate: {
        x: 1322.4,
        y: 693,
        width: 95.6,
        height: 57,
      },
      checkedBy: {
        x: 1066,
        y: 694,
        width: 120,
        height: 30,
      },
      checkedDate: {
        x: 1066,
        y: 723,
        width: 120,
        height: 30,
      },
      approvedBy: {
        x: 1190,
        y: 694,
        width: 130,
        height: 30,
      },
      approvedDate: {
        x: 1190,
        y: 723,
        width: 130,
        height: 30,
      },
    },
  },
  a4: {
    width: 1123,
    height: 794,
    logoGeometry: {
      x: 863,
      y: 598,
      width: 208,
      height: 44.49,
    },
    titleBlockGeometry: {
      assemblyDescription: {
        x: 620,
        y: 660,
        width: 228,
        height: 30,
      },
      assemblyDrawingNumber: {
        x: 860,
        y: 680,
        width: 140,
        height: 35,
      },
      assemblyRevision: {
        x: 1015.5,
        y: 680,
        width: 50,
        height: 36,
      },
      assemblyDesignState: {
        x: 1010,
        y: 490.5,
        width: 61,
        height: 40,
      },
      customerPN: {
        x: 580,
        y: 522,
        width: 130,
        height: 63,
      },
      project: {
        x: 596,
        y: 724,
        width: 252,
        height: 22,
      },
      subject: {
        x: 596,
        y: 696,
        width: 252,
        height: 20,
      },
      designer: {
        x: 680,
        y: 487,
        width: 60,
        height: 47,
      },
      designDate: {
        x: 940,
        y: 493,
        width: 60,
        height: 35,
      },
      checkedBy: {
        x: 754,
        y: 487,
        width: 87,
        height: 23,
      },
      checkedDate: {
        x: 755,
        y: 510,
        width: 87,
        height: 23,
      },
      approvedBy: {
        x: 841,
        y: 487,
        width: 93,
        height: 23,
      },
      approvedDate: {
        x: 841,
        y: 510,
        width: 93,
        height: 23,
      },
    },
  },
  b3: {
    width: 1890,
    height: 1334,
    logoGeometry: {
      x: 1469,
      y: 1010,
      width: 320,
      height: 68.27,
    },
    titleBlockGeometry: {
      assemblyDescription: {
        x: 1050,
        y: 1111,
        width: 380,
        height: 50,
      },
      assemblyDrawingNumber: {
        x: 1450,
        y: 1141,
        width: 230,
        height: 60,
      },
      assemblyRevision: {
        x: 1700,
        y: 1141,
        width: 100,
        height: 60,
      },
      assemblyDesignState: {
        x: 1702,
        y: 827,
        width: 100,
        height: 60,
      },
      customerPN: {
        x: 910,
        y: 870,
        width: 210,
        height: 100,
      },
      project: {
        x: 1000,
        y: 1216,
        width: 420,
        height: 40,
      },
      subject: {
        x: 1000,
        y: 1169,
        width: 420,
        height: 40,
      },
      designer: {
        x: 1137,
        y: 822,
        width: 120,
        height: 70,
      },
      designDate: {
        x: 1580,
        y: 828.5,
        width: 100,
        height: 57,
      },
      checkedBy: {
        x: 1270,
        y: 823,
        width: 140,
        height: 30,
      },
      checkedDate: {
        x: 1270,
        y: 857,
        width: 140,
        height: 30,
      },
      approvedBy: {
        x: 1420,
        y: 823,
        width: 150,
        height: 30,
      },
      approvedDate: {
        x: 1420,
        y: 857,
        width: 150,
        height: 30,
      },
    },
  },
  b4: {
    width: 1334,
    height: 945,
    logoGeometry: {
      x: 1030,
      y: 712,
      width: 240,
      height: 51.27,
    },
    titleBlockGeometry: {
      assemblyDescription: {
        x: 740,
        y: 789,
        width: 260,
        height: 30,
      },
      assemblyDrawingNumber: {
        x: 1018,
        y: 810,
        width: 170,
        height: 40,
      },
      assemblyRevision: {
        x: 1191,
        y: 807.5,
        width: 89,
        height: 45,
      },
      assemblyDesignState: {
        x: 1198,
        y: 583.5,
        width: 80,
        height: 46.5,
      },
      customerPN: {
        x: 640,
        y: 620,
        width: 150,
        height: 60,
      },
      project: {
        x: 702,
        y: 859,
        width: 309,
        height: 30,
      },
      subject: {
        x: 710,
        y: 825.5,
        width: 300,
        height: 30,
      },
      designer: {
        x: 800,
        y: 583.5,
        width: 90,
        height: 47,
      },
      designDate: {
        x: 1114.5,
        y: 583.5,
        width: 76.5,
        height: 46.5,
      },
      checkedBy: {
        x: 901,
        y: 583.5,
        width: 90,
        height: 20,
      },
      checkedDate: {
        x: 901,
        y: 610.5,
        width: 90,
        height: 20,
      },
      approvedBy: {
        x: 1005,
        y: 583.5,
        width: 100,
        height: 20,
      },
      approvedDate: {
        x: 1005,
        y: 610,
        width: 100,
        height: 20,
      },
    },
  },
  letter: {
    width: 1054,
    height: 816,
    logoGeometry: {
      x: 816,
      y: 599,
      width: 185,
      height: 39.52,
    },
    titleBlockGeometry: {
      assemblyDescription: {
        x: 590,
        y: 655,
        width: 210,
        height: 30,
      },
      assemblyDrawingNumber: {
        x: 811,
        y: 673,
        width: 120,
        height: 33,
      },
      assemblyRevision: {
        x: 947,
        y: 675,
        width: 60,
        height: 33,
      },
      assemblyDesignState: {
        x: 953,
        y: 493,
        width: 50,
        height: 43,
      },
      customerPN: {
        x: 510,
        y: 521,
        width: 110,
        height: 58,
      },
      project: {
        x: 559,
        y: 715,
        width: 241,
        height: 20,
      },
      subject: {
        x: 559,
        y: 690,
        width: 241,
        height: 20,
      },
      designer: {
        x: 632,
        y: 500,
        width: 70,
        height: 30,
      },
      designDate: {
        x: 880,
        y: 493,
        width: 61,
        height: 43,
      },
      checkedBy: {
        x: 709,
        y: 496,
        width: 80,
        height: 20,
      },
      checkedDate: {
        x: 709,
        y: 516,
        width: 80,
        height: 20,
      },
      approvedBy: {
        x: 789,
        y: 494,
        width: 90,
        height: 22,
      },
      approvedDate: {
        x: 788,
        y: 517,
        width: 90,
        height: 18,
      },
    },
  },
  legal: {
    width: 1346,
    height: 816,
    logoGeometry: {
      x: 986.5,
      y: 615,
      width: 207.5,
      height: 44.33,
    },
    titleBlockGeometry: {
      assemblyDescription: {
        x: 733,
        y: 680,
        width: 237,
        height: 30,
      },
      assemblyDrawingNumber: {
        x: 980,
        y: 697,
        width: 140,
        height: 33,
      },
      assemblyRevision: {
        x: 1134,
        y: 705,
        width: 60,
        height: 30,
      },
      assemblyDesignState: {
        x: 1132,
        y: 505.75,
        width: 70,
        height: 38.5,
      },
      customerPN: {
        x: 650,
        y: 530,
        width: 130,
        height: 60,
      },
      project: {
        x: 710,
        y: 746,
        width: 250,
        height: 20,
      },
      subject: {
        x: 710,
        y: 715,
        width: 260,
        height: 20,
      },
      designer: {
        x: 788,
        y: 510,
        width: 80,
        height: 30,
      },
      designDate: {
        x: 1058,
        y: 510,
        width: 70,
        height: 30,
      },
      checkedBy: {
        x: 870,
        y: 501,
        width: 90,
        height: 20,
      },
      checkedDate: {
        x: 870,
        y: 525,
        width: 90,
        height: 20,
      },
      approvedBy: {
        x: 957,
        y: 501,
        width: 103,
        height: 20,
      },
      approvedDate: {
        x: 957,
        y: 525,
        width: 103,
        height: 20,
      },
    },
  },
  ledger: {
    width: 1633,
    height: 1054,
    logoGeometry: {
      x: 1222,
      y: 796,
      width: 270,
      height: 57.7,
    },
    titleBlockGeometry: {
      assemblyDescription: {
        x: 900,
        y: 880,
        width: 300,
        height: 30,
      },
      assemblyDrawingNumber: {
        x: 1212,
        y: 902,
        width: 186,
        height: 46,
      },
      assemblyRevision: {
        x: 1407,
        y: 901,
        width: 91,
        height: 50,
      },
      assemblyDesignState: {
        x: 1410,
        y: 648.5,
        width: 90,
        height: 57,
      },
      customerPN: {
        x: 787,
        y: 680,
        width: 170,
        height: 80,
      },
      project: {
        x: 858,
        y: 961,
        width: 342,
        height: 30,
      },
      subject: {
        x: 860,
        y: 922,
        width: 340,
        height: 30,
      },
      designer: {
        x: 970,
        y: 651.5,
        width: 90,
        height: 47,
      },
      designDate: {
        x: 1314,
        y: 650.5,
        width: 90,
        height: 57,
      },
      checkedBy: {
        x: 1070,
        y: 650.5,
        width: 120,
        height: 31.5,
      },
      checkedDate: {
        x: 1070,
        y: 684,
        width: 120,
        height: 21.5,
      },
      approvedBy: {
        x: 1184,
        y: 650,
        width: 130,
        height: 30,
      },
      approvedDate: {
        x: 1184,
        y: 679.75,
        width: 130,
        height: 30,
      },
    },
  },
};

export const getPaperSize = (size: string) => {
  return paperSizeData[size];
};
