import { message, Select } from 'antd';
import React from 'react';

type SelectEmailInputProps = {
  onEmailsChange: (emails: string[]) => void;
  emails: string[];
};

export const SelectEmailInput = ({ onEmailsChange, emails }: SelectEmailInputProps) => {
  const isValidEmail = (email: string) => {
    return email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
  };

  const handleChange = (values: string[]) => {
    if (values.length > 0 && !(emails.length > values.length)) {
      if (!isValidEmail(values[values.length - 1])) {
        message.error(`${values[values.length - 1]} is not a valid email address`);

        return;
      }
    }

    onEmailsChange(values);
  };

  return (
    <Select
      mode="tags"
      placeholder="Type email and press enter..."
      style={{ width: '100%' }}
      value={emails}
      allowClear
      onChange={handleChange}
    />
  );
};
