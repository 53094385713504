import { Breadcrumb as AntBreadcrumb } from 'antd';
import { Link } from 'react-router-dom';

import styles from './Breadcrumb.module.scss';

type BreadcrumbData = {
  url: string;
  name: string;
};

type BreadcrumbProps = {
  breadcrumbData: BreadcrumbData[];
};

const Breadcrumb = ({ breadcrumbData }: BreadcrumbProps) => {
  const getItems = () => {
    const items = breadcrumbData.map((item) => {
      return {
        key: item.url,
        title: <Link to={item.url}>{item.name}</Link>,
      };
    });

    return items;
  };

  return (
    <AntBreadcrumb className={styles.breadcrumb} separator=">" items={getItems()}></AntBreadcrumb>
  );
};

export default Breadcrumb;
