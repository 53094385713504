import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React from 'react';

import CheckoutForm from './CheckoutForm';
import Plans from './Plans';

const stripePromise = loadStripe('pk_test_utpKwi7IuUTHsdtojZFMdJBX00eKq4Jo7w');

type PaymentProps = {
  plans: any;
  planSwitcher: (plan: any, amount: number) => void;
  currentPlan: any;
  amount: number;
  summaryDetails: any;
  couponSelected: any;
  handleCouponSelected: (coupon: any) => void;
  subscriptionDataHandler: (data: any) => void;
  handleAgreement: () => void;
  isAgree: boolean;
  isUpdatePlan?: boolean;
  isUpdateCard?: boolean;
  isActionUpdateCard?: boolean;
  callback?: () => void;
};

const Payment: React.FC<PaymentProps> = (props) => {
  return (
    <Elements stripe={stripePromise}>
      {!props.isUpdateCard && (
        <Plans
          amount={props.amount}
          currentPlan={props.currentPlan}
          planSwitcher={props.planSwitcher}
          plans={props.plans}
        />
      )}

      <CheckoutForm
        amount={props.amount}
        callback={props.callback}
        couponSelected={props.couponSelected}
        currentPlan={props.currentPlan}
        handleAgreement={props.handleAgreement}
        handleCouponSelected={props.handleCouponSelected}
        isActionUpdateCard={props.isActionUpdateCard}
        isAgree={props.isAgree}
        isUpdateCard={props.isUpdateCard}
        isUpdatePlan={props.isUpdatePlan}
        subscriptionDataHandler={props.subscriptionDataHandler}
        summaryDetails={props.summaryDetails}
      />
    </Elements>
  );
};

export default Payment;
