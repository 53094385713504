import { CaretDownOutlined } from '@ant-design/icons';
import { ReactNode } from 'react';

import styles from './MenuButton.module.scss';

type MenuButtonProps = {
  icon: ReactNode;
  label: string;
};

const MenuButton = ({ icon, label }: MenuButtonProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>{icon}</div>
      <span>{label}</span>
      <CaretDownOutlined style={{ fontSize: '10px' }} />
    </div>
  );
};

export default MenuButton;
