import { combineReducers } from 'redux';

import { TenantConfig } from '../../../api/API';

import { GET_ALL_USER, GET_NOTIFICATIONS, GET_USER, WHOAMI } from './../../../constants/index';
import { authReducer, AuthState, initialAuthState } from './Auth';
import {
  initialResponseState,
  ResponseState,
  dataReducer,
  tenantConfigReducer,
} from './ActionResponse';

export type Tenant = {
  active: string;
  list: string[];
};

export type DataState = {
  auth: AuthState;
  whoami: ResponseState<any>;
  users: ResponseState<any>;
  user: ResponseState<any>;
  tenantConfig: ResponseState<TenantConfig>;
  notifications: ResponseState<any>;
};

export const initialDataState: DataState = {
  auth: initialAuthState,
  whoami: initialResponseState,
  users: initialResponseState,
  user: initialResponseState,
  tenantConfig: initialResponseState,
  notifications: initialResponseState,
};

export const dataReducers = combineReducers<DataState>({
  auth: authReducer,
  whoami: dataReducer(WHOAMI),
  users: dataReducer(GET_ALL_USER),
  user: dataReducer(GET_USER),
  tenantConfig: tenantConfigReducer,
  notifications: dataReducer(GET_NOTIFICATIONS),
});
