import { Button, Space, Tag } from 'antd';
import { JSONSchema7 } from 'json-schema';
import { useState } from 'react';
import { CSVLink } from 'react-csv';
import { connect } from 'react-redux';

import { Part } from '~/api/API';
import { FormSchema } from '~/components/FormSchema/FormSchema';
import { ERROR } from '~/constants';
import { snackBarOpen } from '~/store/actions/ui/SnackBar';
import { wizardSetBillOfMaterialsData } from '~/store/actions/wizard/Action';
import { AppState } from '~/store/reducers';
import { BillOfMaterialsData, ListRectangleData } from '~/store/reducers/wizard/State';
import { getPaymentStatus, paymentStatusIsPaid } from '~/utils/helper';

type PropsFromState = {
  assemblyName?: string;
  schema: JSONSchema7;
  billOfMaterialsData: BillOfMaterialsData[];
  components: {
    [type: string]: ListRectangleData;
  };
  parts: Part[] | undefined;
  whoamiData: {
    name?: string;
    payment_status?: string;
    type?: string;
  };
};

type PropsFromDispatch = {
  wizardSetBillOfMaterialsData: typeof wizardSetBillOfMaterialsData;
  snackBarOpen: typeof snackBarOpen;
};

type WizardBOMProps = PropsFromState & PropsFromDispatch;

const WizardBOM = ({
  assemblyName,
  schema,
  billOfMaterialsData,
  parts,
  components,
  snackBarOpen,
  wizardSetBillOfMaterialsData,
  whoamiData,
}: WizardBOMProps) => {
  const paymentStatus = getPaymentStatus(whoamiData);
  const isPaid = paymentStatusIsPaid(paymentStatus);

  const [data, setData] = useState<BillOfMaterialsData[]>(billOfMaterialsData);
  const csvHeaders = [
    {
      label: 'Item No',
      key: 'itemNo',
    },
    {
      label: 'Quantity',
      key: 'itemQuantity',
    },
    {
      label: 'Units',
      key: 'itemUnits',
    },
    {
      label: 'Description',
      key: 'itemDescription',
    },
    {
      label: 'Part Number',
      key: 'itemPartNumber',
    },
    {
      label: 'Manufacturer',
      key: 'itemManufacturer',
    },
  ];

  const calculateCableQty = (cables: ListRectangleData) => {
    let qty = 0;

    for (const [, value] of Object.entries(cables)) {
      qty += value.cableLength;
    }

    return qty;
  };

  const buildBOMData = (component: ListRectangleData) => {
    const billOfMaterials: BillOfMaterialsData[] = [];

    const findManufacturerKey = (keys: string[]) => {
      const manufacturerKey = keys.find((key) => key.includes('manufacturer'));

      return manufacturerKey;
    };

    for (const [, value] of Object.entries(component)) {
      const objectKey = Object.keys(value)[0];
      const valueKeys = Object.keys(value[objectKey]);
      let itemManufacturer = '';

      const manufacturerKey = findManufacturerKey(valueKeys);

      if (manufacturerKey) {
        const manufacturer = value[objectKey][manufacturerKey];

        if (typeof manufacturer === 'object') {
          const manufacturerKey = findManufacturerKey(Object.keys(manufacturer[0]));

          if (manufacturerKey) {
            const listManufacturer = manufacturer.map((item: any) => item[manufacturerKey]);
            itemManufacturer = listManufacturer.join(', ');
          }
        } else {
          itemManufacturer = value[objectKey][manufacturerKey];
        }
      }

      const partConfig = parts?.find((part) => part.name === value[objectKey].type);

      const billOfMaterial: BillOfMaterialsData = {
        itemNo: '',
        itemQuantity: value[objectKey].cableLength
          ? calculateCableQty(value)
          : Object.keys(value).length,
        itemUnits: partConfig?.units ?? '',
        itemDescription: value[objectKey].name,
        itemPartNumber: '',
        itemManufacturer,
      };

      billOfMaterials.push(billOfMaterial);
    }

    return billOfMaterials;
  };

  const handleLoadBOMComponents = () => {
    let billOfMaterialsData: BillOfMaterialsData[] = [];

    if (Object.keys(components).length === 0) {
      snackBarOpen('No components added, please add one or more components first.', ERROR);

      return;
    }

    for (const [, item] of Object.entries(components)) {
      const bomData = buildBOMData(item);
      billOfMaterialsData = [...billOfMaterialsData, ...bomData];
    }

    setData(billOfMaterialsData);

    wizardSetBillOfMaterialsData(billOfMaterialsData);
  };

  const handleResetBOMData = () => {
    const billOfMaterialsData: BillOfMaterialsData[] = [];
    setData(billOfMaterialsData);

    wizardSetBillOfMaterialsData(billOfMaterialsData);
  };

  const handleChange = (data: any) => {
    const formData: BillOfMaterialsData[] = [...data.formData.bill_of_materials];
    setData(formData);

    wizardSetBillOfMaterialsData(formData);
  };

  const renderForm = () => {
    const formData = { bill_of_materials: data || [] };

    return (
      <FormSchema
        cancelAction={() => console.log('cancel call')}
        confirmAction={() => console.log('confirm call')}
        formData={formData}
        isPaid={isPaid}
        schema={schema as JSONSchema7}
        showActions={false}
        onChange={handleChange}
      />
    );
  };

  return (
    <div className="wizard_bom">
      <Space wrap>
        <Button disabled={!isPaid} type="primary" onClick={() => handleLoadBOMComponents()}>
          Load/Reload BOM Data
        </Button>
        <Button disabled={data.length === 0 || !assemblyName || !isPaid} type="primary">
          <CSVLink data={data} filename={`BOM-${assemblyName}.csv`} headers={csvHeaders}>
            Export to CSV
          </CSVLink>
        </Button>
        <Button disabled={!isPaid} type="primary" danger onClick={() => handleResetBOMData()}>
          Reset BOM
        </Button>
        {!isPaid && (
          <Tag color="red">
            This is paid feature, please update your payment to use this feature.
          </Tag>
        )}
      </Space>
      {renderForm()}
    </div>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    assemblyName: state.assemblyWizard.name,
    schema: JSON.parse(state.data.tenantConfig.data.bill_of_materials_table_config_input),
    billOfMaterialsData: state.assemblyWizard.billOfMaterials.data ?? [],
    components: state.assemblyWizard.components,
    parts: state.data.tenantConfig.data?.parts,
    whoamiData: state.data.whoami.data,
  };
};

const mapDispatchToProps = {
  wizardSetBillOfMaterialsData,
  snackBarOpen,
};

export default connect(mapStateToProps, mapDispatchToProps)(WizardBOM);
