import React, { useState } from 'react';
import { Modal, Input, Form } from 'antd';

type SplitPinModalProps = {
  visible: boolean;
  onCancel: () => void;
  onOk: (pinNumber: number) => void;
};

const SplitPinModal: React.FC<SplitPinModalProps> = ({ visible, onCancel, onOk }) => {
  const [pinNumber, setPinNumber] = useState<number | null>(null);

  const handleOk = () => {
    if (pinNumber !== null) {
      onOk(pinNumber);
    }
  };

  return (
    <Modal title="Split at contact number" open={visible} onCancel={onCancel} onOk={handleOk}>
      <Form layout="vertical">
        <Form.Item label="Contact Number">
          <Input
            type="number"
            value={pinNumber ?? ''}
            onChange={(e) => setPinNumber(Number(e.target.value))}
            placeholder="Enter the pin number"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default SplitPinModal;
