import { Reducer } from 'redux';

import { BrowserSupportActionTypes, WebGL, WebP } from '../../actions/ui/BrowserSupport';

export type BrowserSupportState = {
  webgl: boolean;
  webp: boolean;
};

export const initialBrowserSupportState: BrowserSupportState = {
  webgl: false,
  webp: false,
};

type BrowserSupportAction = WebGL | WebP;

export const browserSupportReducer: Reducer<BrowserSupportState, BrowserSupportAction> = (
  state: BrowserSupportState = initialBrowserSupportState,
  action: WebGL | WebP,
) => {
  switch (action.type) {
    case BrowserSupportActionTypes.WEBGL:
      return {
        ...state,
        webgl: action.supported,
      };
    case BrowserSupportActionTypes.WEBP:
      return {
        ...state,
        webp: action.supported,
      };
    default:
      return state;
  }
};
