import { Reducer } from 'redux';

import { TenantConfig } from '../../../api/API';
import { HOME_TENANT, TENANT_CONFIG } from '../../../constants';
import { ApiRequest } from '../../actions/Api';

import { ApiFailure, ApiSuccess } from './../../actions/Api';

export type ResponseState<T> = {
  data: T;
  isTenant?: boolean;
};

export const initialResponseState: ResponseState<any> = {
  data: undefined,
};

export const dataReducer = <T>(name: string): Reducer<ResponseState<T>> => {
  return (
    state: ResponseState<T> = initialResponseState,
    action: ApiRequest | ApiSuccess | ApiFailure,
  ) => {
    switch (action.type) {
      case `${name}_REQUEST`:
        return initialResponseState;
      case `${name}_SUCCESS`:
        return {
          ...state,
          data: (action as ApiSuccess).data,
        };
      case `${name}_RESET`:
        return initialResponseState;
      default:
        return state;
    }
  };
};

export const tenantConfigReducer: Reducer<ResponseState<TenantConfig>> = (
  state: ResponseState<TenantConfig> = initialResponseState,
  action: ApiRequest | ApiSuccess | ApiFailure,
) => {
  switch (action.type) {
    case `${TENANT_CONFIG}_SUCCESS`:
      return {
        ...state,
        data: TenantConfig.createFrom((action as ApiSuccess).data),
        isTenant: (action as ApiSuccess).data.name !== HOME_TENANT,
      };
    default:
      return state;
  }
};
