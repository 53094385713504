import { RefObject, useEffect, useState } from 'react';

// whitelisted for add component auto close
const whitelistedIds = [
  'wizard-header-search-result',
  'parts-filter-add-modal-filter-selected',
  'parts-filter-tree-view-delete',
  'parts-filter-tree-view-filter-label',
  'parts-filter-item-condition',
  'parts-filter-item-condition-text',
  'parts-filter-tree-view-filter-tree',
  'parts-filter-add-modal-cancel',
  'parts-filter-add-modal-apply',
  'parts-filter-tree-view-delete-icon',
  'parts-filter-item-condition-input-number',
  'parts-filter-tree-view-row',
  'parts-filter-add-modal-no-filters',
  'parts-filter-add-modal-add-more-filters',
  'parts-filter-add-modal-applied-filters',
];
const whitelistedClasses = [
  'ant-select-item-option-content',
  'ant-select-item',
  'ant-select-item-option',
  'ant-select-item-option-active',
  'ant-select-item-option-selected',
  'ant-modal-title',
  'ant-modal-body',
  'ant-modal-content',
  'ant-modal-footer',
  'ant-select-selection-item',
  'ant-select-selector',
  'ant-modal-wrap',
  'ant-input-number-handler ',
  'ant-input-number-handler-down',
  'ant-input-number-handler-up',
  'ant-spin',
  'ant-spin-spinning',
  'ant-spin-container',
  'ant-divider',
  'ant-btn-icon',
  'ant-modal-close',
  'ant-modal-close-x',
  'PartsFilterTreeView_deleteButton',
  'rc-virtual-list-scrollbar-thumb',
];
const whitelistedNodeNames = ['BODY', 'svg'];
const whitelistedTexts = ['Cancel', 'Apply'];

// Improved version of https://usehooks.com/useOnClickOutside/
const useClickOutside = (ref: RefObject<HTMLDivElement>, handler: () => void) => {
  const [startedInside, setStartedInside] = useState(false);
  const [startedWhenMounted, setStartedWhenMounted] = useState(false);

  useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent) => {
      // Do nothing if `mousedown` or `touchstart` started inside ref element
      if (startedInside || !startedWhenMounted) return;

      // Do nothing if clicking ref's element or descendent elements
      const target = event.target as HTMLElement;

      if (
        !ref.current ||
        ref.current.contains(target) ||
        whitelistedIds.some((e) => target.id && String(target.id).includes(e)) ||
        whitelistedClasses.some((e) => target.className && String(target.className).includes(e)) ||
        whitelistedNodeNames.some((e) => target.nodeName && String(target.nodeName).includes(e)) ||
        whitelistedTexts.some((e) => target.innerHTML && String(target.innerHTML).includes(e))
      ) {
        return;
      }

      handler();
    };

    const validateEventStart = (event: MouseEvent | TouchEvent) => {
      setStartedWhenMounted(Boolean(ref.current));
      setStartedInside(Boolean(ref.current?.contains(event.target as Node)));
    };

    document.addEventListener('mousedown', validateEventStart);
    document.addEventListener('touchstart', validateEventStart);
    document.addEventListener('click', listener);

    return () => {
      document.removeEventListener('mousedown', validateEventStart);
      document.removeEventListener('touchstart', validateEventStart);
      document.removeEventListener('click', listener);
    };
  }, [ref, handler, startedInside, startedWhenMounted]);
};

export default useClickOutside;
