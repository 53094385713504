import { AnyAction } from 'redux';

export const makeActionCreator = (type: string, ...argNames: string[]) => {
  return (...args: unknown[]) => {
    const action: AnyAction = { type };
    argNames.forEach((arg, index) => {
      action[arg] = args[index];
    });

    return action;
  };
};
