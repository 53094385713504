import { UploadOutlined } from '@ant-design/icons';
import { Button, Divider, message, Modal, Space, Spin, Typography, Upload } from 'antd';
import { RcFile } from 'antd/es/upload';
import { useState } from 'react';
import { postCreateQuoteResponse } from '~/api/AuthorizedPosts';

type BIDModalProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  data: any;
  updateQuoteResponse: (data: any) => void;
};

const BIDModal = ({ open, setOpen, data, updateQuoteResponse }: BIDModalProps) => {
  const [quoteDocument, setQuoteDocument] = useState<RcFile>();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    if (!quoteDocument) {
      message.error('Please upload your quote document');
      return;
    }

    setLoading(true);

    const payload = {
      request_id: data.id,
      bidding: true,
    };

    const response = await postCreateQuoteResponse(payload, quoteDocument);

    if (response?.id) {
      updateQuoteResponse(response);
      setOpen(false);
      setQuoteDocument(undefined);
    }

    setLoading(false);
  };

  const handleCancel = () => {
    setOpen(false);
    setQuoteDocument(undefined);
  };

  return (
    <Modal
      title="BID this Quote Request"
      open={open}
      onOk={handleSubmit}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel} disabled={loading}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={handleSubmit} loading={loading}>
          Submit
        </Button>,
      ]}
    >
      <Spin spinning={loading}>
        <Divider style={{ margin: '0' }} />
        <Space direction="vertical" style={{ width: '100%', marginTop: '1rem' }}>
          <Space direction="vertical">
            <Typography.Text>Upload your quote document:</Typography.Text>
            <Upload
              maxCount={1}
              accept=".pdf"
              onRemove={() => {
                setQuoteDocument(undefined);
              }}
              beforeUpload={(file) => {
                setQuoteDocument(file);
                return false;
              }}
              fileList={quoteDocument ? [quoteDocument] : []}
            >
              <Button icon={<UploadOutlined />}>Upload Quote Document (PDF)</Button>
            </Upload>
          </Space>
        </Space>
      </Spin>
    </Modal>
  );
};

export default BIDModal;
