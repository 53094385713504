import { mxGraph, mxGraphExportObject } from '@anekonnect/mxgraph';

import Constant from './Constant';

export const setGraphStyles = (mxGraphObj: mxGraphExportObject, graph: mxGraph) => {
  const { mxConstants } = mxGraphObj;

  const STROKE_WIDTH = 1;
  const JOIN_NODE_SIZE = 7;

  let style = graph.getStylesheet().getDefaultEdgeStyle();
  style['fontColor'] = Constant.baseColor;
  style['fontSize'] = Constant.edgeFontSize;
  style[mxConstants.STYLE_MOVABLE] = '0';
  style['strokeColor'] = Constant.baseColor;
  // style['strokeWidth'] = Constant.defaultStrokeWidth;
  // Sets join node size
  style['startSize'] = JOIN_NODE_SIZE;
  style['endSize'] = JOIN_NODE_SIZE;

  // Vertex
  style = graph.getStylesheet().getDefaultVertexStyle();
  style['gradientDirection'] = 'south';
  style['strokeColor'] = Constant.baseColor;
  style['fillColor'] = 'none';
  style['fontColor'] = Constant.baseColor;
  style['fontStyle'] = '0';
  style['fontSize'] = Constant.vertexFontSize;
  style['rounded'] = '1';
  style['strokeWidth'] = STROKE_WIDTH;
};

export default setGraphStyles;
