import { configsReducer } from './Configs';

export type ConfigProp = {
  mirrorable: boolean;
  editable: boolean;
  deleteable: boolean;
  moveable: boolean;
  rotateable: boolean;
  resizeable: boolean;
  connectable: boolean;
  orderFront?: boolean;
  orderBack?: boolean;
  hideable?: boolean;
  splittable: boolean;
  mergeable: boolean;
};

export type ObjectShape = {
  type: string;
  configs: ConfigProp;
  default_value: string;
};

export type Config = {
  shape: string;
  objects: ObjectShape[];
};

export type PartConfig = {
  [part: string]: Config[];
};

export type ConfigsState = {
  engineeringDrawing: PartConfig;
  schematics: PartConfig;
};

export const initialConfigsState: ConfigsState = {
  engineeringDrawing: {},
  schematics: {},
};

export const configsReducers = configsReducer;
