import { Action, ActionCreator } from 'redux';

export enum BrowserSupportActionTypes {
  WEBGL = 'SET_SUPPORTS_WEBGL',
  WEBP = 'SET_SUPPORTS_WEBP',
}

// actions
export type WebGL = {
  supported: boolean;
} & Action<BrowserSupportActionTypes.WEBGL>;

export type WebP = {
  supported: boolean;
} & Action<BrowserSupportActionTypes.WEBP>;

// action creators
export const setSupportsWebgl: ActionCreator<WebGL> = (supported: boolean) => ({
  type: BrowserSupportActionTypes.WEBGL,
  supported,
});

export const setSupportsWebP: ActionCreator<WebP> = (supported: boolean) => ({
  type: BrowserSupportActionTypes.WEBP,
  supported,
});
