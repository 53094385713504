import { Divider, message, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import * as queryString from 'query-string';

import { Part } from '../../../../api/API';
import { KeyValue, PartFilterFields } from '../../../../types';

import PartsFilterTreeView from './PartsFilterTreeView';
import styles from './PartsFilterAddModal.module.scss';
import Loading from '~/components/Loading';
import {
  convertStringKeyToObject,
  getListOfKeys,
  hasMultipleKeys,
  isMultipleFilter,
} from '~/utils/filter';

type PartsFilterAddModalProps = {
  open: boolean;
  isLoading?: boolean;
  partFilters: PartFilterFields[];
  selectedFilters: PartFilterFields[];
  partFiltersData: KeyValue<KeyValue<number>>;
  onClose: () => void;
  selectedFiltersData: queryString.ParsedQuery;
  handleValueSelection: (
    partFilter: PartFilterFields,
    value: string | null,
    rootKey?: string,
  ) => void;
  handleFilterSelection: (filter: PartFilterFields) => void;
  part: Part;
};

const PartsFilterAddModal = ({
  open,
  isLoading,
  partFilters,
  selectedFilters,
  partFiltersData,
  selectedFiltersData,
  part,
  onClose,
  handleValueSelection,
  handleFilterSelection,
}: PartsFilterAddModalProps) => {
  // State to manage delayed loading
  const [delayedLoading, setDelayedLoading] = useState(false);

  useEffect(() => {
    if (isLoading) {
      // Immediately set delayedLoading to true
      setDelayedLoading(true);
    } else {
      // Set a timeout to delay setting delayedLoading to false
      const timer = setTimeout(() => {
        setDelayedLoading(false);
      }, 800); // 0.8 second delay

      // Cleanup the timer if the component unmounts or if isLoading changes
      return () => clearTimeout(timer);
    }
  }, [isLoading]);

  const filterSelectionHandler = (partFilter: PartFilterFields) => {
    if (selectedFilters.length > 0) {
      const newSelectedFiltersData = convertStringKeyToObject(
        JSON.parse(JSON.stringify(selectedFiltersData)),
      );
      const lastSelectedFilter = selectedFilters[selectedFilters.length - 1];

      if (isMultipleFilter(lastSelectedFilter)) {
        const listKeys = getListOfKeys(lastSelectedFilter);

        const exists = listKeys.map((key) => {
          return !newSelectedFiltersData['multiple'][lastSelectedFilter.key][key];
        });

        if (exists.includes(true)) {
          message.error('Please select a value for the last multiple filter.');
          return;
        }
      } else {
        if (!newSelectedFiltersData[lastSelectedFilter.key]) {
          message.error('Please select a value for the last filter.');
          return;
        }
      }
    }

    handleFilterSelection(partFilter);
  };

  const filterHandler = () => {
    onClose();
  };

  const cancelHandler = () => {
    onClose();
  };

  const avaliablePartFilters =
    !partFilters || !selectedFiltersData
      ? []
      : partFilters.filter((filter) => {
          return (
            !selectedFilters.find((selectedFilter) => selectedFilter.key === filter.key) &&
            typeof selectedFiltersData[filter.key] === 'undefined' &&
            (filter.key in partFiltersData || hasMultipleKeys(partFiltersData, filter))
          );
        });

  return (
    <Modal
      footer={[]}
      title="Add Filter"
      open={open}
      width={880}
      onOk={() => filterHandler()}
      onCancel={() => cancelHandler()}
    >
      <React.Fragment>
        <>
          <React.Fragment>
            <Divider style={{ marginTop: '0' }}>
              <h4 id="parts-filter-add-modal-applied-filters">Applied filters</h4>
            </Divider>

            {selectedFilters.length === 0 && (
              <div id="parts-filter-add-modal-no-filters" className={styles.noFilters}>
                You have not applied any filters.
              </div>
            )}

            <PartsFilterTreeView
              part={part}
              partFiltersData={partFiltersData}
              selectedFilters={selectedFilters}
              selectedFiltersData={selectedFiltersData}
              onFilterValueSelection={(partFilters, value, rootKey) => {
                handleValueSelection(partFilters, value, rootKey);
              }}
            />

            <Divider>
              <h4 id="parts-filter-add-modal-add-more-filters">Add more filters</h4>
            </Divider>
          </React.Fragment>

          <Loading isLoading={delayedLoading}>
            {!!avaliablePartFilters.length &&
              avaliablePartFilters.map((filter) => {
                return (
                  <div
                    id="parts-filter-add-modal-filter-selected"
                    key={filter.key}
                    className={styles.filterNode}
                    onClick={() => filterSelectionHandler(filter)}
                  >
                    {isMultipleFilter(filter) ? filter.name : filter.display_name}
                  </div>
                );
              })}
          </Loading>

          {!delayedLoading && !avaliablePartFilters.length && (
            <div id="parts-filter-add-modal-no-filters" className={styles.noFilters}>
              Sorry! No {partFilters.length ? `more` : ''} filters are available!
            </div>
          )}
        </>
      </React.Fragment>
    </Modal>
  );
};

export default PartsFilterAddModal;
