import { mxCell, mxGraph } from '@anekonnect/mxgraph';
import { useEffect } from 'react';
import { connect } from 'react-redux';

import { mx } from '~/constants/wizard';
import { wizardSetRevisionsPosition } from '~/store/actions/wizard/Action';
import { AppState } from '~/store/reducers';
import { Point, Revision } from '~/store/reducers/wizard/State';
import { BottomToTopTableTemplate, TopToBottomTableTemplate } from './templates';

type PropsFromState = {
  revisionsData: Revision[];
  revisionsPosition: Point;
  revisionTableConfig: string;
};

type PropsFromDispatch = {
  wizardSetRevisionsPosition: typeof wizardSetRevisionsPosition;
};

type RevisionsTableProps = {
  graph: mxGraph;
} & PropsFromState &
  PropsFromDispatch;

const RevisionsTable = ({
  graph,
  revisionsData,
  revisionsPosition,
  revisionTableConfig,
  wizardSetRevisionsPosition,
}: RevisionsTableProps) => {
  const tablePrefix = 'revisions-table';

  // Save last position of Revisions table
  useEffect(() => {
    graph.addListener(mx.mxEvent.CELLS_MOVED, (_sender, evt) => {
      const cells = evt.properties.cells;

      if (cells.length > 0) {
        cells.forEach((cell: mxCell) => {
          if (cell.id === tablePrefix) {
            wizardSetRevisionsPosition(cell.getGeometry());
          }
        });
      }
    });
  }, [graph, wizardSetRevisionsPosition]);

  if (!revisionTableConfig) {
    return;
  }

  const tableConfigJson = JSON.parse(revisionTableConfig);
  const template = tableConfigJson?.table?.template;
  const showTitle =
    tableConfigJson?.title?.showTitle !== undefined ? tableConfigJson?.title?.showTitle : true;

  switch (template) {
    case 'top-to-bottom':
      return (
        <TopToBottomTableTemplate
          graph={graph}
          tablePrefix={tablePrefix}
          tableConfigJson={tableConfigJson}
          data={revisionsData}
          position={revisionsPosition}
          showTitle={showTitle}
        />
      );
    case 'bottom-to-top':
      return (
        <BottomToTopTableTemplate
          graph={graph}
          tablePrefix={tablePrefix}
          tableConfigJson={tableConfigJson}
          data={revisionsData}
          position={revisionsPosition}
          showTitle={showTitle}
        />
      );
    default:
      return (
        <TopToBottomTableTemplate
          graph={graph}
          tablePrefix={tablePrefix}
          tableConfigJson={tableConfigJson}
          data={revisionsData}
          position={revisionsPosition}
          showTitle={showTitle}
        />
      );
  }
};

const mapStateToProps = (state: AppState) => {
  return {
    revisions: state.assemblyWizard.revisions,
    revisionsData: state.assemblyWizard.revisions.data ?? [],
    revisionsPosition: state.assemblyWizard.revisions.position,
    revisionTableConfig: state.data.tenantConfig.data?.revisions_table_config_display,
  };
};

const mapDispatchToProps = {
  wizardSetRevisionsPosition,
};

export default connect(mapStateToProps, mapDispatchToProps)(RevisionsTable);
