import { Button, Form, Input, Modal, Select, Tag, Tooltip } from 'antd';
import { union } from 'lodash';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { getTags } from '~/api/AuthorizedGets';
import { postNewTag } from '~/api/AuthorizedPosts';
import { wizardSetTags } from '~/store/actions/wizard/Action';
import { useAppSelector } from '~/store/hooks';
import { AppState } from '~/store/reducers';
import { getPaymentStatus, paymentStatusIsPaid } from '~/utils/helper';

type TagType = {
  id: number;
  color: string;
  name: string;
  parent_tag?: number;
};

type PropsFromState = {
  tags: number[];
  whoamiData: {
    payment_status?: string;
    type?: string;
  };
};

type PropsFromDispatch = {
  wizardSetTags: typeof wizardSetTags;
};

type WizardHeaderTagProps = PropsFromState & PropsFromDispatch;

const WizardHeaderTag = ({ wizardSetTags, tags, whoamiData }: WizardHeaderTagProps) => {
  const { status: assemblyStatus } = useAppSelector((state) => state.assemblyWizard);

  const [listTags, setListTags] = useState<TagType[]>([]);
  const [inputVisible, setInputVisible] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [openAddTagModal, setOpenAddTagModal] = useState<boolean>(false);
  const [tagValue, setTagValue] = useState<string>('');
  const [form] = Form.useForm();
  const paymentStatus = getPaymentStatus(whoamiData);

  useEffect(() => {
    form.setFieldsValue({
      tagName: tagValue,
      tagParent: 'no-parent',
    });
  }, [tagValue, form]);

  const getListTags = async () => {
    const res = await getTags();

    setListTags(res);
  };

  useEffect(() => {
    getListTags();
  }, []);

  const handleClose = (removeTag: number) => {
    const newTags = tags.filter((tag) => tag !== removeTag);
    wizardSetTags(newTags);
  };

  const showInput = () => {
    setInputVisible(true);
  };

  const getListOfParent = (tag: TagType) => {
    const list: number[] = [tag.id];

    const fnBuild = (tag: TagType) => {
      if (tag.parent_tag) {
        const parent = listTags.find((item) => item.id === tag.parent_tag);

        if (parent) {
          list.push(parent.id);
          fnBuild(parent);
        }
      }
    };

    fnBuild(tag);

    return list;
  };

  const handleSelectTag = (id: number, tag?: TagType) => {
    const currentTag = tag ? tag : listTags.find((tag) => tag.id === id);

    if (currentTag) {
      const listData = getListOfParent(currentTag);
      wizardSetTags(union(listData, tags));
    }
  };

  const renderAddTagModal = () => {
    const onCancel = () => {
      setOpenAddTagModal(false);
    };

    const onOk = () => {
      const { tagName, tagParent } = form.getFieldsValue();
      const params: { name: string; parent_tag?: string } = {
        name: tagName,
      };

      if (tagParent && tagParent !== 'no-parent') {
        params.parent_tag = tagParent;
      }

      postNewTag(params).then((tag: TagType) => {
        setOpenAddTagModal(false);
        handleSelectTag(tag.id, tag);
        getListTags();
      });
    };

    return (
      <Modal forceRender title="Add New Tag" open={openAddTagModal} onCancel={onCancel} onOk={onOk}>
        <Form form={form}>
          <Form.Item
            label="Tag Name"
            name="tagName"
            rules={[{ required: true, message: 'Please input tag name!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Parent Tag" name="tagParent" rules={[{ required: true }]}>
            <Select
              filterOption={(input, option) =>
                String(option?.label ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              optionFilterProp="children"
              autoFocus
              showSearch
            >
              <Select.Option key="no-parent" value="no-parent">
                No Parent
              </Select.Option>
              {listTags.map((tag) => {
                return (
                  <Select.Option key={tag.id} value={tag.id}>
                    {tag.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    );
  };

  return (
    <div style={{ display: 'flex' }}>
      {renderAddTagModal()}

      {tags.map((id) => {
        const tag = listTags.find((tag) => tag.id === id);

        if (!tag) {
          return false;
        }

        const isLongTag = tag.name.length > 20;
        const tagElem = (
          <Tag
            key={tag.id}
            className="edit-tag"
            closable={paymentStatusIsPaid(paymentStatus)}
            color={tag.color}
            style={{ display: 'flex', alignItems: 'center', fontSize: '14px' }}
            onClose={() => handleClose(tag.id)}
          >
            <span>{isLongTag ? `${tag.name.slice(0, 20)}...` : tag.name}</span>
          </Tag>
        );

        return isLongTag ? (
          <Tooltip key={tag.id} title={tag.name}>
            {tagElem}
          </Tooltip>
        ) : (
          tagElem
        );
      })}

      {inputVisible && (
        <Select
          filterOption={(input, option) =>
            String(option?.label ?? '')
              .toLowerCase()
              .includes(input.toLowerCase())
          }
          notFoundContent={
            <Button
              type="primary"
              onClick={() => {
                setOpenAddTagModal(true);
                setTagValue(searchValue);
              }}
            >
              <Tooltip title={`Add Tag ${searchValue}`}>
                + {searchValue.length > 12 ? `${searchValue.slice(0, 12)}...` : searchValue}
              </Tooltip>
            </Button>
          }
          optionFilterProp="children"
          placeholder="+ New Tag"
          style={{ width: '150px' }}
          autoFocus
          showSearch
          onChange={(value: number) => {
            handleSelectTag(value);
            setInputVisible(false);
          }}
          onSearch={(value) => {
            setSearchValue(value);
          }}
        >
          {listTags.map((tag) => {
            return (
              <Select.Option key={tag.id} value={tag.id}>
                {tag.name}
              </Select.Option>
            );
          })}
        </Select>
      )}

      {!inputVisible && paymentStatusIsPaid(paymentStatus) && (
        <Button
          style={{ width: '150px' }}
          type="dashed"
          onClick={showInput}
          disabled={assemblyStatus.toLowerCase() !== 'draft'}
        >
          Add New Tag
        </Button>
      )}
    </div>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    tags: state.assemblyWizard.tags,
    whoamiData: state.data.whoami.data,
  };
};

const mapDispatchToProps = {
  wizardSetTags,
};

export default connect(mapStateToProps, mapDispatchToProps)(WizardHeaderTag);
