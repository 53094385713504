import { DownloadOutlined, ExclamationCircleFilled, SearchOutlined } from '@ant-design/icons';
import { Button, Input, InputRef, Modal, Space, Spin, Table, Typography } from 'antd';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  getAllQuoteResponse,
  getAllRequestQuote,
  getDataAssembliesItem,
} from '~/api/AuthorizedGets';
import { postCreateQuoteResponse, postExportSVG } from '~/api/AuthorizedPosts';
import { mx } from '~/constants/wizard';
import { getPaperSize } from '~/utils/paperSize';
import { mxGraph as mxGraphType } from '@anekonnect/mxgraph';
import WizardEngineeringDrawing from '../assemblies/wizard/components/WizardEngineeringDrawing';
import {
  loadGlobalSetting,
  setGraphSetting,
} from '../assemblies/wizard/components/MxGraph/Graph/SetSettings';
import setGraphStyles from '../assemblies/wizard/components/MxGraph/Graph/SetStyles';
import setWireConnections from '../assemblies/wizard/components/MxGraph/Schematics/Wire/Connections';
import setWireSettings from '../assemblies/wizard/components/MxGraph/Schematics/Wire/Settings';
import setWireStyles from '../assemblies/wizard/components/MxGraph/Schematics/Wire/Styles';
import { EdgeRef } from '../assemblies/wizard/components/MxGraph/Schematics/Wire/Types';
import { CSVLink } from 'react-csv';
import { BIDModal, ViewQuoteModal } from './modals';
import { deleteQuoteResponse } from '~/api/AuthorizedDeletes';
import { ReviseQuoteModal } from './modals';
import { useAppSelector } from '~/store/hooks';
import { AppState } from '~/store/reducers';
import { ColumnType } from 'antd/es/table';
import { FilterConfirmProps, SortOrder } from 'antd/es/table/interface';
import Highlighter from 'react-highlight-words';

const { mxGraph } = mx;
const mxGraphValue = new mxGraph(null as unknown as HTMLElement);

const csvHeaders = [
  {
    label: 'Item No',
    key: 'itemNo',
  },
  {
    label: 'Quantity',
    key: 'itemQuantity',
  },
  {
    label: 'Units',
    key: 'itemUnits',
  },
  {
    label: 'Description',
    key: 'itemDescription',
  },
  {
    label: 'Part Number',
    key: 'itemPartNumber',
  },
  {
    label: 'Manufacturer',
    key: 'itemManufacturer',
  },
];

const RequestedQuote = () => {
  const userData = useAppSelector((state: AppState) => state.data.whoami.data);
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [quoteResponses, setQuoteResponses] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [graph, setGraph] = useState<mxGraphType>(mxGraphValue);
  const [isDownloadBOM, setIsDownloadBOM] = useState(false);
  const [bomData, setBomData] = useState([]);
  const [assemblyName, setAssemblyName] = useState('');
  const edgeRef = useRef<EdgeRef>({
    currentEdge: null,
    points: null,
    isEdgeSelected: false,
    groupingEdges: [],
  });
  const csvLink = useRef<any>();

  const [openViewQuoteModal, setOpenViewQuoteModal] = useState(false);
  const [openBIDModal, setOpenBIDModal] = useState(false);
  const [openReviseQuoteModal, setOpenReviseQuoteModal] = useState(false);
  const [activeRecord, setActiveRecord] = useState<any>({});
  const [activeQuoteResponse, setActiveQuoteResponse] = useState<any>({});
  const [listOfCountries, setListOfCountries] = useState<any[]>([]);

  // Search Table
  const searchInput = useRef<InputRef>(null);
  const [searchText, setSearchText] = useState('');

  const handleSearch = (selectedKeys: string[], confirm: (param?: FilterConfirmProps) => void) => {
    confirm();
    setSearchText(selectedKeys[0]);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = useCallback(
    (dataIndex: any, key?: string): ColumnType<any> => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
        <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
          <Input
            ref={searchInput}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys as string[], confirm)}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys as string[], confirm)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button
              onClick={() => clearFilters && handleReset(clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                close();
              }}
            >
              close
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered: boolean) => (
        <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
      ),
      onFilter: (value, record) => {
        const data = key ? record[dataIndex][key] : record[dataIndex];

        return data
          .toString()
          .toLowerCase()
          .includes((value as string).toLowerCase());
      },
      onFilterDropdownOpenChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.current?.select(), 100);
        }
      },
      render: (cell) => {
        let text = cell ? cell.toString() : '';

        if (key) {
          text = cell[key] ? cell[key].toString() : '';
        }

        return (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text}
          />
        );
      },
    }),
    [searchText],
  );

  const columns = useMemo(
    () => [
      {
        title: 'Quote ID',
        dataIndex: 'id',
        key: 'id',
        ...getColumnSearchProps('id'),
      },
      {
        title: 'Quantity',
        dataIndex: 'quantity',
        key: 'quantity',
      },
      {
        title: 'Need By Date',
        dataIndex: 'expected_date',
        key: 'expected_date',
        render: (text: string) => moment(text).format('LL'),
        sorter: (a: any, b: any) => moment(a.expected_date).unix() - moment(b.expected_date).unix(),
      },
      {
        title: 'Country of Usage',
        dataIndex: 'country_of_usage',
        key: 'country_of_usage',
        filters: listOfCountries,
        onFilter: (value: any, record: any) => record.country_of_usage.includes(value),
      },
      {
        title: 'Requested By',
        dataIndex: 'requester',
        key: 'requester',
        ...getColumnSearchProps('requester', 'username'),
      },
      {
        title: 'Date of RFQ',
        dataIndex: 'creation_date',
        key: 'creation_date',
        render: (text: string) => moment(text).format('LL'),
        defaultSortOrder: 'descend' as SortOrder,
        sorter: (a: any, b: any) => moment(a.creation_date).unix() - moment(b.creation_date).unix(),
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        filters: [
          {
            text: 'RFQ Received',
            value: 'RFQ Received',
          },
          {
            text: 'Quote Uploaded',
            value: 'Quote Uploaded',
          },
          {
            text: 'Quote Updated',
            value: 'Quote Updated',
          },
          {
            text: 'Quote Deleted',
            value: 'Quote Deleted',
          },
          {
            text: 'No Bid Selected',
            value: 'No Bid Selected',
          },
        ],
        onFilter: (value: any, record: any) => record.status[userData?.id].includes(value),
        render: (text: string) => text[userData?.id] || '-',
      },
      {
        title: 'Actions',
        dataIndex: 'actions',
        key: 'actions',
        render: (_: string, record: any) => {
          const initialButtons = (
            <Space>
              <Button
                type="primary"
                onClick={() => {
                  setActiveRecord(record);
                  setOpenBIDModal(true);
                }}
                block
              >
                BID
              </Button>
              <Button
                block
                onClick={() =>
                  Modal.confirm({
                    title: 'Are you sure do you want to no bid this request quote?',
                    icon: <ExclamationCircleFilled />,
                    okText: 'Yes',
                    cancelText: 'No',
                    onOk: async () => {
                      const payload = {
                        request_id: record.id,
                        bidding: false,
                      };

                      const response = await postCreateQuoteResponse(payload);

                      if (response?.id) {
                        const newQuoteResponses = [
                          ...quoteResponses,
                          {
                            ...payload,
                            id: response.id,
                          },
                        ];
                        const newQuoteStatus = dataSource.map((item: any) => {
                          if (item.id === record.id) {
                            return {
                              ...item,
                              status: {
                                ...item.status,
                                [userData?.id]: 'No Bid Selected',
                              },
                            };
                          }
                          return item;
                        });

                        setDataSource(newQuoteStatus);
                        setQuoteResponses(newQuoteResponses);
                      }
                    },
                  })
                }
              >
                No BID
              </Button>
            </Space>
          );

          const noBidding = <Typography.Text type="secondary">No Bidding</Typography.Text>;

          const hasBidding = (quote: any) => (
            <Space direction="vertical" style={{ width: '100%' }}>
              <Button
                block
                onClick={() => {
                  setActiveQuoteResponse(quote);
                  setOpenViewQuoteModal(true);
                }}
              >
                View Quote
              </Button>
              <Button
                type="primary"
                block
                onClick={() => {
                  setActiveQuoteResponse(quote);
                  setOpenReviseQuoteModal(true);
                }}
              >
                Revise Quote
              </Button>
              <Button
                type="primary"
                danger
                block
                onClick={() =>
                  Modal.confirm({
                    title: 'Are you sure do you want to delete this quote?',
                    icon: <ExclamationCircleFilled />,
                    okText: 'Yes',
                    okType: 'danger',
                    cancelText: 'No',
                    onOk: async () => {
                      const response = await deleteQuoteResponse(quote.id);

                      if (response.status === 'done') {
                        const newQuoteResponses = quoteResponses.filter(
                          (item) => item.id !== quote.id,
                        );
                        const newQuoteStatus = dataSource.map((item: any) => {
                          if (item.id === quote.request_id) {
                            return {
                              ...item,
                              status: {
                                ...item.status,
                                [userData?.id]: 'Quote Deleted',
                              },
                            };
                          }
                          return item;
                        });

                        setDataSource(newQuoteStatus);
                        setQuoteResponses(newQuoteResponses);
                      }
                    },
                  })
                }
              >
                Delete Quote
              </Button>
            </Space>
          );

          const isBidding = quoteResponses.find((item) => item.request_id === record.id);

          if (isBidding) {
            if (isBidding.bidding) {
              return hasBidding(isBidding);
            } else {
              return noBidding;
            }
          } else {
            return initialButtons;
          }
        },
      },
    ],
    [dataSource, getColumnSearchProps, listOfCountries, quoteResponses, userData?.id],
  );

  useEffect(() => {
    const getRequestedQuotes = async () => {
      const response = await getAllRequestQuote();
      if (response.data) {
        const newData = response.data.map((item: any, index: number) => {
          return {
            ...item,
            key: index,
          };
        });
        setDataSource(newData);

        const unique = Array.from(new Set(response.data.map((item: any) => item.country_of_usage)));
        const filtersFormat = unique.map((item) => {
          return {
            text: item,
            value: item,
          };
        });

        setListOfCountries(filtersFormat);
      }
    };

    const getQuoteResponses = async () => {
      const response = await getAllQuoteResponse();

      if (response.data) {
        setQuoteResponses(response.data);
      }
    };

    getRequestedQuotes();
    getQuoteResponses();
  }, []);

  const loadMxGraphDrawing = useCallback(
    (drawing: any) => {
      const details = JSON.parse(drawing);

      setGraphSetting(mx, graph, 'a4');
      loadGlobalSetting(mx, graph);
      setGraphStyles(mx, graph);
      setWireConnections(mx, graph, edgeRef);
      setWireSettings(mx, graph);
      setWireStyles(mx, graph);

      graph.model.clear();
      graph.getModel().beginUpdate();

      try {
        const doc = mx.mxUtils.parseXml(details['engineeringDrawing']);
        const codec = new mx.mxCodec(doc);
        const model = graph.getModel();
        codec.decode(doc.documentElement, model);
      } catch (err) {
        console.error('error while load drawing', err);
      } finally {
        graph.getModel().endUpdate();
      }
    },
    [graph],
  );

  const onExport = useCallback(
    (fileName: string | undefined, type: string) => {
      const paperSizeData = getPaperSize('a4');
      const backgrounds = graph.getBackgroundImages();

      graph.zoomActual(); // zoom to actual size
      const imgExport = new mx.mxImageExport();
      const bounds = graph.getGraphBounds();

      let width = Math.ceil(bounds.x + bounds.width);
      let height = Math.ceil(bounds.y + bounds.height);

      if (backgrounds.length > 0) {
        width = Math.max(
          ...[
            Math.max(
              ...backgrounds.map(function (o) {
                return o.x + o.width;
              }),
            ),
            width,
          ],
        );

        height = Math.max(
          ...[
            Math.max(
              ...backgrounds.map(function (o) {
                return o.y + o.height;
              }),
            ),
            height,
          ],
        );
      }

      const horizontalPageCount = Math.ceil(width / paperSizeData.width);
      const verticalPageCount = Math.ceil(height / paperSizeData.height);

      const getSVG = (x: number, y: number) => {
        // Prepares SVG document that holds the output
        const svgDoc = mx.mxUtils.createXmlDocument();
        const root =
          svgDoc.createElementNS != null
            ? svgDoc.createElementNS(mx.mxConstants.NS_SVG, 'svg')
            : svgDoc.createElement('svg');

        if (root.style != null) {
          root.style.backgroundColor = '#FFFFFF';
        } else {
          root.setAttribute('style', 'background-color: #ffffff;');
        }

        if (svgDoc.createElementNS == null) {
          root.setAttribute('xmlns', mx.mxConstants.NS_SVG);
          root.setAttribute('xmlns:xlink', mx.mxConstants.NS_XLINK);
        } else {
          // KNOWN: Ignored in IE9-11, adds namespace for each image element instead. No workaround.
          root.setAttributeNS(
            'http://www.w3.org/2000/xmlns/',
            'xmlns:xlink',
            mx.mxConstants.NS_XLINK,
          );
        }

        if (type === 'pdf') {
          root.setAttribute('width', (paperSizeData.width - 20).toString());
          root.setAttribute('height', (paperSizeData.height - 20).toString());
          root.setAttribute(
            'viewBox',
            `${x - 10} ${y - 10} ${paperSizeData.width + 20} ${paperSizeData.height + 20}`,
          );
        } else {
          root.setAttribute('width', width.toString());
          root.setAttribute('height', height.toString());
        }

        root.setAttribute('version', '1.1');

        if (backgrounds != null && backgrounds.length > 0) {
          const groupBgImage =
            svgDoc.createElementNS != null
              ? svgDoc.createElementNS(mx.mxConstants.NS_SVG, 'g')
              : svgDoc.createElement('g');
          groupBgImage.setAttribute('transform', 'translate(0.5,0.5)');

          backgrounds.forEach((background) => {
            const backgroundImage =
              svgDoc.createElementNS != null
                ? svgDoc.createElementNS(mx.mxConstants.NS_SVG, 'image')
                : svgDoc.createElement('image');
            backgroundImage.setAttribute('x', background.x.toString());
            backgroundImage.setAttribute('y', background.y.toString());
            backgroundImage.setAttribute('xlink:href', background.src);
            backgroundImage.setAttribute('width', background.width.toString());
            backgroundImage.setAttribute('height', background.height.toString());

            groupBgImage.appendChild(backgroundImage);
          });

          root.appendChild(groupBgImage);
        }

        // Adds group for anti-aliasing via transform
        const group =
          svgDoc.createElementNS != null
            ? svgDoc.createElementNS(mx.mxConstants.NS_SVG, 'g')
            : svgDoc.createElement('g');
        group.setAttribute('transform', 'translate(0.5,0.5)');
        root.appendChild(group);
        svgDoc.appendChild(root);

        // Renders graph. Offset will be multiplied with state's scale when painting state.
        const svgCanvas = new mx.mxSvgCanvas2D(group);

        // Displayed if a viewer does not support foreignObjects (which is needed to HTML output)
        svgCanvas.foAltText = '[Not supported by viewer]';
        imgExport.drawState(graph.getView().getState(graph.model.root), svgCanvas);

        // Fix wire colors issue
        const paths = root.querySelectorAll('path');
        paths.forEach((path) => {
          const isDashed = path.getAttribute('stroke-dasharray');
          const isWhite = path.getAttribute('stroke') === 'white';

          if (isDashed) {
            path.setAttribute('stroke-dasharray', '10 20');
          }

          if (isWhite && !isDashed) {
            const whiteStyle = `filter: drop-shadow(-1px 1px 0px rgb(128, 128, 128)) drop-shadow(1px -1px 0px rgb(128, 128, 128));`;
            path.setAttribute('style', whiteStyle);
          }
        });

        return root;
      };

      if (type === 'pdf') {
        const printPDF = async () => {
          const svgsData = [];

          try {
            for (let v = 0; v < verticalPageCount; v++) {
              for (let h = 0; h < horizontalPageCount; h++) {
                const svg = getSVG(h * paperSizeData.width, v * paperSizeData.height);
                const svgData = new XMLSerializer().serializeToString(svg);
                const xml = encodeURIComponent(svgData);
                svgsData.push(xml);
              }
            }
          } catch (error) {
            console.log(error);
          }

          postExportSVG({
            data: svgsData,
            width: paperSizeData.width,
            height: paperSizeData.height,
          })
            .then((data: any) => {
              const blob = new Blob([data], { type: 'application/pdf' });
              const href = window.URL.createObjectURL(blob);
              const a = document.createElement('a');
              a.href = href;
              a.download = fileName + '.pdf';
              document.body.appendChild(a);
              a.click();
              document.body.removeChild(a);
              setIsLoading(false);
            })
            .catch((err: any) => {
              console.error(err);
              setIsLoading(false);
            });
        };

        printPDF();
      }

      if (type === 'svg') {
        const svg = getSVG(0, 0);
        const svgData = new XMLSerializer().serializeToString(svg);
        const xml = encodeURIComponent(svgData);
        const a = document.createElement('a');
        a.href = 'data:image/svg+xml; charset=utf8, ' + xml;
        a.download = fileName + '.svg';
        a.innerHTML = 'download the svg file';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    },
    [graph],
  );

  const downloadPDF = useCallback(
    (assemblyId: string) => {
      setIsLoading(true);
      getDataAssembliesItem(assemblyId).then((data: any) => {
        loadMxGraphDrawing(data.details);
        onExport(data.name, 'pdf');
      });
    },
    [loadMxGraphDrawing, onExport],
  );

  const downloadBOM = useCallback((assemblyId: string) => {
    setIsLoading(true);
    setIsDownloadBOM(true);
    getDataAssembliesItem(assemblyId).then((data: any) => {
      const { data: bomData } = JSON.parse(data.bill_of_materials_table);

      setBomData(bomData);
      setAssemblyName(data.name);
    });
  }, []);

  useEffect(() => {
    if (csvLink.current && isDownloadBOM && assemblyName !== '' && isLoading) {
      csvLink.current.link.click();
      setIsLoading(false);
      setBomData([]);
      setAssemblyName('');
      setIsDownloadBOM(false);
    }
  }, [bomData, assemblyName, isLoading, isDownloadBOM]);

  const renderDetailsItem = useCallback(
    (record: any) => {
      return (
        <Space direction="vertical">
          <p>
            <b>Notes: </b> {record.notes || '-'}
          </p>
          <p>
            <b>Preferred Currency Payment: </b> {record.preferred_currency}
          </p>
          <p>
            <b>Components Provided: </b> {record.components_provided || '-'}
          </p>
          <p>
            <b>Destination Address:</b> {record.destination_address.line_1},{' '}
            {record.destination_address.line_2}, {record.destination_address.city},{' '}
            {record.destination_address.state}, {record.destination_address.zip_code},{' '}
            {record.destination_address.country}
          </p>
          <p>
            <b>Billing Address:</b> {record.billing_address.line_1}, {record.billing_address.line_2}
            , {record.billing_address.city}, {record.billing_address.state},{' '}
            {record.billing_address.zip_code}, {record.billing_address.country}
          </p>
          <p>
            <b>Download PDF of Drawing: </b>
            <Button
              type="primary"
              icon={<DownloadOutlined />}
              onClick={() => downloadPDF(record.assembly_id)}
            >
              Download
            </Button>
          </p>
          <p>
            <b>Download BOM data: </b>
            <Button
              type="primary"
              icon={<DownloadOutlined />}
              onClick={() => downloadBOM(record.assembly_id)}
            >
              Download
            </Button>
          </p>
        </Space>
      );
    },
    [downloadBOM, downloadPDF],
  );

  return (
    <Spin spinning={isLoading} tip="Loading...">
      <Space direction="vertical" style={{ width: '100%' }}>
        <Typography.Title level={2}>Requested Quotes</Typography.Title>
        <Table
          dataSource={dataSource}
          columns={columns}
          expandable={{
            expandedRowRender: (record: any) => renderDetailsItem(record),
          }}
        />
        <WizardEngineeringDrawing
          activeTabKey="engineeringDrawing"
          graph={graph}
          setGraph={setGraph}
          isHide={true}
        />
        <CSVLink
          data={bomData}
          filename={`BOM-${assemblyName}.csv`}
          headers={csvHeaders}
          ref={csvLink}
        />
        <BIDModal
          open={openBIDModal}
          setOpen={setOpenBIDModal}
          data={activeRecord}
          updateQuoteResponse={(data) => {
            const newQuoteResponses = [...quoteResponses, data];
            const newQuoteStatus = dataSource.map((item: any) => {
              if (item.id === data.request_id) {
                return {
                  ...item,
                  status: {
                    ...item.status,
                    [userData?.id]: 'Quote Uploaded',
                  },
                };
              }

              return item;
            });

            setDataSource(newQuoteStatus);
            setQuoteResponses(newQuoteResponses);
          }}
        />
        <ViewQuoteModal
          pdf={activeQuoteResponse?.quote_document?.url}
          open={openViewQuoteModal}
          setOpen={setOpenViewQuoteModal}
        />
        <ReviseQuoteModal
          open={openReviseQuoteModal}
          setOpen={setOpenReviseQuoteModal}
          data={activeQuoteResponse}
          updateQuoteResponse={(data) => {
            const newQuoteResponses = quoteResponses.map((item) => {
              if (item.id === data.id) {
                return data;
              }

              return item;
            });
            const newQuoteStatus = dataSource.map((item: any) => {
              if (item.id === data.request_id) {
                return {
                  ...item,
                  status: {
                    ...item.status,
                    [userData?.id]: 'Quote Updated',
                  },
                };
              }

              return item;
            });

            setDataSource(newQuoteStatus);
            setQuoteResponses(newQuoteResponses);
          }}
        />
      </Space>
    </Spin>
  );
};

export default RequestedQuote;
