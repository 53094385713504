import { mxGraphExportObject, mxGraph } from '@anekonnect/mxgraph';

import { Ref } from '../../Types';

import { EdgeRef } from './Types';

const setWireConnections = (mxObj: mxGraphExportObject, graph: mxGraph, edgeRef: Ref<EdgeRef>) => {
  const {
    mxConnectionConstraint,
    mxPoint,
    mxConstants,
    mxConstraintHandler,
    mxImage,
    mxEdgeHandler,
    mxUtils,
  } = mxObj;
  const graphModel = graph.getModel();

  // Alternative solution for implementing connection points without child cells.
  // This can be extended as shown in portrefs.html example to allow for per-port
  // incoming/outgoing direction.
  graph.getAllConnectionConstraints = (terminal) => {
    const isRectangle = terminal.cell.id.includes('rectangle');
    const isDrawingTools = terminal.cell.id.includes('drawing_tools');
    const isTable = terminal.cell.id.includes('table');

    if (isTable) {
      return [];
    }

    const selectedCell = graph.getSelectionCell();
    const isEdge = graph.model.isEdge(selectedCell);

    if (isRectangle && isEdge) {
      mxConstraintHandler.prototype.pointImage = new mxImage('/img/point.gif', 5, 5);

      const { width, height } = terminal.cell.geometry;

      const pixelWidth = 1 / width;
      const pixelHeight = 1 / height;
      const connectionPoints = [];

      for (let i = 0; i <= width; i++) {
        if (i % 7 === 0) {
          connectionPoints.push(new mxConnectionConstraint(new mxPoint(pixelWidth * i, 0), true));
          connectionPoints.push(new mxConnectionConstraint(new mxPoint(pixelWidth * i, 1), true));
        }
      }

      for (let i = 0; i <= height; i++) {
        if (i % 7 === 0) {
          connectionPoints.push(new mxConnectionConstraint(new mxPoint(0, pixelHeight * i), true));
          connectionPoints.push(new mxConnectionConstraint(new mxPoint(1, pixelHeight * i), true));
        }
      }

      return connectionPoints;
    }

    if (isDrawingTools) {
      mxConstraintHandler.prototype.pointImage = new mxImage('/img/dot.gif', 7, 7);

      const { width, height } = terminal.cell.geometry;

      const pixelWidth = 1 / width;
      const pixelHeight = 1 / height;
      const connectionPoints = [];

      for (let i = 0; i <= width; i++) {
        if (i % 7 === 0) {
          connectionPoints.push(new mxConnectionConstraint(new mxPoint(pixelWidth * i, 0), true));
          connectionPoints.push(new mxConnectionConstraint(new mxPoint(pixelWidth * i, 1), true));
        }
      }

      for (let i = 0; i <= height; i++) {
        if (i % 7 === 0) {
          connectionPoints.push(new mxConnectionConstraint(new mxPoint(0, pixelHeight * i), true));
          connectionPoints.push(new mxConnectionConstraint(new mxPoint(1, pixelHeight * i), true));
        }
      }

      return connectionPoints;
    }

    const geo = terminal ? graph.getCellGeometry(terminal.cell) : null;
    const isVertex = graphModel.isVertex(terminal.cell);
    const isZeroChildCount = graphModel.getChildCount() === 0;
    const isRelativeCoordinates = geo?.relative || false;

    // Hover point
    terminal.setCursor(mxConstants.CURSOR_TERMINAL_HANDLE);

    // Set points & term when edges hovered
    // Get edge position when clicked { terminal.absolutePoints }
    edgeRef.current = {
      ...edgeRef.current,
      points: terminal.absolutePoints,
    };

    if ((geo ? !isRelativeCoordinates : false) && isVertex && isZeroChildCount) {
      return [
        new mxConnectionConstraint(new mxPoint(0, 0.5), false),
        new mxConnectionConstraint(new mxPoint(1, 0.5), false),
      ];
    }

    return [];
  };

  // Makes sure non-relative cells can only be connected via constraints
  graph.connectionHandler.isConnectableCell = (cell) => {
    if (graphModel.isEdge(cell)) {
      const isDimensionLine = cell.id.includes('dimension_line');
      const isBezierCurve = cell.id.includes('bezier_curve');

      if (isDimensionLine || isBezierCurve) {
        return false;
      }

      return true;
    }

    const geo = cell ? graph.getCellGeometry(cell) : null;
    const isRelativeCoordinates = geo?.relative;

    // isRelativeCoordinates
    if (isRelativeCoordinates) {
      return geo ? isRelativeCoordinates : false;
    }

    return false;
  };

  mxEdgeHandler.prototype.isConnectableCell = (cell) => {
    return graph.connectionHandler.isConnectableCell(cell);
  };

  // Avoids any connections for gestures within tolerance except when in wire-mode
  // or when over a port
  const connectionHandlerMouseUp = graph.connectionHandler.mouseUp;
  graph.connectionHandler.mouseUp = function (sender, me) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    type possible = any;

    // TODO: Type checking is doesn't match @typed-mxgraph, make your own types
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self: possible = this;

    if (self.first != null && self.previous != null) {
      const point = mxUtils.convertPoint(self.graph.container, me.getX(), me.getY());
      const dx = Math.abs(point.x - self.first.x);
      const dy = Math.abs(point.y - self.first.y);

      if (dx < self.graph.tolerance && dy < self.graph.tolerance) {
        // Selects edges in non-wire mode for single clicks, but starts
        // connecting for non-edges regardless of wire-mode
        if (self.graph.getModel().isEdge(self.previous.cell)) {
          self.reset();
        }

        return;
      }
    }

    connectionHandlerMouseUp.apply(self, [sender, me]);
  };
};

export default setWireConnections;
