import { mxCell } from '@anekonnect/mxgraph';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Form } from 'antd';

import { GraphProps } from '../../Graph/Types';

import ActionButtons from './ActionButtons';
import setWireConnections from './Connections';
import setWireSettings from './Settings';
import Events from './Events';
import EditEdgeModal from './Modal';
import Constants from './Constants';
import { findEdgeCategories, generateDashedEdgeId } from './Helper';
import { EdgeRef } from './Types';
import setWireStyles from './Styles';

import { mx } from '~/constants/wizard';
import { useAppDispatch } from '~/store/hooks';
import { wizardSetShowWireControl } from '~/store/actions/wizard/Component';
import { forEach } from 'lodash';

export type WireProps = Pick<GraphProps, 'graph'>;

const Wire = ({ graph }: WireProps) => {
  const dispatch = useAppDispatch();

  const [edgeModalVisible, setEdgeModalVisible] = useState(false);
  const [currentEdge, setCurrentEdge] = useState<mxCell | null>(null);

  const connectorPinRef = useRef<mxCell>(null);
  const selectedEdgesRef = useRef<mxCell[]>([]);
  const edgeRef = useRef<EdgeRef>({
    currentEdge: null,
    points: null,
    isEdgeSelected: false,
    groupingEdges: [],
  });
  const edgeActionDOMRef = useRef<HTMLDivElement>(null);
  const [editEdgeForm] = Form.useForm();

  const handleCloseWireControl = useCallback(() => {
    dispatch(wizardSetShowWireControl(false));
  }, [dispatch]);

  const handleEditEdge = useCallback(() => {
    const { baseColor, tracerColor } = editEdgeForm.getFieldsValue();
    const currentSelection = graph.getSelectionCells();

    const setEdgeBaseColor = (edge: mxCell) => {
      if (baseColor && edge) {
        const state = graph.view.getState(edge);
        const edges = graph.model.getEdgesBetween(state.cell.source, state.cell.target);

        const { defaultEdge, dashedEdge } = findEdgeCategories(edges);

        if (!dashedEdge) {
          edge.setStyle(`strokeColor=${baseColor}`);

          graph.refresh(edge);
        } else {
          if (defaultEdge) {
            defaultEdge.setStyle(`strokeColor=${baseColor}`);

            graph.refresh(defaultEdge);
          }
        }
      }
    };

    const setEdgeTracerColor = (currentEdge: mxCell, groupingEdges: mxCell[]) => {
      if (currentEdge && groupingEdges.length) {
        const { defaultEdge, dashedEdge: existingDashedEdge } = findEdgeCategories(groupingEdges);

        if (defaultEdge) {
          const dashedId = generateDashedEdgeId(currentEdge, defaultEdge.id);

          if (!existingDashedEdge) {
            const clone = currentEdge.clone();
            const dashedEdge = graph.addEdge(
              clone,
              clone.getParent(),
              currentEdge.source,
              currentEdge.target,
            );
            dashedEdge.id = `${dashedId}`;
            dashedEdge.style = `strokeColor=${tracerColor};dashed=1;strokeWidth=${Constants.dashedEdgeStrokeWidth};startSize=${Constants.startSize};endSize=${Constants.endSize};`;
            dashedEdge.geometry = currentEdge.geometry;

            graph.refresh(dashedEdge);
            graph.orderCells(false, [currentEdge, dashedEdge]);
          } else {
            existingDashedEdge.setStyle(
              `strokeColor=${tracerColor};dashed=1;strokeWidth=${Constants.dashedEdgeStrokeWidth};startSize=${Constants.startSize};endSize=${Constants.endSize};`,
            );
            graph.refresh(existingDashedEdge);
          }
        }
      }
    };

    forEach(currentSelection, (cell) => {
      const componentList = ['dimension_line', 'bezier_curve', 'drawing_tools'];

      if (cell.isEdge() && !componentList.some((list) => cell.id.includes(list))) {
        setEdgeBaseColor(cell);

        const edges = graph.getModel().getEdgesBetween(cell.source, cell.target);

        if (edges.length) {
          setEdgeTracerColor(cell, edges);
        }
      }
    });

    setEdgeModalVisible(false);
    handleCloseWireControl();
    graph.clearSelection();
  }, [editEdgeForm, handleCloseWireControl, graph]);

  const deleteEdges = useCallback(() => {
    if (graph) {
      const selectionCells = graph.getSelectionCells();

      if (selectionCells && selectionCells.length) {
        return selectionCells.map((selectedCell) => {
          if (selectedCell.isEdge()) {
            const removeList = [selectedCell];

            const id = selectedCell.id.split('_').pop();

            if (id && graph.model.getCell(id)) {
              removeList.push(graph.model.getCell(id));
            }

            graph.removeCells(removeList);
            handleCloseWireControl();
          }

          return selectedCell;
        });
      }
    }
  }, [graph, handleCloseWireControl]);

  const showEdgeModal = useCallback(() => {
    setEdgeModalVisible(true);
  }, []);

  const setSettings = useCallback(() => {
    const { mxClient, mxUtils } = mx;

    if (!mxClient.isBrowserSupported) {
      mxUtils.error('Browser is not supported!', 200, false);
    } else {
      setWireConnections(mx, graph, edgeRef);
      setWireSettings(mx, graph);
      setWireStyles(mx, graph);
    }
  }, [graph]);

  useEffect(setSettings, [setSettings]);

  const eventsProps = {
    graph,
    connectorPinRef,
    forms: {
      editEdgeForm,
    },
    edgeRefs: {
      edgeRef,
      edgeActionDOMRef,
      selectedEdgesRef,
    },
    setCurrentEdge,
  };

  const actionButtonsProps = {
    graph,
    actionRef: edgeActionDOMRef,
    onDelete: deleteEdges,
    onEdit: showEdgeModal,
    currentEdge,
  };

  const edgeModalProps = {
    title: 'Wire',
    defaultValue: {
      baseColor: 'Black',
      tracerColor: '',
    },
    form: editEdgeForm,
    visible: edgeModalVisible,
    onOk: handleEditEdge,
    onCancel: () => setEdgeModalVisible(false),
  };

  return (
    <React.Fragment>
      <EditEdgeModal {...edgeModalProps} />
      <Events {...eventsProps} />
      <ActionButtons {...actionButtonsProps} />
    </React.Fragment>
  );
};

export default Wire;
