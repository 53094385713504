import { Reducer } from 'redux';

import {
  SideMenuBarActionTypes,
  SideMenuBarClose,
  SideMenuBarOpen,
} from './../../actions/ui/SideMenuBar';

// STATE
export type SideMenuBarState = {
  open: boolean;
};

export const initialSideMenuBarState: SideMenuBarState = {
  open: true,
};

// REDUCER
export const sideMenuBarReducer: Reducer<SideMenuBarState> = (
  state: SideMenuBarState = initialSideMenuBarState,
  action: SideMenuBarOpen | SideMenuBarClose,
) => {
  switch (action.type) {
    case SideMenuBarActionTypes.OPEN:
      return {
        ...state,
        open: true,
      };
    case SideMenuBarActionTypes.CLOSE:
      return {
        ...state,
        open: false,
      };
    default:
      return state;
  }
};
