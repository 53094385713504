import { Form } from 'antd';
import { mxCell, mxGraph as mxGraphType } from '@anekonnect/mxgraph';
import { useRef, useState, useCallback } from 'react';

import MxGraph from '../MxGraph';
import { GraphRefs, ShapeContainer } from '../MxGraph/Graph/Types';
import TabulatedDataTable from '../MxGraph/Tables/TabulatedDataTable';
import RevisionsTable from '../MxGraph/Tables/RevisionsTable';
import DrawingNotesTable from '../MxGraph/Tables/DrawingNotesTable';
import TitleBlock from '../MxGraph/Tables/TitleBlock';
import BOMTable from '../MxGraph/Tables/BOMTable';

import Components from './Components';
import ApprovalHistoriesTable from '../MxGraph/Tables/ApprovalHistoriesTable';

type WizardEngineeringDrawingProps = {
  graph: mxGraphType;
  activeTabKey: 'engineeringDrawing';
  setGraph: (graph: mxGraphType) => void;
  isHide?: boolean;
};

const WizardEngineeringDrawing = ({
  graph,
  activeTabKey,
  setGraph,
  isHide = false,
}: WizardEngineeringDrawingProps) => {
  const [container, setContainer] = useState<HTMLElement>();
  const [modalEditVisible, setModalEditVisible] = useState(false);

  const containerRef = useRef(null);
  const refs = useRef<GraphRefs>({
    componentCellRef: useRef<ShapeContainer>({
      componentContainer: null,
    }),
  });

  const [currentCell, setCurrentCell] = useState<mxCell | null>(null);

  const [editEdgeForm] = Form.useForm();
  const [editComponentForm] = Form.useForm();
  // const revalidateGraph = useCallback(() => {
  //   if (graph) {
  //     console.log('revalidate graph');

  //     graph.view.validate(); // Revalidates and refreshes the graph's view
  //   }
  // }, [graph]);

  const handleMxGraphAction = useCallback(
    (graph: mxGraphType, containerEl: HTMLElement) => {
      containerEl.style.background = "url('/img/wires-grid.gif')";
      containerEl.style.height = `${window.innerHeight - 185}px`;
      containerEl.style.overflow = 'scroll';

      setGraph(graph);
      setContainer(containerEl);
      // revalidateGraph(); // Call revalidateGraph here to refresh
    },
    [setGraph, setContainer],
  );

  const graphProps = {
    container,
    graph,
    graphRefs: refs,
    graphForms: {
      editEdgeForm,
      editComponentForm,
    },
    graphVisibles: {
      modalEditVisible,
    },
    graphSetVisibles: {
      setModalEditVisible,
    },
    componentContainerModal: {
      title: 'Edit Component',
      defaultValue: {
        componentAlias: '',
      },
      form: editComponentForm,
      visible: modalEditVisible,
      onCancel: () => {
        setModalEditVisible(false);
        editComponentForm.resetFields();
      },
    },
    onAction: handleMxGraphAction,
    activeTabKey,
    currentCell,
    setCurrentCell,
  };
  console.log('eng index file ');

  return (
    <MxGraph
      forwardedRef={containerRef}
      {...graphProps}
      style={{ border: '1px solid #d9d9d9', display: `${isHide ? 'none' : 'block'}` }}
    >
      <TitleBlock graph={graph} />
      <BOMTable graph={graph} />
      <TabulatedDataTable graph={graph} />
      <RevisionsTable graph={graph} />
      <DrawingNotesTable graph={graph} />
      <ApprovalHistoriesTable graph={graph} />
      {graph.container && <Components graph={graph} />}
      {/* <Components graph={graph} /> */}
    </MxGraph>
  );
};

export default WizardEngineeringDrawing;
