import { Part } from './../api/API';
import { isEmpty } from 'lodash';
import { Filter, Filters } from '~/api/API';

import { ParsedQuery } from 'query-string';

export const isMultipleFilterExist = (parsedSearch: ParsedQuery) => {
  for (const key in parsedSearch) {
    if (key.startsWith('multiple[')) {
      return true;
    }
  }
  return false;
};

export const sortMultiple = (
  nestedData: {
    [key: string]: any;
  },
  config: Filters,
) => {
  const sortedMultiple: { [key: string]: { [key: string]: string } } = {};

  if (!config?.multiple) {
    return nestedData;
  }

  // Get the order of keys for each core type in the config
  const multipleConfig = config.multiple.reduce((acc, item) => {
    const selectKeys = item.filters.select?.map((filter) => filter.key) || [];
    const numericKeys = item.filters.numeric?.map((filter) => filter.key) || [];
    acc[item.key] = selectKeys.concat(numericKeys);
    return acc;
  }, {} as { [key: string]: string[] });

  // Sort the entries in nestedData.multiple based on the order in multipleConfig
  for (const coreKey in nestedData.multiple) {
    if (Object.prototype.hasOwnProperty.call(nestedData.multiple, coreKey)) {
      const coreData = nestedData.multiple[coreKey];
      const sortedCoreData: { [key: string]: string } = {};

      const order = multipleConfig[coreKey];
      if (order) {
        order.forEach((key) => {
          if (coreData[key]) {
            sortedCoreData[key] = coreData[key];
          }
        });
      }

      // Add any remaining keys that were not in the order
      for (const key in coreData) {
        if (Object.prototype.hasOwnProperty.call(coreData, key) && !sortedCoreData[key]) {
          sortedCoreData[key] = coreData[key];
        }
      }

      sortedMultiple[coreKey] = sortedCoreData;
    }
  }

  return {
    ...nestedData,
    multiple: sortedMultiple,
  };
};

export const getSingleFilterDetails = (part: Part, key: string, value: any) => {
  if (part) {
    const partFilters = part.filters;
    if (partFilters) {
      const partSingleKey: { [key: string]: any } = {};

      for (const filter in partFilters) {
        const filterData = partFilters[filter as keyof Filters];
        const findFilter: any = (filterData as Filter[]).find((f) => f.key === key);

        if (!isEmpty(findFilter)) {
          findFilter.type = filter;
          findFilter.value = value;
          partSingleKey[key] = findFilter;

          break;
        }
      }

      return partSingleKey;
    }
  }

  return null;
};

export const getMultipleFilterDetails = (
  part: Part,
  rootKey: string,
  childKey: string,
  value: any,
) => {
  if (part) {
    const partFilters = part.filters;
    if (partFilters) {
      const partMultipleFilters: { [key: string]: any } = {};

      if (partFilters.multiple) {
        for (const filter of partFilters.multiple) {
          const filterKey = filter.key;

          if (filterKey === rootKey) {
            for (const childFilterKey in filter.filters) {
              const childFilters = filter.filters[childFilterKey as keyof Filters];
              const findFilter: any = (childFilters as Filter[]).find((cf) => cf.key === childKey);

              if (findFilter) {
                findFilter.type = childFilterKey;
                findFilter.value = value;
                partMultipleFilters[`multiple[${rootKey}][${childKey}]`] = findFilter;

                break;
              }
            }
          }
        }
      }

      return partMultipleFilters;
    }
  }

  return null;
};

export const getFiltersDetails = (
  part: Part,
  nestedData: { [key: string]: { [key: string]: any } },
) => {
  const filtersDetails = [];

  for (const outerKey in nestedData) {
    if (outerKey === 'multiple') {
      const innerData = nestedData[outerKey];
      for (const rootKey in innerData) {
        const childKeys = innerData[rootKey];

        for (const childKey in childKeys) {
          const value = childKeys[childKey];

          const result = getMultipleFilterDetails(part, rootKey, childKey, value);

          if (!isEmpty(result)) {
            filtersDetails.push(result);
          }
        }
      }
    } else {
      const value = nestedData[outerKey];
      const result = getSingleFilterDetails(part, outerKey, value);

      if (!isEmpty(result)) {
        filtersDetails.push(result);
      }
    }
  }

  return filtersDetails;
};

export const convertToNestedFormat = (
  data: { [key: string]: any },
  keysToRemove: string[] = [],
): { [key: string]: any } => {
  const nestedData: { [key: string]: any } = {};

  for (const key in data) {
    // Skip keys that need to be removed
    if (keysToRemove.includes(key)) {
      continue;
    }

    const match = key.match(/([^[\]]+)/g);
    if (match) {
      let currentLevel = nestedData;
      for (let i = 0; i < match.length; i++) {
        const part = match[i];
        if (i === match.length - 1) {
          currentLevel[part] = data[key];
        } else {
          if (!currentLevel[part]) {
            currentLevel[part] = {};
          }
          currentLevel = currentLevel[part];
        }
      }
    }
  }

  return nestedData;
};

export const filterData = (
  data: any[],
  filters: { [key: string]: { [key: string]: string } }[],
): any[] => {
  // Group filters by their core identifier
  const groupedFilters = filters.reduce((acc, filter) => {
    const [key, filterData] = Object.entries(filter)[0];
    const coreIdentifier = key.match(/multiple\[(.*?)\]/)?.[1] || key;
    if (!acc[coreIdentifier]) {
      acc[coreIdentifier] = [];
    }
    acc[coreIdentifier].push(filterData);
    return acc;
  }, {} as { [key: string]: { [key: string]: string }[] });

  // Helper function to check numeric range
  const isInRange = (value: number, range: string): boolean => {
    const [min, max] = range.split(':').map(Number);
    if (range.startsWith(':')) {
      return value < max;
    }
    if (range.endsWith(':')) {
      return value > min;
    }
    if (!isNaN(min) && !isNaN(max)) {
      return value > min && value < max;
    }
    return value === min;
  };

  // Filter data based on grouped filters
  return data.filter((item) => {
    return Object.values(groupedFilters).every((filterGroup) => {
      let index: number | undefined = undefined;

      return filterGroup.every((filterData) => {
        const { field, value, type } = filterData;
        const parts = field.split('/');
        let valueToCompare: any = item;

        for (const part of parts) {
          if (Array.isArray(valueToCompare)) {
            valueToCompare = valueToCompare.map((v) => v[part]);
          } else {
            valueToCompare = valueToCompare[part];
          }
          if (!valueToCompare) break;
        }

        if (Array.isArray(valueToCompare)) {
          if (index === undefined) {
            index = valueToCompare.findIndex((v) => {
              if (type === 'numeric') {
                return isInRange(Number(v), value);
              }
              return String(v) === String(value);
            });
            if (index === -1) {
              return false;
            }
          } else {
            if (type === 'numeric') {
              if (!isInRange(Number(valueToCompare[index]), value)) {
                return false;
              }
            } else {
              if (String(valueToCompare[index]) !== String(value)) {
                return false;
              }
            }
          }
        } else {
          if (type === 'numeric') {
            if (!isInRange(Number(valueToCompare), value)) {
              return false;
            }
          } else {
            if (String(valueToCompare) !== String(value)) {
              return false;
            }
          }
        }

        return true;
      });
    });
  });
};
