import { Alert, message, Space, Spin, Typography } from 'antd';
import { JSONSchema7 } from 'json-schema';
import { useState } from 'react';

import { postCreateQuote } from '~/api/AuthorizedPosts';
import { useAppSelector } from '~/store/hooks';
import { AppState } from '~/store/reducers';

import { FormSchema } from '~/components/FormSchema/FormSchema';
import QuotesHistory from './components/quotes-history';

import styles from './request-quote.module.scss';

const requestQuoteSchema: JSONSchema7 = {
  type: 'object',
  required: ['quantity', 'country_of_usage', 'vendors', 'expected_date'],
  properties: {
    quantity: {
      type: 'integer',
      title: 'Quantity of Cable Needed',
      default: 1,
    },
    expected_date: {
      type: 'string',
      title: 'Need By Date',
      format: 'date',
    },
    destination_address: {
      type: 'object',
      title: 'Destination Address',
      required: ['line_1', 'city', 'state', 'zip_code', 'country'],
      properties: {
        line_1: {
          type: 'string',
          title: 'Address Line 1',
        },
        line_2: {
          type: 'string',
          title: 'Address Line 2',
        },
        city: {
          type: 'string',
          title: 'City',
        },
        state: {
          type: 'string',
          title: 'State',
        },
        zip_code: {
          type: 'string',
          title: 'Zip Code',
        },
        country: {
          type: 'string',
          title: 'Country',
        },
      },
    },
    country_of_usage: {
      type: 'string',
      title: 'Country of End Use',
    },
    user_provided_components: {
      type: 'string',
      title: 'Components Provided By User',
    },
    preferred_currency: {
      type: 'string',
      title: 'Preferred Currency Of Payment',
      default: 'USD',
      anyOf: [
        {
          type: 'string',
          title: 'USD',
          enum: ['USD'],
        },
        {
          type: 'string',
          title: 'GBP',
          enum: ['GBP'],
        },
        {
          type: 'string',
          title: 'EUR',
          enum: ['EUR'],
        },
        {
          type: 'string',
          title: 'SGD',
          enum: ['SGD'],
        },
        {
          type: 'string',
          title: 'AUD',
          enum: ['AUD'],
        },
        {
          type: 'string',
          title: 'JPY',
          enum: ['JPY'],
        },
      ],
    },
    notes: {
      type: 'string',
      title: 'Notes',
    },
    billing_address: {
      type: 'object',
      title: 'Billing Address',
      required: ['line_1', 'city', 'state', 'zip_code', 'country'],
      properties: {
        line_1: {
          type: 'string',
          title: 'Address Line 1',
        },
        line_2: {
          type: 'string',
          title: 'Address Line 2',
        },
        city: {
          type: 'string',
          title: 'City',
        },
        state: {
          type: 'string',
          title: 'State',
        },
        zip_code: {
          type: 'string',
          title: 'Zip Code',
        },
        country: {
          type: 'string',
          title: 'Country',
        },
      },
    },
    vendors: {
      type: 'string',
      title: 'Cable Molding Partner',
    },
  },
};

const RequestQuote = () => {
  const {
    id: assemblyId,
    drawingNumber,
    drawingRevision,
    requestedQuotes,
  } = useAppSelector((state: AppState) => state.assemblyWizard);

  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState<any>({});

  const handleSubmit = async (data: any) => {
    setIsLoading(true);
    data.assembly_id = assemblyId;
    data.expected_date = new Date(data.expected_date).toISOString();

    try {
      const response = await postCreateQuote(data);

      if (response) {
        message.success('Quote requested successfully');
        setTimeout(() => window.location.reload(), 1000);
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  return (
    <Spin spinning={isLoading}>
      <Space direction="vertical" className={styles.container}>
        {!(assemblyId && drawingNumber && drawingRevision) && (
          <Alert
            message="Please save the assembly and fill in the drawing number and revision on the title block before requesting a quote. Thank you for your cooperation."
            type="warning"
            showIcon
            style={{ marginBottom: '1rem' }}
          />
        )}
        {requestedQuotes.length !== 0 && <QuotesHistory requestedQuotes={requestedQuotes} />}

        <Typography.Title level={3} className={styles.header}>
          Request New Quote
        </Typography.Title>
        <Space className={styles.drawingInfo}>
          <Space>
            <Typography.Text style={{ fontSize: '18px' }}>Drawing Number:</Typography.Text>
            <Typography.Text type="secondary" style={{ fontSize: '18px' }}>
              {drawingNumber || '-'}
            </Typography.Text>
          </Space>
          <Space>
            <Typography.Text style={{ fontSize: '18px' }}>Drawing Revision:</Typography.Text>
            <Typography.Text type="secondary" style={{ fontSize: '18px' }}>
              {drawingRevision || '-'}
            </Typography.Text>
          </Space>
        </Space>
        <FormSchema
          formData={formData}
          uiSchema={{
            vendors: {
              'ui:customUI': 'vendors',
            },
            destination_address: {
              'ui:field': 'address',
            },
            billing_address: {
              'ui:field': 'address',
              'func:copyAddress': [
                {
                  id: 'destination_address',
                  title: 'Copy From Destination Address',
                  values: formData.destination_address,
                },
              ],
            },
          }}
          schema={requestQuoteSchema}
          cancelAction={() => console.log('cancel')}
          confirmAction={(data) => handleSubmit(data.formData)}
          submitText="Submit"
          onChange={(data) => setFormData(data.formData)}
          disabled={!(assemblyId && drawingNumber && drawingRevision)}
        />
      </Space>
    </Spin>
  );
};

export default RequestQuote;
