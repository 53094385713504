import { DatePicker, Modal } from 'antd';
import { RangePickerProps } from 'antd/lib/date-picker';
import dayjs from 'dayjs';

import { putUpdateTrialDate } from '~/api/AuthorizedPuts';

type EditTrialDateProps = {
  isOpen: boolean;
  onCancel: () => void;
  onOk: () => void;
  id: string;
  value: dayjs.Dayjs | null;
  handleDateChange: any;
};

const disabledDate: RangePickerProps['disabledDate'] = (current) => {
  // Can not select days before today and today
  return current && current < dayjs().endOf('day');
};

const EditTrialDate = ({
  isOpen,
  onCancel,
  onOk,
  id,
  value,
  handleDateChange,
}: EditTrialDateProps) => {
  return (
    <Modal
      title="Edit Trial Date"
      open={isOpen}
      onCancel={onCancel}
      onOk={async () => {
        if (value) {
          await putUpdateTrialDate(id, { trial_date: value.toDate() });
        }
        onOk();
      }}
    >
      <DatePicker
        disabledDate={disabledDate}
        format="MMMM Do YYYY, h:mm:ss a"
        showNow={false}
        value={value}
        showTime
        onChange={handleDateChange}
      />
    </Modal>
  );
};

export default EditTrialDate;
