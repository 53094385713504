import React from 'react';

import { GoogleLoginIcon } from './GoogleLoginIcon';
import { darkStyle, lightStyle, disabledStyle, hoverStyle } from './GoogleLoginStyles';

type GoogleLoginButtonProps = {
  label: string;
  disabled: boolean;
  type: string;
  tabIndex: number;
  onClick: (e: any) => void;
  style?: Record<string, unknown>;
  className?: string;
};

export class GoogleLoginButton extends React.PureComponent<GoogleLoginButtonProps> {
  static defaultProps = {
    label: 'Sign in with Google',
    disabled: false,
    type: 'dark',
    tabIndex: 0,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onClick: () => {},
  };

  state = {
    hovered: false,
  };

  getStyle = (propStyles: Record<string, unknown> | undefined) => {
    const baseStyle = this.props.type === 'dark' ? darkStyle : lightStyle;

    if (this.state.hovered) {
      return { ...baseStyle, ...hoverStyle, ...propStyles };
    }

    if (this.props.disabled) {
      return { ...baseStyle, ...disabledStyle, ...propStyles };
    }

    return { ...baseStyle, ...propStyles };
  };

  mouseOver = () => {
    if (!this.props.disabled) {
      this.setState({ hovered: true });
    }
  };

  mouseOut = () => {
    if (!this.props.disabled) {
      this.setState({ hovered: false });
    }
  };

  click = (e: unknown) => {
    if (!this.props.disabled) {
      this.props.onClick(e);
    }
  };

  render() {
    const { label, style, ...otherProps } = this.props;

    return (
      <div
        {...otherProps}
        role="button"
        style={this.getStyle(style)}
        onClick={this.click}
        onMouseOut={this.mouseOut}
        onMouseOver={this.mouseOver}
      >
        <GoogleLoginIcon {...this.props} />
        <span>{label}</span>
      </div>
    );
  }
}
