import { Grid } from 'antd';
import React from 'react';

import { Filter, Filters, Part } from '../../../api/API';
import Breadcrumb from '../../../components/Breadcrumb';
import { PartItem } from '../../../types';

type PartsBreadcrumbProps = {
  parts: Part[];
  data?: PartItem;
  search: string;
  partName: string;
};

const getFilterName = (key: string, filters: Filter[]) => {
  const filter = filters.find((item: Filter) => item.key === key);
  const filterName = filter ? `${filter.display_name} : ` : '';

  return filterName;
};

const decodeString = (value: string) => {
  const string = decodeURIComponent(value);
  const stringSplitArray = string.split(':');

  if (stringSplitArray.length === 2) {
    if (stringSplitArray[0] === '') {
      return `Less than ${stringSplitArray[1]}`;
    } else if (stringSplitArray[1] === '') {
      return `Greater than ${stringSplitArray[0]}`;
    }

    return `Between ${stringSplitArray[0]} and ${stringSplitArray[1]}`;
  }

  return string;
};

const { useBreakpoint } = Grid;

const PartsBreadcrumb: React.FC<PartsBreadcrumbProps> = (props) => {
  const { lg } = useBreakpoint();
  const { parts, data, search, partName } = props;
  let displayName = partName;

  if (parts) {
    const part: Part | undefined = parts.find((part) => part.name === partName);

    if (part) {
      displayName = part && part.display_name;
    }
  }

  const partType = data ? data.type : partName;
  const partProps = parts.find((item) => item.name === partType);
  const partFilters: Filters | undefined = partProps ? partProps.filters : undefined;
  let filterSet: Filter[] = [];

  if (partFilters && partFilters.select) {
    filterSet = [...filterSet, ...partFilters.select];
  }

  if (partFilters && partFilters.numeric) {
    filterSet = [...filterSet, ...partFilters.numeric];
  }

  const breadcrumbs = [];

  breadcrumbs.push({
    url: `/parts`,
    name: 'Parts',
  });

  if (data) {
    breadcrumbs.push({
      url: `/parts/${data.type}`,
      name: data.type.charAt(0).toUpperCase() + data.type.substr(1).toLowerCase(),
    });
  } else {
    breadcrumbs.push({
      url: `/parts/${partName}`,
      name: displayName,
    });
  }

  const querySplitArray = search ? search.split('&') : [];
  let partQuery = '';
  lg &&
    querySplitArray.map((item: string, index: number) => {
      const andReq = index !== 0 ? '&' : '';
      partQuery += andReq + item;
      const itemArray = item.split('=');
      const filterKey = itemArray[0].charAt(0) === '?' ? itemArray[0].substr(1) : itemArray[0];
      const filterValue = itemArray[1];

      if (filterKey === 'page') {
        return null;
      }

      if (data) {
        breadcrumbs.push({
          url: `/parts/${data.type}${partQuery}`,
          name: getFilterName(filterKey, filterSet) + decodeString(filterValue),
        });
      }

      if (partName) {
        breadcrumbs.push({
          url: `/parts/${partName}${partQuery}`,
          name: getFilterName(filterKey, filterSet) + decodeString(filterValue),
        });
      }

      return null;
    });

  if (data && lg) {
    breadcrumbs.push({
      url: `/parts/${data.type}/${data.name}`,
      name: data.name,
    });
  }

  return <Breadcrumb breadcrumbData={breadcrumbs} />;
};

export default PartsBreadcrumb;
