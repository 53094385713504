/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { mxShape } from '@anekonnect/mxgraph';

import { mx } from '~/constants/wizard';

export const customTwistedPairShape = () => {
  const { mxCylinder, mxCellRenderer } = mx;

  function TwistedPairShape() {}

  TwistedPairShape.prototype = new mxCylinder(null as any, null as any, null as any);
  TwistedPairShape.prototype.constructor = TwistedPairShape;
  TwistedPairShape.prototype.redrawPath = function (path, x, y, w, h, isForeground) {
    const dx = w / 16;

    if (isForeground) {
      const startMove = 15;
      const startX = (w - (w / 2 + dx * 2)) / 2;
      const yOffset = h - startX;

      path.moveTo(0, startX);
      path.lineTo(startX, yOffset);
      path.lineTo(w - startX, yOffset);
      path.lineTo(w - startX + startX, startMove);

      path.end();
    }
  };
  mxCellRenderer.registerShape(
    'twistedPairShape',
    TwistedPairShape as unknown as new (...args: any) => mxShape,
  );
};

export const customQuadTwistedShape = () => {
  const { mxCylinder, mxCellRenderer } = mx;

  function QuadTwistedShape() {}

  QuadTwistedShape.prototype = new mxCylinder(null as any, null as any, null as any);
  QuadTwistedShape.prototype.constructor = QuadTwistedShape;
  QuadTwistedShape.prototype.redrawPath = function (path, x, y, w, h, isForeground) {
    if (isForeground) {
      const startMoveY = 2;
      const moveTop = 13;
      const reduceOffsetY = 7;

      path.begin();
      path.moveTo(0, startMoveY);
      path.curveTo(10, -moveTop - reduceOffsetY, 3, 5, 5, h - moveTop + reduceOffsetY);
      path.curveTo(5, h - 2, 8, h - moveTop + reduceOffsetY, 5 * 2, h - moveTop);
      path.end();
    }
  };

  mxCellRenderer.registerShape(
    'quadTwistedShape',
    QuadTwistedShape as unknown as new (...args: any) => mxShape,
  );
};
