import { stringify } from 'querystring';

import { ThunkDispatch } from 'redux-thunk';
import Axios, { AxiosError, CancelTokenSource } from 'axios';
import { AnyAction } from 'redux';

import { KeyValue } from '../types';
import { serializeQuery } from '../utils/query';
import { baseApiUrl } from '../utils/env';

import {
  TENANT_CONFIG,
  WHOAMI,
  GET_SUBSCRIPTION,
  GET_PLANS,
  GET_COUPON,
  GET_ALL_USER,
  GET_USER,
  SEARCH_PART_ITEMS,
  READ_PART_FILTERS,
  READ_PART_ITEMS,
  READ_PART_ITEM,
  GET_USER_BY_EMAIL,
  GET_DATA_ASSEMBLIES,
  GET_DATA_ASSEMBLIES_BY_ID,
  REQUEST_DOWNLOAD_SVG_FILES,
  GET_TAGS,
  GET_NOTIFICATIONS,
  GET_USER_ROLES,
  GET_ALL_VENDOR_USERS,
  GET_ALL_REQUEST_QUOTE,
  GET_ALL_QUOTE_RESPONSE,
  GET_ALL_QUOTE_ORDERS,
  GET_REVIEWERS,
  GET_APPROVAL_HISTORY,
  GET_ZENDESK_TOKEN,
} from './../constants/index';
import { makeActionCreator } from './../store/actions/index';
import {
  ErrorHandler,
  defaultErrorHandler,
  handleResponseDataNotJson,
  handleAPIError,
} from './HttpError';
import { AppState } from './../store/reducers/index';
import { store } from '~/store';
import { ParsedQuery } from 'query-string';
import { customStringify } from '~/utils/filter';
import { cloneDeep } from 'lodash';

const getApiGenerator =
  (route: string, name: string, cancelRequest?: CancelTokenSource) =>
  async (id?: string, data?: unknown, errorHandler: ErrorHandler = defaultErrorHandler) => {
    const dispatch = store.dispatch as ThunkDispatch<AppState, void, AnyAction>;
    const getState = store.getState as () => AppState;

    const token = getState().data.auth.token;

    // dispatch request action
    dispatch(makeActionCreator(`${name}_REQUEST`)());

    // prepare get request
    const config = {
      headers: { Authorization: `Bearer ${token}` },
      params: data,
      cancelToken: cancelRequest?.token,
    };

    const dataLayer: unknown[] = [];
    let updatedRoute = route;

    if (id !== undefined && id !== null) {
      updatedRoute = `${route}/${id}`;
    }

    try {
      const response = await Axios.get(baseApiUrl + updatedRoute, config);
      let responseData = response.data;

      if (responseData === null) {
        responseData = [];
      }

      if (responseData instanceof Object) {
        dispatch(makeActionCreator(`${name}_SUCCESS`, 'data')(responseData));
      } else {
        handleResponseDataNotJson(dispatch, name, 'GET', dataLayer, route); // response data is not json
      }
      return responseData;
    } catch (error: any) {
      if (Axios.isCancel(error)) {
        // console.log('Request canceled: ', error.message);
        return;
      }

      if ((error as AxiosError).response) {
        handleAPIError(errorHandler, dispatch, error.response, 'GET', dataLayer, route, name);
      }

      return Promise.reject(error);
    }
  };

export const getWhoami = getApiGenerator('/_a/users/whoami', WHOAMI);

export const getUsers = getApiGenerator('/_a/users', GET_ALL_USER);

export const getUser = getApiGenerator('/_a/user', GET_USER);

export const getTenantConfig = getApiGenerator('/_a/tenant-config', TENANT_CONFIG);

export const getSubscription = getApiGenerator('/_a/user_subscription', GET_SUBSCRIPTION);

export const getPlans = getApiGenerator('/_a/plans', GET_PLANS);

export const getCoupon = getApiGenerator('/_a/coupons', GET_COUPON);

export const getSearchPartItems = (
  name?: string,
  filters?: object,
  cancelRequest?: CancelTokenSource,
) => {
  let queryParams = { q: name };

  if (filters) {
    queryParams = { ...queryParams, ...filters };
  }

  const stringQuery = Object.entries(queryParams)
    .filter(([_, val]) => !!val)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

  return getApiGenerator('/_a/parts?' + stringQuery, SEARCH_PART_ITEMS, cancelRequest)();
};

export const getPartFilters = (
  queryParams?: KeyValue | ParsedQuery<string>,
  cancelRequest?: CancelTokenSource,
) => {
  const cloneQueryParams = cloneDeep(queryParams);

  if (cloneQueryParams) {
    // Remove the 'multiple' key if it exists
    delete cloneQueryParams.multiple;

    // Iterate over each key in cloneQueryParams
    for (const key in cloneQueryParams) {
      if (Object.prototype.hasOwnProperty.call(cloneQueryParams, key)) {
        // Check if the key matches the pattern 'multiple[...][...]'
        if (key.startsWith('multiple[') && key.includes(']')) {
          // Remove the key
          delete cloneQueryParams[key];
        }
      }
    }

    // Remove the 'page' key if it exists
    if ('page' in cloneQueryParams) {
      delete cloneQueryParams.page;
    }
  }

  const query = stringify(cloneQueryParams);

  return getApiGenerator('/_a/parts_filters?' + query, READ_PART_FILTERS, cancelRequest)();
};

export const getPartItems = (
  partname: string,
  queryParams: KeyValue | ParsedQuery<string> = {},
  isPagination = false,
  limit = 20,
  offset = 0,
  cancelRequest: CancelTokenSource,
) => {
  if (queryParams && 'page' in queryParams) {
    delete queryParams.page;
  }

  const query = customStringify(queryParams);
  let url = `/_a/parts?${serializeQuery({
    t: partname,
    isPagination,
    limit,
    offset,
  })}`;

  if (query) {
    url += '&' + query;
  }

  return getApiGenerator(url, READ_PART_ITEMS, cancelRequest)();
};

export const getPartItem = getApiGenerator('/_a/part', READ_PART_ITEM);

export const getUserByEmail = getApiGenerator('/_a/users/lookup', GET_USER_BY_EMAIL);

export const getDataAssemblies = (
  limit = 10,
  offset = 0,
  query = {},
  cancelRequest?: CancelTokenSource,
) => {
  return getApiGenerator(
    `/_a/v2/assemblies?limit=${limit}&offset=${offset}&${stringify(query)}`,
    GET_DATA_ASSEMBLIES,
    cancelRequest,
  )();
};

export const getDataSharedAssemblies = (
  limit = 10,
  offset = 0,
  query = {},
  cancelRequest?: CancelTokenSource,
) => {
  return getApiGenerator(
    `/_a/v2/shared-assemblies?limit=${limit}&offset=${offset}&${stringify(query)}`,
    GET_DATA_ASSEMBLIES,
    cancelRequest,
  )();
};

export const getDataOtherAssemblies = (
  limit = 10,
  offset = 0,
  query = {},
  cancelRequest?: CancelTokenSource,
) => {
  return getApiGenerator(
    `/_a/v2/other-assemblies?limit=${limit}&offset=${offset}&${stringify(query)}`,
    GET_DATA_ASSEMBLIES,
    cancelRequest,
  )();
};

export const getDataReviewRequestedAssemblies = (
  limit = 10,
  offset = 0,
  query = {},
  cancelRequest?: CancelTokenSource,
) => {
  return getApiGenerator(
    `/_a/v2/pending-review-assemblies?limit=${limit}&offset=${offset}&${stringify(query)}`,
    GET_DATA_ASSEMBLIES,
    cancelRequest,
  )();
};

export const getDataAssembliesItem = getApiGenerator(
  '/_a/v2/assemblies',
  GET_DATA_ASSEMBLIES_BY_ID,
);

export const requestDownloadSVGFiles = getApiGenerator(
  '/_a/request/part',
  REQUEST_DOWNLOAD_SVG_FILES,
);

export const getTags = getApiGenerator('/_a/tags', GET_TAGS);

export const getNotifications = getApiGenerator('/_a/feed', GET_NOTIFICATIONS);

export const getUserRoles = getApiGenerator('/_a/user-roles', GET_USER_ROLES);

export const getVendorUsers = getApiGenerator('/_a/vendors', GET_ALL_VENDOR_USERS);

export const getAllRequestQuote = getApiGenerator('/_a/quote/request', GET_ALL_REQUEST_QUOTE);

export const getAllQuoteResponse = getApiGenerator('/_a/quote/response', GET_ALL_QUOTE_RESPONSE);

export const getAllQuoteOrders = getApiGenerator('/_a/quote/order', GET_ALL_QUOTE_ORDERS);

export const getReviewers = getApiGenerator('/_a/reviewers', GET_REVIEWERS);

export const getApprovalHistory = (id: number) => {
  return getApiGenerator(`/_a/v2/assemblies/${id}/history`, GET_APPROVAL_HISTORY)();
};

export const getZendeskToken = getApiGenerator('/_a/sso/zendesk', GET_ZENDESK_TOKEN);
