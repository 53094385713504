import { Form, Modal, Select } from 'antd';
import React from 'react';

import { GraphModalProps } from '../../Graph/Types';

const EditEdgeModal = ({ form, title, visible, onCancel, onOk, defaultValue }: GraphModalProps) => {
  return (
    <React.Fragment>
      <Modal title={title} open={visible} forceRender onCancel={onCancel} onOk={onOk}>
        <Form form={form} initialValues={defaultValue} layout="vertical">
          <Form.Item label="Base Color" name="baseColor">
            <Select>
              <Select.Option value="black">Black</Select.Option>
              <Select.Option value="white">White</Select.Option>
              <Select.Option value="red">Red</Select.Option>
              <Select.Option value="blue">Blue</Select.Option>
              <Select.Option value="brown">Brown</Select.Option>
              <Select.Option value="yellow">Yellow</Select.Option>
              <Select.Option value="green">Green</Select.Option>
              <Select.Option value="orange">Orange</Select.Option>
              <Select.Option value="pink">Pink</Select.Option>
              <Select.Option value="purple">Purple</Select.Option>
              <Select.Option value="turquoise">Turquoise</Select.Option>
              <Select.Option value="violet">Violet</Select.Option>
              <Select.Option value="grey">Grey</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label="Tracer Color" name="tracerColor">
            <Select defaultValue="">
              <Select.Option value="">None</Select.Option>
              <Select.Option value="black">Black</Select.Option>
              <Select.Option value="white">White</Select.Option>
              <Select.Option value="red">Red</Select.Option>
              <Select.Option value="blue">Blue</Select.Option>
              <Select.Option value="brown">Brown</Select.Option>
              <Select.Option value="yellow">Yellow</Select.Option>
              <Select.Option value="green">Green</Select.Option>
              <Select.Option value="orange">Orange</Select.Option>
              <Select.Option value="pink">Pink</Select.Option>
              <Select.Option value="purple">Purple</Select.Option>
              <Select.Option value="turquoise">Turquoise</Select.Option>
              <Select.Option value="violet">Violet</Select.Option>
              <Select.Option value="grey">Grey</Select.Option>
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default EditEdgeModal;
