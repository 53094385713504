import { ParsedQuery } from 'query-string';
import { MultipleFilter } from '~/api/API';
import { KeyValue, PartFilterFields } from '~/types';

export const getListOfKeys = (data: MultipleFilter): string[] => {
  const { filters } = data;
  const keys: string[] = [];

  if (!filters) {
    return [];
  }

  for (const filterType of Object.values(filters)) {
    if (!filterType) {
      continue;
    }

    for (const filter of filterType) {
      keys.push(filter.key);
    }
  }

  return keys;
};

export const isMultipleFilter = (obj: any): obj is MultipleFilter => {
  return typeof obj === 'object' && 'filters' in obj;
};

export const hasMultipleKeys = (filtersData: KeyValue<KeyValue<number>>, obj: any): boolean => {
  if (isMultipleFilter(obj)) {
    const keys = getListOfKeys(obj);
    return keys.every((key) => key in filtersData);
  }

  return false;
};

export const extractNestedKey = (key: string, index = 0): string => {
  const matches = key.match(/\[(.*?)\]/g);
  if (matches && index < matches.length) {
    // Extract the nested key from the matched substring using the given index
    const nestedKey = matches[index].substring(1, matches[index].length - 1);
    return nestedKey;
  }
  return '';
};

export const getSelectedValue = (
  filter: PartFilterFields,
  selectedFilterData: ParsedQuery,
  rootKey?: string,
) => {
  if (selectedFilterData[filter.key]) {
    return selectedFilterData[filter.key] || '';
  } else {
    const findKey = rootKey ? `[${rootKey}][${filter.key}]` : filter.key;
    const matchingKey = Object.keys(selectedFilterData).find((key) => {
      return key.includes(findKey);
    });

    if (matchingKey) {
      return selectedFilterData[matchingKey];
    } else {
      return '';
    }
  }
};

export const createDeepObject = (obj: any, keys: string[], value: any): void => {
  let currentObj = obj;

  // Traverse through each key in the keys array
  for (let i = 0; i < keys.length - 1; i++) {
    const key = keys[i];

    // Check if the key exists in the current object
    if (!Object.prototype.hasOwnProperty.call(currentObj, key)) {
      // If the key doesn't exist, create a new object for it
      currentObj[key] = {};
    }

    // Move to the next level of the object
    currentObj = currentObj[key];
  }

  // Assign the value to the last key
  currentObj[keys[keys.length - 1]] = value;
};

export const convertStringKeyToObject = (filters: { [key: string]: any }): any => {
  const result: any = {};

  // Iterate over each key in the filters object
  Object.keys(filters).forEach((key) => {
    // Split the key into parts based on '[' and ']'
    const parts = key.split(/\[|\]/).filter(Boolean);

    // Initialize a reference to the current level of the result object
    let currentLevel = result;

    // Iterate over each part of the key
    for (let i = 0; i < parts.length; i++) {
      const part = parts[i];

      // If it's the last part, assign the value
      if (i === parts.length - 1) {
        currentLevel[part] = filters[key];
      } else {
        // If the key doesn't exist, create an empty object
        currentLevel[part] = currentLevel[part] || {};

        // Move to the next level
        currentLevel = currentLevel[part];
      }
    }
  });

  return result;
};

// Custom stringify function to handle nested objects
export const customStringify = (params: any) => {
  const parts: string[] = [];
  for (const key in params) {
    if (Object.prototype.hasOwnProperty.call(params, key)) {
      const value = params[key];
      if (key === 'multiple') {
        // If key is 'multiple', handle nested objects separately
        if (typeof value === 'object') {
          for (const nestedKey in value) {
            if (Object.prototype.hasOwnProperty.call(value, nestedKey)) {
              const nestedValue = value[nestedKey];
              if (typeof nestedValue === 'object') {
                // Handle nested objects
                for (const subKey in nestedValue) {
                  if (Object.prototype.hasOwnProperty.call(nestedValue, subKey)) {
                    parts.push(
                      `multiple[${nestedKey}][${subKey}]=${encodeURIComponent(
                        nestedValue[subKey],
                      )}`,
                    );
                  }
                }
              } else {
                parts.push(`multiple[${nestedKey}]=${encodeURIComponent(nestedValue)}`);
              }
            }
          }
        }
      } else {
        // For other keys, append key-value pair directly
        parts.push(`${key}=${encodeURIComponent(value)}`);
      }
    }
  }
  return parts.join('&');
};

export const convertDeepObjectKeysToString = (obj: any, parentKey = ''): any => {
  const result: any = {};

  for (const key in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(key)) {
      const newKey = parentKey ? `${parentKey}[${key}]` : key;

      if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
        const nestedObj = convertDeepObjectKeysToString(obj[key], newKey);
        Object.assign(result, nestedObj);
      } else {
        result[newKey] = obj[key];
      }
    }
  }

  return result;
};
