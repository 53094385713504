import { EyeOutlined } from '@ant-design/icons';
import { Button, Collapse, List, Space } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { mxGraph } from '@anekonnect/mxgraph';

import { AppState } from '~/store/reducers';
import { HiddenComponent } from '~/store/reducers/wizard/State';
import { wizardRemoveHiddenComponent } from '~/store/actions/wizard/Component';

type PropsFromState = {
  hiddenComponents: HiddenComponent[];
};

type PropsFromDispatch = {
  wizardRemoveHiddenComponent: (id: string) => void;
};

type HiddenComponentProps = {
  graph: mxGraph;
} & PropsFromState &
  PropsFromDispatch;

const HiddenComponentList = ({
  graph,
  hiddenComponents,
  wizardRemoveHiddenComponent,
}: HiddenComponentProps) => {
  const handleShowComponent = (id: string) => {
    const cell = graph.getModel().getCell(id);
    cell.setVisible(true);
    graph.refresh();

    wizardRemoveHiddenComponent(id);
  };

  return (
    <Collapse defaultActiveKey={['hidden-list']}>
      <Collapse.Panel key="hidden-list" header="Components Hidden">
        <List
          dataSource={hiddenComponents}
          itemLayout="horizontal"
          renderItem={(item) => (
            <List.Item>
              <Space style={{ paddingLeft: '5px' }}>
                <Button
                  icon={<EyeOutlined />}
                  shape="circle"
                  type="primary"
                  onClick={() => handleShowComponent(item.id)}
                />
                <span style={{ textTransform: 'capitalize' }}>{item.identifier}</span>
              </Space>
            </List.Item>
          )}
        />
      </Collapse.Panel>
    </Collapse>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    hiddenComponents: state.assemblyWizard.hiddenComponents,
  };
};

const mapDispatchToProps = {
  wizardRemoveHiddenComponent,
};

export default connect(mapStateToProps, mapDispatchToProps)(HiddenComponentList);
