import { ExclamationCircleOutlined, SaveFilled } from '@ant-design/icons';
import { Button, message, Modal, Spin, Tooltip, Image } from 'antd';
import { mxGraph } from '@anekonnect/mxgraph';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { ActionCreator } from 'redux';
import { connect } from 'react-redux';
import { FormInstance } from 'antd/lib/form';
import _ from 'lodash';

import { AssemblyForm, WizardJson } from './Types';
import styles from './WizardHeaderSaveAssembly.module.scss';

import { postSaveDataAssembly } from '~/api/AuthorizedPosts';
import { AppState } from '~/store/reducers';
import { SnackBarOpen, snackBarOpen } from '~/store/actions/ui/SnackBar';
import {
  BillOfMaterials,
  DrawingNotes,
  HiddenComponent,
  ListComponent,
  Revisions,
  TitleBlockData,
} from '~/store/reducers/wizard/State';
import { LINK_ASSEMBLY_EDIT } from '~/constants/paths';
import {
  putUpdateAssemblyNoRedirect,
  putUpdateTemplateSettings,
  putUpdateTitleBlockSchema,
} from '~/api/AuthorizedPuts';
import { mx } from '~/constants/wizard';
import { PaperSize, PaperSizeData } from '~/utils/paperSize';
import {
  wizardSetNewTemplatePosition,
  wizardSetNewTitleBlockStyle,
  wizardUpdatePaperSizeData,
} from '~/store/actions/wizard/Action';
import { getPaymentStatus, paymentStatusIsPaid } from '~/utils/helper';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '~/store/hooks';

type PropsFromState = {
  components: ListComponent;
  isAuthenticated: boolean;
  assemblyName: string;
  assemblyId?: number;
  assemblyGuestEmailId: string;
  drawingNotes: DrawingNotes;
  billOfMaterials: BillOfMaterials;
  tabulatedData: unknown;
  revisions: Revisions;
  titleBlockData: TitleBlockData[];
  tags: number[];
  hiddenComponents: HiddenComponent[];
  paperSize: string;
  paperSizeData: PaperSizeData;
  newTemplatePosition: PaperSize | null;
  newTitleBlockStyle: TitleBlockData[] | null;
  isViewOnly: boolean;
  drawingNumber: string;
  drawingRevision: string;
  cableTestCardData: any;
  menuViewActiveList: string[];
};

type PropsFromDispatch = {
  snackBarOpen: ActionCreator<SnackBarOpen>;
  wizardUpdatePaperSizeData: typeof wizardUpdatePaperSizeData;
  wizardSetNewTemplatePosition: typeof wizardSetNewTemplatePosition;
  wizardSetNewTitleBlockStyle: typeof wizardSetNewTitleBlockStyle;
};

type WizardHeaderSaveAssemblyProps = {
  graph: mxGraph;
  assemblyForm: FormInstance<AssemblyForm>;
  buttonType?: 'default' | 'file';
} & PropsFromState &
  PropsFromDispatch;

type DataAssemblyState = {
  name: string;
  guest_email_id?: string;
  details: string;
  components: string;
  drawing_notes_table: string;
  bill_of_materials_table: string;
  tabulated_data_table: string;
  revisions_table: string;
  title_block_data: string;
  tags: number[];
  hidden_components: string;
  paper_size: string;
  user_drawing_number: string;
  user_drawing_revision: string;
  cable_test_card_data: string;
  menu_view_active_list: string;
};

const WizardHeaderSaveAssembly = ({
  graph,
  assemblyForm,
  buttonType = 'default',
  components,
  isAuthenticated,
  assemblyName,
  assemblyId,
  assemblyGuestEmailId,
  drawingNotes,
  billOfMaterials,
  tabulatedData,
  revisions,
  titleBlockData,
  tags,
  hiddenComponents,
  paperSize,
  paperSizeData,
  newTemplatePosition,
  newTitleBlockStyle,
  isViewOnly,
  drawingNumber,
  drawingRevision,
  cableTestCardData,
  menuViewActiveList,
  snackBarOpen,
  wizardUpdatePaperSizeData,
  wizardSetNewTemplatePosition,
  wizardSetNewTitleBlockStyle,
}: WizardHeaderSaveAssemblyProps) => {
  const navigate = useNavigate();
  const { status: assemblyStatus } = useAppSelector((state) => state.assemblyWizard);
  const whoamiData = useAppSelector((state) => state.data.whoami.data);
  const roles = useMemo(() => (whoamiData !== undefined ? whoamiData['roles'] : []), [whoamiData]);
  const isAdmin = useMemo(() => roles.includes('SuperAdmin') || roles.includes('Admin'), [roles]);

  const [openGuestModal, setOpenGuestModal] = useState(false);
  const [ifrmaeLoaded, setIframeLoaded] = useState(false);
  const [isFormSubmited, setIsFormSubmitted] = useState(false);
  const [showCloseButton, setShowCloseButton] = useState(false);

  const paymentStatus = getPaymentStatus(whoamiData);
  const isPaid = paymentStatusIsPaid(paymentStatus);

  const modalTemplateConfirm = useCallback(() => {
    if (newTemplatePosition && whoamiData) {
      Modal.confirm({
        title: 'We are detect you changes the default template, do you want to save it?',
        icon: <ExclamationCircleOutlined />,
        okText: 'Yes',
        cancelText: 'No',
        onOk: async () => {
          const data = _.cloneDeep(paperSizeData);
          data[paperSize] = newTemplatePosition;

          await putUpdateTemplateSettings('', {
            template_settings: JSON.stringify(data),
          });
          wizardUpdatePaperSizeData(data);
          wizardSetNewTemplatePosition(null);

          message.success(`Template updated for ${paperSize} size`);
        },
        onCancel: () => {
          wizardSetNewTemplatePosition(null);
        },
      });
    }
  }, [
    newTemplatePosition,
    paperSize,
    paperSizeData,
    wizardSetNewTemplatePosition,
    wizardUpdatePaperSizeData,
    whoamiData,
  ]);

  const modalTitleBlockConfirm = useCallback(() => {
    if (newTitleBlockStyle) {
      Modal.confirm({
        title: 'We are detect you changes the default title block style, do you want to save it?',
        icon: <ExclamationCircleOutlined />,
        okText: 'Yes',
        cancelText: 'No',
        onOk: async () => {
          const getNewSchema = (titleBlockData: TitleBlockData[]) => {
            return titleBlockData.map((data) => {
              return {
                key: data.key,
                fieldName: data.fieldName,
                fieldValue: '',
                style: data.style,
              };
            });
          };

          const newSchema = getNewSchema(newTitleBlockStyle);

          await putUpdateTitleBlockSchema('', {
            title_block_schema: JSON.stringify(newSchema),
          });

          wizardSetNewTitleBlockStyle(null);
          message.success('Title block style updated');
        },
        onCancel: () => {
          wizardSetNewTitleBlockStyle(null);
        },
      });
    }
  }, [newTitleBlockStyle, wizardSetNewTitleBlockStyle]);

  const onSave = useCallback(() => {
    modalTemplateConfirm();
    modalTitleBlockConfirm();

    const getXml = (graph: mxGraph): string => {
      const encoder = new mx.mxCodec();
      const node = encoder.encode(graph.getModel());
      const xml = mx.mxUtils.getXml(node);

      return xml;
    };

    const setPostDataAssembly = (values: DataAssemblyState) => {
      return postSaveDataAssembly({ ...values }).then(
        (result: { name: string; details: string; url_identifier: string }) => {
          snackBarOpen('Data assembly successfully saved');
          navigate(`${LINK_ASSEMBLY_EDIT}/${result.url_identifier}`);
        },
      );
    };

    const newJson: WizardJson = {
      engineeringDrawing: getXml(graph),
    };

    assemblyForm.validateFields();
    const { assemblyName, email } = assemblyForm.getFieldsValue();

    if (!assemblyName) {
      return;
    }

    const assemblyParameters: DataAssemblyState = {
      name: assemblyName,
      details: JSON.stringify(newJson),
      components: JSON.stringify(components),
      drawing_notes_table: JSON.stringify(drawingNotes),
      bill_of_materials_table: JSON.stringify(billOfMaterials),
      tabulated_data_table: JSON.stringify(tabulatedData),
      revisions_table: JSON.stringify(revisions),
      title_block_data: JSON.stringify(titleBlockData),
      tags,
      hidden_components: JSON.stringify(hiddenComponents),
      paper_size: paperSize,
      user_drawing_number: drawingNumber,
      user_drawing_revision: drawingRevision,
      cable_test_card_data: JSON.stringify(cableTestCardData),
      menu_view_active_list: menuViewActiveList.toString(),
    };

    if (assemblyId) {
      putUpdateAssemblyNoRedirect(String(assemblyId), assemblyParameters).then(
        (assembly: { id: number }) => {
          if (assembly.id) {
            snackBarOpen('Data assembly successfully updated');
          }
        },
      );
    } else {
      // unauthenticated user must provide email
      if (!isAuthenticated) {
        if (!email) {
          return;
        }
        assemblyParameters.guest_email_id = email;
      }

      setPostDataAssembly(assemblyParameters);
    }
  }, [
    modalTemplateConfirm,
    modalTitleBlockConfirm,
    graph,
    assemblyForm,
    components,
    drawingNotes,
    billOfMaterials,
    tabulatedData,
    revisions,
    titleBlockData,
    tags,
    hiddenComponents,
    paperSize,
    drawingNumber,
    drawingRevision,
    cableTestCardData,
    menuViewActiveList,
    assemblyId,
    snackBarOpen,
    navigate,
    isAuthenticated,
  ]);

  const onSaveValidator = useCallback(() => {
    assemblyForm.validateFields();
    const { assemblyName, email } = assemblyForm.getFieldsValue();

    if (!assemblyName) {
      return;
    }

    if (!isAuthenticated && !email) {
      return;
    }

    if (!isAuthenticated) {
      setOpenGuestModal(true);

      if (assemblyId || isFormSubmited) {
        setTimeout(() => {
          setShowCloseButton(true); // delay 5 seconds to show close button
        }, 5000);
      }
    } else {
      onSave();
    }
  }, [isAuthenticated, onSave, assemblyForm, assemblyId, isFormSubmited]);

  useEffect(() => {
    window.addEventListener(
      'message',
      (e) => {
        if (e.origin === 'https://webforms.pipedrive.com') {
          const payload = e.data.payload;

          // We are detect that form is submitted by the height, because we don't have any other way to detect it
          if (payload?.height === 124) {
            setIsFormSubmitted(true);

            setTimeout(() => {
              setShowCloseButton(true); // delay 5 seconds to show close button
            }, 5000);
          }
        }
      },
      false,
    );
  }, []);

  const pipeDriveListener = useCallback(() => {
    if (!openGuestModal) {
      return;
    }

    const iframe = document.getElementById('guest-user-registration');

    if (iframe) {
      iframe.addEventListener('load', () => {
        setIframeLoaded(true);
      });
    }
  }, [openGuestModal]);

  useEffect(() => {
    pipeDriveListener();
  }, [pipeDriveListener]);

  useEffect(() => {
    assemblyForm.setFieldsValue({
      assemblyName,
      email: assemblyGuestEmailId,
    });
  }, [assemblyName, assemblyForm, assemblyGuestEmailId]);

  if (isViewOnly) {
    return (
      <Tooltip
        placement="left"
        title="This assembly is view only, and you cannot save your changes."
      >
        <Button disabled>View Only</Button>
      </Tooltip>
    );
  }

  if (!isPaid && !isAdmin && buttonType === 'default') {
    return (
      <Tooltip
        placement="left"
        title="This assembly is view only, and you cannot save your changes."
      >
        <Button disabled>Please update your payment to save your assembly</Button>
      </Tooltip>
    );
  }

  if (assemblyStatus.toLowerCase() === 'under review') {
    return (
      <Tooltip
        placement="left"
        title="You can't update this assembly after the request for approval made."
      >
        <Button disabled>Under Review</Button>
      </Tooltip>
    );
  }

  if (assemblyStatus.toLowerCase() === 'published') {
    return (
      <Tooltip
        placement="left"
        title="You can't update this assembly after the assembly is published."
      >
        <Button disabled>Published</Button>
      </Tooltip>
    );
  }

  const parentId = 'guest-user-registration';
  const formId = '6UUxIs5kf2SimnZFkjWM62HFKY92AyVhRcEucIHr6RvAT22cUfK1NozftYO14KphXd';

  const getButtonType = () => {
    const text = assemblyId ? 'Update' : 'Save';

    switch (buttonType) {
      case 'default':
        return (
          <Button icon={<SaveFilled />} type="primary" onClick={onSaveValidator}>
            {text}
          </Button>
        );

      case 'file':
        return (
          <div
            className={`${styles.fileButton} ${!isPaid && styles.disabled}`}
            onClick={() => isPaid && onSaveValidator()}
          >
            {text}
          </div>
        );
    }
  };

  const getAds = () => (
    <>
      <div className={styles.ads}>
        <h3>Ad</h3>
        <Image alt="Subsea Supplies" src="/img/ads-OI24.png" />
      </div>
      <Button
        disabled={!showCloseButton}
        loading={!showCloseButton}
        style={{
          width: '100%',
          padding: '8px 12px',
          height: '100%',
          borderRadius: '24px',
          fontSize: '16px',
          fontWeight: 600,
          lineHeight: '20px',
        }}
        onClick={() => {
          onSave();
          setOpenGuestModal(false);
          setShowCloseButton(false);
        }}
      >
        Close
      </Button>
    </>
  );

  return (
    <Fragment>
      <Modal
        closable={false}
        footer={null}
        style={{
          textAlign: 'center',
        }}
        open={openGuestModal && !assemblyId}
      >
        <iframe
          height={isFormSubmited ? 124 : 500}
          id={parentId}
          src={`https://webforms.pipedrive.com/f/${formId}?embeded=1&uuid=${parentId}`}
          style={{ border: 'none', display: !ifrmaeLoaded ? 'none' : 'block' }}
          title="Guest User Registration"
          width="100%"
          seamless
        />
        <Spin spinning={!ifrmaeLoaded} tip="Please wait..." />
        {isFormSubmited && getAds()}
      </Modal>
      <Modal
        closable={false}
        footer={null}
        style={{
          textAlign: 'center',
        }}
        open={openGuestModal && !!assemblyId}
      >
        <div className={styles.guestModalBody}>
          <p className={styles.title}>Design Cables 10X Faster 10X Cheaper</p>
          <p>Did you find the cable diagramming application useful to you and your organization?</p>
          <p>
            Setup a{' '}
            <a
              href="https://anekonnect.pipedrive.com/scheduler/1eevEOS6/follow-up-to-cable-diagramming-application-demonstration-at-tradeshow"
              rel="noopener noreferrer"
              target="_blank"
            >
              follow up meeting
            </a>{' '}
            with our solutions engineer
          </p>
        </div>
        {getAds()}
      </Modal>
      {getButtonType()}
    </Fragment>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    components: state.assemblyWizard.components,
    isAuthenticated: state.data.auth.authenticated,
    assemblyName: state.assemblyWizard.name,
    assemblyId: state.assemblyWizard.id,
    assemblyGuestEmailId: state.assemblyWizard.guestEmailId,
    drawingNotes: state.assemblyWizard.drawingNotes,
    billOfMaterials: state.assemblyWizard.billOfMaterials,
    tabulatedData: state.assemblyWizard.tabulatedData,
    revisions: state.assemblyWizard.revisions,
    titleBlockData: state.assemblyWizard.titleBlockData,
    tags: state.assemblyWizard.tags,
    hiddenComponents: state.assemblyWizard.hiddenComponents,
    paperSize: state.assemblyWizard.paperSize,
    paperSizeData: state.assemblyWizard.paperSizeData,
    newTemplatePosition: state.assemblyWizard.newTemplatePosition,
    newTitleBlockStyle: state.assemblyWizard.newTitleBlockStyle,
    isViewOnly: state.assemblyWizard.isViewOnly,
    drawingNumber: state.assemblyWizard.drawingNumber,
    drawingRevision: state.assemblyWizard.drawingRevision,
    cableTestCardData: state.assemblyWizard.cableTestCardData,
    menuViewActiveList: state.assemblyWizard.menuViewActiveList,
  };
};

const mapDispatchToProps = {
  snackBarOpen,
  wizardUpdatePaperSizeData,
  wizardSetNewTemplatePosition,
  wizardSetNewTitleBlockStyle,
};

export default connect(mapStateToProps, mapDispatchToProps)(WizardHeaderSaveAssembly);
