const graphConfig = {
  baseColor: 'black',
  vertexSelectionColor: '#00FF00',
  edgeSelectionColor: 'green',
  shadowColor: '#C0C0C0',
  defaultStrokeWidth: 2,
  edgeSelectionStrokeWidth: 4,
  arrowStyle: 'oval',
  deleteCellKeys: ['del', 'backspace'],
  selectAllCellsKeys: ['command+shift', 'ctrl+shift'],
  componentContainer: 'container_parent',
  componentContainerShielded: 'container_shielded_parent',
  marginTopLabel: '-40',
  marginTopComponent: -5,
  actionWidth: 32,
  edgeFontSize: '9',
  vertexFontSize: '14',
};

export default graphConfig;
