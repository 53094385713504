import { Form, Modal, Input, Select, Switch } from 'antd';
import { mxCell } from '@anekonnect/mxgraph';
import React, { useEffect } from 'react';

import { Void } from '../Types';

import { GraphModalProps, GraphModalsProps } from './Types';

import { mx } from '~/constants/wizard';
import { useAppSelector } from '~/store/hooks';
import { findComponentByCellId } from '~/utils/component';
import { CSS_COLOR_NAMES } from '~/constants/colors';

const EditComponentContainerModal = ({
  form,
  title,
  visible,
  onCancel,
  onOk,
  defaultValue,
  currentCell,
}: GraphModalProps & { currentCell: mxCell | null }) => {
  const parts = useAppSelector((state) => state.data.tenantConfig.data?.parts);
  const components = useAppSelector((state) => state.assemblyWizard.components);

  const showCableForm = currentCell?.id.includes('bezier_curve');

  useEffect(() => {
    if (visible) {
      form.resetFields();
    }

    if (currentCell) {
      const formCableData = JSON.parse(window.localStorage.getItem('formCableData') || '{}');
      const formData = formCableData[currentCell.id] || {};

      formData.componentAlias = mx.mxUtils.isNode(currentCell.value, 'component')
        ? currentCell.value.getAttribute('label')
        : currentCell.value;

      form.setFieldsValue(formData);
    }
  }, [currentCell, form, visible]);

  const { Option } = Select;

  const cableLengthAfter = (
    <Form.Item name="cableUnit" noStyle style={{ height: 0 }}>
      <Select defaultValue="mm" style={{ width: 100 }} className="cabel-dropdown">
        <Option value="mm">mm</Option>
        <Option value="cm">cm</Option>
        <Option value="meters">meters</Option>
        <Option value="in">in</Option>
        <Option value="ft">ft</Option>
      </Select>
    </Form.Item>
  );
  const toleranceAfter = (
    <Form.Item name="toleranceUnit" noStyle style={{ height: 0 }}>
      <Select defaultValue="mm" style={{ width: 100 }} className="cabel-dropdown">
        <Option value="mm">mm</Option>
        <Option value="cm">cm</Option>
        <Option value="in">in</Option>
        <Option value="%">%</Option>
      </Select>
    </Form.Item>
  );

  const cableForm = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const cableConfig = parts.find((part) => part.name === 'cable');
    const cellId = currentCell?.id || '';
    const component = findComponentByCellId(components, cellId);

    const defaultCableColor = component?.default_color || 'black';
    const defaultCableThickness = component?.default_thickness || 20;

    return (
      <React.Fragment>
        <Form.Item label="Cable Length (Connector to Connector)">
          <Input.Group compact>
            <Form.Item name="cableLength" noStyle>
              <Input addonAfter={cableLengthAfter} placeholder="3" />
            </Form.Item>
          </Input.Group>
        </Form.Item>
        <Form.Item label="Tolerance for Total Length of Cable">
          <Input.Group compact>
            <Form.Item name="cableTolerance" noStyle>
              <Input addonAfter={toleranceAfter} placeholder="0" />
            </Form.Item>
          </Input.Group>
        </Form.Item>
        <Form.Item label="Cable Thickness (px)" name="cableTickness">
          <Input placeholder="20" defaultValue={defaultCableThickness} />
        </Form.Item>
        <Form.Item label="Cable Jacket Color" name="cableColor">
          <Select
            placeholder="Select a color"
            defaultValue={defaultCableColor}
            showSearch
            options={CSS_COLOR_NAMES}
            filterOption={(input: string, option?: { label: string; value: string }) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            optionFilterProp="children"
            virtual={false}
          />
        </Form.Item>
        <Form.Item label="Show Dimension Line" name="showDimensionLine" valuePropName="checked">
          <Switch defaultChecked />
        </Form.Item>
      </React.Fragment>
    );
  };

  return (
    <Modal title={title} open={visible} forceRender onCancel={onCancel} onOk={onOk}>
      <Form form={form} initialValues={defaultValue} layout="vertical">
        <Form.Item label="Component Alias" name="componentAlias">
          <Input />
        </Form.Item>
        {showCableForm && cableForm()}
      </Form>
    </Modal>
  );
};

const Modals = ({
  currentCell,
  componentContainerModal,
  onOk,
}: GraphModalsProps & {
  onOk: Void;
  currentCell: mxCell | null;
}) => {
  return (
    <React.Fragment>
      <EditComponentContainerModal
        currentCell={currentCell}
        defaultValue={componentContainerModal.defaultValue}
        form={componentContainerModal.form}
        title={componentContainerModal.title}
        visible={componentContainerModal.visible}
        onCancel={componentContainerModal.onCancel}
        onOk={onOk}
      />
    </React.Fragment>
  );
};

export default Modals;
