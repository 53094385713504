import { stringify } from 'querystring';

import Axios from 'axios';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { baseApiUrl } from '../utils/env';

import { removeToken } from './../utils/token';
import {
  loginFailure,
  loginRequest,
  loginSuccess,
  logoutFailure,
  logoutRequest,
  logoutSuccess,
  oauthFailure,
  oauthRequest,
  oauthSuccess,
} from './../store/actions/data/Auth';
import { store } from '~/store';
import { AppState } from '~/store/reducers';

export const loginUser = (username: string, password: string, rememberMe: boolean) => {
  const dispatch = store.dispatch as ThunkDispatch<AppState, void, AnyAction>;

  dispatch(loginRequest());

  return Axios.post(
    baseApiUrl + '/_a/login',
    stringify({
      username,
      password,
      rememberMe,
    }),
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    },
  ).then(
    (response) => {
      const { token, expires_at } = response.data;

      if (rememberMe) {
        localStorage.setItem('token', token);
        localStorage.setItem('expires_at', expires_at);
      } else {
        sessionStorage.setItem('token', token);
        sessionStorage.setItem('expires_at', expires_at);
      }

      dispatch(loginSuccess(token, expires_at));
    },
    (error) => {
      const errorResponse = error.response;
      dispatch(
        loginFailure(errorResponse.status, errorResponse.data.error.what, errorResponse.status),
      );
    },
  );
};

export const oauthAuthorization = (source: string, redirectUrl: string) => {
  const dispatch = store.dispatch as ThunkDispatch<AppState, void, AnyAction>;

  dispatch(oauthRequest());

  return Axios.get(
    baseApiUrl + `/_a/oauth/authorize?source=${source}&redirectUrl=${redirectUrl}`,
  ).then(
    (response) => {
      const authCodeUrl = response.data.redirect_url;
      dispatch(oauthSuccess(authCodeUrl));

      return authCodeUrl;
    },
    (error) => {
      const errorResponse = error.response;
      dispatch(
        oauthFailure(errorResponse.status, errorResponse.data.error.what, errorResponse.status),
      );
    },
  );
};

export const oauthCallbackToken = (source: string, code: string, redirectUrl: string) => {
  const dispatch = store.dispatch as ThunkDispatch<AppState, void, AnyAction>;

  dispatch(loginRequest());

  return Axios.get(
    baseApiUrl + `/_a/oauth/token?state=${source}&code=${code}&redirectUrl=${redirectUrl}`,
  ).then(
    (response) => {
      const { token, expires_at } = response.data;

      localStorage.setItem('token', token);
      localStorage.setItem('expires_at', expires_at);

      dispatch(loginSuccess(token, expires_at));
      window.location.replace('/assemblies/wizard');
    },
    (error) => {
      const errorResponse = error.response;
      dispatch(
        loginFailure(errorResponse.status, errorResponse.data.error.what, errorResponse.status),
      );
    },
  );
};

export const logout = () => {
  const dispatch = store.dispatch as ThunkDispatch<AppState, void, AnyAction>;

  dispatch(logoutRequest());

  Axios.post(baseApiUrl + '/_a/logout').then(
    () => {
      removeToken();
      dispatch(logoutSuccess());
      window.location.replace('/assemblies/wizard');
    },
    (error) => {
      dispatch(logoutFailure(error.response.status, error.response.statusText));
    },
  );
};
