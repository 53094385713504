import { mxGraph, mxCell } from '@anekonnect/mxgraph';

import Constant from '../Constant';
import { getCenter } from '../Helper';
import { ComponentData } from '../../../Types';

import { mx } from '~/constants/wizard';
import { ConfigProp } from '~/store/reducers/configs';

export const createCableContainer = (
  graph: mxGraph,
  data: ComponentData,
  id: string,
  value: string,
  parent: mxCell,
  totalHeight: number,
  point: {
    x: number;
    y: number;
  },
  configs: ConfigProp,
) => {
  const parentId = data['is_shielded'] ? `${id}_shielded_parent` : `${id}_parent`;
  const found = graph.getModel().getCell(parentId);

  if (found) {
    return found;
  }

  const doc = mx.mxUtils.createXmlDocument();
  const node = doc.createElement('component');
  node.setAttribute('label', value);
  node.setAttribute('configs', JSON.stringify(configs));

  const cableContainer = graph.insertVertex(
    parent,
    parentId,
    node,
    point.x,
    point.y,
    Constant.cableContainerWidth,
    totalHeight,
    `fillColor=none;strokeColor=none;strokeWidth=0;rounded=0;fontColor=${Constant.baseColor};fontSize=${Constant.fontSize};resizable=0;`,
  );

  cableContainer.geometry.offset = new mx.mxPoint(0, -totalHeight / 2 - 27);
  cableContainer.setConnectable(false);

  return cableContainer;
};

export const createCablePin = (
  graph: mxGraph,
  data: ComponentData,
  id: string,
  value: string | null,
  parent: mxCell,
  width: number,
  height: number,
  offsetY: number,
  isFirstKey: boolean,
) => {
  const { mxPoint } = mx;

  const cablePin = graph.insertVertex(
    parent,
    id,
    value,
    0,
    0,
    width,
    height,
    `fillColor=#d3d3d3;strokeColor=none;strokeWidth=0;rounded=0;rotatable=0;`,
  );

  cablePin.id = id;
  cablePin.geometry.relative = true;
  cablePin.geometry.offset = new mxPoint(0, offsetY);
  cablePin.setConnectable(false);

  graph.orderCells(true, [cablePin]);

  if (data['is_shielded'] === true && isFirstKey) {
    const firstCore = data['cores'][0].type;
    const firstCableCore = `core_${firstCore}`;

    if (id.includes(firstCableCore)) {
      cablePin.geometry.offset.y = cablePin.geometry.offset.y + 15;
    }
  }

  return cablePin;
};

export const createLabelPin = (
  graph: mxGraph,
  id: string,
  value: string,
  width: number,
  height: number,
  parent: mxCell,
) => {
  const { mxPoint } = mx;

  let labelPinWidth = 0;
  let labelPinHeight = 0;

  const labelPin = graph.insertVertex(
    parent,
    id,
    value,
    0,
    0,
    width,
    height,
    `fillColor=#fff;strokeColor=none;strokeWidth=0;rounded=0;fontSize=7;fontColor=#000;rotatable=0;`,
  );
  labelPinWidth = labelPin.geometry.width;
  labelPinHeight = labelPin.geometry.height;

  labelPin.geometry.relative = true;
  labelPin.geometry.offset = new mxPoint(
    getCenter(parent.geometry.x, parent.geometry.width, labelPinWidth),
    getCenter(parent.geometry.y, parent.geometry.height, labelPinHeight),
  );
  labelPin.setConnectable(false);

  return labelPin;
};
