import React from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CustomTitleField = ({ title, required }: any) => {
  const legend = required ? title + '*' : title;

  return <div className="control-label">{legend}</div>;
};

export default CustomTitleField;
