import { Button, Tooltip } from 'antd';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
  BackwardFilled,
  DeleteOutlined,
  EyeInvisibleOutlined,
  FormOutlined,
  ForwardFilled,
  SplitCellsOutlined,
} from '@ant-design/icons';
import { mxCell, mxGraph } from '@anekonnect/mxgraph';
import { PiArrowsSplitBold, PiArrowsMergeBold } from 'react-icons/pi';

import { useAppSelector } from '~/store/hooks';

import Constant from './Constant';
import { mx } from '~/constants/wizard';
import { ConfigProp } from '~/store/reducers/configs';

const { mxConstants } = mx;

type ActionsProps = {
  graph: mxGraph;
  currentCell: mxCell | null;
  onEdit?: () => void;
  onDelete?: () => void;
  onMirror?: () => void;
  onOrderFrontable?: () => void;
  onOrderBackable?: () => void;
  onHideable?: () => void;
  onSplitt?: () => void;
  onMerge?: () => void;
};

type SingleProp = {
  onClick?: () => void;
};

const Mirrorable = (props: SingleProp & Pick<ConfigProp, 'mirrorable'>) => {
  if (!props.mirrorable) return null;

  return (
    <Tooltip title="Mirror">
      <Button icon={<SplitCellsOutlined />} type="primary" onClick={props.onClick} />
    </Tooltip>
  );
};

const Splittable = (props: SingleProp & Pick<ConfigProp, 'splittable'>) => {
  if (!props.splittable) return null;

  return (
    <Tooltip title="Split">
      <Button icon={<PiArrowsSplitBold size={'20px'} />} type="primary" onClick={props.onClick} />
    </Tooltip>
  );
};
const Mergeable = (props: SingleProp & Pick<ConfigProp, 'mergeable'>) => {
  if (!props.mergeable) return null;

  return (
    <Tooltip title="Merge">
      <Button icon={<PiArrowsMergeBold size={'20px'} />} type="primary" onClick={props.onClick} />
    </Tooltip>
  );
};
const Editable = (props: SingleProp & Pick<ConfigProp, 'editable'>) => {
  if (!props.editable) return null;

  return (
    <Tooltip title="Edit">
      <Button icon={<FormOutlined />} type="primary" onClick={props.onClick} />
    </Tooltip>
  );
};

const Deletable = (props: SingleProp & Pick<ConfigProp, 'deleteable'>) => {
  if (!props.deleteable) return null;

  return (
    <Tooltip title="Delete">
      <Button icon={<DeleteOutlined />} type="primary" onClick={props.onClick} />
    </Tooltip>
  );
};

const OrderFrontable = (props: SingleProp & Pick<ConfigProp, 'orderFront'>) => {
  if (!props.orderFront) return null;

  return (
    <Tooltip title="Bring to Front">
      <Button icon={<ForwardFilled />} type="primary" onClick={props.onClick} />
    </Tooltip>
  );
};

const OrderBackable = (props: SingleProp & Pick<ConfigProp, 'orderBack'>) => {
  if (!props.orderBack) return null;

  return (
    <Tooltip title="Bring to Back">
      <Button icon={<BackwardFilled />} type="primary" onClick={props.onClick} />
    </Tooltip>
  );
};

const Hideable = (props: SingleProp & Pick<ConfigProp, 'hideable'>) => {
  if (!props.hideable) return null;

  return (
    <Tooltip title="Hide">
      <Button icon={<EyeInvisibleOutlined />} type="primary" onClick={props.onClick} />
    </Tooltip>
  );
};

const Actions = ({
  onEdit,
  onDelete,
  onMirror,
  onOrderFrontable,
  onOrderBackable,
  onHideable,
  onSplitt,
  onMerge,
  graph,
  currentCell,
}: ActionsProps) => {
  const showComponentControl = useAppSelector((state) => state.assemblyWizard.showComponentControl);
  const actionRef = useRef<HTMLDivElement | null>(null);

  const [state, setState] = useState({
    mirrorable: false,
    editable: false,
    deleteable: false,
    hideable: false,
    splitable: false,
    mergeable: false,
  });

  const handleActionVisible = useCallback(() => {
    if (showComponentControl) {
      if (currentCell) {
        const idData = currentCell.id.split('_'); // structure id componentType_wizardType_componentId_componentSubId_furtherInformation
        const wizardTypeIndex = idData.findIndex((data) => data === 'ed' || data === 'schematics');
        const componentType = idData.slice(0, wizardTypeIndex).join('_');
        const componentId = idData[wizardTypeIndex + 1];
        const componentSubId = idData[wizardTypeIndex + 2];

        const othercell: any = [];

        currentCell.parent.children.forEach((item: any) => {
          const idData = item.id.split('_'); // structure id componentType_wizardType_componentId_componentSubId_furtherInformation
          const wizardTypeIndex = idData.findIndex(
            (data: any) => data === 'ed' || data === 'schematics',
          );
          // const separatedValue = idData.split('_')[1]; // Gets the part between the first and second underscores

          const type = idData.slice(0, wizardTypeIndex).join('_');
          const id = idData[wizardTypeIndex + 1];
          const subid = idData[wizardTypeIndex + 2];
          console.log(type, id, subid, wizardTypeIndex, 'action log data', item.id);

          if (
            componentType === type &&
            componentId === id &&
            componentSubId === subid &&
            item.style === 'resizable=0;'
          ) {
            if (item.id !== currentCell.id) {
              // console.log(item.style, 'id not match', item);
              othercell.push(item);
            } else {
              // console.log(item.style, ' match ', item);
            }
          }
        });

        const configs = currentCell.getAttribute('configs', null);
        // console.log(configs, 'confi_____g', currentCell, 'length', othercell.length);

        if (configs) {
          const { mirrorable, editable, deleteable, hideable } = JSON.parse(configs);

          setState({
            mirrorable,
            editable,
            deleteable,
            hideable,
            splitable: false,
            mergeable: false,
          });
          if (idData[1] === 'schematics' && idData[0] === 'connector') {
            if (othercell.length === 0) {
              // console.log('merge false');

              setState((prevState) => ({
                ...prevState,
                mergeable: false,
                splitable: true,
              }));
            } else {
              // console.log('merge true');

              setState((prevState) => ({
                ...prevState,
                mergeable: true,
                splitable: false,
              }));
            }
          }
        }
      }
    }
  }, [currentCell, showComponentControl]);

  useEffect(handleActionVisible, [handleActionVisible]);

  useEffect(() => {
    const cell = graph.getSelectionCell();

    if (!cell) return;

    const parentId = cell.parent.getId();
    let destinationCell = cell;

    if (parentId.includes('instrument') || parentId.includes('group')) {
      destinationCell = cell.parent;
    }

    const { x, y, width } = destinationCell.getGeometry();

    if (actionRef.current) {
      const edgeHeight = mxConstants.EDGE_SELECTION_STROKEWIDTH;
      const actionDOMHeight = actionRef.current.offsetHeight;

      const marginTopLabel = +Constant.marginTopLabel;
      let calcY = y - actionDOMHeight - edgeHeight + marginTopLabel + Constant.marginTopComponent;
      let calcX = x + width / 2;

      if (destinationCell.id.includes('bezier_curve')) {
        calcY =
          destinationCell.geometry.sourcePoint.y - actionDOMHeight - edgeHeight + marginTopLabel;
        calcX = destinationCell.geometry.targetPoint.x - width / 2;
      }

      actionRef.current.style.position = 'absolute';
      actionRef.current.style.left = `${String(calcX)}px`;
      actionRef.current.style.top = `${String(calcY)}px`;
      actionRef.current.style.transform = `translate(-50%, 0)`;
    }
  }, [graph]);

  if (!showComponentControl) return null;

  return (
    <div ref={actionRef}>
      <Button.Group>
        <Hideable hideable={state.hideable} onClick={onHideable} />
        <Splittable splittable={state.splitable} onClick={onSplitt} />
        <Mergeable mergeable={state.mergeable} onClick={onMerge} />
        <Mirrorable mirrorable={state.mirrorable} onClick={onMirror} />
        <Editable editable={state.editable} onClick={onEdit} />
        <Deletable deleteable={state.deleteable} onClick={onDelete} />

        <OrderFrontable orderFront onClick={onOrderFrontable} />
        <OrderBackable orderBack onClick={onOrderBackable} />
      </Button.Group>
    </div>
  );
};

export default Actions;
