import { minYPos, pinHeight } from '../constants/wizard';

import { PartItem } from './../types/Parts';
import {
  RectanglePosition,
  ListRectangleData,
  ListRectanglePosition,
  Point,
  ListBezierCurvePosition,
} from './../store/reducers/wizard/State';

export const getRectangleHeight = (rectangle: PartItem) => {
  let height = 0;
  // const pins = parseInt(rectangle.contacts ?? 1);
  const pins = 1; // set to 1 until we difine the schematics diagram

  height += pins * pinHeight;

  // Check for shell
  if (rectangle.has_shell) {
    height += 60;
  }

  height += 40; // bottom padding

  return height;
};

export const getPositionForNewRectangle = (
  rectanglesData: ListRectangleData,
  rectanglesPositions: ListRectanglePosition,
): RectanglePosition => {
  const xLeft = 230;
  const xRight = 600;
  const yOffset = 60;

  let yLeftMax = 0;
  yLeftMax += yOffset;
  let yRightMax = 0;
  yRightMax += yOffset;

  const rectangleIds = Object.keys(rectanglesPositions);

  rectangleIds.forEach((rectangleId) => {
    const subIds: string[] = Object.keys(rectanglesPositions[rectangleId]);

    subIds.forEach((subId) => {
      const rectangle = rectanglesData[rectangleId];
      const rectanglePosition = rectanglesPositions[rectangleId][subId];
      const rectangleHeight = getRectangleHeight(rectangle);

      if (rectanglePosition.schematics.position.x === xLeft) {
        yLeftMax += rectangleHeight + yOffset;
      } else {
        yRightMax += rectangleHeight + yOffset;
      }
    });
  });

  if (yLeftMax <= yRightMax) {
    // The new rectangle will be placed on the left
    return {
      engineeringDrawing: {
        position: {
          x: xLeft,
          y: yLeftMax,
        },
        angle: 0,
        mirror: false,
      },
      schematics: {
        position: {
          x: xLeft,
          y: yLeftMax,
        },
        angle: 0,
        mirror: false,
      },
    };
  }

  // The new rectangle will be placed on the right
  return {
    engineeringDrawing: {
      position: {
        x: xRight,
        y: yRightMax,
      },
      angle: 0,
      mirror: false,
    },
    schematics: {
      position: {
        x: xRight,
        y: yRightMax,
      },
      angle: 0,
      mirror: false,
    },
  };
};

export const updatePositionWithDelta = (rectanglePosition: Point, delta: Point): Point => {
  let engineeringDrawingPositionX = rectanglePosition.x + delta.x;
  engineeringDrawingPositionX = engineeringDrawingPositionX < 0 ? 0 : engineeringDrawingPositionX;

  let engineeringDrawingPositionY = rectanglePosition.y + delta.y;
  engineeringDrawingPositionY =
    engineeringDrawingPositionY < minYPos ? minYPos : engineeringDrawingPositionY;

  return { x: engineeringDrawingPositionX, y: engineeringDrawingPositionY };
};

export const getDefaultImageRectangle = (type: string) => {
  switch (type) {
    case 'connector_face':
      return '/img/default-connector-face.png';
    case 'connector':
      return '/img/default-connector.png';
    case 'split_junction':
      return '/img/default-split-junction.png';
    case 'penetrator_face':
      return '/img/default-penetrator-face.png';
    case 'penetrator':
      return '/img/default-penetrator.png';
    default:
      return undefined;
  }
};

export const getBezierCurvePosition = (bezierCurvesPositions: ListBezierCurvePosition) => {
  const xLeft = 300;
  const xRight = xLeft * 2.5;
  const yOffset = 250;
  const multiply = 3;
  const defaultStartEnd = 50 * multiply;
  const defaultX1X2 = 25 * multiply;

  let yLeftMax = 0;
  yLeftMax += yOffset;
  let yRightMax = 0;
  yRightMax += yOffset;

  const bezierCurveIds = Object.keys(bezierCurvesPositions);

  bezierCurveIds.forEach((id) => {
    const subIds = Object.keys(bezierCurvesPositions[id]);

    subIds.forEach((subId: string) => {
      const bezierCurvePosition = bezierCurvesPositions[id][subId];
      const newHeight = bezierCurvePosition.y + (yOffset - 120) * 2;

      if (bezierCurvePosition.x === xLeft) {
        yLeftMax = newHeight;
      } else {
        yRightMax = newHeight;
      }
    });
  });

  if (yLeftMax <= yRightMax) {
    // The new bezier curve will be placed on the left
    return {
      x: xLeft,
      y: yLeftMax,
      edStart: {
        x: xLeft - defaultStartEnd,
        y: yLeftMax,
      },
      edEnd: {
        x: xLeft + defaultStartEnd,
        y: yLeftMax,
      },
      edX1: {
        x: xLeft - defaultX1X2,
        y: yLeftMax,
      },
      edX2: {
        x: xLeft + defaultX1X2,
        y: yLeftMax,
      },
    };
  }

  // The new cable will be placed on the right
  return {
    x: xRight,
    y: yRightMax,

    edStart: {
      x: xRight - defaultStartEnd,
      y: yRightMax,
    },
    edEnd: {
      x: xRight + defaultStartEnd,
      y: yRightMax,
    },
    edX1: {
      x: xRight - defaultX1X2,
      y: yRightMax,
    },
    edX2: {
      x: xRight + defaultX1X2,
      y: yRightMax,
    },
  };
};
