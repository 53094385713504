import { mxGraph } from '@anekonnect/mxgraph';
import React from 'react';

import { ComponentData } from '../../Types';

import Draw from './Draw';
import Events from './Events';

import { ObjectShape } from '~/store/reducers/configs';

type BezierCurveProps = {
  data: ComponentData;
  graph: mxGraph;
  objects: ObjectShape[];
};

const BezierCurve = ({ graph, data, objects }: BezierCurveProps) => {
  const drawProps = {
    graph,
    data,
    objects,
  };

  const eventsProps = {
    graph,
  };

  return (
    <React.Fragment>
      <Draw {...drawProps} />
      <Events {...eventsProps} />
    </React.Fragment>
  );
};

export default BezierCurve;
