import { mxCell, StyleMap } from '@anekonnect/mxgraph';
import React, { useCallback, useMemo, useRef } from 'react';
import { Form } from 'antd';
import { connect } from 'react-redux';

import { GraphEventsProps, GraphProps } from '../../Graph/Types';
import { redrawLabels } from '../../Helper';
import Wire from '../Wire';

import ConnectorPinModal from './Modal';
import { findConnectorPortCell } from './Helper';
import Draw from './Draw';
import { ConnectorShareProps } from './Types';
import Events from './Events';

import { wizardSetOpenModalEditConnectorPin } from '~/store/actions/wizard/Action';

type PropsFromDispatch = {
  wizardSetOpenModalEditConnectorPin: typeof wizardSetOpenModalEditConnectorPin;
};

export type ConnectorProps = Pick<GraphProps, 'graph'> & {
  splitValue: number;
  parentid: any;
};
type Props = ConnectorShareProps & ConnectorProps & GraphEventsProps & PropsFromDispatch;

const Connector = (props: Props) => {
  const { splitValue, parentid, graph, data, objects, wizardSetOpenModalEditConnectorPin } = props;
  const [connectorPinForm] = Form.useForm();

  const defaultStylesRef = useRef<StyleMap>();
  const connectorPinRef = useRef<mxCell>(null);

  const handleEditConnectorPin = useCallback(() => {
    if (connectorPinRef.current) {
      const cell = connectorPinRef.current;
      const state = graph.view.getState(cell);

      const changePortValue = (leftCell: mxCell, rightCell: mxCell) => {
        const { labelValue, position } = connectorPinForm.getFieldsValue();

        switch (position) {
          case 'left':
            leftCell.valueChanged(labelValue);
            rightCell.valueChanged(null);
            break;
          case 'right':
            rightCell.valueChanged(labelValue);
            leftCell.valueChanged(null);
            break;
          default:
            break;
        }
      };

      if (state) {
        const { leftCell, rightCell } = findConnectorPortCell(graph, cell);

        if (leftCell && rightCell) {
          // Change left, right port value
          changePortValue(leftCell, rightCell);

          // Re-render cell, when label value changed
          redrawLabels(graph, [leftCell, rightCell]);

          // Close the modal
          wizardSetOpenModalEditConnectorPin(false);

          graph.clearSelection();
        }
      }
    }
  }, [graph, connectorPinForm, wizardSetOpenModalEditConnectorPin]);

  const drawProps = {
    graph,
    defaultStylesRef,
    data,
    objects,
    splitValue,
    parentid,
  };

  const connectorPinModalProps = useMemo(() => {
    return {
      form: connectorPinForm,
      title: 'Edit Pin Label',
      onCancel: () => {
        wizardSetOpenModalEditConnectorPin(false);
      },
      onOk: handleEditConnectorPin,
      defaultValue: {
        labelValue: '',
        position: 'right',
      },
    };
  }, [connectorPinForm, handleEditConnectorPin, wizardSetOpenModalEditConnectorPin]);

  const eventsProps = useMemo(() => {
    return {
      graph,
      connectorPinRef,
      forms: {
        connectorPinForm,
      },
    };
  }, [connectorPinForm, graph]);

  return (
    <React.Fragment>
      <Draw {...drawProps} />
      <ConnectorPinModal {...connectorPinModalProps} />
      <Events {...eventsProps} />
      <Wire {...props} />
    </React.Fragment>
  );
};

const mapDispatchToProps = {
  wizardSetOpenModalEditConnectorPin,
};

export default connect(null, mapDispatchToProps)(React.memo(Connector));
