import { Fragment, useEffect, useMemo, useState } from 'react';
import { Button, Col, message, Row, Space, Spin } from 'antd';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import styles from './UserProfile.module.scss';

import { isStateLoading } from '~/utils/state';
import Payment from '~/components/Subscription/Payment';
import { getPlans, getSubscription, getWhoami } from '~/api/AuthorizedGets';
import { snackBarOpen } from '~/store/actions/ui/SnackBar';
import { deleteCancelSubscription } from '~/api/AuthorizedDeletes';
import {
  putDisableUser,
  putUpdateSubscription,
  putUpdateTemplateSettings,
  putUpdateTitleBlockSchema,
} from '~/api/AuthorizedPuts';
import { AppState } from '~/store/reducers';
import { getPaymentStatus, paymentStatusIsPaid } from '~/utils/helper';
import { logout } from '~/api/Authorizations';

type PropsFromState = {
  whoamiData: {
    id: number;
    name: string;
    username: string;
    email: string;
    payment_status: string;
    type: string;
    roles: string[];
    template_settings: string;
    title_block_schema: string;
  };
  isLoading: boolean;
  isTenant?: boolean;
};

type PropsFromDispatch = {
  snackBarOpen: typeof snackBarOpen;
};

type UserProfileProps = PropsFromState & PropsFromDispatch;

const UserProfile = (props: UserProfileProps) => {
  const { whoamiData, isLoading, isTenant } = props;
  const paymentStatus = getPaymentStatus(whoamiData);
  const paymentType = whoamiData ? whoamiData.type : 'User';
  const roles = useMemo(() => (whoamiData !== undefined ? whoamiData['roles'] : []), [whoamiData]);
  const isAdmin = useMemo(() => roles.includes('SuperAdmin') || roles.includes('Admin'), [roles]);

  const [plans, setPlans] = useState([]);
  const [currentPlan, setCurrentPlan] = useState({ id: '', nickname: '' });
  const [summaryDetails, setSummaryDetails] = useState([{}]);
  const [amount, setAmount] = useState(0);
  const [couponSelected, setCouponSelected] = useState({
    id: '',
  });
  const [data, setData] = useState<{
    plan: {
      id?: string;
      nickname?: string;
    };
    customer_balance: number;
    last_charge_date: string;
    last_charge_amount: string;
    next_charge_date: string;
  }>({
    plan: {},
    customer_balance: 0,
    last_charge_date: '',
    last_charge_amount: '',
    next_charge_date: '',
  });
  const [isAgree, setIsAgree] = useState(false);
  const [showUpdatePlan, setShowUpdatePlan] = useState(false);
  const [showUpdateCard, setShowUpdateCard] = useState(false);
  const [isActionUpdateCard, setIsActionUpdateCard] = useState(false);

  const handleAgreement = () => setIsAgree(!isAgree);

  const fetchPlansSubscription = () => {
    if (isTenant === undefined || isTenant === true || isAdmin) {
      return;
    }

    getPlans().then((plans: any) => {
      const currentPlan = plans.find((plan: any) => plan.interval === 'month');
      const amount = currentPlan.amount / 100;
      const summaryDetails = [
        {
          title: currentPlan.nickname,
          amount,
        },
      ];

      setPlans(plans);
      setCurrentPlan(currentPlan);
      setAmount(amount);
      setSummaryDetails(summaryDetails);
    });

    getSubscription().then((data: any) => {
      if (data && data.plan) {
        setCurrentPlan(data.plan);
        setData(data);
      }
    });
  };

  useEffect(fetchPlansSubscription, [isAdmin, isTenant]);

  const getCouponDiscount = (coupon: any, amount: number) => {
    return amount * (coupon.percent_off / 100);
  };

  const getCouponSummary = (coupon: any, amount: number) => {
    const discount = getCouponDiscount(coupon, amount);

    const couponSummary = {
      title: `${coupon.name} (${coupon.percent_off}% off)`,
      amount: `-$${discount}`,
    };

    return couponSummary;
  };

  const planSwitcher = (plan: any, amount = 0) => {
    const summaryDetails = [
      {
        title: plan.nickname,
        amount: `$${amount}`,
      },
    ];
    setCurrentPlan(plan);

    if (amount > 0) {
      let couponDiscount = 0;

      if (!isEmpty(couponSelected.id)) {
        const couponSummary = getCouponSummary(couponSelected, amount);
        couponDiscount = getCouponDiscount(couponSelected, amount);
        summaryDetails.push(couponSummary);
      }

      setSummaryDetails(summaryDetails);
      setAmount(amount - couponDiscount);
    }
  };

  const handleCouponSelected = (coupon: any) => {
    const couponSummary = getCouponSummary(coupon, amount);
    const couponDiscount = getCouponDiscount(coupon, amount);
    const summaryCouponDetails = summaryDetails;

    summaryCouponDetails.push(couponSummary);
    setCouponSelected(coupon);
    setSummaryDetails(summaryCouponDetails);
    setAmount(amount - couponDiscount);
  };

  const subscriptionDataHandler = (data: any) => {
    setData(data);
  };

  const getTrialStatus = () => {
    if (paymentType === 'Admin') {
      return 'You are an admin, no need to subscribe!';
    }

    if (paymentStatus === 'Trial') {
      return '14 day free trial from date of initial signup with no restrictions';
    }

    if (!paymentStatusIsPaid(paymentStatus)) {
      return 'Restricted access to design features and storage space';
    }

    return 'Trial';
  };

  const handleCancelSubscription = () => {
    deleteCancelSubscription('').then(() => {
      props.snackBarOpen('Subscription Cancelled');
      getWhoami();
    });
  };

  const handleUpdatePlan = () => {
    putUpdateSubscription('', {
      plan_id: currentPlan.id,
      coupon_id: couponSelected.id,
    }).then(() => {
      getSubscription().then((data: any) => {
        setCurrentPlan(data.plan);
        setData(data);
      });
    });
    getWhoami();

    setShowUpdatePlan(false);
  };

  const renderUpdatePlan = () => {
    return (
      <Modal
        footer={[
          <Button key="cancel" onClick={() => setShowUpdatePlan(false)}>
            Cancel
          </Button>,
          <Button
            key="update-plan"
            disabled={!isAgree}
            type="primary"
            onClick={() => handleUpdatePlan()}
          >
            Update Plan
          </Button>,
        ]}
        title="Update Plan Subscription"
        open={showUpdatePlan}
        width={1100}
        centered
        onCancel={() => setShowUpdatePlan(false)}
      >
        <Payment
          amount={amount}
          couponSelected={couponSelected}
          currentPlan={currentPlan}
          handleAgreement={handleAgreement}
          handleCouponSelected={handleCouponSelected}
          isAgree={isAgree}
          planSwitcher={planSwitcher}
          plans={plans}
          subscriptionDataHandler={subscriptionDataHandler}
          summaryDetails={summaryDetails}
          isUpdatePlan
        />
      </Modal>
    );
  };

  const handleUpdateCard = () => {
    setIsActionUpdateCard(true);
  };

  const renderUpdateCard = () => {
    return (
      <Modal
        footer={[
          <Button key="cancel" onClick={() => setShowUpdateCard(false)}>
            Cancel
          </Button>,
          <Button
            key="update-card"
            disabled={!isAgree}
            type="primary"
            onClick={() => handleUpdateCard()}
          >
            Update Card
          </Button>,
        ]}
        title="Update Credit Card Information"
        open={showUpdateCard}
        width={1000}
        centered
        onCancel={() => setShowUpdateCard(false)}
      >
        <Payment
          amount={amount}
          callback={() => {
            setShowUpdateCard(false);
          }}
          couponSelected={couponSelected}
          currentPlan={currentPlan}
          handleAgreement={handleAgreement}
          handleCouponSelected={handleCouponSelected}
          isActionUpdateCard={isActionUpdateCard}
          isAgree={isAgree}
          planSwitcher={planSwitcher}
          plans={plans}
          subscriptionDataHandler={subscriptionDataHandler}
          summaryDetails={summaryDetails}
          isUpdateCard
        />
      </Modal>
    );
  };

  const confirmCancelSubscription = () => {
    Modal.confirm({
      title: 'Confirm Cancel Subscription',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure to cancel the subscription?',
      okText: 'Yes I am Sure',
      cancelText: 'No, cancel it!',
      centered: true,
      okType: 'danger',
      onOk: () => handleCancelSubscription(),
    });
  };

  const closeAccount = () => {
    putDisableUser(String(props.whoamiData.id)).then((response: { status: string }) => {
      if (response.status === 'done') {
        deleteCancelSubscription('').then(() => {
          logout();
        });
      }
    });
  };

  return (
    <Fragment>
      {renderUpdatePlan()}
      {renderUpdateCard()}
      <Row justify="center">
        <Col lg={20} md={24} sm={24} xs={24}>
          <h1 className={styles.header}>My Profile</h1>
          <div className={styles.profileInfo}>
            <Spin className={styles.loading} spinning={isLoading}>
              <Row>
                <Col className={styles.leftContent} lg={12} md={12} sm={24} xs={24}>
                  <h1>Account Information</h1>
                  {whoamiData && (
                    <>
                      {whoamiData.name && <p>Name: {whoamiData.name}</p>}
                      <p>Username: {whoamiData.username || '-'}</p>
                      <p>Email: {whoamiData.email || '-'}</p>
                    </>
                  )}
                </Col>
                <Col className={styles.buttons} lg={12} md={12} sm={24} xs={24}>
                  <Space direction="vertical">
                    <Link to="/user/edit">
                      <Button size="middle" type="primary" block>
                        Update Account Information
                      </Button>
                    </Link>
                    <Link to="/user/password">
                      <Button size="middle" type="primary" block>
                        Update Password
                      </Button>
                    </Link>

                    <Button
                      size="middle"
                      type="primary"
                      block
                      onClick={async () => {
                        const result = await putUpdateTemplateSettings('', {
                          template_settings: '',
                        });

                        if (result) {
                          message.success('Logo & title block position successfully reseted.');

                          setTimeout(() => {
                            window.location.reload();
                          }, 1000);
                        }
                      }}
                      disabled={whoamiData?.template_settings === ''}
                    >
                      Reset Logo & Title Block Position
                    </Button>

                    <Button
                      size="middle"
                      type="primary"
                      block
                      onClick={async () => {
                        const result = await putUpdateTitleBlockSchema('', {
                          title_block_schema: '',
                        });

                        if (result) {
                          message.success('Title block schema data successfully reseted.');

                          setTimeout(() => {
                            window.location.reload();
                          }, 1000);
                        }
                      }}
                      disabled={whoamiData?.title_block_schema === ''}
                    >
                      Reset Title Block Schema Data
                    </Button>
                  </Space>
                </Col>
              </Row>
            </Spin>
          </div>
          {isTenant === false && (
            <div className={`${styles.profileInfo} ${styles.planInfo}`}>
              <Spin className={styles.loading} spinning={isLoading}>
                {isAdmin
                  ? 'You are an admin, no need to subscribe!'
                  : paymentStatus !== 'Paid' && (
                      <Row>
                        <Col className={styles.leftContent} lg={12} md={12} sm={24} xs={24}>
                          <h1>Subscription Information</h1>
                          <p>{getTrialStatus()}</p>
                        </Col>
                        <Col span={24}>
                          <Payment
                            amount={amount}
                            couponSelected={couponSelected}
                            currentPlan={currentPlan}
                            handleAgreement={handleAgreement}
                            handleCouponSelected={handleCouponSelected}
                            isAgree={isAgree}
                            planSwitcher={planSwitcher}
                            plans={plans}
                            subscriptionDataHandler={subscriptionDataHandler}
                            summaryDetails={summaryDetails}
                          />
                        </Col>
                      </Row>
                    )}
                {paymentStatus === 'Paid' && data.plan?.id && (
                  <Spin className={styles.loading} spinning={isLoading}>
                    <Row>
                      <Col className={styles.leftContent} lg={12} md={12} sm={24} xs={24}>
                        <h1>Subscription Information</h1>
                        <p>Current Plan: {currentPlan.nickname}</p>
                        {data.customer_balance > 0 && (
                          <p>
                            Balance After Downgrade: ${data.customer_balance} (the amount will be
                            refunded in 10 business days)
                          </p>
                        )}
                        <p>Last Charged: {data.last_charge_date}</p>
                        <p>Amount Charged: {data.last_charge_amount}</p>
                        <p>Plan Expired: {data.next_charge_date}</p>
                      </Col>
                      <Col className={styles.buttons} lg={12} md={12} sm={24} xs={24}>
                        <Space direction="vertical">
                          {/* Disable since we only have 1 plan, enable it if we have more than 1 plans  */}
                          {/* <Button type="primary" onClick={() => setShowUpdatePlan(true)}>
                        Update Plan
                      </Button> */}
                          <Button type="primary" onClick={() => setShowUpdateCard(true)}>
                            Update Credit Card
                          </Button>
                          <Button type="default" onClick={() => confirmCancelSubscription()}>
                            Cancel Subscription
                          </Button>
                        </Space>
                      </Col>
                    </Row>
                  </Spin>
                )}
              </Spin>
            </div>
          )}
          {isTenant === false && (
            <div className={`${styles.profileInfo} ${styles.statusInfo}`}>
              <Row>
                <Col className={styles.leftContent} lg={12} md={12} sm={24} xs={24}>
                  <p>Account status: Open</p>
                </Col>
                <Col className={styles.buttons} lg={12} md={12} sm={24} xs={24}>
                  <Button size="middle" type="default" onClick={() => closeAccount()}>
                    Close Account
                  </Button>
                </Col>
              </Row>
            </div>
          )}
        </Col>
      </Row>
    </Fragment>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    whoamiData: state.data.whoami.data,
    isLoading: isStateLoading(
      state.action,
      'whoami',
      'plans',
      'subscription',
      'createSubscription',
      'updateSubscription',
      'cancelSubscription',
      'putUpdateTemplateSettings',
    ),
    isTenant: state.data.tenantConfig.isTenant,
  };
};

const mapDispatchToProps = {
  snackBarOpen,
};
export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
