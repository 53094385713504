import { Button, Input, message } from 'antd';
import React, { useEffect, useState } from 'react';

export const CopyInput = () => {
  const [text, setText] = useState('Copy me!');

  useEffect(() => {
    setText(window.location.href);
  }, []);

  const handleCopy = () => {
    navigator.clipboard.writeText(text).then(
      function () {
        message.success('Copying to clipboard was successful!');
      },
      function (err) {
        message.error('Could not copy text: ', err);
      },
    );
  };

  return (
    <Input.Group compact>
      <Input style={{ width: 'calc(100% - 65.57px)' }} value={text} disabled />
      <Button type="primary" onClick={handleCopy}>
        Copy
      </Button>
    </Input.Group>
  );
};
