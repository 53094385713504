import { Action, ActionCreator } from 'redux';

import { ErrorDetails } from './../../../api/HttpError';
import { SUCCESS, ERROR } from './../../../constants/index';

export enum SnackBarActionTypes {
  OPEN = 'SET_SNACKBAR_OPEN',
  CLOSE = 'SET_SNACKBAR_CLOSE',
}

// ACTIONS
export type SnackBarOpen = {
  message: string;
  status: string;
  delay: number;
} & Action<SnackBarActionTypes.OPEN>;

export type SnackBarClose = Action<SnackBarActionTypes.CLOSE>;

// ACTION CREATORS
export const snackBarOpen: ActionCreator<SnackBarOpen> = (
  message: string,
  status: string = SUCCESS,
  error?: ErrorDetails,
  delay = 600,
) => {
  if (status === ERROR && error) {
    return {
      type: SnackBarActionTypes.OPEN,
      message: `${message}: ${error.what}`,
      status,
      delay,
    };
  }

  return {
    type: SnackBarActionTypes.OPEN,
    message,
    status,
    delay,
  };
};

export const snackBarClose: ActionCreator<SnackBarClose> = () => ({
  type: SnackBarActionTypes.CLOSE,
});
