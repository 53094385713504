import { mxGraph, StyleMap } from '@anekonnect/mxgraph';
import { getColumnWidth, sumColumnWidths, TableColumnSchema } from '../TableHelper';
import { mx } from '~/constants/wizard';
import { Point } from '~/store/reducers/wizard/State';
import moment from 'moment';

type BottomToTopTableTemplateProps<TData> = {
  graph: mxGraph;
  tablePrefix: string;
  tableConfigJson: any;
  data: TData[];
  position: Point;
  showTitle?: boolean;
};

const BottomToTopTableTemplate = <TData,>({
  graph,
  tablePrefix,
  tableConfigJson,
  data,
  position,
  showTitle = true,
}: BottomToTopTableTemplateProps<TData>) => {
  const found = graph.getModel().getCell(tablePrefix);

  // if table already exists, remove it
  if (found) {
    graph.getModel().remove(found);
  }

  if (data === undefined || data.length === 0) {
    return;
  }

  const columnList: TableColumnSchema = tableConfigJson.columnList;
  const tableWidth = sumColumnWidths(columnList, data);
  const rowHeight = parseInt(tableConfigJson.table.rowHeight);
  const defaultColumnStyles =
    'shape=partialRectangle;html=1;whiteSpace=wrap;connectable=0;fillColor=none;top=0;left=0;bottom=0;right=0;overflow=hidden;pointerEvents=1;columnLock;rounded=0;strokeColor=#000;';

  const countData = data.length + 1 + (showTitle ? 1 : 0); // Adjust countData based on showTitle

  const parent = graph.getDefaultParent();
  graph.getModel().beginUpdate();

  try {
    const tableLockStyle: StyleMap = {};
    tableLockStyle[mx.mxConstants.STYLE_RESIZABLE] = '0';
    tableLockStyle[mx.mxConstants.STYLE_ROTATABLE] = '0';
    tableLockStyle[mx.mxConstants.STYLE_EDITABLE] = '0';
    tableLockStyle[mx.mxConstants.STYLE_DELETABLE] = '0';
    graph.getStylesheet().putCellStyle('tableLock', tableLockStyle);

    const columnLockStyle: StyleMap = {};
    columnLockStyle[mx.mxConstants.STYLE_RESIZABLE] = '0';
    columnLockStyle[mx.mxConstants.STYLE_MOVABLE] = '0';
    columnLockStyle[mx.mxConstants.STYLE_ROTATABLE] = '0';
    columnLockStyle[mx.mxConstants.STYLE_EDITABLE] = '0';
    columnLockStyle[mx.mxConstants.STYLE_DELETABLE] = '0';
    graph.getStylesheet().putCellStyle('columnLock', columnLockStyle);

    const table = graph.insertVertex(
      parent,
      tablePrefix,
      '',
      position.x,
      position.y - rowHeight * countData,
      tableWidth,
      rowHeight * countData,
      'shape=table;startSize=40;container=1;collapsible=0;childLayout=tableLayout;fontStyle=1;align=center;pointerEvents=1;connectable=0;allowArrows=0;rounded=0;tableLock;strokeColor=#000;',
    );
    table.setVertex(true);
    table.setConnectable(false);

    if (showTitle) {
      const tableTitle = graph.insertVertex(
        table,
        `${tablePrefix}-title`,
        tableConfigJson.title.value,
        0,
        rowHeight * (countData - 1),
        tableWidth,
        rowHeight,
        `shape=partialRectangle;html=1;whiteSpace=wrap;connectable=0;fillColor=none;top=0;left=0;bottom=0;right=0;overflow=hidden;pointerEvents=1;fontStyle=1;columnLock;rounded=0;strokeColor=#000;fontSize=${tableConfigJson.title.fontSize};fontColor=${tableConfigJson.title.fontColor}`,
      );
      tableTitle.setVertex(true);
      tableTitle.setConnectable(false);
    }

    // Calculate the y-position for the first row
    const firstRowY = showTitle ? rowHeight * (countData - 2) : rowHeight * (countData - 1);
    const firstRow = graph.insertVertex(
      table,
      `${tablePrefix}-first-row`,
      '',
      0,
      firstRowY,
      tableWidth,
      rowHeight,
      'shape=partialRectangle;html=1;whiteSpace=wrap;collapsible=0;dropTarget=0;pointerEvents=0;fillColor=none;top=0;left=0;bottom=0;right=0;points=[[0,0.5],[1,0.5]];portConstraint=eastwest;columnLock;rounded=0;strokeColor=#000;',
    );
    firstRow.setVertex(true);
    firstRow.setConnectable(false);

    Object.entries(columnList).forEach(([key, field], index) => {
      const fieldVertex = graph.insertVertex(
        firstRow,
        `${tablePrefix}-field-${key}`,
        field?.title,
        sumColumnWidths(columnList, data, index - 1),
        0,
        getColumnWidth(data, field),
        rowHeight,
        `${defaultColumnStyles}fontSize=${field.fontSize};fontColor=${field.fontColor};`,
      );
      fieldVertex.setVertex(true);
      fieldVertex.setConnectable(false);
    });

    data.forEach((rowData, dataIndex) => {
      const rowTableY = rowHeight * (countData - 2 - (showTitle ? 1 : 0) - dataIndex);
      const rowTable = graph.insertVertex(
        table,
        `${tablePrefix}-row-${dataIndex}`,
        '',
        0,
        rowTableY,
        tableWidth,
        rowHeight,
        'shape=partialRectangle;html=1;whiteSpace=wrap;collapsible=0;dropTarget=0;pointerEvents=0;fillColor=none;top=0;left=0;bottom=0;right=0;points=[[0,0.5],[1,0.5]];portConstraint=eastwest;columnLock;rounded=0;strokeColor=#000;',
      );
      rowTable.setVertex(true);
      rowTable.setConnectable(false);

      Object.entries(columnList).forEach(([key, field], columnIndex) => {
        const dataKey = field.dataKey as keyof TData;
        let fieldData = rowData[dataKey];

        if (field.dataType === 'date') {
          fieldData = moment(fieldData as string).format('LL') as TData[keyof TData];
        }

        const fieldVertexValue = graph.insertVertex(
          rowTable,
          `${tablePrefix}-value-${key}-${dataIndex}`,
          String(fieldData || field.defaultData),
          sumColumnWidths(columnList, data, columnIndex - 1),
          0,
          getColumnWidth(data, field),
          rowHeight,
          `${defaultColumnStyles}fontSize=${field.fontSize};fontColor=${field.fontColor};`,
        );
        fieldVertexValue.setVertex(true);
        fieldVertexValue.setConnectable(false);
      });
    });
  } finally {
    graph.getModel().endUpdate();
  }

  return null;
};

export default BottomToTopTableTemplate;
