import { ActionCreator } from 'redux';

import { ApiRequest, ApiSuccess, ApiFailure } from '../Api';

// ACTION TYPES
export enum AuthActionTypes {
  LOGIN_USER_REQUEST = 'LOGIN_USER_REQUEST',
  LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE',
  LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS',
  OAUTH_USER_REQUEST = 'OAUTH_USER_REQUEST',
  OAUTH_USER_SUCCESS = 'OAUTH_USER_SUCCESS',
  OAUTH_USER_FAILURE = 'OAUTH_USER_FAILURE',
  LOGOUT_USER_REQUEST = 'LOGOUT_USER_REQUEST',
  LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS',
  LOGOUT_USER_FAILURE = 'LOGOUT_USER_FAILURE',
}

// ACTIONS
export type LoginUserRequest = ApiRequest<AuthActionTypes.LOGIN_USER_REQUEST>;

export type LoginUserSuccess = {
  token: string;
  expiresAt: string;
} & ApiSuccess<AuthActionTypes.LOGIN_USER_SUCCESS>;

export type LoginUserFailure = ApiFailure<AuthActionTypes.LOGIN_USER_FAILURE>;

export type OAuthUserRequest = ApiRequest<AuthActionTypes.OAUTH_USER_REQUEST>;

export type OAuthUserSuccess = {
  authCodeURL: string;
} & ApiSuccess<AuthActionTypes.OAUTH_USER_SUCCESS>;

export type OAuthUserFailure = ApiFailure<AuthActionTypes.OAUTH_USER_FAILURE>;

export type LogoutUserRequest = ApiRequest<AuthActionTypes.LOGOUT_USER_REQUEST>;

export type LogoutUserSuccess = ApiSuccess<AuthActionTypes.LOGOUT_USER_SUCCESS>;

export type LogoutUserFailure = ApiFailure<AuthActionTypes.LOGOUT_USER_FAILURE>;

// ACTION CREATORS
export const loginRequest: ActionCreator<LoginUserRequest> = () => ({
  type: AuthActionTypes.LOGIN_USER_REQUEST,
});

export const loginSuccess: ActionCreator<LoginUserSuccess> = (
  token: string,
  expiresAt: string,
) => ({
  type: AuthActionTypes.LOGIN_USER_SUCCESS,
  token,
  expiresAt,
  data: undefined,
});

export const loginFailure: ActionCreator<LoginUserFailure> = (
  status: string,
  statusText: string,
  code: string,
) => ({
  type: AuthActionTypes.LOGIN_USER_FAILURE,
  status,
  statusText,
  code,
});

export const oauthRequest: ActionCreator<OAuthUserRequest> = () => ({
  type: AuthActionTypes.OAUTH_USER_REQUEST,
});

export const oauthSuccess: ActionCreator<OAuthUserSuccess> = (authCodeURL: string) => ({
  type: AuthActionTypes.OAUTH_USER_SUCCESS,
  authCodeURL,
  data: undefined,
});

export const oauthFailure: ActionCreator<OAuthUserFailure> = (
  status: string,
  statusText: string,
  code: string,
) => ({
  type: AuthActionTypes.OAUTH_USER_FAILURE,
  status,
  statusText,
  code,
});

export const logoutRequest: ActionCreator<LogoutUserRequest> = () => ({
  type: AuthActionTypes.LOGOUT_USER_REQUEST,
});

export const logoutSuccess: ActionCreator<LogoutUserSuccess> = () => ({
  type: AuthActionTypes.LOGOUT_USER_SUCCESS,
  data: undefined,
});

export const logoutFailure: ActionCreator<LogoutUserFailure> = (
  status: string,
  statusText: string,
  code: string,
) => ({
  type: AuthActionTypes.LOGOUT_USER_FAILURE,
  status,
  statusText,
  code,
});
