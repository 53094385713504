import { mxCell, mxGraph } from '@anekonnect/mxgraph';
import { Form, Modal, Input } from 'antd';
import React, { useEffect } from 'react';

import { redrawLabels } from '../../Helper';

type PenetratorPinModalProps = {
  graph: mxGraph;
  isOpen: boolean;
  handleClose: () => void;
  cell: mxCell | null;
};

const PenetratorPinModal = ({ graph, isOpen, handleClose, cell }: PenetratorPinModalProps) => {
  const [form] = Form.useForm();

  useEffect(() => {
    let value = null;

    if (cell) {
      const pinLeft = cell.id.includes('pin_left');
      const pinRight = cell.id.includes('pin_right');

      if (pinLeft) {
        const portLeftId = cell.id.replace('penetrator_pin_left', 'port_left');
        const portLeft = cell.parent.children.find((child) => child.id.includes(portLeftId));

        if (portLeft) {
          value = portLeft.value;
        }
      }

      if (pinRight) {
        const portRightId = cell.id.replace('penetrator_pin_right', 'port_right');
        const portRight = cell.parent.children.find((child) => child.id.includes(portRightId));

        if (portRight) {
          value = portRight.value;
        }
      }
    }

    form.setFieldsValue({
      labelValue: value,
    });
  }, [cell, form]);

  const handleOk = () => {
    const { labelValue } = form.getFieldsValue();

    if (cell) {
      const pinLeft = cell.id.includes('pin_left');
      const pinRight = cell.id.includes('pin_right');

      if (pinLeft) {
        const portLeftId = cell.id.replace('penetrator_pin_left', 'port_left');
        const portLeft = cell.parent.children.find((child) => child.id.includes(portLeftId));

        if (portLeft) {
          portLeft.valueChanged(labelValue);
          redrawLabels(graph, [portLeft]);
        }
      }

      if (pinRight) {
        const portRightId = cell.id.replace('penetrator_pin_right', 'port_right');
        const portRight = cell.parent.children.find((child) => child.id.includes(portRightId));

        if (portRight) {
          portRight.valueChanged(labelValue);
          redrawLabels(graph, [portRight]);
        }
      }
    }

    handleClose();
  };

  return (
    <Modal title="Edit Pin Label" open={isOpen} forceRender onCancel={handleClose} onOk={handleOk}>
      <Form form={form} layout="vertical">
        <Form.Item label="Label Value" name="labelValue">
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default PenetratorPinModal;
