import { Button, Tooltip } from 'antd';
import { useCallback, useEffect, useRef, useState } from 'react';
import { DeleteOutlined, FormOutlined } from '@ant-design/icons';
import { mxCell, mxGraph, mxPoint } from '@anekonnect/mxgraph';

import { useAppSelector } from '~/store/hooks';

import { mx } from '~/constants/wizard';
import { ConfigProp } from '~/store/reducers/configs';

type ActionButtonsProps = {
  graph: mxGraph;
  currentEdge: mxCell | null;
  onEdit?: () => void;
  onDelete?: () => void;
};

type SingleProp = {
  onClick?: () => void;
};

const Editable = (props: SingleProp & Pick<ConfigProp, 'editable'>) => {
  if (!props.editable) return null;

  return (
    <Tooltip title="Edit">
      <Button icon={<FormOutlined />} type="primary" onClick={props.onClick} />
    </Tooltip>
  );
};

const Deletable = (props: SingleProp & Pick<ConfigProp, 'deleteable'>) => {
  if (!props.deleteable) return null;

  return (
    <Tooltip title="Delete">
      <Button icon={<DeleteOutlined />} type="primary" onClick={props.onClick} />
    </Tooltip>
  );
};

const ActionButtons = ({ onEdit, onDelete, currentEdge, graph }: ActionButtonsProps) => {
  const actionRef = useRef<HTMLDivElement | null>(null);
  const showWireControl = useAppSelector((state) => state.assemblyWizard.showWireControl);

  const [state, setState] = useState({
    editable: false,
    deleteable: false,
  });

  useEffect(() => {
    const findPointWithLowestY = (points: mxPoint[]) => {
      // Initialize with the first point in the array
      const initialPoint = points[0];

      // Use reduce to find the point with the lowest y value
      const pointWithLowestY = points.reduce((minPoint, currentPoint) => {
        return currentPoint.y < minPoint.y ? currentPoint : minPoint;
      }, initialPoint);

      return pointWithLowestY;
    };

    if (showWireControl && currentEdge) {
      const cellState = graph.view.getState(currentEdge);
      const { x, y } = findPointWithLowestY(cellState.absolutePoints);

      if (actionRef.current) {
        const edgeHeight = mx.mxConstants.EDGE_SELECTION_STROKEWIDTH;
        const actionDOMHeight = 32;

        actionRef.current.style.position = 'absolute';
        actionRef.current.style.left = `${String(x)}px`;
        actionRef.current.style.top = `${String(y - actionDOMHeight - edgeHeight - 10)}px`;
      }
    }
  }, [currentEdge, actionRef, showWireControl, graph.view]);

  const handleActionVisible = useCallback(() => {
    if (showWireControl && currentEdge) {
      const isDrawingTools = currentEdge.id.includes('drawing_tools');
      const isSourceDrawingTools = currentEdge.source
        ? currentEdge.source.id.includes('drawing_tools')
        : false;

      setState({
        editable: isDrawingTools || isSourceDrawingTools ? false : true,
        deleteable: true,
      });
    }
  }, [currentEdge, showWireControl]);

  useEffect(handleActionVisible, [handleActionVisible]);

  if (!showWireControl) return null;

  return (
    <div ref={actionRef}>
      <Button.Group>
        <Editable editable={state.editable} onClick={onEdit} />
        <Deletable deleteable={state.deleteable} onClick={onDelete} />
      </Button.Group>
    </div>
  );
};

export default ActionButtons;
