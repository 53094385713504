import React from 'react';
import { FieldTemplateProps } from '@anekonnect/react-jsonschema-form';

const showDescription = false;
const CustomFieldTemplate = ({
  id,
  classNames,
  label,
  help,
  hidden,
  required,
  description,
  errors,
  children,
}: FieldTemplateProps) => {
  if (hidden) {
    return <div />;
  }

  return (
    <div className={classNames}>
      <label className="control-label" htmlFor={id}>
        {label}
        {required ? '*' : null}
      </label>
      <div className="field-content-input">
        {showDescription && description}
        {children}
        {showDescription && errors}
        {showDescription && help}
      </div>
    </div>
  );
};

export default CustomFieldTemplate;
