import { mxGraph } from '@anekonnect/mxgraph';
import React from 'react';

import DrawingTools from './DrawingTools';
import HiddenComponentList from './HiddenComponentList';

type LeftSidebarProps = {
  graph: mxGraph;
};

const LeftSidebar = ({ graph }: LeftSidebarProps) => {
  return (
    <>
      <DrawingTools graph={graph} />
      <HiddenComponentList graph={graph} />
    </>
  );
};

export default LeftSidebar;
