import { Modal } from 'antd';
import React, { useCallback } from 'react';
import { wizardSetShowComponentControl } from '~/store/actions/wizard/Component';
import { useAppDispatch, useAppSelector } from '~/store/hooks';
import { getComponentsNameFromCells } from '~/utils/component';

import { Void } from '../Types';

import { deleteCells } from './Helper';
import { GraphProps } from './Types';

type ControlProps = {
  children: (fn: Record<string, Void>) => React.ReactNode;
} & Pick<GraphProps, 'graph'>;

const Control = ({ graph, children }: ControlProps) => {
  const dispatch = useAppDispatch();
  const components = useAppSelector((state) => state.assemblyWizard.components);
  const parts = useAppSelector((state) => state.data.tenantConfig.data?.parts);

  const deleteSelectionCell = useCallback(() => {
    if (graph && graph.isEnabled()) {
      const cells = graph.getSelectionCells();
      const listPartsKey = parts?.map((part) => part.name) || [];
      const componentsName = getComponentsNameFromCells(cells, components, listPartsKey);

      const deleteHandler = () => {
        deleteCells(graph, graph.getDeletableCells(graph.getSelectionCells()));

        dispatch(wizardSetShowComponentControl(false));
      };

      if (componentsName.length === 0) {
        Modal.confirm({
          title: 'Are you sure you want to delete the selected component?',
          onOk() {
            deleteHandler();
          },
          okText: 'Yes',
        });
      } else {
        Modal.confirm({
          title: 'Are you sure you want to delete the selected component?',
          content: (
            <div>
              <p>
                The following components will be deleted:
                <br />
                {componentsName.map((name, key) => (
                  <>
                    <b>
                      {key + 1 + '.'} <span key={name}>{name}</span>
                    </b>
                    <br />
                  </>
                ))}
              </p>
              <p>Do you want to continue?</p>
            </div>
          ),
          onOk() {
            deleteHandler();
          },
          okText: 'Yes',
        });
      }
    }
  }, [graph, parts, components, dispatch]);

  return <React.Fragment children={children({ deleteSelectionCell })} />;
};

export default Control;
