import { Button, Checkbox, Form, Input, Modal } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { ActionCreator } from 'redux';

import { requestDownloadSVGFiles } from '~/api/AuthorizedGets';
import { REQUEST_DOWNLOAD_SVG_FILES_SUCCESS_RESPONSE } from '~/constants';
import { LINK_PRIVACY_POLICY } from '~/constants/paths';
import { SnackBarOpen, snackBarOpen } from '~/store/actions/ui/SnackBar';
import { AppState } from '~/store/reducers';
import { PartItem } from '~/types';

type PropsFromState = {
  authenticated: boolean;
};

type PropsFromDispatch = {
  snackBarOpen: ActionCreator<SnackBarOpen>;
};

type PartsDownloadSVGModalProps = {
  item?: PartItem;
  isOpen: boolean;
  handleCancel: () => void;
} & PropsFromState &
  PropsFromDispatch;

const PartsDownloadSVGModal: React.FC<PartsDownloadSVGModalProps> = (props) => {
  const { item } = props;
  const [form] = Form.useForm();
  const [isAgree, setIsAgree] = React.useState<boolean>(false);

  const handleCancel = () => {
    props.handleCancel();
  };

  const handleRequestDownload = () => {
    form.validateFields().then((values: { email: string }) => {
      requestDownloadSVGFiles(`${item?.url_identifier}/${values.email}`).then(() => {
        props.snackBarOpen(REQUEST_DOWNLOAD_SVG_FILES_SUCCESS_RESPONSE);
        handleCancel();
        form.resetFields();
      });
    });
  };

  const renderFooter = () => {
    return (
      <Button disabled={!isAgree} type="primary" onClick={handleRequestDownload}>
        Receive Files
      </Button>
    );
  };

  return (
    <Modal
      footer={[renderFooter()]}
      maskClosable={false}
      title="Receive Visio(TM) Compatible Files"
      open={props.isOpen}
      width={530}
      onCancel={handleCancel}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          name="email"
          rules={[
            {
              type: 'email',
            },
          ]}
        >
          <Input placeholder="Email Address" />
        </Form.Item>
      </Form>
      <Checkbox style={{ marginBottom: '-20px' }} onChange={(e) => setIsAgree(e.target.checked)}>
        I agree to <a href="https://anekonnect.io/termsandconditions">terms and conditions</a>{' '}
        and&nbsp;
        <a href={LINK_PRIVACY_POLICY}>privacy policy</a> of Anekonnect Inc
      </Checkbox>
    </Modal>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    authenticated: state.data.auth.authenticated,
  };
};

const mapDispatchToProps = {
  snackBarOpen,
};

export default connect(mapStateToProps, mapDispatchToProps)(PartsDownloadSVGModal);
