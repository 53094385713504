import { useEffect, useState, ReactNode } from 'react';
import { Layout as AntLayout } from 'antd';

import Header from './Header';
import styles from './Layout.module.scss';
import { useLocation } from 'react-router-dom';

const { Content, Footer } = AntLayout;

type LayoutProps = {
  children: ReactNode;
};

const Layout = ({ children }: LayoutProps) => {
  const currentDate = new Date();
  const location = useLocation();
  const [isAssemblyWizard, setIsAssemblyWizard] = useState(false);

  useEffect(() => {
    if (location.pathname.includes('/assemblies/wizard')) {
      setIsAssemblyWizard(true);
    } else {
      setIsAssemblyWizard(false);
    }
  }, [location]);

  return (
    <AntLayout>
      <Header />
      <Content className={styles.wrapper}>
        <div className={styles.content}>{children}</div>
      </Content>
      <Footer style={{ textAlign: 'center', display: `${isAssemblyWizard ? 'none' : 'block'}` }}>
        &#169;Anekonnect {currentDate.getFullYear()}. All Rights Reserved
      </Footer>
    </AntLayout>
  );
};

export default Layout;
