import { Col, Row, Spin } from 'antd';
import { JSONSchema7 } from 'json-schema';
import { connect } from 'react-redux';
import { ActionCreator } from 'redux';

import styles from './EditProfile.module.scss';

import { getWhoami } from '~/api/AuthorizedGets';
import { putUpdateUser } from '~/api/AuthorizedPuts';
import { FormSchema } from '~/components/FormSchema/FormSchema';
import { snackBarOpen, SnackBarOpen } from '~/store/actions/ui/SnackBar';
import { AppState } from '~/store/reducers';
import { AuthState } from '~/store/reducers/data/Auth';
import { isStateLoading } from '~/utils/state';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

type PropsFromState = {
  auth: AuthState;
  whoamiData: any;
  userSchema: JSONSchema7;
  adminSchema: JSONSchema7;
  isAdmin: boolean;
  isLoading: boolean;
};

type EditProfileDispatchProps = {
  snackBarOpen: ActionCreator<SnackBarOpen>;
};

type EditProfileProps = PropsFromState & EditProfileDispatchProps;

const EditProfile = ({
  whoamiData,
  userSchema,
  adminSchema,
  isAdmin,
  isLoading,
}: EditProfileProps) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});

  useEffect(() => {
    setFormData(whoamiData);
  }, [whoamiData]);

  const handleSubmit = async (values: any, files?: any) => {
    let logo = {};
    let template = {};

    if (files) {
      if (files.root_logo) {
        logo = {
          id: 'logo',
          data: files.root_logo,
        };
      }

      if (files.root_template) {
        template = {
          id: 'template',
          data: files.root_template,
        };
      }
    }

    await putUpdateUser(values.formData, logo, template);
    snackBarOpen('Profile updated successfully');
    await getWhoami();
    navigate('/user/profile');
  };

  const handleCancel = () => {
    navigate('/user/profile');
  };

  return (
    <Spin className={styles.loading} spinning={isLoading}>
      <Row justify="center">
        <Col lg={20} md={24} sm={24} xs={24}>
          <h1 className={styles.header}>Edit Profile</h1>

          {!isAdmin && formData && userSchema && (
            <FormSchema
              arrayFieldTemplate="form"
              cancelAction={handleCancel}
              confirmAction={handleSubmit}
              formData={formData}
              schema={userSchema}
              uiSchema={{
                username: {
                  'ui:disabled': true,
                },
                logo: {
                  'ui:options': {
                    accept: '.png, .jpg, .jpeg',
                  },
                },
                template: {
                  'ui:options': {
                    accept: '.svg',
                  },
                },
              }}
              onChange={(data) => {
                if (data.formData.id) {
                  setFormData(data.formData);
                }
              }}
            />
          )}
          {isAdmin && formData && adminSchema && (
            <FormSchema
              arrayFieldTemplate="form"
              cancelAction={handleCancel}
              confirmAction={handleSubmit}
              formData={formData}
              schema={adminSchema}
              uiSchema={{
                username: {
                  'ui:disabled': true,
                },
              }}
              onChange={(data) => setFormData(data.formData)}
            />
          )}
        </Col>
      </Row>
    </Spin>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    whoamiData: state.data.whoami.data,
    userSchema: state.data.tenantConfig.data
      ? JSON.parse(state.data.tenantConfig.data.user_schema)
      : {},
    adminSchema: state.data.tenantConfig.data
      ? JSON.parse(state.data.tenantConfig.data.admin_schema)
      : {},
    isAdmin:
      state.data.whoami && state.data.whoami.data ? state.data.whoami.data.type === 'Admin' : false,
    isLoading: isStateLoading(state.action, 'whoami', 'updateUser'),
  };
};

export default connect(mapStateToProps, { snackBarOpen })(EditProfile);
