import { mxCell, mxGraph, StyleMap } from '@anekonnect/mxgraph';
import { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';

import { mx } from '~/constants/wizard';
import { wizardSetDrawingNotesPosition } from '~/store/actions/wizard/Action';
import { AppState } from '~/store/reducers';
import { DrawingNotes } from '~/store/reducers/wizard/State';

type PropsFromState = {
  drawingNotes: DrawingNotes;
};

type PropsFromDispatch = {
  wizardSetDrawingNotesPosition: typeof wizardSetDrawingNotesPosition;
};

type DrawingNotesTableProps = {
  graph: mxGraph;
} & PropsFromState &
  PropsFromDispatch;

const DrawingNotesTable = ({
  graph,
  drawingNotes,
  wizardSetDrawingNotesPosition,
}: DrawingNotesTableProps) => {
  const tablePrefix = 'drawing-notes-table';

  const addTable = useCallback(() => {
    const found = graph.getModel().getCell(tablePrefix);

    // if table already exists, remove it
    if (found) {
      graph.getModel().remove(found);
    }

    if (drawingNotes.data === undefined || drawingNotes.data.length === 0) {
      return;
    }

    const countData = drawingNotes.data.length;

    const parent = graph.getDefaultParent();
    graph.getModel().beginUpdate();

    try {
      const tableLockStyle: StyleMap = {};
      tableLockStyle[mx.mxConstants.STYLE_RESIZABLE] = '0';
      tableLockStyle[mx.mxConstants.STYLE_ROTATABLE] = '0';
      tableLockStyle[mx.mxConstants.STYLE_EDITABLE] = '0';
      tableLockStyle[mx.mxConstants.STYLE_DELETABLE] = '0';
      graph.getStylesheet().putCellStyle('tableLock', tableLockStyle);

      const columnLockStyle: StyleMap = {};
      columnLockStyle[mx.mxConstants.STYLE_RESIZABLE] = '0';
      columnLockStyle[mx.mxConstants.STYLE_MOVABLE] = '0';
      columnLockStyle[mx.mxConstants.STYLE_ROTATABLE] = '0';
      columnLockStyle[mx.mxConstants.STYLE_EDITABLE] = '0';
      columnLockStyle[mx.mxConstants.STYLE_DELETABLE] = '0';
      graph.getStylesheet().putCellStyle('columnLock', columnLockStyle);

      const nameWidth = 25;
      const descriptionWidth = 400;

      const tableWidth = nameWidth + descriptionWidth;

      const drawingNotesTable = graph.insertVertex(
        parent,
        tablePrefix,
        '',
        drawingNotes.position.x,
        drawingNotes.position.y,
        tableWidth,
        40 * countData,
        'shape=table;startSize=40;container=1;collapsible=0;childLayout=tableLayout;fontStyle=1;pointerEvents=1;connectable=0;allowArrows=0;rounded=0;tableLock;strokeColor=none;fillColor=none;',
      );
      drawingNotesTable.setVertex(true);

      drawingNotes.data.forEach((data, index: number) => {
        const rowTable = graph.insertVertex(
          drawingNotesTable,
          `${tablePrefix}-row-${index}`,
          '',
          0,
          40 * index,
          tableWidth,
          40,
          'shape=partialRectangle;html=1;whiteSpace=wrap;collapsible=0;dropTarget=0;pointerEvents=0;top=0;left=0;bottom=0;right=0;points=[[0,0.5],[1,0.5]];portConstraint=eastwest;columnLock;rounded=0;strokeColor=none;fillColor=none;',
        );
        rowTable.setVertex(true);

        const valueName = graph.insertVertex(
          rowTable,
          `${tablePrefix}-value-name-${index}`,
          data.name || '-',
          0,
          0,
          nameWidth,
          40,
          'shape=partialRectangle;html=1;whiteSpace=wrap;connectable=0;top=0;left=0;bottom=0;right=0;overflow=hidden;pointerEvents=1;columnLock;rounded=0;strokeColor=none;fillColor=none;align=left;',
        );
        valueName.setVertex(true);

        const valueDescription = graph.insertVertex(
          rowTable,
          `${tablePrefix}-value-date-${index}`,
          data.description || '-',
          nameWidth,
          0,
          descriptionWidth,
          40,
          'shape=partialRectangle;html=1;whiteSpace=wrap;connectable=0;top=0;left=0;bottom=0;right=0;overflow=hidden;pointerEvents=1;columnLock;rounded=0;strokeColor=none;fillColor=none;align=left;',
        );
        valueDescription.setVertex(true);
      });
    } finally {
      graph.getModel().endUpdate();
    }
  }, [graph, drawingNotes]);

  useEffect(addTable, [addTable]);

  // Save last position of Drawing Notes table
  useEffect(() => {
    graph.addListener(mx.mxEvent.CELLS_MOVED, (_sender, evt) => {
      const cells = evt.properties.cells;

      if (cells.length > 0) {
        cells.forEach((cell: mxCell) => {
          if (cell.id === tablePrefix) {
            wizardSetDrawingNotesPosition(cell.getGeometry());
          }
        });
      }
    });
  }, [graph, wizardSetDrawingNotesPosition]);

  return null;
};

const mapStateToProps = (state: AppState) => {
  return {
    drawingNotes: state.assemblyWizard.drawingNotes,
  };
};

const mapDispatchToProps = {
  wizardSetDrawingNotesPosition,
};

export default connect(mapStateToProps, mapDispatchToProps)(DrawingNotesTable);
