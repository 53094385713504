import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Carousel } from 'antd';
import React from 'react';
import { Document, Page } from 'react-pdf';
import { useWindowSize } from 'usehooks-ts';

import { FileType, Part } from '~/api/API';
import X3d from '~/components/X3d';
import { PartItem } from '~/types';

import styles from './PartsCaraousel.module.scss';

type PartCarouselProps = {
  itemData: PartItem;
  part: Part;
  supportsWebgl: boolean;
};

type PartAsset = {
  type: string;
  url: string;
  '-': string;
};

type RenderPDFProps = {
  item: PartAsset;
};

const x3dHeight = 450;
const x3dWidth = window.innerWidth <= 480 ? 480 : 535;

const getPartAssets = (part: Part, itemData: PartItem) => {
  return part.read_display_types.reduce((assets: any, key: string) => {
    if (itemData.getFileAssetObject(key)) {
      assets.push(itemData.getFileAssetObject(key));
    }

    return assets;
  }, []);
};

const generateImageTitle = (item: PartItem) => {
  let title = '';

  switch (item.domain) {
    case 'Subsea Connectors':
      title = `An underwater electrical connector belonging to ${item.subDomain} ${item.name} series`;
      break;
    default:
    //
  }

  return title;
};

const renderImage = (itemData: PartItem, part: Part, file?: FileType) => {
  const { name, domain } = itemData;
  let imgfileUrl: string | null;

  if (file) {
    imgfileUrl = itemData.getFileUrl(file.name);
  } else {
    imgfileUrl = itemData.getFileUrl(part.read_display_types);
  }

  if (!imgfileUrl) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <img alt="Generic Parts" src="/img/parts-generic.png" />
      </div>
    );
  }

  const title = generateImageTitle(itemData);

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <img alt={`${domain} ${name}`} height="300px" src={imgfileUrl} title={title} />
    </div>
  );
};

const renderXD = (
  itemData: any,
  x3dWidth: number,
  x3dHeight: number,
  fileUrl: string,
  jpegFileUrl: string,
) => {
  const { name } = itemData;

  return (
    <X3d
      fileUrl={fileUrl}
      height={x3dHeight}
      jpegFileUrl={jpegFileUrl}
      name={name}
      width={x3dWidth}
    />
  );
};

const renderDetailImage = (itemData: any, x3dWidth: number, x3dHeight: number, part: Part) => {
  const x3dUrl = itemData.getFileUrl('x3d');
  const pdfUrl = itemData.getFileUrl('pdf');

  if (x3dUrl || pdfUrl) {
    return renderXD(itemData, x3dWidth, x3dHeight, x3dUrl, pdfUrl);
  }

  return renderImage(itemData, part);
};

const RenderPDF = ({ item }: RenderPDFProps) => {
  const screen = useWindowSize();
  const screenWidth = screen?.width || 0;
  const [pageNumber, changePageNumber] = React.useState(1);
  const [numOfPages, setNumOfPages] = React.useState(0);

  const onDocumentLoad = ({ numPages }: { numPages: number }) => {
    setNumOfPages(numPages);
  };

  const togglePdfPages = (num: number) => {
    if (num === -1) {
      if (pageNumber > 1) {
        changePageNumber(pageNumber - 1);
      }
    } else if (num === 1) {
      if (pageNumber < numOfPages) {
        changePageNumber(pageNumber + 1);
      }
    }
  };

  return (
    <div className={styles.pdfWrapper}>
      <Document className="assembly-pdf" file={item.url} onLoadSuccess={onDocumentLoad}>
        <Page
          pageNumber={pageNumber}
          width={screenWidth < 768 ? screenWidth - 100 : screenWidth / 2}
        />
        <div className={styles.pdfPageWrapper}>
          <Button
            onClick={() => togglePdfPages(-1)}
            icon={<LeftOutlined />}
            disabled={pageNumber <= 1}
            type="text"
          />
          {pageNumber} of {numOfPages}
          <Button
            onClick={() => togglePdfPages(1)}
            icon={<RightOutlined />}
            disabled={pageNumber >= numOfPages}
            type="text"
          />
        </div>
      </Document>
    </div>
  );
};

const renderCarouselComponent = (part: Part, itemData: PartItem, supportsWebgl: boolean) => {
  const partAssets = getPartAssets(part, itemData);

  return partAssets.map((item: PartAsset) => {
    const file = part && part.file_types.find((fileType) => fileType.name === item.type);

    if (file) {
      switch (file.mime_type) {
        case 'model/x3d+xml':
          return (
            <>
              {supportsWebgl ? (
                <div>
                  {renderDetailImage(itemData, x3dWidth, x3dHeight, part)}
                  <div className="detail-fave item-card">
                    <p className="detail-txt pull-left">
                      CLICK & DRAG <br />
                      <span>360&#176; view</span>
                    </p>
                  </div>
                </div>
              ) : (
                <div>
                  <div className="converter-img clearfix">{renderImage(itemData, part)}</div>
                </div>
              )}
            </>
          );
        case 'application/pdf':
          return <RenderPDF item={item} />;
        default:
          return (
            <div>
              <div className="converter-img clearfix">{renderImage(itemData, part, file)}</div>
            </div>
          );
      }
    } else {
      return null;
    }
  });
};

const PartCarousel = (props: PartCarouselProps) => {
  const { itemData, supportsWebgl, part } = props;

  return <Carousel>{renderCarouselComponent(part, itemData, supportsWebgl)}</Carousel>;
};

export default PartCarousel;
