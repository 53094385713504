import { useEffect } from 'react';
import { Button, Form, Input, message } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { connect } from 'react-redux';

import { putCreateDiscussionTopic } from '~/api/AuthorizedPuts';
import { AppState } from '~/store/reducers';
import { getPaymentStatus, paymentStatusIsPaid } from '~/utils/helper';
import { DiscussionTopic } from '~/store/reducers/wizard/State';

const ACTIONS = ['ADD_TOPIC', 'ADD_REPLY', 'EDIT_TOPIC', 'EDIT_REPLY'] as const;
type ACTION = (typeof ACTIONS)[number];

type Values = { title: string; description?: string };

type PropsFromState = {
  assemblyId?: number;
  whoamiData: {
    payment_status?: string;
    type?: string;
  };
};

type EditorProps = {
  action: ACTION;
  setDiscussions: (discussions: DiscussionTopic[], scroll?: boolean) => void;
  topicId?: string;
  replyId?: string;
  data?: {
    title?: string;
    description?: string;
  };
  callback?: () => void;
} & PropsFromState;

const Editor = ({
  action,
  assemblyId,
  topicId,
  replyId,
  setDiscussions,
  data,
  callback,
  whoamiData,
}: EditorProps) => {
  const [form] = Form.useForm();
  const paymentStatus = getPaymentStatus(whoamiData);
  const isPaid = paymentStatusIsPaid(paymentStatus);

  useEffect(() => {
    form.setFieldsValue(data);
  }, [form, data]);

  const submitTopic = (values: Values) => {
    const topic = {
      title: values.title,
      description: values.description || '',
    };

    putCreateDiscussionTopic(`${assemblyId}/discussion-topic`, topic).then(
      (assembly: { discussions: string }) => {
        if (assembly.discussions) {
          message.success('Your topic added successfully');
          const scroll = true;
          setDiscussions(JSON.parse(assembly.discussions), scroll);
          form.resetFields();
        }
      },
    );
  };

  const submitReply = (value: string) => {
    const topicReply = {
      reply: value,
    };

    putCreateDiscussionTopic(`${assemblyId}/discussion-topic/${topicId}/reply`, topicReply).then(
      (assembly: { discussions: string }) => {
        if (assembly.discussions) {
          message.success('Your reply added successfully');
          setDiscussions(JSON.parse(assembly.discussions));
          form.resetFields();
        }
      },
    );
  };

  const editTopic = (values: Values) => {
    const topic = {
      title: values.title,
      description: values.description || '',
    };

    putCreateDiscussionTopic(`${assemblyId}/discussion-topic/${topicId}`, topic).then(
      (assembly: { discussions: string }) => {
        if (assembly.discussions) {
          message.success('Your topic edited successfully');
          setDiscussions(JSON.parse(assembly.discussions));

          if (callback) {
            callback();
          }

          form.resetFields();
        }
      },
    );
  };

  const editReply = (value: string) => {
    const topicReply = {
      reply: value,
    };

    putCreateDiscussionTopic(
      `${assemblyId}/discussion-topic/${topicId}/reply/${replyId}`,
      topicReply,
    ).then((assembly: { discussions: string }) => {
      if (assembly.discussions) {
        message.success('Your reply edited successfully');
        setDiscussions(JSON.parse(assembly.discussions));

        if (callback) {
          callback();
        }

        form.resetFields();
      }
    });
  };

  const onFinish = (values: Values) => {
    switch (action) {
      case 'ADD_TOPIC':
        submitTopic(values);
        break;
      case 'ADD_REPLY':
        if (values.description) {
          submitReply(values.description);
        } else {
          message.error('Please enter your reply');
        }
        break;
      case 'EDIT_TOPIC':
        editTopic(values);
        break;
      case 'EDIT_REPLY':
        if (values.description) {
          editReply(values.description);
        } else {
          message.error('Please enter your reply');
        }
        break;
    }
  };

  return (
    <Form form={form} initialValues={data} layout="vertical" name="editor" onFinish={onFinish}>
      {action.includes('TOPIC') && (
        <Form.Item
          name="title"
          rules={[{ required: true, message: 'Please input your topic discussion!' }]}
        >
          <Input placeholder="Topic Name" />
        </Form.Item>
      )}

      <Form.Item name="description">
        <TextArea
          placeholder={action.includes('TOPIC') ? 'Topic Description' : 'Your reply'}
          rows={4}
        />
      </Form.Item>
      <Form.Item>
        <Button disabled={!isPaid} htmlType="submit" type="primary">
          {action.includes('TOPIC') ? 'Submit' : 'Add Reply'}
        </Button>
      </Form.Item>
    </Form>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    assemblyId: state.assemblyWizard.id,
    whoamiData: state.data.whoami.data,
  };
};

export default connect(mapStateToProps)(Editor);
