import React from 'react';
import { Button, Tooltip } from 'antd';
import { AppState } from '~/store/reducers';
import { useAppSelector } from '~/store/hooks';

type RenderButtonProps = {
  title: string;
  icon: React.ReactNode;
  onClick: () => void;
  disabled?: boolean;
};

const RenderButton = ({ title, icon, onClick, disabled }: RenderButtonProps) => {
  const tenantConfig = useAppSelector((state: AppState) => state.data.tenantConfig.data);
  const primaryColor = tenantConfig?.ui_config?.primary_color || '#00D3B2';

  return (
    <Tooltip color={primaryColor} title={title}>
      <Button disabled={disabled} icon={icon} type="primary" onClick={onClick} />
    </Tooltip>
  );
};

export default RenderButton;
