import React from 'react';
import { useRef, useState, useCallback } from 'react';
import { HexColorInput, HexColorPicker } from 'react-colorful';

import styles from './PopoverPicker.module.scss';

import useClickOutside from '~/hooks/useClickOutside';

type PopoverPickerProps = {
  color: string;
  onChange: (color: string) => void;
};

const PopoverPicker = ({ color, onChange }: PopoverPickerProps) => {
  const popoverRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = useCallback(() => setIsOpen(false), []);
  useClickOutside(popoverRef, handleClose);

  return (
    <div className={styles.picker}>
      <div className={styles.inputWrapper}>
        <HexColorInput
          style={{
            boxSizing: 'border-box',
            margin: 0,
            padding: '4px 11px',
            color: 'rgba(0, 0, 0, 0.88)',
            fontSize: '14px',
            lineHeight: '1.5714285714285714',
            listStyle: 'none',
            fontFamily:
              "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
            position: 'relative',
            display: 'inline-block',
            width: '90%',
            minWidth: 0,
            backgroundColor: '#ffffff',
            backgroundImage: 'none',
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: '#d9d9d9',
            borderRadius: '2px',
            transition: 'all 0.2s',
          }}
          color={color}
          alpha
          prefixed
          onChange={onChange}
        />
        <div
          className={styles.swatch}
          style={{ backgroundColor: color }}
          onClick={() => setIsOpen(true)}
        />
      </div>

      {isOpen && (
        <div ref={popoverRef} className={styles.popover}>
          <HexColorPicker color={color} onChange={onChange} />
        </div>
      )}
    </div>
  );
};

export default PopoverPicker;
