import React from 'react';
import { Form, Input, Button, Row, Col, Spin } from 'antd';
import {
  UserOutlined,
  LockOutlined,
  MailOutlined,
  IdcardOutlined,
  PhoneOutlined,
  HomeOutlined,
} from '@ant-design/icons';
import { connect } from 'react-redux';

import { AppState } from '~/store/reducers';
import { isStateLoading } from '~/utils/state';
import { postCreateUser } from '~/api/AuthorizedPosts';

import styles from './create.module.scss';

type OwnProps = {
  isLoading: boolean;
};

type VendorsCreateProps = OwnProps;

type valuesProps = {
  name: string;
  username: string;
  email: string;
  password: string;
  companyName: string;
  phoneNumber: string;
};

const VendorsCreate: React.FC<VendorsCreateProps> = ({ isLoading }) => {
  const onFinish = (values: valuesProps) => {
    postCreateUser({
      name: values.name,
      username: values.username,
      email: values.email,
      password: values.password,
      companyname: values.companyName,
      phoneNumber: values.phoneNumber,
      roles: ['Vendor'],
    });
  };

  return (
    <div className={styles.signBox}>
      <Spin spinning={isLoading}>
        <Row>
          <Col offset={3} span={18}>
            <h1 className={styles.title}>Vendor Creation</h1>

            <Form
              className="vendors-create-form"
              initialValues={{ remember: true }}
              name="vendors-create"
              onFinish={onFinish}
            >
              <Form.Item
                name="name"
                rules={[{ required: true, message: 'Please input your Name!' }]}
              >
                <Input placeholder="Name" prefix={<IdcardOutlined />} size="large" />
              </Form.Item>

              <Form.Item
                name="username"
                rules={[{ required: true, message: 'Please input your Username!' }]}
              >
                <Input placeholder="Username" prefix={<UserOutlined />} size="large" />
              </Form.Item>

              <Form.Item
                name="companyName"
                rules={[{ required: true, message: 'Please input your Company Name!' }]}
              >
                <Input placeholder="Company Name" prefix={<HomeOutlined />} size="large" />
              </Form.Item>

              <Form.Item
                name="phoneNumber"
                rules={[{ required: true, message: 'Please input your Phone Number!' }]}
              >
                <Input placeholder="Phone Number" prefix={<PhoneOutlined />} size="large" />
              </Form.Item>

              <Form.Item
                name="email"
                rules={[
                  { required: true, message: 'Please input your Email!' },
                  { type: 'email', message: 'The input is not valid Email!' },
                ]}
              >
                <Input placeholder="Email" prefix={<MailOutlined />} size="large" />
              </Form.Item>

              <Form.Item
                name="password"
                rules={[{ required: true, message: 'Please input your Password!' }]}
              >
                <Input
                  placeholder="Password"
                  prefix={<LockOutlined />}
                  size="large"
                  type="password"
                />
              </Form.Item>

              <Form.Item>
                <Button
                  className={styles.submitButton}
                  htmlType="submit"
                  size="large"
                  type="primary"
                >
                  Create Vendor
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Spin>
    </div>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    isLoading: isStateLoading(state.action, 'createUser'),
  };
};

export default connect(mapStateToProps)(VendorsCreate);
