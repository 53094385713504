import _ from 'lodash';
import { mxCell, mxGraph, StyleMap } from '@anekonnect/mxgraph';
import { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';

import { mx } from '~/constants/wizard';
import { wizardSetTabulatedDataPosition } from '~/store/actions/wizard/Action';
import { AppState } from '~/store/reducers';

type PropsFromState = {
  tabulatedData: any;
};

type PropsFromDispatch = {
  wizardSetTabulatedDataPosition: typeof wizardSetTabulatedDataPosition;
};

type TabulatedDataTableProps = {
  graph: mxGraph;
} & PropsFromState &
  PropsFromDispatch;

const TabulatedDataTable = ({
  graph,
  tabulatedData,
  wizardSetTabulatedDataPosition,
}: TabulatedDataTableProps) => {
  const tablePrefix = 'tabulated-data-table';

  const addTable = useCallback(() => {
    const found = graph.getModel().getCell(tablePrefix);

    // if table already exists, remove it
    if (found) {
      graph.getModel().remove(found);
    }

    const data = tabulatedData.data;
    const columns = tabulatedData.columns;
    const position = tabulatedData.position;

    if (data.length > 3 && columns.length > 2) {
      const filteredData = data.filter((single: any) => {
        return single.extension !== 'Column Width in Pixels';
      });
      const filteredColumns = columns.filter((column: any) => {
        return column.dataIndex !== 'operation';
      });

      const columnWidth = _.omit(data[1], ['key', 'extension']);
      const columnWidthValues = Object.values(columnWidth).map((obj) => parseInt(obj, 10));
      const tableWidth = _.sum(columnWidthValues) + 100;
      const tableHeight = filteredData.length * 40;

      const parent = graph.getDefaultParent();
      graph.getModel().beginUpdate();

      try {
        const tableLockStyle: StyleMap = {};
        tableLockStyle[mx.mxConstants.STYLE_RESIZABLE] = '0';
        tableLockStyle[mx.mxConstants.STYLE_ROTATABLE] = '0';
        tableLockStyle[mx.mxConstants.STYLE_EDITABLE] = '0';
        tableLockStyle[mx.mxConstants.STYLE_DELETABLE] = '0';
        graph.getStylesheet().putCellStyle('tableLock', tableLockStyle);

        const columnLockStyle: StyleMap = {};
        columnLockStyle[mx.mxConstants.STYLE_RESIZABLE] = '0';
        columnLockStyle[mx.mxConstants.STYLE_MOVABLE] = '0';
        columnLockStyle[mx.mxConstants.STYLE_ROTATABLE] = '0';
        columnLockStyle[mx.mxConstants.STYLE_EDITABLE] = '0';
        columnLockStyle[mx.mxConstants.STYLE_DELETABLE] = '0';
        graph.getStylesheet().putCellStyle('columnLock', columnLockStyle);

        const tabulatedDataTable = graph.insertVertex(
          parent,
          tablePrefix,
          '',
          position.x,
          position.y,
          tableWidth,
          tableHeight,
          'shape=table;startSize=40;container=1;collapsible=0;childLayout=tableLayout;fontStyle=1;align=center;pointerEvents=1;connectable=0;allowArrows=0;rounded=0;tableLock;strokeColor=#000;',
        );
        tabulatedDataTable.setVertex(true);

        filteredData.forEach((_data: any, index: number) => {
          const row = graph.insertVertex(
            tabulatedDataTable,
            `${tablePrefix}-${index}-row`,
            '',
            0,
            0,
            tableWidth,
            40,
            'shape=partialRectangle;html=1;whiteSpace=wrap;collapsible=0;dropTarget=0;pointerEvents=0;fillColor=none;top=0;left=0;bottom=0;right=0;points=[[0,0.5],[1,0.5]];portConstraint=eastwest;columnLock;rounded=0;strokeColor=#000;',
          );
          row.setVertex(true);
        });

        filteredColumns.forEach((elementColumn: any, indexColumn: number) => {
          filteredData.forEach((currentData: any, index: number) => {
            const row = graph.getModel().getCell(`${tablePrefix}-${index}-row`);

            if (row) {
              const defaultWidth = 140;
              const currentWidth = parseInt(data[1][elementColumn.dataIndex], 10);
              const width = indexColumn === 0 ? defaultWidth : currentWidth;
              const height = 40;

              const xPosition =
                indexColumn > 0
                  ? _.sum(columnWidthValues.slice(0, indexColumn - 1)) + defaultWidth
                  : 0;
              const yPosition = index * height;

              const column = graph.insertVertex(
                row,
                `${tablePrefix}-row-${index}-column-${indexColumn}`,
                currentData[elementColumn.dataIndex],
                xPosition,
                yPosition,
                width,
                height,
                'shape=partialRectangle;html=1;whiteSpace=wrap;connectable=0;fillColor=none;top=0;left=0;bottom=0;right=0;overflow=hidden;pointerEvents=1;columnLock;rounded=0;strokeColor=#000;',
              );
              column.setVertex(true);
            }
          });
        });

        graph.refresh();
      } finally {
        graph.getModel().endUpdate();
      }
    }
  }, [graph, tabulatedData]);

  useEffect(addTable, [addTable]);

  // Save last position of Tabulated Data Table
  useEffect(() => {
    graph.addListener(mx.mxEvent.CELLS_MOVED, (_sender, evt) => {
      const cells = evt.properties.cells;

      if (cells.length > 0) {
        cells.forEach((cell: mxCell) => {
          if (cell.id === tablePrefix) {
            wizardSetTabulatedDataPosition(cell.getGeometry());
          }
        });
      }
    });
  }, [graph, wizardSetTabulatedDataPosition]);

  return null;
};

const mapStateToProps = (state: AppState) => {
  return {
    tabulatedData: state.assemblyWizard.tabulatedData,
  };
};

const mapDispatchToProps = {
  wizardSetTabulatedDataPosition,
};

export default connect(mapStateToProps, mapDispatchToProps)(TabulatedDataTable);
