import React from 'react';
import { Form, Input, Button, Row, Col, Checkbox, Spin } from 'antd';
import { UserOutlined, LockOutlined, MailOutlined, IdcardOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import OAuthLogin from '~/components/OAuthLogin';
import { AppState } from '~/store/reducers';
import { isStateLoading } from '~/utils/state';
import { postCreateUser } from '~/api/AuthorizedPosts';

import styles from './SignUp.module.scss';
import { LINK_PRIVACY_POLICY, LINK_TERMS_CONDITIONS } from '~/constants/paths';

type OwnProps = {
  isLoading: boolean;
};

type SignUpProps = OwnProps;

type valuesProps = {
  name: string;
  username: string;
  email: string;
  password: string;
};

const SignUp: React.FC<SignUpProps> = (props) => {
  const onFinish = (values: valuesProps) => {
    postCreateUser({
      name: values.name,
      username: values.username,
      email: values.email,
      password: values.password,
    });
  };

  return (
    <div className={styles.signBox}>
      <Spin spinning={props.isLoading}>
        <Row>
          <Col offset={3} span={18}>
            <h1 className={styles.title}>Account Registration</h1>

            <OAuthLogin />

            <Form
              className="signup-form"
              initialValues={{ remember: true }}
              name="signup"
              onFinish={onFinish}
            >
              <Form.Item
                name="name"
                rules={[{ required: true, message: 'Please input your Name!' }]}
                hasFeedback
              >
                <Input placeholder="Name" prefix={<IdcardOutlined />} size="large" />
              </Form.Item>

              <Form.Item
                name="username"
                rules={[
                  { required: true, message: 'Please input your Username!' },
                  { min: 6, message: 'Username must be at least 6 characters' },
                  () => ({
                    validator(_, value) {
                      const format = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;

                      if (!value || !format.test(value)) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('Special characters are not allowed!'));
                    },
                  }),
                ]}
                hasFeedback
              >
                <Input placeholder="Username" prefix={<UserOutlined />} size="large" />
              </Form.Item>

              <Form.Item
                name="email"
                rules={[
                  { required: true, message: 'Please input your Email!' },
                  { type: 'email', message: 'The input is not valid Email!' },
                ]}
                hasFeedback
              >
                <Input placeholder="Email" prefix={<MailOutlined />} size="large" />
              </Form.Item>

              <Form.Item
                name="password"
                rules={[
                  { required: true, message: 'Please input your Password!' },
                  { min: 8, message: 'Password must be at least 8 characters!' },
                  () => ({
                    validator(_, value) {
                      const strongRegex = new RegExp(
                        '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})',
                      );
                      const mediumRegex = new RegExp(
                        '^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{8,})',
                      );

                      if (!value) {
                        return Promise.resolve();
                      }

                      if (value.length >= 8) {
                        if (strongRegex.test(value)) {
                          return Promise.resolve();
                        } else if (mediumRegex.test(value)) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject(new Error('Your password is too weak!'));
                        }
                      } else {
                        return Promise.resolve();
                      }
                    },
                  }),
                ]}
                hasFeedback
              >
                <Input.Password placeholder="Password" prefix={<LockOutlined />} size="large" />
              </Form.Item>

              <Form.Item
                name="confirm-password"
                dependencies={['password']}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: 'Please confirm your password!',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error('The password that you entered do not match!'),
                      );
                    },
                  }),
                ]}
              >
                <Input.Password
                  placeholder="Confirm Password"
                  prefix={<LockOutlined />}
                  size="large"
                />
              </Form.Item>

              <Form.Item
                name="agreement"
                rules={[
                  {
                    validator: (_, value) =>
                      value
                        ? Promise.resolve()
                        : Promise.reject('Please agree to privacy policy and terms and conditions'),
                  },
                ]}
                valuePropName="checked"
              >
                <Checkbox>
                  I agree to the{' '}
                  <a href={LINK_PRIVACY_POLICY} rel="noopener noreferrer" target="_blank">
                    Privacy Policy
                  </a>{' '}
                  and{' '}
                  <a href={LINK_TERMS_CONDITIONS} rel="noopener noreferrer" target="_blank">
                    Terms and Conditions
                  </a>
                </Checkbox>
              </Form.Item>

              <Form.Item>
                <Button
                  className={styles.signUpButton}
                  htmlType="submit"
                  size="large"
                  type="primary"
                >
                  Sign Up
                </Button>
              </Form.Item>
            </Form>

            <div className={styles.loginWrapper}>
              <span>
                Already have an account?{' '}
                <Link className={styles.loginLink} to="/login">
                  Log In
                </Link>
              </span>
            </div>
          </Col>
        </Row>
      </Spin>
    </div>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    isLoading: isStateLoading(state.action, 'createUser', 'oauth'),
  };
};

export default connect(mapStateToProps)(SignUp);
