import { Button, message, Space } from 'antd';
import { useState } from 'react';
import { putUpdateRequestReview } from '~/api/AuthorizedPuts';
import { useAppSelector } from '~/store/hooks';

const WizardHeaderRFA = () => {
  const assemblyId = useAppSelector((state) => state.assemblyWizard.id);
  const userId = useAppSelector((state) => state.data.whoami.data?.id);

  const [approveLoading, setApproveLoading] = useState(false);
  const [rejectLoading, setRejectLoading] = useState(false);

  const approveHandler = async () => {
    try {
      setApproveLoading(true);
      const response = await putUpdateRequestReview(`${assemblyId}/review/approve`, {
        reviewers: [userId],
      });

      if (response) {
        message.success('Assembly approved');

        // reload with delay
        setTimeout(() => {
          window.location.reload();
        }, 500);
      }

      setApproveLoading(false);
    } catch (error) {
      setApproveLoading(false);
      message.error('Something went wrong');
    }
  };

  const rejectHandler = async () => {
    try {
      setRejectLoading(true);
      const response = await putUpdateRequestReview(`${assemblyId}/review/reject`, {
        reviewers: [userId],
      });

      if (response) {
        message.success('Assembly rejected');

        // reload with delay
        setTimeout(() => {
          window.location.reload();
        }, 500);
      }

      setRejectLoading(false);
    } catch (error) {
      setRejectLoading(false);
      message.error('Something went wrong');
    }
  };

  return (
    <Space>
      <Button type="primary" danger onClick={rejectHandler} loading={rejectLoading}>
        Reject
      </Button>
      <Button type="primary" onClick={approveHandler} loading={approveLoading}>
        Approve
      </Button>
    </Space>
  );
};

export default WizardHeaderRFA;
