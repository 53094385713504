const Constants = {
  edgeSelectionColor: 'green',
  edgeSelectionStrokeWidth: 4,
  startSize: 5,
  endSize: 5,
  dashedEdgeId: 'dashed_edge',
  deleteCellKeys: ['del', 'backspace'],
  dashedEdgeStrokeWidth: 2,
  dashedEdgeClassName: 'dashedEdge',
  colorCategoryHaveStroke: ['white'],
  defaultStrokeWidth: 2,
  arrowStyle: 'oval',
};

export default Constants;
