import { Comment } from '@ant-design/compatible';
import { Button, List, message, Popconfirm, Tag } from 'antd';
import moment from 'moment';
import React, { useRef } from 'react';
import { connect } from 'react-redux';
import { PlusOutlined } from '@ant-design/icons';

import styles from './index.module.scss';
import Editor from './Editor';
import EditModal from './EditModal';

import { AppState } from '~/store/reducers';
import { DiscussionTopic, DiscussionTopicReply } from '~/store/reducers/wizard/State';
import { snackBarOpen } from '~/store/actions/ui/SnackBar';
import { wizardLoadDiscussions } from '~/store/actions/wizard/Action';
import { deleteAssemblyDiscussion } from '~/api/AuthorizedDeletes';
import { getPaymentStatus, paymentStatusIsPaid } from '~/utils/helper';

type PropsFromState = {
  discussions: DiscussionTopic[];
  assemblyId?: number;
  whoamiData: {
    name?: string;
    payment_status?: string;
    type?: string;
  };
};

type PropsFromDispatch = {
  snackBarOpen: (message: string, status?: string) => void;
  wizardLoadDiscussions: typeof wizardLoadDiscussions;
};

type WizardDiscussionProps = PropsFromState & PropsFromDispatch;

const WizardDiscussion = ({ discussions, assemblyId, whoamiData }: WizardDiscussionProps) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const paymentStatus = getPaymentStatus(whoamiData);
  const isPaid = paymentStatusIsPaid(paymentStatus);

  const [state, setState] = React.useState({
    discussions,
    showEditor: false,
  });
  const [showEditModal, setShowEditModal] = React.useState(false);
  const [topicId, setTopicId] = React.useState('');
  const [replyId, setReplyId] = React.useState('');
  const [editAction, setEditAction] = React.useState<'EDIT_TOPIC' | 'EDIT_REPLY'>('EDIT_TOPIC');
  const [editData, setEditData] = React.useState({
    title: '',
    description: '',
  });

  const setDiscussions = (discussions: DiscussionTopic[], scroll = false) => {
    setState({
      discussions,
      showEditor: false,
    });

    if (scroll && wrapperRef.current) {
      wrapperRef.current.scrollTop = wrapperRef.current.scrollHeight;
    }
  };

  const Header = () => {
    return (
      <>
        <div className={styles.header}>
          <div className={styles.left}>
            <h3>Discussion</h3>
            <p>
              Discuss your assembly with other users. You can also post comments to other users.
            </p>
          </div>
          <div className={styles.right}>
            {!whoamiData ? (
              <Tag color="red">Please create an account to add a new topic</Tag>
            ) : !assemblyId ? (
              <Tag color="red">Please save the assembly to add a new topic</Tag>
            ) : (
              !isPaid && <Tag color="red">Please update your payment to use this feature.</Tag>
            )}
            <Button
              disabled={!assemblyId || !isPaid || !whoamiData}
              icon={<PlusOutlined />}
              type="primary"
              onClick={() => setState({ ...state, showEditor: true })}
            >
              New Topic
            </Button>
          </div>
        </div>
        {state.showEditor && <Editor action="ADD_TOPIC" setDiscussions={setDiscussions} />}
      </>
    );
  };

  const handleDeleteTopic = (topicId: string) => {
    deleteAssemblyDiscussion(`${assemblyId}/discussion-topic/${topicId}`).then(
      (assembly: { discussions: string }) => {
        if (assembly.discussions) {
          message.success('Your topic deleted successfully');
          setDiscussions(JSON.parse(assembly.discussions));
        }
      },
    );
  };

  const handleDeleteTopicReply = (topicId: string, replyId: string) => {
    deleteAssemblyDiscussion(`${assemblyId}/discussion-topic/${topicId}/reply/${replyId}`).then(
      (assembly: { discussions: string }) => {
        if (assembly.discussions) {
          message.success('Your reply deleted successfully');
          setDiscussions(JSON.parse(assembly.discussions));
        }
      },
    );
  };

  const handleEditTopic = (topic: DiscussionTopic) => {
    setTopicId(topic.id);
    setEditAction('EDIT_TOPIC');
    setEditData({
      title: topic.title,
      description: topic.description,
    });
    setShowEditModal(true);
  };

  const handleEditTopicReply = (topicId: string, reply: DiscussionTopicReply) => {
    setTopicId(topicId);
    setReplyId(reply.id);
    setEditAction('EDIT_REPLY');
    setEditData({
      title: '',
      description: reply.reply,
    });
    setShowEditModal(true);
  };

  return (
    <>
      <EditModal
        action={editAction}
        data={editData}
        replyId={replyId}
        setDiscussions={setDiscussions}
        topicId={topicId}
        visible={showEditModal}
        onCancel={() => setShowEditModal(false)}
      />
      <div ref={wrapperRef} className={styles.wrapper}>
        <List
          className="comment-list"
          dataSource={state.discussions}
          header={Header()}
          itemLayout="horizontal"
          renderItem={(topic) => (
            <li>
              <Comment
                actions={[
                  <span key={topic.id}>{`${
                    topic.replies ? topic.replies.length : 0
                  } Replies`}</span>,
                  <span key={topic.id} onClick={() => handleEditTopic(topic)}>
                    Edit
                  </span>,
                  <Popconfirm
                    key={topic.id}
                    cancelText="No"
                    okText="Yes"
                    title="Are you sure to delete this topic?"
                    onConfirm={() => handleDeleteTopic(topic.id)}
                  >
                    <span>Delete</span>
                  </Popconfirm>,
                ]}
                author={topic.author}
                avatar={`https://ui-avatars.com/api/?name=${topic.author}&background=random`}
                content={
                  <>
                    <h3>{topic.title}</h3>
                    <p>{topic.description}</p>
                  </>
                }
                datetime={moment(topic.updated_at).fromNow()}
              >
                {topic.replies &&
                  topic.replies.map((reply) => {
                    return (
                      <Comment
                        key={reply.id}
                        actions={[
                          <span
                            key={reply.id}
                            onClick={() => handleEditTopicReply(topic.id, reply)}
                          >
                            Edit
                          </span>,
                          <Popconfirm
                            key={reply.id}
                            cancelText="No"
                            okText="Yes"
                            title="Are you sure to delete this reply?"
                            onConfirm={() => handleDeleteTopicReply(topic.id, reply.id)}
                          >
                            <span>Delete</span>
                          </Popconfirm>,
                        ]}
                        author={reply.author}
                        avatar={`https://ui-avatars.com/api/?name=${reply.author}&background=random`}
                        content={<p>{reply.reply}</p>}
                        datetime={moment(reply.updated_at).fromNow()}
                      />
                    );
                  })}
                <Comment
                  avatar={`https://ui-avatars.com/api/?name=${whoamiData.name}&background=random`}
                  content={
                    <Editor action="ADD_REPLY" setDiscussions={setDiscussions} topicId={topic.id} />
                  }
                />
              </Comment>
            </li>
          )}
          split
        />
      </div>
    </>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    discussions: state.assemblyWizard.discussions,
    assemblyId: state.assemblyWizard.id,
    whoamiData: state.data.whoami.data,
  };
};

const mapDispatchToProps = {
  snackBarOpen,
  wizardLoadDiscussions,
};

export default connect(mapStateToProps, mapDispatchToProps)(WizardDiscussion);
