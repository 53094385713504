import moment from 'moment';

export type ColumnData = {
  title: string;
  dataKey: string;
  dataType?: string;
  defaultData: string;
  columnWidth: string | number;
  minWidth?: number;
  fontSize: string;
  fontColor: string;
};

export type TableColumnSchema = {
  [key: string]: ColumnData;
};

export const sumColumnWidths = <T>(
  columnConfig: TableColumnSchema,
  tableData: T[],
  maxIndex?: number,
): number => {
  return Object.entries(columnConfig).reduce((sum, [_key, column], index) => {
    if (maxIndex !== undefined && index > maxIndex) {
      return sum;
    }

    return sum + getColumnWidth(tableData, column);
  }, 0);
};

export const getWidthOfTheField = <T>(
  data: T[],
  fieldName: keyof T,
  type: string,
  minWidth = 75,
): number => {
  if (data.length === 0) {
    return minWidth;
  }

  let longestText: number;

  if (type === 'date') {
    longestText = moment(data[0][fieldName] as string).format('LL').length;
  } else {
    longestText = (data[0][fieldName] as string).length;
  }

  for (let i = 1; i < data.length; i++) {
    let fieldLength: number;

    if (type === 'date') {
      fieldLength = moment(data[i][fieldName] as string).format('LL').length;
    } else {
      fieldLength = (data[i][fieldName] as string).length;
    }

    if (fieldLength > longestText) {
      longestText = fieldLength;
    }
  }

  const widthName = longestText * 9;

  return widthName > minWidth ? widthName : minWidth;
};

export const getColumnWidth = <T>(tableData: T[], column: ColumnData) => {
  if (column.columnWidth === 'auto') {
    const autoWidth = getWidthOfTheField(
      tableData,
      column.dataKey as keyof T,
      column.dataType || '',
      column.minWidth || 75,
    );
    return autoWidth;
  }

  return parseInt(column.columnWidth.toString(), 10);
};
