import { mxCell, mxGraph } from '@anekonnect/mxgraph';
import { useEffect, useState } from 'react';
import { getApprovalHistory } from '~/api/AuthorizedGets';
import camelcaseKeys from 'camelcase-keys';

import { mx } from '~/constants/wizard';
import { useAppDispatch, useAppSelector } from '~/store/hooks';
import { wizardSetApprovalHistoriesTablePosition } from '~/store/actions/wizard/Action';
import { BottomToTopTableTemplate, TopToBottomTableTemplate } from './templates';

type ApprovalHistoriesTableProps = {
  graph: mxGraph;
};

type ApprovalHistoriesData = {
  id: string;
  tenant: string;
  assemblyId: string;
  action: string;
  actionBy: number;
  actionByName: string;
  actionOccurredAt: string;
  newStatus: string;
};

const ApprovalHistoriesTable = ({ graph }: ApprovalHistoriesTableProps) => {
  const dispatch = useAppDispatch();
  const tableConfig = useAppSelector(
    (state) => state.data.tenantConfig.data?.approval_history_table_config_display,
  );
  const assemblyWizard = useAppSelector((state) => state.assemblyWizard);
  const { id: assemblyId, status, approvalHistoriesTable, menuViewActiveList } = assemblyWizard;
  const approvalPosition = approvalHistoriesTable.position;

  const tablePrefix = 'approval-histories-table';
  const [approvalData, setApprovalData] = useState<ApprovalHistoriesData[]>([]);

  useEffect(() => {
    const getData = async (assemblyId: number) => {
      const response = await getApprovalHistory(assemblyId);

      if (response?.data) {
        const data = camelcaseKeys(response.data, { deep: true }) as ApprovalHistoriesData[];
        // Find the index of the first occurrence of "Cancel Review" or "Reject" action
        const discardReviewIndex = data.findIndex(
          (item) => item.action === 'Cancel Review' || item.action === 'Reject',
        );

        // Exclude items starting from the "Cancel Review" or "Reject" action
        const filteredData = discardReviewIndex !== -1 ? data.slice(0, discardReviewIndex) : data;
        setApprovalData(filteredData);
      }
    };

    if (assemblyId && status === 'Published' && menuViewActiveList.includes('approval-histories')) {
      getData(assemblyId);
    }
  }, [assemblyId, menuViewActiveList, status]);

  // Save last position of BOM table
  useEffect(() => {
    graph.addListener(mx.mxEvent.CELLS_MOVED, (_sender, evt) => {
      const cells = evt.properties.cells;

      if (cells.length > 0) {
        cells.forEach((cell: mxCell) => {
          if (cell.id === tablePrefix) {
            dispatch(wizardSetApprovalHistoriesTablePosition(cell.getGeometry()));
          }
        });
      }
    });
  }, [dispatch, graph]);

  if (!tableConfig) {
    return;
  }

  if (!menuViewActiveList.includes('approval-histories')) {
    return;
  }

  const tableConfigJson = JSON.parse(tableConfig);
  const template = tableConfigJson?.table?.template;
  const showTitle =
    tableConfigJson?.title?.showTitle !== undefined ? tableConfigJson?.title?.showTitle : true;

  switch (template) {
    case 'top-to-bottom':
      return (
        <TopToBottomTableTemplate
          graph={graph}
          tablePrefix={tablePrefix}
          tableConfigJson={tableConfigJson}
          data={approvalData}
          position={approvalPosition}
          showTitle={showTitle}
        />
      );
    case 'bottom-to-top':
      return (
        <BottomToTopTableTemplate
          graph={graph}
          tablePrefix={tablePrefix}
          tableConfigJson={tableConfigJson}
          data={approvalData}
          position={approvalPosition}
          showTitle={showTitle}
        />
      );
    default:
      return (
        <TopToBottomTableTemplate
          graph={graph}
          tablePrefix={tablePrefix}
          tableConfigJson={tableConfigJson}
          data={approvalData}
          position={approvalPosition}
          showTitle={showTitle}
        />
      );
  }
};

export default ApprovalHistoriesTable;
