import Axios from 'axios';
import { isEmpty } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { getSearchPartItems } from '~/api/AuthorizedGets';
import { PartItem } from '~/types';
import { FormSchema } from '../../FormSchema';

const schema: any = {
  items: {
    required: ['contact_number', 'contact_type', 'contact_size', 'units'],
    properties: {
      contact_number: {
        type: 'string',
        title: 'Contact#',
      },
      contact_type: {
        type: 'string',
        title: 'Contact Type',
        enum: ['Electrical_Power', 'Electrical_Data_Signal', 'Coax', 'FiberOptic'],
        enumNames: ['Electrical Power', 'Electrical Data Signal', 'Coax', 'FiberOptic'],
      },
      contact_size: {
        type: 'string',
        title: 'Contact Size or Specification',
      },
      units: {
        type: 'string',
        title: 'Units',
        enum: ['awg', 'mm^2', 'uM', 'ohm'],
        enumNames: ['awg', 'mm^2', 'uM', 'Ohm'],
      },
      contact_representation: {
        type: 'string',
        title: 'Contact Representation',
      },
      contact_prefilled_annotation: {
        type: 'string',
        title: 'Contact Pre-Filled Annotation',
      },
      annotation_position: {
        type: 'string',
        title: 'Annotation Position',
        enum: ['left', 'right'],
        enumNames: ['Left', 'Right'],
        default: 'left',
      },
    },
    additionalProperties: false,
    type: 'object',
  },
  type: 'array',
  title: 'Contact Details',
};

type ContactDetailsType = {
  onChange: (data: any) => any;
  formData: any[];
  contactName: string;
  filterBy: string;
};

const ContactDetails = ({ onChange, formData, contactName, filterBy }: ContactDetailsType) => {
  const [data, setData] = useState(formData);
  const [partItems, setPartItems] = useState<PartItem[]>([]);
  const [newSchema, setNewSchema] = useState<any>(schema);

  const doSearch = useCallback(() => {
    if (isEmpty(contactName)) {
      setPartItems([]);

      return;
    }

    const source = Axios.CancelToken.source();
    const filters = {
      t: filterBy,
    };

    getSearchPartItems(contactName, filters, source).then((result: PartItem[]) => {
      if (result !== undefined) {
        setPartItems(PartItem.fromArray(result));
      }
    });

    return () => {
      // Cancel Request when unmounting
      source.cancel();
    };
  }, [filterBy, contactName]);

  useEffect(doSearch, [doSearch]);

  useEffect(() => {
    if (partItems.length > 0) {
      const newData = JSON.parse(JSON.stringify(schema));
      newData.title = `${newData.title} (Max ${partItems[0].contacts} Contacts)`;

      setNewSchema(newData);
    }
  }, [partItems]);

  return partItems.length > 0 ? (
    <FormSchema
      schema={newSchema}
      formData={data}
      cancelAction={() => console.log('cancel')}
      confirmAction={() => console.log('confirm')}
      showActions={false}
      onChange={(data) => {
        setData(data.formData);
        onChange(data.formData);
      }}
    />
  ) : (
    <></>
  );
};

export default ContactDetails;
