import { Input } from 'antd';
import { SearchComponentUI, VendorsUI } from './custom-ui';

const CustomTextField = (props: any) => {
  const { placeholder, autofocus, disabled, readonly, value, required } = props;

  let type = props.schema.type;
  const units = props.schema?.units;

  const customUI = props?.options?.customUI;

  switch (type) {
    case 'integer' || 'number':
      type = 'number';
      break;
    default:
      type = 'text';
  }

  const getComponent = () => {
    switch (customUI) {
      case 'search-component':
        return <SearchComponentUI {...props} />;
      case 'vendors':
        return <VendorsUI {...props} />;
      default:
        return (
          <Input
            addonAfter={units}
            autoFocus={autofocus}
            id={props.id}
            size="large"
            type={type}
            value={value}
            onChange={(event) => props.onChange(event.target.value)}
            disabled={disabled || readonly}
            placeholder={placeholder}
            required={required}
          />
        );
    }
  };

  return getComponent();
};

export default CustomTextField;
