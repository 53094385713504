import { Form } from 'antd';
import EditableContext from './EditableContex';

type EditableRowProps = {
  index: number;
};

const EditableRow = ({ index, ...props }: EditableRowProps) => {
  const [form] = Form.useForm();

  return (
    <Form component={false} form={form}>
      <EditableContext.Provider value={form}>
        <tr tabIndex={index} {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

export default EditableRow;
