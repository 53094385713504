import React from 'react';
import { Button, Col, Form, Input, Row, Spin } from 'antd';
import { connect } from 'react-redux';
import { ActionCreator } from 'redux';

import styles from './ChangePassword.module.scss';

import { putChangePassword } from '~/api/AuthorizedPuts';
import { AppState } from '~/store/reducers';
import { isStateLoading } from '~/utils/state';
import { SnackBarOpen, snackBarOpen } from '~/store/actions/ui/SnackBar';

type PropsFromState = {
  isLoading: boolean;
};

type PropsFromDispatch = {
  snackBarOpen: ActionCreator<SnackBarOpen>;
};

type ChangePasswordProps = PropsFromState & PropsFromDispatch;

type valuesProps = {
  password: string;
};

const ChangePassword: React.FC<ChangePasswordProps> = (props) => {
  const onFinish = (values: valuesProps) => {
    putChangePassword('', { password: values.password });
    props.snackBarOpen('Password changed successfully');
  };

  return (
    <Row className={styles.center} justify="center">
      <Col lg={6} md={12} sm={24} xs={24}>
        <h1 className={styles.header}>Change Password</h1>
        <Spin spinning={props.isLoading}>
          <Form layout="vertical" name="changePassword" onFinish={onFinish}>
            <Form.Item
              label="New Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Please input your password!',
                },
                {
                  min: 8,
                  message: 'Password should be more than 8 characters',
                },
              ]}
              hasFeedback
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              dependencies={['password']}
              label="Confirm New Password"
              name="confirm"
              rules={[
                {
                  required: true,
                  message: 'Please confirm your password!',
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }

                    return Promise.reject('The two passwords that you entered do not match!');
                  },
                }),
              ]}
              hasFeedback
            >
              <Input.Password />
            </Form.Item>

            <Form.Item>
              <Button htmlType="submit" type="primary">
                Change Password
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    isLoading: isStateLoading(state.action, 'changePassword'),
  };
};

const mapDispatchToProps = {
  snackBarOpen,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
