import { Space } from 'antd';
import React from 'react';
import { mxGraph } from '@anekonnect/mxgraph';
import { FormInstance } from 'antd/lib/form';

import styles from './WizardHeaderMenu.module.scss';
import MenuFile from './components/MenuFile';
import MenuShare from './components/MenuShare';
import MenuView from './components/MenuView';
import MenuPage from './components/MenuPage';
import MenuDownload from './components/MenuDownload';
import MenuInfo from './components/MenuInfo';
import { AssemblyForm } from './Types';
import { useAppSelector } from '~/store/hooks';
import { AppState } from '~/store/reducers';

type WizardHeaderMenuProps = {
  graph: mxGraph;
  assemblyForm: FormInstance<AssemblyForm>;
};

const WizardHeaderMenu = ({ graph, assemblyForm }: WizardHeaderMenuProps) => {
  const { isTenant } = useAppSelector((state: AppState) => state.data.tenantConfig);
  const { authenticated } = useAppSelector((state: AppState) => state.data.auth);

  return (
    <React.Fragment>
      <Space className={styles.menu} size="middle">
        <MenuFile assemblyForm={assemblyForm} graph={graph} />
        <MenuShare />
        <MenuView graph={graph} />
        <MenuPage />
        <MenuDownload graph={graph} />
        {isTenant === false && !authenticated && <MenuInfo />}
      </Space>
    </React.Fragment>
  );
};

export default WizardHeaderMenu;
