import { Select } from 'antd';
import React from 'react';

import { OptionShape } from '../../../types';

import styles from './CustomSelect.module.scss';

type EnumOption = {
  label: string;
  value: string;
};

const mapEnumOptionToOptionShape = (opt: EnumOption): OptionShape => ({
  value: opt.value,
  text: opt.label,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CustomSelect = (props: any) => {
  const { options, onChange, value, disabled, schema } = props; // id, classNames, label, help, required, description, errors, schema
  const enumOptions: EnumOption[] = options.enumOptions || [];
  const dropdownOptions: OptionShape[] = enumOptions.map(mapEnumOptionToOptionShape);

  let defaultSelected: OptionShape | undefined;

  if (schema?.default) {
    defaultSelected = dropdownOptions.find((e) => String(e.value) === String(schema?.default));
  }

  if (value) {
    defaultSelected = dropdownOptions.find((e) => String(e.value) === String(value));
  }

  return (
    <Select
      className={styles.customSelect}
      disabled={disabled}
      size="large"
      value={defaultSelected?.text}
      onChange={onChange}
    >
      {dropdownOptions.map((option) => {
        return (
          <Select.Option
            key={String(option.value) + String(option.text)}
            value={String(option.value)}
          >
            {option.text}
          </Select.Option>
        );
      })}
    </Select>
  );
};

export default CustomSelect;
