/* eslint-disable object-shorthand */
import { InputNumber, Select } from 'antd';
import React, { Fragment, useEffect, useState } from 'react';

import { PartFilterFields, OptionShape } from '../../../../types';

import styles from './PartsFilterItem.module.scss';

const GREATER_THAN: OptionShape = {
  text: 'GREATER THAN',
  value: 'GREATER_THAN',
};

const LESS_THAN: OptionShape = {
  text: 'LESS THAN',
  value: 'LESS_THAN',
};

const RANGE: OptionShape = {
  text: 'RANGE',
  value: 'RANGE',
};

const EQUAL_TO: OptionShape = {
  text: 'EQUAL TO',
  value: 'EQUAL_TO',
};

type PartsFilterItemProps = {
  filterType?: string;
  filter: PartFilterFields;
  options: OptionShape[];
  selectedValue: string;
  handleFilterInputChange: (value: string, filter: PartFilterFields) => void;
};

const numericOptions: OptionShape[] = [EQUAL_TO, GREATER_THAN, LESS_THAN, RANGE];
const getOption = (option: string) => {
  switch (option) {
    case 'EQUAL_TO':
      return EQUAL_TO;
    case 'GREATER_THAN':
      return GREATER_THAN;
    case 'LESS_THAN':
      return LESS_THAN;
    case 'RANGE':
      return RANGE;
    default:
      return EQUAL_TO;
  }
};

const PartsFilterItem = ({
  filterType,
  filter,
  options,
  selectedValue,
  handleFilterInputChange,
}: PartsFilterItemProps) => {
  const [selectedFilterCriteria, handleFilterCriteria] = useState(EQUAL_TO as OptionShape);

  useEffect(() => {
    if (selectedValue) {
      const valueArray = selectedValue.split(':');

      if (valueArray.length === 2) {
        if (valueArray[0] === '') {
          handleFilterCriteria(LESS_THAN);
        } else if (valueArray[1] === '') {
          handleFilterCriteria(GREATER_THAN);
        } else {
          handleFilterCriteria(RANGE);
        }
      }
    }
  }, [selectedValue]);

  let filterComponent: JSX.Element;
  let filterValueComponent: JSX.Element;

  switch (filterType) {
    case 'numeric':
      filterComponent = (
        <div id="parts-filter-item-condition" className={styles.filterCondition}>
          <Select
            className={styles.filterValueSelect}
            value={selectedFilterCriteria.text}
            onChange={(value) => {
              switch (value) {
                case 'RANGE':
                  handleFilterInputChange('0:0', filter);
                  break;
                case 'GREATER_THAN':
                  handleFilterInputChange('0:', filter);
                  break;
                case 'LESS_THAN':
                  handleFilterInputChange(':0', filter);
                  break;
                default:
                  handleFilterInputChange('0', filter);
                  break;
              }
              handleFilterCriteria(getOption(value));
            }}
          >
            {numericOptions.map((option) => {
              return (
                <Select.Option key={option.value} value={option.value}>
                  {option.text}
                </Select.Option>
              );
            })}
          </Select>
        </div>
      );
      break;
    case 'select':
      filterComponent = (
        <div id="parts-filter-item-condition" className={styles.filterCondition}>
          <div id="parts-filter-item-condition-text" className={styles.filterConditionText}>
            EQUAL TO
          </div>
        </div>
      );
      break;
    default:
      filterComponent = (
        <div id="parts-filter-item-condition" className={styles.filterCondition}></div>
      );
  }

  const dropdownObject = {
    text: selectedValue,
    value: selectedValue,
  };

  switch (selectedFilterCriteria.value) {
    case 'RANGE':
      filterValueComponent = (
        <div id="parts-filter-item-condition" className={styles.filterCondition}>
          <InputNumber
            id="parts-filter-item-condition-input-number"
            className={styles.filterInputRange}
            placeholder="0"
            type="number"
            value={(selectedValue && parseInt(selectedValue.split(':')[0])) || 0}
            onChange={(value) => {
              if (value) {
                handleFilterInputChange(
                  `${value}:${selectedValue && selectedValue.split(':')[1]}`,
                  filter,
                );
              } else {
                handleFilterInputChange(
                  `0:${selectedValue && selectedValue.split(':')[1]}`,
                  filter,
                );
              }
            }}
          />
          <div className={styles.filterInputRangeTo}>to</div>
          <InputNumber
            id="parts-filter-item-condition-input-number"
            className={styles.filterInputRange}
            placeholder="0"
            type="number"
            value={(selectedValue && parseInt(selectedValue.split(':')[1])) || 0}
            onChange={(value) => {
              if (value) {
                handleFilterInputChange(
                  `${selectedValue && selectedValue.split(':')[0]}:${value}`,
                  filter,
                );
              } else {
                handleFilterInputChange(
                  `${selectedValue && selectedValue.split(':')[0]}:0`,
                  filter,
                );
              }
            }}
          />
        </div>
      );
      break;
    case 'GREATER_THAN':
      filterValueComponent = (
        <div id="parts-filter-item-condition" className={styles.filterCondition}>
          <InputNumber
            id="parts-filter-item-condition-input-number"
            className={styles.filterInput}
            placeholder="0"
            type="number"
            value={(selectedValue && parseInt(selectedValue.split(':')[0])) || 0}
            onChange={(value) => {
              if (value) {
                handleFilterInputChange(`${value}:`, filter);
              } else {
                handleFilterInputChange(`0:`, filter);
              }
            }}
          />
        </div>
      );
      break;
    case 'LESS_THAN':
      filterValueComponent = (
        <div id="parts-filter-item-condition" className={styles.filterCondition}>
          <InputNumber
            id="parts-filter-item-condition-input-number"
            className={styles.filterInput}
            placeholder="0"
            type="number"
            value={(selectedValue && parseInt(selectedValue.split(':')[1])) || 0}
            onChange={(value) => {
              if (value) {
                handleFilterInputChange(`:${value}`, filter);
              } else {
                handleFilterInputChange(`:0`, filter);
              }
            }}
          />
        </div>
      );
      break;
    default:
      if (filterType === 'numeric') {
        filterValueComponent = (
          <div id="parts-filter-item-condition" className={styles.filterCondition}>
            <InputNumber
              id="parts-filter-item-condition-input-number"
              className={styles.filterInput}
              type="number"
              value={parseInt(selectedValue)}
              onChange={(value) => {
                handleFilterInputChange((value || 0).toString(), filter);
              }}
            />
          </div>
        );
      } else if (filterType === 'select') {
        filterValueComponent = (
          <div id="parts-filter-item-condition" className={styles.filterCondition}>
            <Select
              className={styles.filterValueSelect}
              value={dropdownObject.text}
              onChange={(value) => handleFilterInputChange(value, filter)}
            >
              {options.map((option) => {
                return (
                  <Select.Option key={option.value} value={option.value}>
                    {option.text}
                  </Select.Option>
                );
              })}
            </Select>
          </div>
        );
      } else {
        filterValueComponent = (
          <div id="parts-filter-item-condition" className={styles.filterCondition}></div>
        );
      }
  }

  return (
    <Fragment>
      {filterComponent}
      {filterValueComponent}
    </Fragment>
  );
};

export default PartsFilterItem;
