import { Reducer } from 'redux';

import {
  AuthActionTypes,
  LoginUserFailure,
  LoginUserRequest,
  LoginUserSuccess,
  LogoutUserFailure,
  LogoutUserRequest,
  LogoutUserSuccess,
} from '../../actions/data/Auth';

// STATE
export type AuthState = {
  authenticated: boolean;
  token: string;
  expiresAt: string;
};

export const initialAuthState: AuthState = {
  token: '',
  expiresAt: '',
  authenticated: false,
};

// REDUCER
export const authReducer: Reducer<AuthState> = (
  state: AuthState = initialAuthState,
  action:
    | LoginUserRequest
    | LoginUserFailure
    | LoginUserSuccess
    | LogoutUserFailure
    | LogoutUserRequest
    | LogoutUserSuccess,
) => {
  switch (action.type) {
    case AuthActionTypes.LOGIN_USER_REQUEST:
    case AuthActionTypes.LOGIN_USER_FAILURE:
    case AuthActionTypes.LOGOUT_USER_REQUEST:
    case AuthActionTypes.LOGOUT_USER_SUCCESS:
    case AuthActionTypes.LOGOUT_USER_FAILURE:
      return initialAuthState;
    case AuthActionTypes.LOGIN_USER_SUCCESS:
      return {
        ...state,
        authenticated: true,
        token: action.token,
        expiresAt: action.expiresAt,
      };
    default:
      return state;
  }
};
