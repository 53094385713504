import { Spin, Table } from 'antd';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { getNotifications } from '~/api/AuthorizedGets';
import { LINK_ASSEMBLY_EDIT } from '~/constants/paths';
import { AppState } from '~/store/reducers';
import { ResponseState } from '~/store/reducers/data/ActionResponse';
import { AuthState } from '~/store/reducers/data/Auth';
import { isStateLoading } from '~/utils/state';

enum EventType {
  User = 'user',
}

enum EntityType {
  Assembly = 'Assembly',
}

enum Action {
  Create = 'Create',
  Update = 'Update',
  ModelRequested = 'ModelRequested',
  ModelUploaded = 'ModelUploaded',
  ModelRequestCanceled = 'ModelRequestCanceled',
  Share = 'Share',
  Delete = 'Delete',
}

type Event = {
  event_type: EventType;
  entity: EntityType;
  action: string;
  object_name: string;
  object_id: number;
  action_by: number;
  action_by_name: string;
  created_at: string;
  details: string;
};

const getActionToDescription = (abc: keyof typeof Action, event: Event, whoami: any) => {
  const objName = event.object_name;
  const assemblyLink = <Link to={`${LINK_ASSEMBLY_EDIT}/${event.object_id}`}>{objName}</Link>;
  const eventDetails = event.details;

  switch (abc) {
    case Action.Create:
      return <>started design of a new assembly {assemblyLink}</>;
    case Action.Delete:
      return whoami.type === 'Admin' ? (
        <>Deleted assembly {assemblyLink}</>
      ) : (
        <>Deleted assembly {objName}</>
      );
    case Action.Update:
      return <>updated design of assembly {assemblyLink}</>;
    case Action.ModelRequested:
      return <>requested modeling for assembly {assemblyLink}</>;
    case Action.ModelUploaded:
      return <>uploaded design for assembly {assemblyLink}</>;
    case Action.Share:
      return (
        <>
          shared assembly {assemblyLink} with {eventDetails}
        </>
      );
    case Action.ModelRequestCanceled:
      return <>reverted status for assembly {assemblyLink} to In Progress</>;
    default:
      return null;
  }
};

type PropsFromState = {
  auth: AuthState;
  notifications: ResponseState<any>;
  whoami: ResponseState<any>;
  isLoading: boolean;
};

type NotificationsProps = PropsFromState;

const Notifications = ({ auth, whoami, notifications, isLoading }: NotificationsProps) => {
  const [notificationData, setNotificationData] = useState<any[]>([]);

  const toDescription = useCallback(
    (event: Event): React.ReactNode => {
      if (event.entity !== EntityType.Assembly || !whoami.data) return '';
      const who = event.action_by === whoami.data.id ? 'You' : event.action_by_name;
      const text = getActionToDescription(event.action as any, event, whoami.data);

      if (!text) return '';

      return (
        <strong>
          {who} {text}
        </strong>
      );
    },
    [whoami.data],
  );

  useEffect(() => {
    if (auth.authenticated) {
      getNotifications();
    }
  }, [auth]);

  const handleTransformedData = useCallback(() => {
    if (notifications.data) {
      const data = notifications.data.filter((e: any) => !e.hide && toDescription(e) !== '');

      const tarnsformedData = data.map((e: any) => {
        return {
          ...e,
          notificationMessage: toDescription(e),
          createdAtDiff: moment(e.created_at).fromNow(),
        };
      });

      setNotificationData(tarnsformedData);
    }
  }, [notifications.data, toDescription]);

  useEffect(handleTransformedData, [handleTransformedData]);

  const columns = [
    {
      title: 'Notification',
      dataIndex: 'notificationMessage',
    },
    {
      title: 'Time',
      dataIndex: 'createdAtDiff',
    },
  ];

  return (
    <Spin spinning={isLoading}>
      <h1>Notifications</h1>
      <Table columns={columns} dataSource={notificationData} />
    </Spin>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    auth: state.data.auth,
    notifications: state.data.notifications,
    whoami: state.data.whoami,
    isLoading: isStateLoading(state.action, 'whoami', 'notifications'),
  };
};

export default connect(mapStateToProps)(Notifications);
