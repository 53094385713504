import { CopyOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, Select, Tooltip } from 'antd';
import { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { connect } from 'react-redux';
import { ActionCreator } from 'redux';

import { getUserByEmail } from '~/api/AuthorizedGets';
import { postSharePart } from '~/api/AuthorizedPosts';
import { defaultErrorHandler } from '~/api/HttpError';
import { PART_SHARE_SUCCESS_RESPONSE } from '~/constants';
import { SnackBarOpen, snackBarOpen } from '~/store/actions/ui/SnackBar';
import { useAppSelector } from '~/store/hooks';
import { AppState } from '~/store/reducers';
import { PartItem } from '~/types';

import styles from './PartsShareItemModal.module.scss';

type PropsFromState = {
  authenticated: boolean;
};

type PropsFromDispatch = {
  snackBarOpen: ActionCreator<SnackBarOpen>;
};

type PartsShareItemModalProps = {
  item?: PartItem;
  isOpen: boolean;
  handleCancel: () => void;
} & PropsFromState &
  PropsFromDispatch;

const PartsShareItemModal = (props: PartsShareItemModalProps) => {
  const { item, authenticated } = props;
  const tenantConfig = useAppSelector((state: AppState) => state.data.tenantConfig.data);
  const primaryColor = tenantConfig?.ui_config?.primary_color || '#00D3B2';
  const baseUrl = window.location.origin;
  const linkText = `${baseUrl}/parts/${item?.type}/${item?.id}`;

  const [isCopied, setIsCopied] = useState(false);
  const [form] = Form.useForm();
  const [userEmails, setUserEmails] = useState([] as Array<string>);
  const [userIds, setUserIds] = useState([] as Array<string>);

  const handleCancel = () => {
    setIsCopied(false);
    props.handleCancel();
  };

  const handleShareComponent = () => {
    form.validateFields().then((values) => {
      form.resetFields();
      postSharePart(
        {
          receivers_message: values.message,
          user_ids: userIds,
          user_emails: userEmails,
        },
        defaultErrorHandler,
        String(props.item?.id),
      ).then(() => {
        props.snackBarOpen(PART_SHARE_SUCCESS_RESPONSE);
        handleCancel();
      });
    });
  };

  const renderFooter = () => {
    return authenticated ? (
      <Button type="primary" onClick={handleShareComponent}>
        Share
      </Button>
    ) : (
      <CopyToClipboard key="copy" text={linkText} onCopy={() => setIsCopied(true)}>
        <Button type="primary">{isCopied ? 'Copied' : 'Copy'}</Button>
      </CopyToClipboard>
    );
  };

  const isValidEmail = (email: string) => {
    const rgx =
      /^(([^<>()[\]\\.,;:\s@\\"]+(\.[^<>()[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return rgx.test(email);
  };

  return (
    <Modal
      footer={[renderFooter()]}
      maskClosable={false}
      title="Share Part"
      open={props.isOpen}
      onCancel={handleCancel}
    >
      {authenticated ? (
        <div className={styles.shareWrapper}>
          <p className={styles.title}>Share By Link</p>
          {isCopied && <h4>Copied</h4>}
          <div className={styles.shareLink}>
            <Input value={linkText} disabled />
            <CopyToClipboard key="copy" text={linkText} onCopy={() => setIsCopied(true)}>
              <Tooltip key="bookmark" color={primaryColor} placement="bottom" title="Copy Link">
                <Button icon={<CopyOutlined />} type="primary" />
              </Tooltip>
            </CopyToClipboard>
          </div>
          <p className={styles.divider}>OR Share By Email</p>
          <div className={styles.shareByEmail}>
            <Form form={form} layout="vertical">
              <Form.Item
                label="Emails"
                name="emails"
                rules={[
                  {
                    required: true,
                    message: 'Please input the email!',
                    type: 'array',
                  },
                  {
                    validator: async (_, emails) => {
                      const userIds = [] as Array<string>;
                      const userEmails = [] as Array<string>;
                      emails &&
                        emails.forEach((email: string) => {
                          if (!isValidEmail(email)) {
                            throw new Error(`${email} is not valid email!`);
                          }

                          getUserByEmail(email).then((user: { id: string; name: string }) => {
                            if (user.id) {
                              userIds.push(user.id);
                            } else {
                              userEmails.push(email);
                            }
                          });
                        });
                      setUserIds(userIds);
                      setUserEmails(userEmails);
                    },
                  },
                ]}
              >
                <Select
                  mode="tags"
                  placeholder="Type email and press enter"
                  tokenSeparators={[',']}
                />
              </Form.Item>
              <Form.Item label="Your message" name="message">
                <Input.TextArea />
              </Form.Item>
            </Form>
          </div>
        </div>
      ) : (
        <Input value={linkText} disabled />
      )}
    </Modal>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    authenticated: state.data.auth.authenticated,
  };
};

const mapDispatchToProps = {
  snackBarOpen,
};

export default connect(mapStateToProps, mapDispatchToProps)(PartsShareItemModal);
