/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import Axios, { AxiosError } from 'axios';

import { baseApiUrl } from '../utils/env';

import { baseSVGExporterUrl } from './../utils/env';
import {
  CREATE_CONNECTOR,
  CREATE_USER,
  SEND_FORGOT_PASSWORD_EMAIL,
  CREATE_SUBSCRIPTION,
  INVITE_USER,
  CREATE_PART_ITEM,
  SHARE_PART,
  SAVE_DATA_ASSEMBLY,
  POST_NEW_TAG,
  COPY_ASSEMBLY,
  SHARE_ASSEMBLY,
  POST_EXPORT_SVG,
  CREATE_QUOTE,
  CREATE_QUOTE_RESPONSE,
  CREATE_QUOTE_ORDER,
  AS_BUILT_ASSEMBLY,
} from './../constants/index';
import { AppState } from './../store/reducers/index';
import { makeActionCreator } from './../store/actions/index';
import {
  defaultErrorHandler,
  ErrorHandler,
  handleResponseDataNotJson,
  handleAPIError,
} from './HttpError';
import { store } from '~/store';
import { RcFile } from 'antd/es/upload';

const postApiGenerator =
  (
    route: string,
    name: string,
    successRedirect?: string,
    multipart?: boolean,
    isSVGExporter?: boolean,
  ) =>
  async (data: unknown, errorHandler: ErrorHandler = defaultErrorHandler, id?: string) => {
    const dispatch = store.dispatch as ThunkDispatch<AppState, void, AnyAction>;
    const getState = store.getState as () => AppState;

    const token = getState().data.auth.token;

    // dispatch request action
    dispatch(makeActionCreator(`${name}_REQUEST`)());

    // prepare post request
    let config = {};

    if (multipart) {
      config = {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      };
    } else {
      config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
    }

    if (isSVGExporter) {
      config = {
        ...config,
        responseType: 'blob',
      };
    }

    const dataLayer: unknown[] = [];

    let updatedRoute = route;

    if (id !== undefined) {
      updatedRoute = route + '/' + id;
    }

    const baseUrl = isSVGExporter ? baseSVGExporterUrl : baseApiUrl;

    try {
      const response = await Axios.post(baseUrl + updatedRoute, data, config);
      const responseData = response.data;
      const responseHeaders = response.headers;

      if (responseData instanceof Object || responseHeaders['content-type'] === 'application/pdf') {
        dispatch(makeActionCreator(`${name}_SUCCESS`, 'data')(responseData));

        // redirect on successful create post
        if (successRedirect) {
          if (id && name !== CREATE_CONNECTOR) {
            window.location.replace(successRedirect + '/' + id);
          } else {
            window.location.replace(successRedirect);
          }
        }
      } else {
        handleResponseDataNotJson(dispatch, name, 'POST', dataLayer, route, errorHandler); // Response data is not json
      }
      return responseData;
    } catch (error: any) {
      if ((error as AxiosError).response) {
        handleAPIError(errorHandler, dispatch, error.response!, 'POST', dataLayer, route, name);
      }

      return Promise.reject(error);
    }
  };

export const postCreateUser = postApiGenerator('/_a/user', CREATE_USER, '/successmessage/signup');

export const postInviteUser = postApiGenerator('/_a/users/invite', INVITE_USER);

export const postSendForgotPasswordEmail = postApiGenerator(
  '/_a/users/send-reset-password-email',
  SEND_FORGOT_PASSWORD_EMAIL,
  '/successmessage/forgot',
);

export const postCreateSubscription = postApiGenerator(
  '/_a/user_subscription',
  CREATE_SUBSCRIPTION,
);

export const postCreatePartItem = (partName: string, data: any, files: any[], ...rest: any[]) => {
  const formData = new FormData();
  formData.append(
    'json',
    JSON.stringify({
      type: partName,
      ...data,
    }),
  );

  if (files && files.length) {
    files.forEach((file) => formData.append(file.id, file.data));
  }

  return postApiGenerator(`/_a/part`, CREATE_PART_ITEM, undefined, true)(formData, ...rest);
};

export const postSharePart = postApiGenerator('/_a/part/share', SHARE_PART);

export const postSaveDataAssembly = postApiGenerator('/_a/v2/assemblies', SAVE_DATA_ASSEMBLY);

export const postNewTag = postApiGenerator('/_a/tag', POST_NEW_TAG);

export const postCopyAssembly = postApiGenerator('/_a/v2/assemblies/copy', COPY_ASSEMBLY);

export const postAsBuiltAssembly = postApiGenerator(
  '/_a/v2/assemblies/as-built',
  AS_BUILT_ASSEMBLY,
);

export const postShareAssembly = postApiGenerator('/_a/v2/assemblies/share', SHARE_ASSEMBLY);

// Node Exporter
// export const postExportSVG = postApiGenerator('/export', POST_EXPORT_SVG, undefined, false, true);

export const postExportSVG = postApiGenerator(
  '/_a/v2/assemblies/export',
  POST_EXPORT_SVG,
  undefined,
  false,
  true,
);

export const postCreateQuote = postApiGenerator('/_a/quote/request', CREATE_QUOTE);

export const postCreateQuoteResponse = async (data: any, quoteDocument?: RcFile) => {
  const formData = new FormData();
  formData.append(
    'json',
    JSON.stringify({
      ...data,
    }),
  );

  if (quoteDocument) {
    formData.append('quote_document', quoteDocument);
  }

  return postApiGenerator('/_a/quote/response', CREATE_QUOTE_RESPONSE, undefined, true)(formData);
};

export const postCreateQuoteOrder = postApiGenerator('/_a/quote/order', CREATE_QUOTE_ORDER);
