import { mxCell, mxCellState, mxGraph } from '@anekonnect/mxgraph';

import { ComponentData, Ref, RefFields } from './Types';
import { PreviousGeo } from './Schematics/Cable/Types';
import { isMultiCableType } from './Schematics/Cable/Helper';

import { BaseSize } from '~/store/reducers/wizard/State';
import { mx } from '~/constants/wizard';

type StyleFields = {
  styleName: string;
  styleValue: unknown;
};

type NextGroupVertex = {
  cell: mxCell;
  parent: mxCell;
  pointX?: number;
  pointY?: number;
  key?: string;
  orderCell?: boolean;
  otherSettings?: (c: mxCell) => void;
};

type ReplStyle = {
  name: string;
  value: string;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Possible = any;

type ShareRefs =
  | 'edgeRef'
  | 'connectorPinRef'
  | 'editConnectorModelRef'
  | 'labelsConnectorRef'
  | 'edgeActionRef'
  | 'connectorActionRef';

/**
 * Avoiding 'none' form value
 * @param  {mxCellState} prevState
 * @param  {mxCellState} nextState
 * @return {string} strokeColor
 */
export const avoidNoneValue = (prevState: mxCellState, nextState: mxCellState) => {
  if (prevState.style.strokeColor === 'none') return nextState.style.strokeColor;

  return prevState.style.strokeColor;
};

/**
 * Update and re-render state for cell
 * @param  {mxGraph} graph
 * @param  {mxCell} cell
 * @param  {StyleFields[]} styles
 * @param  {(state:mxCellState)=>void} setAttribute?
 */
export const applyState = (
  graph: mxGraph,
  cell: mxCell,
  styles: StyleFields[],
  setAttribute?: (state: mxCellState) => void,
) => {
  const { mxUtils } = mx;

  const state = graph.view.getState(cell);
  const isFunction = typeof setAttribute === 'function';

  if (state) {
    state.style = mxUtils.clone(state.style);
    styles.map((style) => (state.style[style.styleName] = style.styleValue));

    if (state.shape) {
      state.view.graph.cellRenderer.configureShape(state);
      state.shape.redraw();

      if (setAttribute && isFunction) setAttribute(state);
    }
  }
};

/**
 * Will re-render label value after changed
 * @param  {mxGraph} graph
 * @param  {mxCell[]} cells
 */
export const redrawLabels = (graph: mxGraph, cells: mxCell[]) => {
  if (cells.length) {
    cells.map((cell) => {
      const state = graph.view.getState(cell);

      if (state) {
        const { cellRenderer } = state.view.graph;

        return cellRenderer.redrawLabel(state, true);
      }

      return cell;
    });
  }

  return cells;
};
/**
 * Returns the different elements of an two arrays
 * @param  {Array<T>} otherArray items to compare
 * @param  {string} key object property name
 *
 * @example
 * l = [{ x: '1', y: '2'}]
 * l2 = [{ x: '2', y: '3'}]
 *
 * v = l.filter(comparer(l2, 'x'))
 */
export function comparer<T>(otherArray: Array<T>, key: string) {
  return function (current: Possible) {
    return (
      otherArray.filter(function (other: Possible) {
        return other[key] === current[key];
      }).length === 0
    );
  };
}

/**
 * Clone and create next group vertex
 * @param  {mxGraph} graph
 * @param  {number} index
 * @param  {NextGroupVertex[]} vals
 */
export function createNextGroupVertex(graph: mxGraph, index: number, vals: NextGroupVertex[]) {
  const { mxPoint } = mx;

  if (vals.length) {
    vals.map((v) => {
      const next = v.cell.clone();
      next.geometry.offset = new mxPoint(v.pointX, v.pointY);

      if (v.key) {
        next.setId(`${v.key}_${index}`);
      }
      v.parent.insert(next, index);

      if (v.orderCell !== undefined) {
        graph.orderCells(v.orderCell, [next]);
      }

      if (v.otherSettings && typeof v.otherSettings === 'function') v.otherSettings(next);

      return v;
    });
  }
}
/**
 * Replace cell styles
 * @param  {mxCell} cell
 * @param  {string} styleName
 * @param  {string} newValue
 */
export const replaceStyles = (cell: mxCell, newStyles: ReplStyle[]) => {
  const style = cell.getStyle() === undefined ? '' : cell.getStyle();
  const styles = style.split(';');

  newStyles.map((style) => {
    styles.filter((val) => {
      if (val.includes(style.name)) {
        const currentIndex = styles.indexOf(val);

        if (currentIndex > -1) {
          styles.splice(currentIndex, 1);
        }
      }

      return val;
    });

    return styles.push(`${style.name}=${style.value}`);
  });

  return styles.join(';');
};

export function filterRef<T>(refs: RefFields<T>[], refName: ShareRefs) {
  return refs.filter(({ name, ref }) => {
    if (name === refName) {
      return ref;
    }

    return ref;
  })[0];
}

export function resizable(imgWidth: number, imgHeight: number) {
  let width = 0;
  let height = 0;

  const ratio = imgWidth / imgHeight;

  if (ratio > 1 && ratio < 2) {
    width = imgWidth / 3.5;
    height = imgHeight / 3.5;

    if (width > 300) {
      width = width / 2.5;
      height = height / 2.5;
    }
  } else if (ratio > 3) {
    width = imgWidth / 3;
    height = imgHeight / 3;
  } else {
    width = imgWidth / 2.5;
    height = imgHeight / 2.5;
  }

  return {
    width,
    height,
  };
}

// Only call once
export const callFnOnce =
  (fn: (...fnArgs: Possible) => void, type: string, callFnRef: Ref<boolean>) =>
  (graph: mxGraph, data: ComponentData, parent: mxCell, geometryRef: Ref<PreviousGeo[]>) => {
    if (isMultiCableType(data, type)) {
      if (!callFnRef.current) {
        fn(graph, data, parent, geometryRef);
        callFnRef.current = true;
      } else {
        callFnRef.current = false;
      }
    } else {
      fn(graph, data, parent, geometryRef);
    }
  };

export const getImageDimension = (url: string) => {
  return new Promise<BaseSize>((resolve) => {
    const img = new Image();
    img.onload = function () {
      resolve({ width: img.naturalWidth, height: img.naturalHeight });
    };
    img.src = url;
  });
};

export const isSquare = (width: number, height: number) => {
  return width === height;
};

export const resizeImageByHeight = (imageElement: BaseSize, newHeight: number) => {
  // Get the current width and height of the image
  const currentWidth = imageElement.width;
  const currentHeight = imageElement.height;

  // Calculate the aspect ratio of the image
  const aspectRatio = currentWidth / currentHeight;

  // Calculate the new width based on the given height
  const newWidth = Math.round(newHeight * aspectRatio);

  return { width: newWidth, height: newHeight };
};
