import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import ReactDOM from 'react-dom/client';

import AppRoutes from './AppRoutes';
import { loginSuccess } from './store/actions/data/Auth';
import { store } from './store';

import './index.css';
import './bootstrap-form.scss';
import './icons.scss';
import './ant-customs.scss';
import { BrowserRouter } from 'react-router-dom';

let token = localStorage.getItem('token');
let expiresAt = localStorage.getItem('expires_at');

if (token === null) {
  token = sessionStorage.getItem('token');
  expiresAt = sessionStorage.getItem('expires_at');
}

if (token !== null) {
  store.dispatch(loginSuccess(token, expiresAt));
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <ReduxProvider store={store}>
      <BrowserRouter>
        <AppRoutes />
      </BrowserRouter>
    </ReduxProvider>
  </React.StrictMode>,
);
