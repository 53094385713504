import {
  FullscreenOutlined,
  RedoOutlined,
  UndoOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from '@ant-design/icons';
import { Space } from 'antd';
import { mxGraph, mxUndoManager } from '@anekonnect/mxgraph';
import React from 'react';

import RenderButton from './RenderButton';

type BottomAppControlProps = {
  graph: mxGraph;
  undoManager?: mxUndoManager;
};

const BottomAppControl = ({ graph, undoManager }: BottomAppControlProps) => {
  const handleUndo = () => {
    if (undoManager) {
      undoManager.undo();
    }
  };

  const handleRedo = () => {
    if (undoManager) {
      undoManager.redo();
    }
  };

  return (
    <Space style={{ position: 'fixed', bottom: '13px', left: '13.5px' }}>
      <RenderButton icon={<ZoomInOutlined />} title="Zoom In" onClick={() => graph.zoomIn()} />
      <RenderButton icon={<ZoomOutOutlined />} title="Zoom Out" onClick={() => graph.zoomOut()} />
      <RenderButton
        icon={<FullscreenOutlined />}
        title="Zoom Actual"
        onClick={() => graph.zoomActual()}
      />
      <RenderButton
        disabled={!undoManager}
        icon={<UndoOutlined />}
        title="Undo"
        onClick={handleUndo}
      />
      <RenderButton
        disabled={!undoManager}
        icon={<RedoOutlined />}
        title="Redo"
        onClick={handleRedo}
      />
    </Space>
  );
};

export default BottomAppControl;
