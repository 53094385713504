import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { AxiosResponse } from 'axios';

import { removeToken } from './../utils/token';
import { makeActionCreator } from './../store/actions/index';
import { ERROR } from './../constants/index';
import { snackBarOpen } from './../store/actions/ui/SnackBar';

export type ErrorDetails = {
  what: string;
  who: string;
  code: number;
};

export type ErrorHandler = {
  badRequest: (
    dispatch: ThunkDispatch<unknown, void, AnyAction>,
    errorDetails: ErrorDetails,
  ) => void;
  unauthenticated: (
    dispatch: ThunkDispatch<unknown, void, AnyAction>,
    errorDetails: ErrorDetails,
  ) => void;
  permissionDenied: (
    dispatch: ThunkDispatch<unknown, void, AnyAction>,
    errorDetails: ErrorDetails,
  ) => void;
  pageNotFound: (
    dispatch: ThunkDispatch<unknown, void, AnyAction>,
    errorDetails: ErrorDetails,
  ) => void;
  serverError: (
    dispatch: ThunkDispatch<unknown, void, AnyAction>,
    errorDetails: ErrorDetails,
  ) => void;
  unknownError: (
    dispatch: ThunkDispatch<unknown, void, AnyAction>,
    errorDetails: ErrorDetails,
  ) => void;
};

export const handleBadRequest = (
  dispatch: ThunkDispatch<unknown, void, AnyAction>,
  errorDetails: ErrorDetails,
) => {
  dispatch(snackBarOpen('Action Failed!', ERROR, errorDetails));
};

export const handleUnauthenticated = (dispatch: ThunkDispatch<unknown, void, AnyAction>) => {
  dispatch(snackBarOpen('Unauthorized access! Please login to continue.', ERROR));
  window.location.replace('/login');
};

export const handlePermissionDenied = (dispatch: ThunkDispatch<unknown, void, AnyAction>) => {
  dispatch(snackBarOpen('Permission Denied!', ERROR));
  window.location.replace('/');
};

export const handlePageNotFound = (dispatch: ThunkDispatch<unknown, void, AnyAction>) => {
  dispatch(snackBarOpen('Page not found!', ERROR));
  window.location.replace('/');
};

export const handleServerError = (dispatch: ThunkDispatch<unknown, void, AnyAction>) => {
  dispatch(snackBarOpen('Something went wrong, please try again later!', ERROR));
};

export const handleUnknownError = (
  dispatch: ThunkDispatch<unknown, void, AnyAction>,
  errorDetails: ErrorDetails,
) => {
  dispatch(snackBarOpen('Unkown Error!', ERROR, errorDetails));
};

export const defaultErrorHandler: ErrorHandler = {
  badRequest: handleBadRequest,
  unauthenticated: handleUnauthenticated,
  permissionDenied: handlePermissionDenied,
  pageNotFound: handlePageNotFound,
  serverError: handleServerError,
  unknownError: handleUnknownError,
};

export const handleAPIError = (
  errorHandler: ErrorHandler,
  dispatch: ThunkDispatch<unknown, void, AnyAction>,
  response: AxiosResponse,
  method: string,
  dataLayer: unknown[],
  route: string,
  name: string,
) => {
  dispatch(
    makeActionCreator(`${name}_FAILURE`, 'status', 'statusText')(
      response.status,
      response.data.error.what,
      response.data.error.code,
    ),
  );

  dataLayer.push({
    event: 'clientError',
    apiPath: route,
    errorCode: response.status,
    httpMethod: method,
  });

  switch (response.status) {
    case 400:
      errorHandler.badRequest(dispatch, response.data.error as ErrorDetails);
      break;
    case 401: {
      removeToken();
      errorHandler.unauthenticated(dispatch, response.data.error as ErrorDetails);
      break;
    }
    case 403:
      errorHandler.permissionDenied(dispatch, response.data.error as ErrorDetails);
      break;
    case 404:
      errorHandler.pageNotFound(dispatch, response.data.error as ErrorDetails);
      break;
    case 500:
      errorHandler.serverError(dispatch, response.data.error as ErrorDetails);
      break;
    default:
      errorHandler.unknownError(dispatch, response.data.error as ErrorDetails);
      break;
  }
};

export const handleResponseDataNotJson = (
  dispatch: ThunkDispatch<unknown, void, AnyAction>,
  name: string,
  method: string,
  dataLayer: unknown[],
  route: string,
  errorHandler?: ErrorHandler,
) => {
  if (errorHandler) {
    errorHandler.badRequest(dispatch, {
      code: 400,
      what: 'JSON expected, but received HTML from server.',
      who: '',
    });
  } else {
    dispatch(
      makeActionCreator(
        `${name}_FAILURE`,
        'status',
        'statusText',
      )(400, 'JSON expected, but received HTML from server.'),
    );
  }
  dataLayer.push({
    event: 'clientError',
    apiPath: route,
    errorCode: '400',
    httpMethod: method,
  });
};
