import { mxGraph, mxCell } from '@anekonnect/mxgraph';

import Constant from '../Constant';
import {
  getTotalHeightOfContainer,
  getLabelPin,
  getCenter,
  getPrevHeight,
  getCurrentLastOffsetY,
} from '../Helper';
import { ComponentData, Possible, Ref } from '../../../Types';
import { PreviousGeo } from '../Types';

import { createCableContainer, createCablePin, createLabelPin } from './Core';

import { mx } from '~/constants/wizard';
import { ConfigProp } from '~/store/reducers/configs';

export const twistedPair = (
  graph: mxGraph,
  componentData: ComponentData,
  parent: mxCell,
  geometryRef: Ref<PreviousGeo[]>,
  indexOfCore: number,
  configs: ConfigProp,
) => {
  const { mxPoint } = mx;
  const { defaultAlias, position, type, id, index } = componentData;

  const containerWidth = Constant.cableContainerWidth;

  const rootId = `${type}_schematics_${id}_${index}_draw`;

  const shieldedTpHeight = Constant.cablePinHeight.shieldedTP;
  const totalHeightOfContainer = getTotalHeightOfContainer(componentData);

  let resultOfssets: Possible = [];
  let componentContainer: mxCell | null = null;

  const coreData = componentData.cores[indexOfCore];
  const lastIndex = coreData.count - 1;
  const { gauge, units } = getLabelPin(componentData, 'cores', indexOfCore);
  const prevHeight = getPrevHeight(componentData, indexOfCore);
  const isFirstKey = indexOfCore === 0;

  if (isFirstKey) {
    componentContainer = createCableContainer(
      graph,
      componentData,
      `${rootId}_container`,
      defaultAlias,
      parent,
      totalHeightOfContainer,
      {
        x: position.schematics.x,
        y: position.schematics.y,
      },
      configs,
    );
  } else {
    componentContainer =
      geometryRef.current[indexOfCore - 1]?.componentContainer ||
      geometryRef.current[0]?.componentContainer;
  }

  const children = componentContainer?.children || [];
  let lastChildIndex = children.length;

  for (let index = 0; index < coreData.count; index++) {
    const { currentlastOffsetY } = getCurrentLastOffsetY(
      geometryRef,
      indexOfCore,
      index,
      Constant.cablePinHeight.shieldedTP,
      prevHeight,
    );

    const currentHeight = Constant.cablePinHeight.shieldedTP;

    let lastOffsetY = 0;

    if (isFirstKey) {
      lastOffsetY = currentlastOffsetY;
    } else {
      lastOffsetY = currentlastOffsetY + currentHeight * index;
    }

    if (componentContainer) {
      const connectorPin = createCablePin(
        graph,
        componentData,
        `${rootId}_core_${coreData.type}_${Constant.cablePinId}_${lastChildIndex}`,
        null,
        componentContainer,
        containerWidth,
        shieldedTpHeight,
        lastOffsetY,
        isFirstKey,
      );

      if (index === lastIndex) {
        resultOfssets.push({
          lastOffsetY: connectorPin.geometry.offset.y,
          componentContainer: isFirstKey ? componentContainer : null,
          key: `${indexOfCore}_${coreData.type}`,
        });

        geometryRef.current[indexOfCore] = resultOfssets[0];
        resultOfssets = [];
      }

      createLabelPin(
        graph,
        `${rootId}_${Constant.labelPinId}_${index}`,
        `TP #${gauge} ${units}`,
        Constant.labelPin.width,
        Constant.labelPin.height,
        connectorPin,
      );

      // Implements custom shape (twistedPairShape)
      const twistedPairTop = graph.insertVertex(
        connectorPin,
        `${rootId}_${Constant.twistedPairTopId}_${index}`,
        null,
        0,
        0,
        connectorPin.geometry.width,
        connectorPin.geometry.height,
        `shape=twistedPairShape;align=center;verticalAlign=middle;fillColor=none;strokeColor=#000;rotatable=0;`,
      );
      twistedPairTop.geometry.relative = true;
      twistedPairTop.geometry.offset = new mxPoint(
        getCenter(
          connectorPin.geometry.x,
          connectorPin.geometry.width,
          twistedPairTop.geometry.width,
        ),
        0,
      );
      twistedPairTop.setConnectable(false);

      const twistedPairBottom = twistedPairTop.clone();
      twistedPairBottom.setId(`${rootId}_${Constant.twistedPairBottomId}_${lastChildIndex}`);
      twistedPairBottom.setStyle(twistedPairTop.style + 'rotation=180;');
      twistedPairBottom.geometry.offset.y = 0;
      connectorPin.insert(twistedPairBottom, 0);

      const portLeftA = graph.insertVertex(
        connectorPin,
        `${rootId}_${Constant.portLeftAId}_${index}`,
        'a',
        0,
        0,
        10,
        4,
        'shape=line;align=right;verticalAlign=bottom;fontSize=8;fontColor=#000;strokeColor=#000;rotatable=0;',
      );
      portLeftA.geometry.relative = true;
      portLeftA.geometry.offset = new mxPoint(
        connectorPin.geometry.x - 9.6,
        connectorPin.geometry.height / 2 -
          Constant.cablePinHeight.standard / 2 -
          portLeftA.geometry.height +
          2,
      );
      portLeftA.setConnectable(true);

      const portRightA = graph.insertVertex(
        connectorPin,
        `${rootId}_${Constant.portRightAId}_${index}`,
        'a',
        0,
        0,
        10,
        4,
        'shape=line;align=left;verticalAlign=bottom;fontSize=8;fontColor=#000;strokeColor=#000;rotatable=0;',
      );
      portRightA.geometry.relative = true;
      portRightA.geometry.offset = new mxPoint(
        connectorPin.geometry.width,
        connectorPin.geometry.height / 2 -
          Constant.cablePinHeight.standard / 2 -
          portLeftA.geometry.height +
          2,
      );
      portRightA.setConnectable(true);

      const portLeftB = graph.insertVertex(
        connectorPin,
        `${rootId}_${Constant.portLeftBId}_${index}`,
        'b',
        0,
        0,
        10,
        4,
        'shape=line;align=right;verticalAlign=bottom;fontSize=8;fontColor=#000;strokeColor=#000;rotatable=0;',
      );
      portLeftB.geometry.relative = true;
      portLeftB.geometry.offset = new mxPoint(
        connectorPin.geometry.x - 9.6,
        connectorPin.geometry.height -
          Constant.cablePinHeight.standard / 2 -
          portLeftA.geometry.height +
          2,
      );
      portLeftB.setConnectable(true);

      const portRightB = graph.insertVertex(
        connectorPin,
        `${rootId}_${Constant.portRightBId}_${index}`,
        'b',
        0,
        0,
        10,
        4,
        'shape=line;align=left;verticalAlign=bottom;fontSize=8;fontColor=#000;strokeColor=#000;rotatable=0;',
      );
      portRightB.geometry.relative = true;
      portRightB.geometry.offset = new mxPoint(
        connectorPin.geometry.width,
        connectorPin.geometry.height -
          Constant.cablePinHeight.standard / 2 -
          portLeftA.geometry.height +
          2,
      );
      portRightB.setConnectable(true);

      lastChildIndex++;
    }
  }
};
