import { Filter, MultipleFilter } from '~/api/API';
import { ItemBasicPermissions } from './ItemPermissions';

export type PartFilterFields = Filter | MultipleFilter;

export type PartSelectedFilters = {
  selectedValue: string;
} & PartFilterFields;

export class PartItem implements ItemBasicPermissions {
  can_copy!: boolean;
  can_delete!: boolean;
  can_edit!: boolean;
  can_bookmark!: boolean;
  can_share!: boolean;
  created_at!: Date;
  created_by!: number;
  files!: File[];
  id!: number;
  is_favorite!: boolean;
  name!: string;
  tags?: number[];
  tenant!: string;
  type!: string;
  updated_at!: Date;
  updated_by!: number;
  url_identifier!: string;
  [s: string]: any;

  private _fileMaps: { [key: string]: File };

  constructor(obj: PartItem) {
    if (obj) {
      Object.keys(obj).forEach((key) => {
        this[key] = obj[key];
      });
    }

    if (this.files) {
      this._fileMaps = this.files.reduce((acc, file) => {
        acc[file.type] = file;

        return acc;
      }, {} as { [key: string]: File });
    } else {
      this._fileMaps = {};
    }
  }

  static from(obj: PartItem) {
    return new PartItem(obj);
  }

  static fromArray(objects: PartItem[]) {
    if (!objects) return [];

    return objects.map((obj) => PartItem.from(obj));
  }

  getFileUrl = (fileKey: string | string[]) => {
    const fileKeys = Array.isArray(fileKey) ? fileKey : [fileKey];

    for (let i = 0; i < fileKeys.length; i++) {
      const key = fileKeys[i];

      if (this._fileMaps && this._fileMaps[key]) {
        return this._fileMaps[key].url;
      }
    }

    return null;
  };

  getFileAssetObject = (fileKey: string | string[]) => {
    const fileKeys = Array.isArray(fileKey) ? fileKey : [fileKey];

    for (let i = 0; i < fileKeys.length; i++) {
      const key = fileKeys[i];

      if (this._fileMaps && this._fileMaps[key]) {
        return this._fileMaps[key];
      }
    }

    return null;
  };
}

class File {
  type!: string;
  url!: string;
  checksum!: string;
}
