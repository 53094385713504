import { Collapse, Descriptions, Empty, Grid, Tabs, TabsProps } from 'antd';
import { JSONSchema4 } from 'json-schema';

import { TableData } from './types/TableData';
import { MultiTableDataType, MultiTableType } from './types/TableData';
import { generateData } from './utils/generateData';
import styles from './PartsViewItemData.module.scss';
import ConditionalLink from '~/components/ConditionalLink';
import { isEmpty } from 'lodash';

type PartsViewItemDataProps = {
  schema: JSONSchema4;
  data: {
    [key: string]: JSONSchema4;
  };
};

const { useBreakpoint } = Grid;

const PartsViewItemData = (props: PartsViewItemDataProps) => {
  const { schema, data } = props;
  const { sm } = useBreakpoint();

  if (!schema || !data) {
    return <Empty />;
  }

  const generatedData = generateData(schema, data);

  const renderData = (infoData: TableData) => {
    switch (infoData.rowSchema.type) {
      case 'string':
        return infoData.data as string;
      case 'boolean':
        return (infoData.data as boolean) ? 'Yes' : 'No';
      case 'array':
        return (infoData.data as []).join(', ');
      case 'link':
        return <ConditionalLink title={infoData.data as string} to={infoData.data as string} />;
      case 'image':
        if (!isEmpty(infoData.data)) {
          return (
            <img alt="" height="40px" src={(infoData.data as any).url as string} width="105px" />
          );
        }

        return <img alt="" height="40px" src="/img/logo.png" width="105px" />;

      default:
        return infoData.data;
    }
  };

  const renderInformationData = (informations: TableData[]) => {
    return (
      <Descriptions layout={sm ? 'horizontal' : 'vertical'} bordered>
        {informations.map((infoData, index) => {
          return (
            <Descriptions.Item
              key={index}
              className={styles.labelStyle}
              label={`${infoData.rowSchema.title} ${
                infoData.rowSchema.units ? `(${infoData.rowSchema.units})` : ''
              }`}
              span={3}
            >
              {renderData(infoData)}
            </Descriptions.Item>
          );
        })}
      </Descriptions>
    );
  };

  const getInformationItems = (information: MultiTableDataType) => {
    const items: TabsProps['items'] =
      information.data.map((tabs, index) => {
        return {
          key: String(index),
          label: tabs[0].data,
          children: renderInformationData(tabs),
        };
      }) || [];

    return items;
  };

  return (
    <Collapse defaultActiveKey={['0']}>
      {generatedData.map((data, index) => {
        const information = data as MultiTableDataType;

        let renderInformation = <Empty />;

        if (information.multiTableType === MultiTableType.FLAT) {
          if (information.data[0].length > 0) {
            renderInformation = renderInformationData(information.data[0]);
          }
        }

        if (information.multiTableType === MultiTableType.TABS) {
          if (information.data.length > 0) {
            renderInformation = <Tabs type="card" items={getInformationItems(information)} />;
          }
        }

        if (renderInformation.type === Tabs) {
          if (renderInformation.props.children?.includes(false)) {
            renderInformation = <Empty />;
          }
        }

        return (
          information.title &&
          renderInformation.type !== Empty && (
            <Collapse.Panel key={index} header={information.title}>
              {renderInformation}
            </Collapse.Panel>
          )
        );
      })}
    </Collapse>
  );
};

export default PartsViewItemData;
