import { mxCell } from '@anekonnect/mxgraph';
import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import { isSquare } from '../Helper';

import { GraphProps } from './Types';

import { mx } from '~/constants/wizard';
import { AppState } from '~/store/reducers';
import { PaperSizeData, paperSizeData as defaultPaperSizeData } from '~/utils/paperSize';
import { wizardSetNewTemplatePosition } from '~/store/actions/wizard/Action';
import { BaseSize } from '~/store/reducers/wizard/State';
import { useAppSelector } from '~/store/hooks';

type PropsFromState = {
  paperSize: string;
  paperSizeData: PaperSizeData;
  logoDimension: BaseSize;
  assemblyStatus: string;
};

type PropsFromDispatch = {
  wizardSetNewTemplatePosition: typeof wizardSetNewTemplatePosition;
};

type PickGraphProps = Pick<GraphProps, 'graph'>;

type EventTemplateProps = PickGraphProps & PropsFromState & PropsFromDispatch;

const EventTemplate = ({
  graph,
  paperSize,
  paperSizeData,
  wizardSetNewTemplatePosition,
  logoDimension,
  assemblyStatus,
}: EventTemplateProps) => {
  const { isTenant, data } = useAppSelector((state) => state.data.tenantConfig);
  const UIConfig = data?.ui_config;

  const redrawTemplatePerPageBreak = useCallback(() => {
    const paperData = _.cloneDeep(paperSizeData[paperSize]);
    const templateLogoPosition = paperData.logoGeometry;
    const templateTitleBlockPosition = paperData.titleBlockGeometry;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const changeHandler = (evt: any) => {
      const backgroundImages = graph.getBackgroundImages();

      const templateLogo = graph.model.getCell('template_logo_page_1');
      const templateLogoStyle = templateLogo?.getStyle();
      const templateTitleBlockStyle =
        'text;html=1;strokeColor=none;fillColor=none;align=center;verticalAlign=middle;whiteSpace=wrap;rounded=0;shadow=0;comic=0;fontFamily=Segoe UI;fontSize=12;';
      const templateWatermark = graph.model.getCell('template_watermark_page_1');
      const templateWatermarkStyle = templateWatermark?.getStyle();

      const backgroundImage = backgroundImages.length > 0 ? backgroundImages[0] : undefined;
      const pageWidth = graph.pageFormat.width;
      const pageHeight = graph.pageFormat.height;
      const cells: mxCell[] = Object.values(graph.model.cells);
      const listTitleBlockByTypes: { [key: string]: mxCell } = {};
      const movedCells = evt.properties.cells;

      if (cells && cells.length > 0) {
        const listX = [pageWidth];
        const listY = [pageHeight];

        cells.forEach((cell) => {
          const isTemplateLogo = cell.id.includes('template_logo_page');
          const isTemplateTitleBlock = cell.id.includes('template_title-block');
          const isTemplateWatermark = cell.id.includes('template_watermark_page');

          if (isTemplateLogo || isTemplateWatermark) {
            graph.model.remove(cell);
          } else if (isTemplateTitleBlock) {
            const id = cell.id.split('_');
            const type = id[2];

            if (typeof listTitleBlockByTypes[type] === 'undefined') {
              listTitleBlockByTypes[type] = cell;
            }

            graph.model.remove(cell);
          } else {
            if (cell.geometry) {
              const x = cell.geometry.x + cell.geometry.width;
              const y = cell.geometry.y + cell.geometry.height;
              listX.push(x);
              listY.push(y);
            }
          }
        });

        movedCells.forEach((cell: mxCell) => {
          if (!cell.id) {
            return;
          }

          const isLogo = cell.id.includes('template_logo_page');
          const isTitleBlock = cell.id.includes('template_title-block');
          const { x, y, width, height } = cell.getGeometry();
          const xPage = Math.ceil(x / pageWidth) - 1;
          const yPage = Math.ceil(y / pageHeight) - 1;

          if (isLogo) {
            templateLogoPosition.x = x > pageWidth ? x - pageWidth * xPage : x;
            templateLogoPosition.y = y > pageHeight ? y - pageHeight * yPage : y;
            templateLogoPosition.width = width;
            templateLogoPosition.height = height;

            wizardSetNewTemplatePosition(paperData);
          }

          if (isTitleBlock) {
            const id = cell.id.split('_');
            const type = id[2];

            if (templateTitleBlockPosition[type]) {
              templateTitleBlockPosition[type].x = x > pageWidth ? x - pageWidth * xPage : x;
              templateTitleBlockPosition[type].y = y > pageHeight ? y - pageHeight * yPage : y;
              templateTitleBlockPosition[type].width = width;
              templateTitleBlockPosition[type].height = height;
            } else {
              templateTitleBlockPosition[type] = {
                x: x > pageWidth ? x - pageWidth * xPage : x,
                y: y > pageHeight ? y - pageHeight * yPage : y,
                width,
                height,
              };
            }

            wizardSetNewTemplatePosition(paperData);
          }
        });

        const longestX = Math.max(...listX);
        const longestY = Math.max(...listY);
        const countHorizontalPage = Math.ceil(longestX / pageWidth);
        const countVerticalPage = Math.ceil(longestY / pageHeight);
        const parent = graph.getDefaultParent();
        graph.model.beginUpdate();

        const syncTitleBlockCell = (page: number, pageX: number, pageY: number) => {
          Object.keys(listTitleBlockByTypes).forEach((type) => {
            const data = listTitleBlockByTypes[type];
            const value = data.value;

            let x = 50;
            let y = 50;
            let width = 200;
            let height = 40;

            if (templateTitleBlockPosition[type]) {
              x = templateTitleBlockPosition[type].x;
              y = templateTitleBlockPosition[type].y;
              width = templateTitleBlockPosition[type].width;
              height = templateTitleBlockPosition[type].height;
            }

            const templateTitleBlockName = `template_title-block_${type}_page_${page}`;

            const found = graph.getModel().getCell(templateTitleBlockName);
            const titleBlockX = x + pageX;
            const titleBlockY = y + pageY;

            if (found) {
              const geometry = found.getGeometry();
              geometry.x = titleBlockX;
              geometry.y = titleBlockY;
              geometry.width = width;
              geometry.height = height;
              found.setGeometry(geometry);

              graph.refresh(found);

              return;
            }

            const templateTitleBlockPerPage = graph.insertVertex(
              parent,
              templateTitleBlockName,
              value,
              titleBlockX,
              titleBlockY,
              width,
              height,
              data.style ? data.style : templateTitleBlockStyle,
            );
            templateTitleBlockPerPage.setConnectable(false);
          });
        };

        try {
          let page = 1;
          const newBackgroundImages = [];

          for (let verticalIndex = 0; verticalIndex < countVerticalPage; verticalIndex++) {
            for (
              let horizontalIndex = 0;
              horizontalIndex < countHorizontalPage;
              horizontalIndex++
            ) {
              const pageX = pageWidth * horizontalIndex;
              const pageY = pageHeight * verticalIndex;

              if (backgroundImage) {
                const newBackgroundImage = mx.mxUtils.clone(backgroundImage);
                newBackgroundImage.x = pageX;
                newBackgroundImage.y = pageY;
                newBackgroundImage.width = pageWidth;
                newBackgroundImage.height = pageHeight;
                newBackgroundImages.push(newBackgroundImage);
              }

              const templateLogoName = `template_logo_page_${page}`;
              const logoX = templateLogoPosition.x + pageX;
              const logoY = templateLogoPosition.y + pageY;

              const templateWatermarkName = `template_watermark_page_${page}`;
              const watermarkX = pageWidth / 2.2 + pageX;
              const watermarkY = pageHeight / 2.2 + pageY;

              let width = paperData.logoGeometry.width;
              let height = paperData.logoGeometry.height;
              const defaultWidth = defaultPaperSizeData[paperSize].logoGeometry.width;
              const defaultHeight = defaultPaperSizeData[paperSize].logoGeometry.height;
              const isLogoSquare = isSquare(logoDimension.width, logoDimension.height);
              const isLogoSettingSquare = isSquare(width, height);

              if (logoDimension.width > 0 && logoDimension.height > 0) {
                if (width === defaultWidth || isLogoSquare) {
                  if (!isLogoSettingSquare) {
                    width = logoDimension.width;
                  }
                } else {
                  if (isLogoSettingSquare && !isLogoSquare) {
                    width = logoDimension.width;
                  }
                }

                if (height === defaultHeight || isLogoSquare) {
                  if (!isLogoSettingSquare) {
                    height = logoDimension.height;
                  }
                } else {
                  if (isLogoSettingSquare && !isLogoSquare) {
                    height = logoDimension.height;
                  }
                }
              }

              const orderCells: mxCell[] = [];

              if (templateLogoStyle) {
                const templateLogoPerPage = graph.insertVertex(
                  parent,
                  templateLogoName,
                  null,
                  logoX,
                  logoY,
                  width,
                  height,
                  templateLogoStyle,
                );
                templateLogoPerPage.setConnectable(false);

                orderCells.push(templateLogoPerPage);
              }

              if (isTenant && templateWatermarkStyle) {
                const templateWatermarkPerPage = graph.insertVertex(
                  parent,
                  templateWatermarkName,
                  UIConfig?.assembly_status_mapping
                    ? UIConfig?.assembly_status_mapping[assemblyStatus] ||
                        assemblyStatus.toUpperCase()
                    : assemblyStatus.toUpperCase(),
                  watermarkX,
                  watermarkY,
                  0,
                  0,
                  templateWatermarkStyle,
                );

                orderCells.unshift(templateWatermarkPerPage);
              }

              if (orderCells.length > 0) {
                graph.orderCells(true, orderCells);
              }

              syncTitleBlockCell(page, pageX, pageY);

              page++;
            }
          }

          graph.setBackgroundImages(newBackgroundImages);
        } finally {
          graph.model.endUpdate();
        }
      }
    };

    graph.addListener(mx.mxEvent.CELLS_MOVED, (_sender, evt) => changeHandler(evt));
    graph.addListener(mx.mxEvent.CELLS_RESIZED, (_sender, evt) => changeHandler(evt));
  }, [
    UIConfig?.assembly_status_mapping,
    assemblyStatus,
    graph,
    isTenant,
    logoDimension.height,
    logoDimension.width,
    paperSize,
    paperSizeData,
    wizardSetNewTemplatePosition,
  ]);

  useEffect(redrawTemplatePerPageBreak, [redrawTemplatePerPageBreak]);

  return <React.Fragment />;
};

const mapStateToProps = (state: AppState) => {
  return {
    paperSize: state.assemblyWizard.paperSize,
    paperSizeData: state.assemblyWizard.paperSizeData,
    logoDimension: state.assemblyWizard.logoDimension,
    assemblyStatus: state.assemblyWizard.status,
  };
};

const mapDispatchToProps = {
  wizardSetNewTemplatePosition,
};

export default connect(mapStateToProps, mapDispatchToProps)(EventTemplate);
