import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { isEmpty, isObject } from 'lodash';
import { GraphProps } from '../Graph/Types';
import { ComponentData } from '../Types';
import { comparer } from '../Helper';
import { AppState } from '~/store/reducers';
import { ListComponent, ComponentOptions } from '~/store/reducers/wizard/State';
import { PartItem } from '~/types';
import { getDefaultImageRectangle } from '~/utils/wizard';
import { ObjectShape } from '~/store/reducers/configs';
import { useAppDispatch } from '~/store/hooks';
// import { wizardAddComponent } from '~/store/actions/wizard/Component';
// import { wizardRemoveTabulatedData } from '~/store/actions/wizard/Action';
// import {
//   wizardSetComponentsData,
//   // wizardSetDBComponentsData,
// } from '~/store/actions/wizard/Component';

type SelectedWizardT = {
  index: number;
  key: string;
  component: PartItem;
};

type PropsFromState = {
  components: ListComponent;
  currentActiveId?: number;
  options: ComponentOptions;
};

class File {
  type!: string;
  url!: string;
}

type ConnectComponentsProps = Pick<GraphProps, 'graph'>;
type ComponentInnerProps = ConnectComponentsProps & PropsFromState;

const connectComponents = <T extends ConnectComponentsProps = ConnectComponentsProps>(
  WrappedComponent: React.ComponentType<any>,
  type: string,
  objects: ObjectShape[],
) => {
  const ComponentInner = (props: ComponentInnerProps) => {
    const { graph } = props;

    console.log('connect component');

    const [selectedWizards, setSelectedWizards] = useState<Array<SelectedWizardT>>([]);
    const [componentDataState, setComponentDataState] = useState<ComponentData>({
      index: 0,
      key: '',
      width: 0,
      height: 0,
      defaultAlias: '',
      lengthOfAllItems: 0,
      totalHeightOfContainer: 0,
      hasShell: false,
      shapeFiles: [],
      wizardType: '',
      position: {
        engineeringDrawing: {
          x: 0,
          y: 0,
        },
        schematics: {
          x: 0,
          y: 0,
        },
      },
      options: {
        engineeringDrawing: {
          width: 0,
          height: 0,
          position: {
            x: 0,
            y: 0,
          },
          angle: 0,
          mirror: false,
        },
        schematics: {
          width: 0,
          height: 0,
          position: {
            x: 0,
            y: 0,
          },
          angle: 0,
          mirror: false,
        },
      },
    });
    const [componentFilesState, setComponentFilesState] = useState<File[]>();
    // const [reload, setreload] = useState<any>(true);
    const componentDataRef = useRef<ComponentData[]>([]);
    const componentPositionRef = useRef({
      engineeringDrawing: {
        x: 250,
        y: 90,
      },
      schematics: {
        x: 120,
        y: 90,
      },
    });
    // eslint-disable-next-line
    const dispatch = useAppDispatch();

    const setComponentData = useCallback(() => {
      const margin = 20;
      const reduceHeight = 10;

      if (selectedWizards.length) {
        const result: ComponentData[] = [];
        const prevData = componentDataRef.current;

        selectedWizards.map((item, itemIndex) => {
          const { index, key, component } = item;
          const { contacts, type, defaultAlias, positions } = component;
          const { schematics, engineeringDrawing } = props.options;
          const lengthOfAllItems = +contacts || 0;

          if (itemIndex <= 1) {
            switch (type) {
              case 'connector': {
                // nothing change, default position
                console.log('connect component connector');

                break;
              }
              case 'cable': {
                componentPositionRef.current.engineeringDrawing.x = 350;
                componentPositionRef.current.engineeringDrawing.y = 300;

                componentPositionRef.current.schematics.x = 220;
                componentPositionRef.current.schematics.y = 250;

                break;
              }
              case 'locking_sleeve': {
                componentPositionRef.current.engineeringDrawing.x = 720;
                componentPositionRef.current.engineeringDrawing.y = 90;

                componentPositionRef.current.schematics.x = 350;
                componentPositionRef.current.schematics.y = 90;
                break;
              }
              case 'split_junction': {
                componentPositionRef.current.engineeringDrawing.x = 500;
                componentPositionRef.current.engineeringDrawing.y = 400;

                componentPositionRef.current.schematics.x = 380;
                componentPositionRef.current.schematics.y = 400;

                break;
              }
              default: {
                componentPositionRef.current.engineeringDrawing.x = 580;
                componentPositionRef.current.engineeringDrawing.y = 290;

                componentPositionRef.current.schematics.x = 420;
                componentPositionRef.current.schematics.y = 300;

                break;
              }
            }
          }

          const { engineeringDrawing: engineeringDrawingPosition, schematics: schematicsPosition } =
            componentPositionRef.current;

          result.push({
            index,
            key,
            defaultAlias,
            lengthOfAllItems,
            // 40: default height for rect in schematics
            totalHeightOfContainer:
              (schematics.height - reduceHeight) * lengthOfAllItems +
              (component['has_shell'] ? margin : 0),
            hasShell: component['has_shell'] || false,
            shapeFiles: componentFilesState || [],
            wizardType: type,
            position: {
              engineeringDrawing: { ...engineeringDrawingPosition },
              schematics: { ...schematicsPosition },
            },
            options: {
              engineeringDrawing: {
                width: engineeringDrawing.width,
                height: engineeringDrawing.height,
                position: {
                  x: positions?.engineeringDrawing?.x || 0,
                  y: positions?.engineeringDrawing?.y || 0,
                },
                angle: 0,
                mirror: false,
              },
              schematics: {
                width: schematics.width,
                height: schematics.height - reduceHeight,
                position: {
                  x: positions?.schematics?.x || 0,
                  y: positions?.schematics?.y || 0,
                },
                angle: 0,
                mirror: false,
              },
            },
            ...component,
          });

          return item;
        });

        componentDataRef.current = result;

        const nextData = componentDataRef.current;
        let currentSelectedData = null as unknown as ComponentData;
        console.log(
          componentDataRef.current,
          prevData,
          'prev data length',

          nextData,
          componentDataRef.current,
          !prevData.length,
          // reload,
        );

        if (!prevData.length) {
          console.log('first time', nextData);

          currentSelectedData = nextData[0];
        }

        if (nextData.filter(comparer(prevData, 'key'))[0] === undefined) {
          console.log('second time added');
          currentSelectedData = nextData[0];
          // setComponentDataState(currentSelectedData);
          if (nextData[0]?.type === 'connector') {
            console.log('this is connector');
            // const dummyComponent = {
            //   id: 'dummy-id',
            //   name: 'Dummy Component',
            //   // Other necessary fields
            // };

            // // Dispatch action to add dummy component to graph and Redux state
            // dispatch(wizardAddComponent(nextData[0], 0, undefined));

            // // Dispatch action to remove the dummy component to force graph refresh
            // dispatch(wizardRemoveTabulatedData(nextData[0]));

            // setreload(false);
          }

          // setnewdata(nextData[0]);
          // currentSelectedData = newdata;
        } else {
          currentSelectedData = nextData.filter(comparer(prevData, 'key'))[0];

          // currentSelectedData = nextData.filter((item) => item.key === prevData[0]?.key)[0];
          // currentSelectedData =
          //   nextData.find((nextItem) =>
          //     prevData.some((prevItem) => prevItem.key === nextItem.key),
          //   ) || nextData[0];
          console.log(
            comparer,
            'Debugging keys:',
            nextData[0]?.key,
            prevData[0]?.key,
            nextData.filter(comparer(prevData, 'key'))[0],
            'test',
            nextData.filter((item) => item.key === prevData[0]?.key)[0],
          );

          console.log('third time', nextData, currentSelectedData);
        }

        console.log(currentSelectedData, 'oppppppp');
        if (currentSelectedData) {
          setComponentDataState(currentSelectedData);
          // dispatch(wizardSetDBComponentsData(props.components));
          // dispatch(wizardSetComponentsData(props.components));
        }
      }
    }, [selectedWizards, componentFilesState, props.options]);

    const getSelectedWizards = useCallback(() => {
      const result: SelectedWizardT[] = [];
      console.log('get selected wizard');

      if (props.components && props.components[type]) {
        const componentIds =
          (isObject(props.components[type]) && Object.keys(props.components[type])) || [];
        let files: File[] = [];

        componentIds.map((id: string) => {
          const subIds =
            (isObject(props.components[type][id]) && Object.keys(props.components[type][id])) || [];

          return subIds.map((subId: string, keySubId) => {
            files = [];

            const index = parseInt(subIds[keySubId], 10);

            const key = `${id}_${index}`;
            const component = props.components[type][id][+subId] as PartItem;

            const componentType = component?.type || '';

            const componentSideImages =
              component && component.files
                ? component.files.filter((componentImage: { type: string }) => {
                    return (
                      componentImage.type === 'side_svg' || componentImage.type === 'faceview_svg'
                    );
                  })
                : undefined;

            const componentSideImage =
              !isEmpty(componentSideImages) && componentSideImages !== undefined
                ? componentSideImages[0].url
                : getDefaultImageRectangle(`${componentType}_face`);

            const componentImages =
              component && component.files
                ? component.files.filter((componentImage: { type: string }) => {
                    return (
                      componentImage.type === 'front_svg' || componentImage.type === 'sideview_svg'
                    );
                  })
                : undefined;

            const componentImage =
              !isEmpty(componentImages) && componentImages !== undefined
                ? componentImages[0].url
                : getDefaultImageRectangle(componentType);

            if (componentSideImage) {
              files.push({
                url: componentSideImage,
                type: `${componentType}_face`,
              });
            }

            if (componentImage) {
              files.push({
                url: componentImage,
                type: componentType,
              });
            }

            if (component.files) {
              if (files.length) {
                setComponentFilesState(files);
              }
            } else {
              setComponentFilesState(undefined);
            }

            return result.push({
              index,
              key,
              component,
            });
          });
        });

        setSelectedWizards(result);
      }
    }, [props.components]);

    useEffect(setComponentData, [setComponentData, props.components]);
    useEffect(getSelectedWizards, [getSelectedWizards]);

    const componentProps = {
      graph,
      data: componentDataState,
      objects,
    };

    if (!componentDataState) return <React.Fragment />;
    console.log('check return ');

    return <WrappedComponent {...componentProps} {...(props as unknown as T)} />;
  };

  const mapStateToProps = (state: AppState) => {
    return {
      currentActiveId: state.assemblyWizard.currentActiveId,
      components: state.assemblyWizard.components,
      options: state.assemblyWizard.options,
    };
  };

  return connect(mapStateToProps)(ComponentInner);
};

export default connectComponents;
