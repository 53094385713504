import { mxCell, mxGraph } from '@anekonnect/mxgraph';
import { Button, Space } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import React from 'react';

import { replaceStyles } from '../../Helper';
import { createCurrentEdgeStyle } from '../../Graph/Helper';

import { mx } from '~/constants/wizard';

type ArrangeTabProps = {
  graph: mxGraph;
};

const ArrangeTab = ({ graph }: ArrangeTabProps) => {
  const [selectionCells, setSelectionCells] = useState<mxCell[]>([]);
  const [showUngroup, setShowUngroup] = useState<boolean>(false);
  const [showPinAutomation, setShowPinAutomation] = useState<boolean>(false);

  const selectionEvent = useCallback(() => {
    document.addEventListener('mouseup', () => {
      const selections = graph.getSelectionCells();

      setSelectionCells(selections);
    });
  }, [graph]);

  useEffect(selectionEvent, [selectionEvent]);

  useEffect(() => {
    setShowUngroup(false);

    // Grouping handler
    if (selectionCells.length > 0) {
      const hasChild = selectionCells.every((cell) => {
        return cell.id.includes('group_') && graph.model.getChildCount(cell) > 0;
      });

      if (hasChild) {
        setShowUngroup(true);
      }
    }

    if (selectionCells.length === 2) {
      const isContainer = selectionCells.every((cell) => {
        return cell.id.includes('container_parent');
      });

      if (isContainer) {
        setShowPinAutomation(true);
      }
    }
  }, [selectionCells, graph.model]);

  const handlePinAutomation = () => {
    const isContainer = selectionCells.every((cell) => {
      return cell.id.includes('container_parent');
    });

    if (isContainer) {
      setShowPinAutomation(true);
    }

    const leftComponent =
      selectionCells[0].geometry.x < selectionCells[1].geometry.x
        ? selectionCells[0]
        : selectionCells[1];
    const rightComponent =
      leftComponent === selectionCells[0] ? selectionCells[1] : selectionCells[0];

    const totalLeftPin = leftComponent.children.filter((child) => {
      return child.id.includes('port_right');
    }).length;

    for (let i = 1; i <= totalLeftPin; i++) {
      const leftPin = leftComponent.children.find((child) => {
        return child.id.includes(`port_right_${i}`);
      });

      const rightPin = rightComponent.children.find((child) => {
        return child.id.includes(`port_left_${i}`);
      });

      if (leftPin && rightPin) {
        const parent = graph.getDefaultParent();

        graph.model.beginUpdate();

        try {
          graph.insertEdge(parent, null, '', leftPin, rightPin, createCurrentEdgeStyle());
        } finally {
          graph.model.endUpdate();
        }
      }
    }
  };

  const handleGroup = () => {
    if (graph.isEnabled()) {
      let cells = mx.mxUtils.sortCells(graph.getSelectionCells(), true);

      if (cells.length > 1) {
        cells = graph.getCellsForGroup(cells);

        const group = graph.groupCells(null, 4, cells);
        group.id = `group_${uuidv4()}`;

        const newStyle = replaceStyles(group, [
          {
            name: 'strokeWidth',
            value: '1',
          },
          {
            name: 'strokeColor',
            value: 'none',
          },
        ]);

        group.setStyle(newStyle);

        graph.refresh();

        graph.setSelectionCell(group);

        setShowUngroup(true);
      }
    }
  };

  const handleUngroup = () => {
    if (graph.isEnabled()) {
      const cells = graph.getSelectionCells();

      graph.model.beginUpdate();

      try {
        if (cells != null) {
          for (let i = 0; i < cells.length; i++) {
            if (graph.model.contains(cells[i])) {
              if (graph.model.getChildCount(cells[i]) === 0 && graph.model.isVertex(cells[i])) {
                graph.setCellStyles('container', '0', [cells[i]]);
              }

              graph.ungroupCells([cells[i]]);
            }
          }
        }
      } finally {
        graph.model.endUpdate();
      }

      setShowUngroup(false);
    }
  };

  const isGroupDisabled = () => {
    if (selectionCells.length !== 0) {
      if (selectionCells.length === 1) {
        const hasChild = selectionCells.some((cell) => {
          return cell.id.includes('group_') && graph.model.getChildCount(cell) > 0;
        });

        if (hasChild) {
          return false;
        }

        return true;
      }

      return false;
    }

    return true;
  };

  useEffect(() => {
    graph.getSelectionModel().addListener(mx.mxEvent.CHANGE, () => {
      const cells = graph.getSelectionCells();

      setSelectionCells(cells);
    });
  }, [graph]);

  return (
    <Space wrap>
      <Button
        disabled={isGroupDisabled()}
        type="primary"
        onClick={() => (showUngroup ? handleUngroup() : handleGroup())}
      >
        {showUngroup ? 'Ungroup' : 'Group'}
      </Button>
      <Button disabled={!showPinAutomation} type="primary" onClick={handlePinAutomation}>
        Pin to Pin Automation
      </Button>
    </Space>
  );
};

export default ArrangeTab;
