import { mxCell, mxGraph } from '@anekonnect/mxgraph';

import Constant from '../Constant';
import {
  getTotalHeightOfContainer,
  getLabelPin,
  getCenter,
  getPrevHeight,
  getCurrentLastOffsetY,
} from '../Helper';
import { ComponentData, Possible, Ref } from '../../../Types';
import { PreviousGeo } from '../Types';

import { createCableContainer, createCablePin, createLabelPin } from './Core';

import { mx } from '~/constants/wizard';
import { ConfigProp } from '~/store/reducers/configs';

export const shieldedTP = (
  graph: mxGraph,
  componentData: ComponentData,
  parent: mxCell,
  geometryRef: Ref<PreviousGeo[]>,
  indexOfCore: number,
  configs: ConfigProp,
) => {
  const { mxPoint } = mx;
  const { defaultAlias, position, type, id, index } = componentData;

  let connectorPinWidth = 0;
  let cablePinHeight = 0;

  const totalHeightOfContainer = getTotalHeightOfContainer(componentData);
  const rootId = `${type}_schematics_${id}_${index}_draw`;

  let resultOfssets: Possible = [];
  let componentContainer: mxCell | null = null;

  const coreData = componentData.cores[indexOfCore];
  const isFirstIndexOfCore = indexOfCore === 0;

  if (isFirstIndexOfCore) {
    componentContainer = createCableContainer(
      graph,
      componentData,
      `${rootId}_container`,
      defaultAlias,
      parent,
      totalHeightOfContainer,
      {
        x: position.schematics.x,
        y: position.schematics.y,
      },
      configs,
    );
  } else {
    componentContainer =
      geometryRef.current[indexOfCore - 1]?.componentContainer ||
      geometryRef.current[0]?.componentContainer;
  }

  const children = componentContainer?.children || [];
  let lastChildIndex = children.length;

  const { gauge, units } = getLabelPin(componentData, 'cores', indexOfCore);
  const lastIndex = coreData.count - 1;
  const prevHeight = getPrevHeight(componentData, indexOfCore);

  for (let index = 0; index < coreData.count; index++) {
    const { currentlastOffsetY } = getCurrentLastOffsetY(
      geometryRef,
      indexOfCore,
      index,
      Constant.cablePinHeight.shieldedTP,
      prevHeight,
    );

    const currentHeight = Constant.cablePinHeight.shieldedTP;
    const lastOffsetY = isFirstIndexOfCore
      ? currentlastOffsetY
      : currentlastOffsetY + currentHeight * index;

    if (componentContainer) {
      const connectorPin = createCablePin(
        graph,
        componentData,
        `${rootId}_core_${coreData.type}_${Constant.cablePinId}_${lastChildIndex}`,
        null,
        componentContainer,
        Constant.cableContainerWidth,
        Constant.cablePinHeight.shieldedTP,
        lastOffsetY,
        isFirstIndexOfCore,
      );

      cablePinHeight = connectorPin.geometry.height;
      connectorPinWidth = connectorPin.geometry.width;
      cablePinHeight = connectorPin.geometry.height;

      if (index === lastIndex) {
        resultOfssets.push({
          lastOffsetY: connectorPin.geometry.offset.y,
          componentContainer: isFirstIndexOfCore ? componentContainer : null,
          key: `${indexOfCore}_${coreData.type}`,
        });

        geometryRef.current[indexOfCore] = resultOfssets[0];
        resultOfssets = [];
      }

      createLabelPin(
        graph,
        `${rootId}_${Constant.labelPinId}_${index}`,
        `TP # ${gauge} ${units}`,
        // TODO: Make to const
        64,
        10,
        connectorPin,
      );

      const portTop = graph.insertVertex(
        connectorPin,
        `${rootId}_${Constant.portTopId}_${index}`,
        null,
        0,
        0,
        connectorPinWidth,
        4,
        'shape=line;align=center;verticalAlign=middle;spacingRight=10;fontColor=#000;strokeColor=#000;rotatable=0;',
      );
      portTop.geometry.relative = true;
      portTop.geometry.offset = new mxPoint(connectorPin.geometry.x, 3);
      portTop.setConnectable(false);

      const smallEllipse = graph.insertVertex(
        connectorPin,
        Constant.bezierSmallEllipseId,
        null,
        0,
        0,
        5,
        5,
        `shape=ellipse;align=center;verticalAlign=middle;fillColor=#000;strokeColor=none;rotatable=0;`,
      );
      smallEllipse.geometry.relative = true;
      smallEllipse.geometry.offset = new mxPoint(
        getCenter(connectorPin.geometry.x, connectorPinWidth, smallEllipse.geometry.width),
        3,
      );
      smallEllipse.setConnectable(false);

      const portLeft = graph.insertVertex(
        connectorPin,
        `${rootId}_${Constant.portLeftId}_${index}`,
        null,
        0,
        0,
        10,
        4,
        'shape=line;align=right;verticalAlign=middle;routingCenterX=0;fontColor=#000;strokeColor=#000;rotatable=0;',
      );
      portLeft.geometry.relative = true;
      portLeft.geometry.offset = new mxPoint(-10, 3);
      portLeft.setConnectable(true);

      const portRight = graph.insertVertex(
        connectorPin,
        `${rootId}_${Constant.portRightId}_${index}`,
        null,
        0,
        0,
        10,
        4,
        'shape=line;align=left;verticalAlign=middle;routingCenterX=0;spacingLeft=10;fontColor=#000;strokeColor=#000;rotatable=0;',
      );
      portRight.geometry.relative = true;
      portRight.geometry.offset = new mxPoint(connectorPin.geometry.width, 3);
      portRight.setConnectable(true);

      const bigEllipse = graph.insertVertex(
        connectorPin,
        `${rootId}_${Constant.bigEllipseId}`,
        null,
        0,
        0,
        23,
        cablePinHeight - 10,
        `shape=ellipse;align=center;verticalAlign=middle;fillColor=none;strokeColor=#000;dashed=1;rotatable=0;`,
      );
      bigEllipse.geometry.relative = true;
      bigEllipse.geometry.offset = new mxPoint(
        getCenter(connectorPin.geometry.x, connectorPinWidth, bigEllipse.geometry.width),
        4,
      );
      bigEllipse.setConnectable(false);

      // Implements custom shape (twistedPairShape)
      const twistedPairTop = graph.insertVertex(
        connectorPin,
        Constant.twistedPairTopId,
        null,
        0,
        0,
        connectorPinWidth,
        cablePinHeight,
        `shape=twistedPairShape;align=center;verticalAlign=middle;fillColor=none;strokeColor=#000;rotatable=0;`,
      );
      twistedPairTop.geometry.relative = true;
      twistedPairTop.geometry.offset = new mxPoint(
        getCenter(connectorPin.geometry.x, connectorPinWidth, twistedPairTop.geometry.width),
        0,
      );
      twistedPairTop.setConnectable(false);

      const twistedPairBottom = twistedPairTop.clone();
      twistedPairBottom.setId(`${rootId}_${Constant.twistedPairBottomId}_${lastChildIndex}`);
      twistedPairBottom.setStyle(twistedPairTop.style + 'rotation=180;');
      twistedPairBottom.geometry.offset.y = 0;
      connectorPin.insert(twistedPairBottom, index);

      const portLeftA = graph.insertVertex(
        connectorPin,
        `${rootId}_${Constant.portLeftAId}_${index}`,
        'a',
        0,
        0,
        10,
        4,
        'shape=line;align=right;verticalAlign=bottom;fontSize=7;fontColor=#000;strokeColor=#000;rotatable=0;',
      );
      portLeftA.geometry.relative = true;
      portLeftA.geometry.offset = new mxPoint(
        connectorPin.geometry.x - 9.6,
        cablePinHeight / 2 - Constant.cablePinHeight.standard / 2 - portLeftA.geometry.height + 2,
      );
      portLeftA.setConnectable(true);

      const portRightA = graph.insertVertex(
        connectorPin,
        `${rootId}_${Constant.portRightAId}_${index}`,
        'a',
        0,
        0,
        10,
        4,
        'shape=line;align=left;verticalAlign=bottom;fontSize=8;fontColor=#000;strokeColor=#000;rotatable=0;',
      );
      portRightA.geometry.relative = true;
      portRightA.geometry.offset = new mxPoint(
        connectorPinWidth,
        cablePinHeight / 2 - Constant.cablePinHeight.standard / 2 - portLeftA.geometry.height + 2,
      );
      portRightA.setConnectable(true);

      const portLeftB = graph.insertVertex(
        connectorPin,
        `${rootId}_${Constant.portLeftBId}_${index}`,
        'b',
        0,
        0,
        10,
        4,
        'shape=line;align=right;verticalAlign=bottom;fontSize=8;fontColor=#000;strokeColor=#000;rotatable=0;',
      );
      portLeftB.geometry.relative = true;
      portLeftB.geometry.offset = new mxPoint(
        connectorPin.geometry.x - 9.6,
        cablePinHeight - Constant.cablePinHeight.standard / 2 - portLeftA.geometry.height + 2,
      );
      portLeftB.setConnectable(true);

      const portRightB = graph.insertVertex(
        connectorPin,
        `${rootId}_${Constant.portRightBId}_${index}`,
        'b',
        0,
        0,
        10,
        4,
        'shape=line;align=left;verticalAlign=bottom;fontSize=8;fontColor=#000;strokeColor=#000;rotatable=0;',
      );
      portRightB.geometry.relative = true;
      portRightB.geometry.offset = new mxPoint(
        connectorPinWidth,
        cablePinHeight - Constant.cablePinHeight.standard / 2 - portLeftA.geometry.height + 2,
      );
      portRightB.setConnectable(true);

      lastChildIndex++;
    }
  }
};
