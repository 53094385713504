/* eslint-disable @typescript-eslint/no-explicit-any */

export const objectFromEntries = (arrayValue: any[]) => {
  return arrayValue.reduce((a, c) => {
    a[c[0]] = c[1];

    return a;
  }, {});
};

export const getPaymentStatus = (user: { payment_status?: string; type?: string }) => {
  if (user?.type === 'Admin') {
    return 'Paid';
  }

  return user?.payment_status || 'Trial';
};

export const paymentStatusIsPaid = (paymentStatus = '') => {
  return (
    paymentStatus === 'Paid' ||
    paymentStatus === 'Trial' ||
    paymentStatus === 'PaymentStatus not required'
  );
};

export const isJson = (item: any) => {
  let value = typeof item !== 'string' ? JSON.stringify(item) : item;

  try {
    value = JSON.parse(value);
  } catch (e) {
    return false;
  }

  return typeof value === 'object' && value !== null;
};
