import { Button } from 'antd';
import { useCallback, useEffect, useMemo } from 'react';

import { oauthAuthorization, oauthCallbackToken } from '../api/Authorizations';

import { GoogleLoginButton } from './Buttons/GoogleLoginButton/GoogleLoginButton';
import styles from './OAuthLogin.module.scss';

type OAuthLoginProps = {
  showButtonList?: Array<'google' | 'microsoft'>;
};

const OAuthLogin = ({ showButtonList = ['google', 'microsoft'] }: OAuthLoginProps) => {
  const redirectUrl = useMemo(() => {
    return `${window.location.origin.toString()}/login`;
  }, []);

  const onCode = useCallback(() => {
    const code = (window.location.search.match(/code=([^&]+)/) || [])[1];
    const source = (window.location.search.match(/state=([^&]+)/) || [])[1];

    if (code !== undefined && source !== undefined) {
      oauthCallbackToken(source, code, redirectUrl);
    }
  }, [redirectUrl]);

  useEffect(onCode, [onCode]);

  const getAuthCodeUrl = (source: string) => {
    oauthAuthorization(source, redirectUrl).then((response: string) => {
      // redirect to provider url
      window.location.assign(response);
    });
  };

  return (
    <div className={styles.oauthWrapper}>
      <span
        className={styles.oauthButtons}
        style={{ justifyContent: showButtonList.length === 1 ? 'center' : 'space-between' }}
      >
        {showButtonList.includes('google') && (
          <GoogleLoginButton
            className={styles.googleButton}
            onClick={() => getAuthCodeUrl('google')}
          />
        )}
        {showButtonList.includes('microsoft') && (
          <Button className={styles.microsoftButton} onClick={() => getAuthCodeUrl('microsoft')}>
            <img alt="microsoft login" src="/img/microsoft-icon.svg" />
            Sign in with Microsoft
          </Button>
        )}
      </span>
    </div>
  );
};

export default OAuthLogin;
