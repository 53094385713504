import { mxCell, mxGraph } from '@anekonnect/mxgraph';
import React, { useCallback, useEffect } from 'react';
import { FormInstance } from 'antd/lib/form/Form';
import { capitalize } from 'lodash';

import { Ref, SetState } from '../../Types';

import Constants from './Constants';
import { findEdgeCategories } from './Helper';
import { EdgeRef } from './Types';

import { mx } from '~/constants/wizard';
import { useAppDispatch } from '~/store/hooks';
import { wizardSetShowWireControl } from '~/store/actions/wizard/Component';

type EventsProps = {
  graph: mxGraph;
  forms: {
    editEdgeForm: FormInstance;
  };
  edgeRefs: {
    edgeRef: Ref<EdgeRef>;
    edgeActionDOMRef: Ref<HTMLDivElement | null>;
    selectedEdgesRef: Ref<mxCell[]>;
  };
  setCurrentEdge: SetState<mxCell | null>;
};

const Events = ({ graph, forms, edgeRefs, setCurrentEdge }: EventsProps) => {
  const dispatch = useAppDispatch();

  const handleCloseWireControl = useCallback(() => {
    dispatch(wizardSetShowWireControl(false));
  }, [dispatch]);

  const handleOpenWireControl = useCallback(() => {
    dispatch(wizardSetShowWireControl(true));
  }, [dispatch]);

  const handleEdgeActions = useCallback(
    (cell: mxCell) => {
      const { edgeRef, edgeActionDOMRef, selectedEdgesRef } = edgeRefs;

      const state = graph.view.getState(cell);

      const resetEdges = () => {
        handleCloseWireControl();
        setCurrentEdge(null);

        selectedEdgesRef.current = [];
        edgeRef.current = {
          ...edgeRef.current,
          groupingEdges: [],
          isEdgeSelected: false,
        };
      };

      const setActionPopup = () => {
        // Start point from source
        const isEdge = graph.getModel().isEdge(state.cell);
        const isCableED = state.cell.id.includes('cable_ed');

        // We need this, because option more than one (stroke, trace) color
        // So the solution is get the edge cell using getEdgesBetween
        const edges = graph.getModel().getEdgesBetween(state.cell.source, state.cell.target);

        if (isEdge && !isCableED) {
          handleOpenWireControl();
          setCurrentEdge(cell);

          edgeRef.current = {
            ...edgeRef.current,
            currentEdge: cell,
            groupingEdges: edges,
          };

          if (edges.length) {
            let baseColor = '';
            let tracerColor = '';

            const { defaultEdge, dashedEdge } = findEdgeCategories(edges);

            if (defaultEdge) {
              const defaultState = graph.view.getState(defaultEdge);

              if (!dashedEdge) {
                const defaultState = graph.view.getState(defaultEdge);

                if (defaultState) {
                  baseColor = capitalize(defaultState.style.strokeColor);
                }
                tracerColor = '';
              } else if (dashedEdge) {
                const dashedState = graph.view.getState(dashedEdge);

                if (dashedState && defaultState) {
                  baseColor = capitalize(defaultState.style.strokeColor);
                  tracerColor = capitalize(dashedState.style.strokeColor);
                }
              }

              forms.editEdgeForm.setFieldsValue({
                baseColor,
                tracerColor,
              });
            }
          }
        } else {
          resetEdges();
        }
      };

      if (state) {
        edgeRef.current = {
          ...edgeRef.current,
          points: state.absolutePoints,
          isEdgeSelected: true,
        };

        if (state.absolutePoints && state.absolutePoints[0]) {
          if (edgeActionDOMRef) {
            setActionPopup();
          }
        }

        // Hide popup action when container clicked
        const isEdge = graph.model.isEdge(state.cell);

        if (!isEdge) {
          handleCloseWireControl();
        }
      } else {
        resetEdges();
      }
    },
    [
      edgeRefs,
      graph,
      handleCloseWireControl,
      setCurrentEdge,
      handleOpenWireControl,
      forms.editEdgeForm,
    ],
  );

  const handleOrderCells = useCallback(() => {
    function onRefresh() {
      graph.view.states.visit(function (_key, state) {
        if (graph.model.isEdge(state.cell)) {
          const cell = state.cell;
          const edges = graph.getModel().getEdgesBetween(cell.source, cell.target);

          const { defaultEdge, dashedEdge } = findEdgeCategories(edges);

          if (defaultEdge && dashedEdge) {
            graph.orderCells(false, [defaultEdge, dashedEdge]);
          }
        }
      });
    }

    graph.addListener(mx.mxEvent.REFRESH, onRefresh);

    return () => graph.removeListener(handleOrderCells);
  }, [graph]);

  useEffect(handleOrderCells, [handleOrderCells]);

  const handleMovableEdge = useCallback(() => {
    forms &&
      graph.model.addListener(mx.mxEvent.CHANGE, function (_sender, evt) {
        const changes = evt.getProperty('edit').changes;

        for (let i = 0; i < changes.length; i++) {
          if (changes[i].constructor.name === 'mxGeometryChange') {
            if (changes[i].cell.edge) {
              const edges = graph
                .getModel()
                .getEdgesBetween(changes[i].cell.source, changes[i].cell.target);

              const { defaultEdge, dashedEdge } = findEdgeCategories(edges);

              if (defaultEdge && dashedEdge) {
                const defaultEdgeGeo = defaultEdge.geometry;
                const dashedEdgeGeo = dashedEdge.geometry;

                if (dashedEdgeGeo !== defaultEdgeGeo) {
                  defaultEdge.geometry = dashedEdgeGeo;
                  graph.refresh(defaultEdge);
                }
              }
            }
          }
        }
      });
  }, [graph, forms]);

  useEffect(handleMovableEdge, [handleMovableEdge]);

  const handleWhiteAndDashedEdge = useCallback(() => {
    function handler() {
      graph.view.states.visit(function (_key, state) {
        if (graph.model.isEdge(state.cell)) {
          const stateColor = state.style.strokeColor;
          const cellId = state.cell.id;

          const isWhiteColor = Constants.colorCategoryHaveStroke.includes(stateColor);
          const isDashedEdge = cellId.includes(Constants.dashedEdgeId);

          if (isWhiteColor) {
            state.shape.node.getElementsByTagName('path')[1].setAttribute('class', 'whiteEdge');
          }

          if (isDashedEdge) {
            state.shape.node
              .getElementsByTagName('path')[1]
              .setAttribute('stroke-dasharray', '10 20');
          }
        }
      });
    }
    graph.addListener(mx.mxEvent.SIZE, handler);

    return () => graph.removeListener(handleWhiteAndDashedEdge);
  }, [graph]);

  useEffect(handleWhiteAndDashedEdge, [handleWhiteAndDashedEdge]);

  useEffect(() => {
    graph.addListener(mx.mxEvent.CLICK, function (_sender, evt) {
      const cell = evt.getProperty('cell'); // cell may be null

      if (cell != null) {
        handleEdgeActions(cell);
      } else {
        handleCloseWireControl();
      }
    });
  }, [graph, handleEdgeActions, dispatch, handleCloseWireControl]);

  return <React.Fragment />;
};

export default Events;
