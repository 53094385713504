import {
  EditOutlined,
  CopyOutlined,
  BookOutlined,
  ShareAltOutlined,
  BookFilled,
} from '@ant-design/icons';
import { Card, List, Tooltip, Image, Badge } from 'antd';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { Part } from '~/api/API';
import {
  GET_LINK_COPY_PARTS_ITEM,
  GET_LINK_EDIT_PARTS_ITEM,
  GET_LINK_VIEW_PARTS_ITEM,
} from '~/constants/paths';
import { useAppSelector } from '~/store/hooks';
import { AppState } from '~/store/reducers';
import { PartItem } from '~/types';
import { getPropertyArray } from '~/utils/card';
import { hasItemAccessOf, selectPartByPartName } from '~/utils/parts';

import styles from './PartsItemCard.module.scss';
import PartsShareItemModal from './PartsShareItemModal';

type PartsItemCardProps = {
  parts: Part[];
  partItems: PartItem[];
  isLoading: boolean;
  handleBookmark: (item: PartItem) => void;
};

const PartsItemCard: React.FC<PartsItemCardProps> = (props) => {
  const navigate = useNavigate();
  const permissions = useAppSelector((state: AppState) => state.data.whoami.data?.permissions);
  const tenantConfig = useAppSelector((state: AppState) => state.data.tenantConfig.data);
  const primaryColor = tenantConfig?.ui_config?.primary_color || '#00D3B2';

  const [openShareModal, setOpenShareModal] = React.useState(false);
  const [partItem, setPartItem] = React.useState<PartItem>();

  return (
    <React.Fragment>
      <PartsShareItemModal
        handleCancel={() => setOpenShareModal(false)}
        isOpen={openShareModal}
        item={partItem}
      />
      <List
        dataSource={props.partItems}
        grid={{
          gutter: 16,
          xs: 1,
          sm: 2,
          md: 3,
          lg: 4,
          xl: 5,
          xxl: 6,
        }}
        loading={props.isLoading}
        renderItem={(item) => {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const part = selectPartByPartName(props.parts, item.type)!;

          if (part) {
            const cardSchema = JSON.parse(part.card_schema);
            const propertyArray = getPropertyArray(cardSchema.properties, item);

            const defaultImage = {
              cable: '/img/cable-generic.png',
              default: '/img/parts-generic.png',
            };
            const media =
              item.getFileUrl(part.card_display_types) ||
              (defaultImage as any)[part.name] ||
              (defaultImage as any)['default'];

            const actions: JSX.Element[] = [];

            const canCopy = hasItemAccessOf(item, 'COPY');
            const canBookmark = hasItemAccessOf(item, 'BOOKMARK');
            const canShare = hasItemAccessOf(item, 'SHARE');

            if (permissions?.includes('WriteAllPart') && !item.library)
              actions.push(
                <Tooltip
                  key="edit"
                  color={primaryColor}
                  placement="bottom"
                  title={`Edit ${part.name}`}
                >
                  <EditOutlined
                    onClick={() => navigate(GET_LINK_EDIT_PARTS_ITEM(part.name, item.id))}
                  />
                </Tooltip>,
              );

            if (canCopy)
              actions.push(
                <Tooltip
                  key="copy"
                  color={primaryColor}
                  placement="bottom"
                  title={`Copy ${part.name}`}
                >
                  <CopyOutlined
                    onClick={() => navigate(GET_LINK_COPY_PARTS_ITEM(part.name, item.id))}
                  />
                </Tooltip>,
              );

            if (canBookmark)
              actions.push(
                <Tooltip
                  key="bookmark"
                  color={primaryColor}
                  placement="bottom"
                  title={`Bookmark ${part.name}`}
                >
                  {item.is_favorite ? (
                    <BookFilled key="bookmark" onClick={() => props.handleBookmark(item)} />
                  ) : (
                    <BookOutlined key="bookmark" onClick={() => props.handleBookmark(item)} />
                  )}
                </Tooltip>,
              );

            if (canShare)
              actions.push(
                <Tooltip
                  key="share"
                  color={primaryColor}
                  placement="bottom"
                  title={`Share ${part.name}`}
                >
                  <ShareAltOutlined
                    key="share"
                    onClick={() => {
                      setPartItem(item);
                      setOpenShareModal(true);
                    }}
                  />
                </Tooltip>,
              );

            return (
              <List.Item>
                {item?.library && (
                  <Badge
                    dot
                    color={primaryColor}
                    style={{ position: 'absolute', zIndex: 1, right: '15px' }}
                  />
                )}
                <Card actions={actions} className={styles.card} title={item.name}>
                  <Link
                    className={styles.cardBody}
                    to={GET_LINK_VIEW_PARTS_ITEM(part.name, item.url_identifier)}
                  >
                    <Image className={styles.cardImage} preview={false} src={media} />
                    <div className={styles.cardOverlay}>
                      {propertyArray.map((item: { label: any; value: any }) => {
                        const { label, value } = item;

                        return (
                          <div key={label} className={styles.cardOverlayTable}>
                            <div className={styles.cardOverlayTableLabel}>{label}</div>
                            <div className={styles.cardOverlayTableValue}>{value}</div>
                          </div>
                        );
                      })}
                    </div>
                  </Link>
                </Card>
              </List.Item>
            );
          }
        }}
      />
    </React.Fragment>
  );
};

export default PartsItemCard;
