import { Divider, Space, Table, Typography } from 'antd';
import moment from 'moment';
import { useCallback } from 'react';

const requestedQuotesColumn = [
  {
    title: 'Quote ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Quantity Requested',
    dataIndex: 'quantity',
    key: 'quantity',
  },
  {
    title: 'Need By Date',
    dataIndex: 'expected_date',
    key: 'expected_date',
    render: (text: string) => moment(text).format('LL'),
  },
  {
    title: 'Preferred Currency',
    dataIndex: 'preferred_currency',
    key: 'preferred_currency',
  },
  {
    title: 'Date Requested',
    dataIndex: 'creation_date',
    key: 'creation_date',
    render: (text: string) => moment(text).format('LL'),
  },
];

type QuotesHistoryType = {
  requestedQuotes: any[];
};

const QuotesHistory = ({ requestedQuotes }: QuotesHistoryType) => {
  const renderDetailsItem = useCallback((record: any) => {
    return (
      <Space direction="vertical">
        <p>
          <b>Destination Address:</b> {record.destination_address.line_1},{' '}
          {record.destination_address.line_2}, {record.destination_address.city},{' '}
          {record.destination_address.state}, {record.destination_address.zip_code},{' '}
          {record.destination_address.country}
        </p>
        <p>
          <b>Billing Address:</b> {record.billing_address.line_1}, {record.billing_address.line_2},{' '}
          {record.billing_address.city}, {record.billing_address.state},{' '}
          {record.billing_address.zip_code}, {record.billing_address.country}
        </p>
        <p>
          <b>Country of Usage:</b> {record.country_of_usage}
        </p>
        <p>
          <b>Components Provided:</b> {record.user_provided_components || '-'}
        </p>
        <p>
          <b>Notes:</b> {record.notes || '-'}
        </p>
      </Space>
    );
  }, []);

  return (
    <>
      <Typography.Title level={3} style={{ textAlign: 'center' }}>
        Quotes History
      </Typography.Title>
      <Table
        dataSource={requestedQuotes}
        columns={requestedQuotesColumn}
        expandable={{
          expandedRowRender: (record: any) => renderDetailsItem(record),
        }}
        pagination={{ pageSize: 5 }}
      />
      <Divider />
    </>
  );
};

export default QuotesHistory;
