import { combineReducers } from 'redux';

import {
  browserSupportReducer,
  BrowserSupportState,
  initialBrowserSupportState,
} from './BrowserSupport';
import { initialSideMenuBarState, sideMenuBarReducer, SideMenuBarState } from './SideMenuBar';
import { SnackBarState, snackBarReducer, initialSnackBarState } from './SnackBar';

export type UIState = {
  snackBar: SnackBarState;
  sideMenuBar: SideMenuBarState;
  browserSupport: BrowserSupportState;
};

export const initialUIState = {
  snackBar: initialSnackBarState,
  sideMenuBar: initialSideMenuBarState,
  browserSupport: initialBrowserSupportState,
};

export const uiReducers = combineReducers<UIState>({
  snackBar: snackBarReducer,
  sideMenuBar: sideMenuBarReducer,
  browserSupport: browserSupportReducer,
});
