import { Form, Input, Button, Checkbox, Row, Col, Spin, Divider } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { Link, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';

import { loginUser } from '~/api/Authorizations';
import OAuthLogin from '~/components/OAuthLogin';
import { AppState } from '~/store/reducers';
import { ActionState } from '~/store/reducers/Actions';
import { FAILURE } from '~/constants';
import { isStateLoading } from '~/utils/state';

import styles from './Login.module.scss';

type OwnProps = {
  action: ActionState;
  isLoading: boolean;
  isTenant?: boolean;
  authSystemConfig: string;
};

type LoginProps = OwnProps;

type valuesProps = {
  username: string;
  password: string;
  rememberMe: boolean;
};

const Login = ({ action, isLoading, isTenant, authSystemConfig }: LoginProps) => {
  const location = useLocation();
  let authSystem = authSystemConfig;

  if (isTenant && location.pathname === '/anekonnect-login') {
    authSystem = 'anekonnect';
  }

  const onFinish = (values: valuesProps) => {
    loginUser(values.username, values.password, values.rememberMe);
  };

  return (
    <div className={styles.signBox}>
      <Spin spinning={isLoading || !authSystem}>
        <Row>
          <Col offset={3} span={18}>
            <h1 className={styles.title}>Account Login</h1>
            {action.result === FAILURE && (
              <h3 className={styles.errorMessage}>{action.statusText}</h3>
            )}
            {authSystem === 'anekonnect' ? (
              <>
                {isTenant === false && <OAuthLogin />}
                <Divider>or</Divider>
                <Form
                  className="login-form"
                  initialValues={{ rememberMe: true }}
                  name="login"
                  onFinish={onFinish}
                >
                  <Form.Item
                    name="username"
                    rules={[{ required: true, message: 'Please input your Email or Username!' }]}
                  >
                    <Input placeholder="Email or Username" prefix={<UserOutlined />} size="large" />
                  </Form.Item>

                  <Form.Item
                    name="password"
                    rules={[{ required: true, message: 'Please input your Password!' }]}
                  >
                    <Input.Password placeholder="Password" prefix={<LockOutlined />} size="large" />
                  </Form.Item>

                  <Form.Item>
                    <Form.Item name="rememberMe" valuePropName="checked" noStyle>
                      <Checkbox>Remember me</Checkbox>
                    </Form.Item>

                    <Link className={styles.forgotPassword} to="/forgot-password">
                      Forgot password
                    </Link>
                  </Form.Item>

                  <Form.Item>
                    <Button
                      className={styles.loginButton}
                      htmlType="submit"
                      size="large"
                      type="primary"
                    >
                      Sign In
                    </Button>
                  </Form.Item>
                </Form>
              </>
            ) : (
              (authSystem === 'google' || authSystem === 'microsoft') && (
                <OAuthLogin showButtonList={[authSystem]} />
              )
            )}
            {isTenant === false && authSystem === 'anekonnect' && (
              <div className={styles.signUpWrapper}>
                <span>
                  Don't have an account?{' '}
                  <Link className={styles.signUpLink} to="/signup">
                    Sign Up
                  </Link>
                </span>
              </div>
            )}
          </Col>
        </Row>
      </Spin>
    </div>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    action: state.action.login,
    isLoading: isStateLoading(state.action, 'login', 'oauth'),
    isTenant: state.data.tenantConfig.isTenant,
    authSystemConfig: state.data.tenantConfig?.data?.auth_system,
  };
};

export default connect(mapStateToProps)(Login);
