import { Card, Col, Row } from 'antd';
import React from 'react';

import styles from './Plans.module.scss';

type Plan = {
  id: string;
  amount: number;
  interval: number;
};

type PlansProps = {
  amount: number;
  plans: Plan[];
  currentPlan: Plan;
  planSwitcher: (plan: Plan, amount: number) => void;
};

const Plans: React.FC<PlansProps> = (props) => {
  const { plans, currentPlan } = props;

  return (
    <div className={styles.plansSection}>
      <Row gutter={[16, 16]} justify="center">
        {plans.map((plan: any) => {
          const amount = plan.amount / 100;
          const duration = plan.interval;

          return (
            <Col lg={12} md={24} sm={24} xs={24}>
              <Card
                key={plan.id}
                className={`${styles.card} ${currentPlan.id === plan.id ? styles.active : ''}`}
                onClick={() => props.planSwitcher(plan, amount)}
              >
                {duration === 'year' && (
                  <div className={styles.bestValue}>
                    <span>BEST VALUE</span>
                  </div>
                )}
                <p className={styles.planTitle}>{plan.nickname}</p>
                <p className={styles.planPrice}>${amount}</p>
                <span>
                  {duration === 'year'
                    ? '20% discount compared to monthly subscription. Charged annually.'
                    : 'Charged monthly till subscription canceled or account closed.'}
                </span>
              </Card>
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

export default Plans;
