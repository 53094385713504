import { mxGraph, mxCell } from '@anekonnect/mxgraph';
import { range } from 'lodash';

import Constant from '../Constant';
import {
  getTotalHeightOfContainer,
  getLabelPin,
  getCenter,
  getPrevHeight,
  getCurrentLastOffsetY,
} from '../Helper';
import { ComponentData, Possible, Ref } from '../../../Types';
import { PreviousGeo } from '../Types';

import { createCableContainer, createCablePin, createLabelPin } from './Core';

import { mx } from '~/constants/wizard';
import { ConfigProp } from '~/store/reducers/configs';

export const shieldedTriTwisted = (
  graph: mxGraph,
  componentData: ComponentData,
  parent: mxCell,
  geometryRef: Ref<PreviousGeo[]>,
  indexOfCore: number,
  configs: ConfigProp,
) => {
  const { mxPoint } = mx;
  const { defaultAlias, position, type, id, index } = componentData;

  const endTerminalPointLeft = '-0.5';
  const endTerminalPointRight = '0.5';

  const totalHeightOfContainer = getTotalHeightOfContainer(componentData);
  const rootId = `${type}_schematics_${id}_${index}_draw`;
  const countCellsQT = 3;

  let resultOfssets: Possible = [];
  let componentContainer: mxCell | null = null;
  let firstPortLeft = null;

  const coreData = componentData.cores[indexOfCore];
  const isFirstKey = indexOfCore === 0;

  if (isFirstKey) {
    componentContainer = createCableContainer(
      graph,
      componentData,
      `${rootId}_container`,
      defaultAlias,
      parent,
      totalHeightOfContainer,
      {
        x: position.schematics.x,
        y: position.schematics.y,
      },
      configs,
    );
  } else {
    componentContainer =
      geometryRef.current[indexOfCore - 1]?.componentContainer ||
      geometryRef.current[0]?.componentContainer;
  }

  const children = componentContainer?.children || [];
  let lastChildIndex = children.length;

  const { gauge, units } = getLabelPin(componentData, 'cores', indexOfCore);
  const prevHeight = getPrevHeight(componentData, indexOfCore);
  const totalCable = coreData.count * countCellsQT;
  const lastIndex = totalCable - 1;

  const countRanges = range(0, totalCable, countCellsQT);

  for (let index = 0; index < totalCable; index++) {
    const stepIndex = countRanges.includes(index);
    const { currentlastOffsetY } = getCurrentLastOffsetY(
      geometryRef,
      indexOfCore,
      index,
      Constant.cablePinHeight.shieldedTriTwisted,
      prevHeight,
    );

    if (componentContainer) {
      const connectorPin = createCablePin(
        graph,
        componentData,
        `${rootId}_core_${coreData.type}_${Constant.cablePinId}_${lastChildIndex}`,
        null,
        componentContainer,
        Constant.cableContainerWidth,
        Constant.cablePinHeight.shieldedTriTwisted,
        isFirstKey
          ? currentlastOffsetY
          : currentlastOffsetY + Constant.cablePinHeight.shieldedTriTwisted * index,
        isFirstKey,
      );
      lastChildIndex++;
      graph.orderCells(true, [connectorPin]);

      const labelPin = createLabelPin(
        graph,
        `${rootId}_${Constant.labelPinId}_${index}`,
        `${gauge} ${units}`,
        // TODO: Make to const
        40,
        10,
        connectorPin,
      );

      const portLeftInside = graph.insertVertex(
        connectorPin,
        `${rootId}_${Constant.portLeftInsideId}_${index}`,
        null,
        0,
        0,
        (connectorPin.geometry.width - labelPin.geometry.width) / 2,
        4,
        'shape=line;align=right;verticalAlign=middle;routingCenterX=' +
          endTerminalPointLeft +
          ';fontColor=#000;strokeColor=#000;rotatable=0;',
      );
      portLeftInside.geometry.relative = true;
      portLeftInside.geometry.offset = new mxPoint(
        0,
        getCenter(
          connectorPin.geometry.y,
          connectorPin.geometry.height,
          portLeftInside.geometry.height,
        ),
      );
      portLeftInside.setConnectable(false);
      firstPortLeft = portLeftInside;

      if (stepIndex) {
        const portTop = graph.insertVertex(
          connectorPin,
          `${rootId}_${Constant.portTopId}_${index}`,
          null,
          0,
          0,
          connectorPin.geometry.width,
          4,
          'shape=line;align=center;verticalAlign=middle;spacingRight=10;fontColor=#000;strokeColor=#000;rotatable=0;',
          true,
        );
        portTop.geometry.relative = true;
        portTop.geometry.offset = new mxPoint(connectorPin.geometry.offset.x, 1.5);
        portTop.setConnectable(false);

        const smallEllipse = graph.insertVertex(
          connectorPin,
          Constant.bezierSmallEllipseId,
          null,
          0,
          0,
          4,
          4,
          `shape=ellipse;align=center;verticalAlign=middle;fillColor=#000;strokeColor=none;rotatable=0;`,
        );
        smallEllipse.geometry.relative = true;
        smallEllipse.geometry.offset = new mxPoint(connectorPin.geometry.width / 2, 1.5);
        smallEllipse.setConnectable(false);
        graph.orderCells(false, [smallEllipse]);

        const portLeftOnTop = graph.insertVertex(
          connectorPin,
          `${rootId}_${Constant.portLeftOnTopId}_${index}`,
          null,
          0,
          0,
          8,
          4,
          'shape=line;align=right;verticalAlign=middle;routingCenterX=0;fontColor=#000;strokeColor=#000;rotatable=0;',
        );
        portLeftOnTop.geometry.relative = true;
        portLeftOnTop.geometry.offset = new mxPoint(-8, 1.5);
        portLeftOnTop.setConnectable(true);

        const portRightOnTop = graph.insertVertex(
          connectorPin,
          `${rootId}_${Constant.portRightOnTopId}_${index}`,
          null,
          0,
          0,
          8,
          4,
          'shape=line;align=left;verticalAlign=middle;routingCenterX=0;spacingLeft=10;fontColor=#000;strokeColor=#000;rotatable=0;',
        );
        portRightOnTop.geometry.relative = true;
        portRightOnTop.geometry.offset = new mxPoint(connectorPin.geometry.width, 1.5);
        portRightOnTop.setConnectable(true);

        let cableTriTwistedOffsetY = 0;

        if (firstPortLeft) {
          const shieldedTriTwistedHeight = Constant.cablePinHeight.shieldedTriTwisted;
          // Implements custom shape (Tri Twisted Shape)
          const cableTriTwisted = graph.insertVertex(
            componentContainer,
            `${rootId}_${Constant.cableQuadTwistedShapeId}_${index}`,
            null,
            5,
            connectorPin.geometry.offset.y + firstPortLeft.geometry.offset.y,
            10,
            shieldedTriTwistedHeight * countCellsQT - shieldedTriTwistedHeight / 2,
            `shape=quadTwistedShape;align=center;verticalAlign=middle;fillColor=none;strokeColor=#000;rotatable=0;`,
          );
          cableTriTwisted.geometry.relative = false;
          cableTriTwistedOffsetY =
            cableTriTwisted.geometry.y - firstPortLeft.geometry.offset.y + countCellsQT;
          cableTriTwisted.setConnectable(false);
        }

        const ellipseCenter = graph.insertVertex(
          componentContainer,
          `${rootId}_${Constant.bigEllipseId}`,
          null,
          0,
          0,
          23,
          Constant.cablePinHeight.shieldedTriTwisted * countCellsQT - 8,
          `shape=ellipse;align=center;verticalAlign=middle;fillColor=none;strokeColor=#000;dashed=1;rotatable=0;`,
        );
        ellipseCenter.geometry.relative = true;
        ellipseCenter.geometry.offset = new mxPoint(
          getCenter(
            connectorPin.geometry.x,
            connectorPin.geometry.width,
            ellipseCenter.geometry.width,
          ),
          cableTriTwistedOffsetY,
        );
        ellipseCenter.setConnectable(false);
      }

      if (lastIndex === index) {
        resultOfssets.push({
          lastOffsetY: connectorPin.geometry.offset.y,
          componentContainer: isFirstKey ? componentContainer : null,
          key: `${indexOfCore}_${coreData.type}`,
        });

        geometryRef.current[indexOfCore] = resultOfssets[0];
        resultOfssets = [];
      }

      const portLeftOutside = graph.insertVertex(
        connectorPin,
        `${rootId}_${Constant.portLeftOutsideId}_${index}`,
        null,
        0,
        0,
        8,
        4,
        'shape=line;align=right;verticalAlign=middle;routingCenterX=' +
          endTerminalPointLeft +
          ';fontColor=#000;strokeColor=#000;rotatable=0;',
      );
      portLeftOutside.geometry.relative = true;
      portLeftOutside.geometry.offset = new mxPoint(
        -8,
        getCenter(
          connectorPin.geometry.y,
          connectorPin.geometry.height,
          portLeftOutside.geometry.height,
        ),
      );
      portLeftOutside.setConnectable(true);

      const portRightInside = graph.insertVertex(
        connectorPin,
        `${rootId}_${Constant.portRightInsideId}_${index}`,
        null,
        0,
        0,
        (connectorPin.geometry.width - labelPin.geometry.width) / 2,
        4,
        `shape=line;align=left;verticalAlign=middle;routingCenterX=${endTerminalPointRight};spacingLeft=10;fontColor=${Constant.baseColor};strokeColor=${Constant.baseColor};rotatable=0;`,
      );
      portRightInside.geometry.relative = true;
      portRightInside.geometry.offset = new mxPoint(
        connectorPin.geometry.width - labelPin.geometry.offset.x,
        getCenter(
          connectorPin.geometry.y,
          connectorPin.geometry.height,
          portRightInside.geometry.height,
        ),
      );
      portRightInside.setConnectable(false);
      const portRightOutside = graph.insertVertex(
        connectorPin,
        `${rootId}_${Constant.portRightOutsideId}_${index}`,
        null,
        0,
        0,
        8,
        4,
        `shape=line;align=left;verticalAlign=middle;routingCenterX=${endTerminalPointRight};spacingLeft=10;fontColor=${Constant.baseColor};strokeColor=${Constant.baseColor};rotatable=0;`,
      );
      portRightOutside.geometry.relative = true;
      portRightOutside.geometry.offset = new mxPoint(
        connectorPin.geometry.width,
        getCenter(
          connectorPin.geometry.y,
          connectorPin.geometry.height,
          portRightInside.geometry.height,
        ),
      );
      portRightOutside.setConnectable(true);
    }
  }
};
